package ui.components

import androidx.compose.runtime.Composable
import org.jetbrains.compose.web.dom.AttrBuilderContext
import org.jetbrains.compose.web.dom.Div
import org.w3c.dom.HTMLDivElement
import tailwind.Layout
import tailwind.TailwindScope
import tailwind.color.Opacity090
import tailwind.color.White
import tailwind.tailwind
import ui.color.BlackMedium
import ui.material.Column

@Composable
fun Tooltip(
    attrs: AttrBuilderContext<HTMLDivElement>? = null,
    content: @Composable () -> Unit,
) {
  Div(
      attrs = {
        attrs?.invoke(this)
        classes("tooltip-item")
        tailwind {
          position(TailwindScope.Position.Absolute)

          py(2)
          px(4)

          round(TailwindScope.Radius.Medium)
          shadow(TailwindScope.Shadow.Lg)

          background(color = BlackMedium, opacity = Opacity090)
          text(color = White, opacity = Opacity090)
          select(TailwindScope.SelectType.None)

          visibility(TailwindScope.Visibility.Invisible)
        }
      }) {
        content()
      }
}

@Composable
fun TooltipContainer(
    attrs: AttrBuilderContext<HTMLDivElement>? = null,
    content: @Composable () -> Unit
) {
  Column(
      justify = Layout.Justify.Center,
      alignItems = Layout.AlignItems.Center,
      attrs = {
        attrs?.invoke(this)
        classes("tooltip")
        tailwind { position(TailwindScope.Position.Relative) }
      }) {
        content()
      }
}
