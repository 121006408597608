package ui.screens.memberDetail

import androidx.compose.runtime.Composable
import api.traak.Recap
import kotlinx.datetime.LocalDateTime
import kotlinx.datetime.TimeZone
import kotlinx.datetime.toLocalDateTime
import org.jetbrains.compose.web.dom.AttrBuilderContext
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.H6
import org.jetbrains.compose.web.dom.Text
import org.w3c.dom.HTMLDivElement
import tailwind.TextScope
import tailwind.tailwind
import ui.color.Secondary400
import ui.data.Loadable
import ui.data.toList
import ui.material.Card
import ui.material.Column
import ui.material.FullColumn
import ui.material.FullRow
import ui.strings.LocalStrings
import utils.endOfWeek
import utils.padWith0
import utils.startOfWeek

private fun LocalDateTime.toFormattedString(): String =
    "${this.dayOfMonth.padWith0()}/${this.monthNumber.padWith0()}"

@Composable
fun RecapList(
    loadableRecaps: Loadable<List<Recap>>,
    attrs: AttrBuilderContext<HTMLDivElement>? = null,
) {
  val strings = LocalStrings.current

  FullColumn(
      attrs = {
        attrs?.invoke(this)

        tailwind { mt(4) }
      }) {
        FullRow {
          H6(
              attrs = {
                tailwind {
                  mt(10)
                  mb(6)
                  ml(2)

                  text(align = TextScope.Align.Left) { headline6() }
                }
              },
          ) {
            Text(strings.recapListTitle)
          }
        }

        Column(
            gap = 10,
        ) {
          loadableRecaps.toList().forEach { recap: Recap -> Recap(recap) }
        }
      }
}

@Composable
private fun Recap(
    recap: Recap,
    attrs: AttrBuilderContext<HTMLDivElement>? = null,
) {
  val strings = LocalStrings.current
  val start =
      recap.creationDate
          .toLocalDateTime(TimeZone.currentSystemDefault())
          .startOfWeek
          .toFormattedString()
  val end =
      recap.creationDate
          .toLocalDateTime(TimeZone.currentSystemDefault())
          .endOfWeek
          .toFormattedString()

  Column(
      gap = 2,
      attrs = attrs,
  ) {
    FullRow(
        attrs = {
          tailwind {
            pl(2)
            text(color = Secondary400) { caption() }
          }
        }) {
          Text(strings.recapListSeparatorTitle(start, end))
        }

    if (recap.description.isBlank()) {
      BlankRecap()
    } else {
      Card(
          attrs = {
            tailwind {
              p(4)
              w(80)

              text { whitespace(TextScope.Whitespace.PreWrap) }
            }
          }) {
            Text(value = recap.description)
          }
    }
  }
}

@Composable
private fun BlankRecap() {
  val strings = LocalStrings.current

  Div(
      attrs = {
        tailwind {
          p(4)
          w(80)

          text { whitespace(TextScope.Whitespace.PreWrap) }
        }
      }) {
        Text(strings.recapListEmptyRecap)
      }
}
