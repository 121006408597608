package ui.components.chips.projects

import androidx.compose.runtime.Composable
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberCoroutineScope
import api.traak.Project
import api.traak.Team
import kotlinx.coroutines.CoroutineScope
import ui.components.chips.checkbox.searchable.Item
import ui.components.chips.checkbox.searchable.SearchableCheckboxState
import ui.components.chips.checkbox.searchable.SearchableCheckboxStateHolder
import ui.components.chips.dropdown.DropdownAlignment
import ui.icons.FolderOutline
import ui.strings.LocalStrings
import ui.strings.LocalizedStrings

data class ProjectItem(
    override val id: String,
    override val text: String,
    override val matchesSearch: (search: String) -> Boolean,
) : Item {
  override fun toString(): String = text
}

@Composable
fun rememberProjectsChipState(
    team: Team,
    coroutineScope: CoroutineScope = rememberCoroutineScope(),
    strings: LocalizedStrings = LocalStrings.current,
): SearchableCheckboxState {
  fun getTitle(items: List<Item>, selectedItems: List<Item>): String {
    val allSelected = items.size == selectedItems.size
    val noneSelected = selectedItems.isEmpty()
    val oneSelected = selectedItems.size == 1

    return if (allSelected) {
      strings.projectsChipTitleAll
    } else if (noneSelected) {
      strings.projectsChipTitleNone
    } else if (oneSelected) {
      selectedItems[0].text
    } else {
      strings.projectsChipTitle(selectedItems.size)
    }
  }

  return remember(
      team,
      coroutineScope,
  ) {
    SearchableCheckboxStateHolder(
        itemsFlow = team.getProjects(null),
        mapper = { it.toItem() },
        coroutineScope = coroutineScope,
        searchPlaceholder = strings.projectsChipSearchPlaceholder,
        chipIcon = { FolderOutline() },
        titleFromState = { items, selectedItems -> getTitle(items, selectedItems) },
        dropdownAlignment = DropdownAlignment.Right,
        startWithAllSelected = true,
    )
  }
}

private fun Project.toItem(): Item =
    ProjectItem(
        this.id.raw,
        this.title,
        matchesSearch = { search ->
          val searchIn =
              listOf(
                  this.title,
                  this.address.locality,
                  this.address.name,
                  this.address.phone,
                  this.address.place,
              )

          return@ProjectItem searchIn.any { it.contains(search, true) }
        },
    )
