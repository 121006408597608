package ui.components.calendar

import kotlinx.datetime.LocalDate

enum class DayItemState {
  NONE,
  START,
  CONTAINED,
  HOVERED,
  END,
}

data class DayItem(
    val date: LocalDate,
    val state: DayItemState,
    val pickerState: PickerState,
)

fun LocalDate.toDayItemState(
    currentHoverDay: LocalDate?,
    startDay: LocalDate?,
    endDay: LocalDate?
): DayItemState {
  if (this == startDay) {
    return DayItemState.START
  }

  if (this == endDay) {
    return DayItemState.END
  }

  if (this == currentHoverDay) {
    return DayItemState.HOVERED
  }

  if (startDay == null || this < startDay) {
    return DayItemState.NONE
  }

  return if (endDay == null) {
    if (currentHoverDay == null || this > currentHoverDay) {
      DayItemState.NONE
    } else {
      DayItemState.CONTAINED
    }
  } else if (this < endDay) {
    DayItemState.CONTAINED
  } else {
    DayItemState.NONE
  }
}
