package firebase.firestore

enum class WhereOperator(private val body: String) {
  LESS("<"),
  LESS_OR_EQUAL("<="),
  EQUAL("=="),
  NOT_EQUAL("!="),
  GREATER(">"),
  GREATER_OR_EQUAL(">="),
  ARRAY_CONTAINS("array-contains"),
  ARRAY_CONTAINS_ANY("array-contains-any"),
  IN("in"),
  NOT_IN("not in");

  override fun toString(): String = this.body
}
