package ui.components.chips.checkbox.searchable

import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateListOf
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.setValue
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.StateFlow
import kotlinx.coroutines.launch
import org.jetbrains.compose.web.dom.AttrBuilderContext
import org.w3c.dom.HTMLDivElement
import ui.components.chips.dropdown.DropdownAlignment
import ui.strings.LocalStrings

enum class ClearSelectButtonMode {
  Clear,
  Select
}

class SearchableCheckboxStateHolder<T>(
    private val itemsFlow: Flow<List<T>>,
    private val mapper: (T) -> Item,
    private val coroutineScope: CoroutineScope,
    private val searchPlaceholder: String,
    private val chipIcon: (@Composable () -> Unit)? = null,
    private val dropdownAlignment: DropdownAlignment,
    private val titleFromState: (items: List<Item>, selectedItems: List<Item>) -> String,
    startWithAllSelected: Boolean = true
) : SearchableCheckboxState {
  private var ready = !startWithAllSelected

  init {
    coroutineScope.launch {
      itemsFlow.collect { unmappedItems ->
        val newItems = unmappedItems.map { mapper(it) }
        val removedItems = _items.minus(newItems)

        _items.clear()
        _items.addAll(newItems)

        // At first, select everything
        if (!ready) {
          _selectedItems.addAll(newItems)
          ready = true
        }

        _selectedItems.removeAll(removedItems)
        _selectedItemsFlow.value = _selectedItems
      }
    }
  }

  private var _items = mutableStateListOf<Item>()
  private var _selectedItems = mutableStateListOf<Item>()
  private var buttonMode by mutableStateOf(ClearSelectButtonMode.Clear)
  private var _selectedItemsFlow: MutableStateFlow<List<Item>> = MutableStateFlow(emptyList())
  override val selectedItems: StateFlow<List<Item>>
    get() = _selectedItemsFlow

  private fun toggleItem(item: Item) {
    if (_selectedItems.contains(item)) {
      _selectedItems.remove(item)
      _selectedItemsFlow.value = _selectedItemsFlow.value.minus(item)
    } else {
      _selectedItems.add(item)
      _selectedItemsFlow.value = _selectedItemsFlow.value.plus(item)
    }

    updateButtonMode()
  }

  private fun updateButtonMode() {
    buttonMode =
        if (_selectedItems.isEmpty()) {
          ClearSelectButtonMode.Select
        } else {
          ClearSelectButtonMode.Clear
        }
  }

  private fun onClearSelectButtonClick() {
    when (buttonMode) {
      ClearSelectButtonMode.Clear -> {
        _selectedItems.clear()
        _selectedItemsFlow.value = emptyList()
      }
      ClearSelectButtonMode.Select -> {
        _selectedItems.clear()
        _selectedItems.addAll(_items)
        _selectedItemsFlow.value = _items
      }
    }

    updateButtonMode()
  }

  @Composable
  override fun DisplayCheckbox(attrs: AttrBuilderContext<HTMLDivElement>?) {
    val strings = LocalStrings.current

    SearchableCheckboxChip(
        text = titleFromState(_items, _selectedItems),
        items = _items,
        onItemClick = { toggleItem(it) },
        isItemSelected = { _selectedItems.contains(it) },
        itemMatches = { item, search -> item.matchesSearch(search) },
        searchPlaceholder = searchPlaceholder,
        selectButtonText =
            when (buttonMode) {
              ClearSelectButtonMode.Clear -> strings.searchableCheckboxDeselectAll
              ClearSelectButtonMode.Select -> strings.searchableCheckboxSelectAll
            },
        onSelectClick = { onClearSelectButtonClick() },
        attrs = attrs,
        dropdownAlignment = dropdownAlignment,
        chipIcon = chipIcon,
    )
  }
}
