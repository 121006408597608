package ui.screens.memberEdition

import androidx.compose.runtime.Composable
import api.traak.Team
import api.traak.user.User
import org.jetbrains.compose.web.dom.AttrBuilderContext
import org.w3c.dom.HTMLDivElement
import tailwind.Full
import tailwind.tailwind
import ui.components.navigationBar.NavigationBar
import ui.layouts.FullScreenLayout
import ui.layouts.HalfScreenLayout
import ui.material.FilledButton
import ui.material.FullColumn
import ui.material.FullRow
import ui.material.OutlineButton
import ui.material.TextInput
import ui.strings.LocalStrings

@Composable
fun MemberEdition(
    team: Team,
    memberId: User.Id,
    attrs: AttrBuilderContext<HTMLDivElement>? = null,
) {
  val state = rememberMemberEditionState(team = team, memberId = memberId)

  MemberEdition(
      firstName = state.firstName,
      lastName = state.lastName,
      onFirstNameInput = { state.firstName = it },
      onLastNameInput = { state.lastName = it },
      buttonsAreDisabled = state.fetchingData || state.savingData,
      saving = state.savingData,
      onResetClick = { state.reset() },
      onSaveClick = { state.save() },
      onBackClick = { state.back() },
      attrs = attrs,
  )
}

@Composable
fun MemberEdition(
    firstName: String,
    lastName: String,
    onFirstNameInput: (String) -> Unit,
    onLastNameInput: (String) -> Unit,
    buttonsAreDisabled: Boolean,
    saving: Boolean,
    onResetClick: () -> Unit,
    onSaveClick: () -> Unit,
    onBackClick: () -> Unit,
    attrs: AttrBuilderContext<HTMLDivElement>? = null,
) {
  val strings = LocalStrings.current

  FullScreenLayout(attrs = attrs) {
    NavigationBar(
        onBackArrowClick = { onBackClick() },
        title = strings.memberEditionTitle,
    )

    HalfScreenLayout {
      FullColumn(gap = 4) {
        TextInput(
            value = firstName,
            onInput = onFirstNameInput,
            label = strings.memberEditionFirstNamePlaceholder,
        ) {
          tailwind {
            w(Full)
            text { body1() }
          }
        }

        TextInput(
            value = lastName,
            onInput = onLastNameInput,
            label = strings.memberEditionLastNamePlaceholder,
        ) {
          tailwind {
            w(Full)
            text { body1() }
          }
        }

        FullRow(gap = 4) {
          OutlineButton(
              text = strings.memberEditionReset,
              onClick = onResetClick,
              disabled = buttonsAreDisabled,
              attrs = { tailwind { w(Full) } },
          )

          FilledButton(
              text = strings.memberEditionSave,
              onClick = onSaveClick,
              disabled = buttonsAreDisabled,
              loading = saving,
              attrs = { tailwind { w(Full) } },
          )
        }
      }
    }
  }
}
