package ui.helpers

import androidx.compose.runtime.Composable
import androidx.compose.runtime.DisposableEffect
import androidx.compose.runtime.MutableState
import kotlinx.browser.window
import org.w3c.dom.events.EventListener

/**
 * This composable will set a listener on the browser's window. When a click is registered somewhere
 * in the window, the [property] will take the value [onOutsideClickValue].
 *
 * If you want another element to still be clickable on the page, do not forget to give it the
 * following property:
 * ```
 * onClick {
 *  it.stopPropagation()
 *  ...
 * }
 * ```
 *
 * @param property The property to modify when a click is registered somewhere in the window.
 * @param onOutsideClickValue The value that the property should take when the click is registered.
 */
@Composable
fun <T> OnOutsideClick(
    property: MutableState<T>,
    onOutsideClickValue: T,
) {
  DisposableEffect(true) {
    val listener = EventListener { property.value = onOutsideClickValue }

    window.addEventListener("click", listener)
    onDispose { window.removeEventListener("click", listener) }
  }
}

/**
 * This composable will set a listener on the browser's window. When a click is registered somewhere
 * in the window, the [onOutsideClick] function will be executed.
 *
 * If you want another element to still be clickable on the page, do not forget to give it the
 * following property:
 * ```
 * onClick {
 *  it.stopPropagation()
 *  ...
 * }
 * ```
 *
 * @param onOutsideClick The function to execute when the outside click is registered.
 */
@Composable
fun OnOutsideClick(
    onOutsideClick: () -> Unit,
) {
  DisposableEffect(true) {
    val listener = EventListener { onOutsideClick() }

    window.addEventListener("click", listener)
    onDispose { window.removeEventListener("click", listener) }
  }
}
