package api.traak

import api.getCurrentDate
import api.traak.dto.BillingAddress
import api.traak.fromFirestore.FromFirestoreMember
import api.traak.fromFirestore.FromFirestoreProject
import api.traak.fromFirestore.FromFirestoreTask
import api.traak.fromFirestore.toMember
import api.traak.fromFirestore.toProject
import api.traak.fromFirestore.toTask
import api.traak.toFirestore.toFirestoreField
import api.traak.user.User
import firebase.firestore.CollectionReference
import firebase.firestore.DocumentData
import firebase.firestore.Firestore
import firebase.firestore.WhereOperator
import firebase.firestore.asFlow
import firebase.firestore.collection
import firebase.firestore.orderBy
import firebase.firestore.where
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.map
import kotlinx.datetime.DatePeriod
import kotlinx.datetime.LocalDate
import kotlinx.datetime.TimeZone
import kotlinx.datetime.atStartOfDayIn
import kotlinx.datetime.plus
import kotlinx.datetime.toJSDate
import utils.Ordering

class TraakTeam(
    private val api: TraakApi,
    private val firestore: Firestore,
    override val name: String,
    override val id: Team.Id,
    override val roles: Team.Roles,
    override val integration: Team.Integration,
    override val billing: BillingAddress,
) : Team {
  private val profilesCollection: CollectionReference<DocumentData> =
      collection(firestore, "${path}/${id.raw}/${profiles}")

  private val projectsCollection = collection(firestore, projectsPath)
  private val tasksCollection = collection(firestore, tasksPath)

  override val members: Flow<List<Member>> =
      this.profilesCollection.asFlow().map { list ->
        list
            .map { FromFirestoreMember.withData(it).toMember(api, this) }
            .sortedWith { a, b -> a.lastName.compareTo(b.lastName) }
      }

  override fun getProjects(status: Project.Status?): Flow<List<Project>> =
      projectsCollection
          .where(team, WhereOperator.EQUAL, this.id.raw)
          .let { query ->
            if (status == null) query
            else query.where(projectStatus, WhereOperator.EQUAL, status.toFirestoreField())
          }
          .orderBy(title)
          .asFlow()
          .map { list -> list.map { FromFirestoreProject.withData(it).toProject() } }

  override fun getTasks(
      startDate: LocalDate,
      endDate: LocalDate?,
      orderBy: Ordering?,
  ): Flow<List<Task>> {
    val orderByDirection = (orderBy ?: Ordering.ASC).toFirestoreOrdering()

    val jsStartDate = startDate.atStartOfDayIn(TimeZone.currentSystemDefault()).toJSDate()
    val jsEndDate =
        (endDate ?: getCurrentDate())
            .plus(DatePeriod(days = 1))
            .atStartOfDayIn(TimeZone.currentSystemDefault())
            .toJSDate()

    return tasksCollection
        .where(team, WhereOperator.EQUAL, this.id.raw)
        .where(start, WhereOperator.GREATER_OR_EQUAL, jsStartDate)
        .where(start, WhereOperator.LESS_OR_EQUAL, jsEndDate)
        .orderBy(start, orderByDirection)
        .asFlow()
        .map { list ->
          list.mapNotNull { transformOrWarn(FromFirestoreTask::withData, it)?.toTask() }
        }
  }

  override fun roleOf(userId: User.Id): Role? =
      if (this.roles.admin.contains(userId)) {
        Role.ADMIN
      } else if (this.roles.manager.contains(userId)) {
        Role.PROJECT_MANAGER
      } else if (this.roles.member.contains(userId)) {
        Role.MEMBER
      } else if (this.roles.removed.contains(userId)) {
        Role.REMOVED
      } else {
        null
      }

  companion object {
    const val path = "/teams"
    const val start = "start"
    const val profiles = "profiles"
    const val profilesName = "name"
    const val pending = "pending"
    const val member = "roles.member"
    const val pendingIntegrations = "pendingIntegrations"

    const val projectsPath = "/projects"
    const val team = "team"
    const val projectStatus = "status"
    const val title = "title"

    const val tasksPath = "/tasks"
  }
}
