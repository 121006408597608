package ui.components

import androidx.compose.runtime.Composable
import org.jetbrains.compose.web.dom.AttrBuilderContext
import org.jetbrains.compose.web.dom.Div
import org.w3c.dom.HTMLDivElement
import tailwind.FlexScope
import tailwind.Layout
import tailwind.fourth
import tailwind.tailwind

@Composable
fun AuthForm(
    attrs: AttrBuilderContext<HTMLDivElement>? = null,
    content: @Composable () -> Unit,
) {
  Div(
      attrs = {
        attrs?.invoke(this)

        tailwind {
          w(1.fourth)
          px(6)
          pb(4)

          flex(
              direction = FlexScope.Direction.Column,
              justify = Layout.Justify.Center,
              alignItems = Layout.AlignItems.Start,
              gap = 6,
          )
        }
      }) {
        content()
      }
}
