package ui.components

import androidx.compose.runtime.Composable
import org.jetbrains.compose.web.css.height
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.css.width
import org.jetbrains.compose.web.dom.AttrBuilderContext
import org.jetbrains.compose.web.dom.Div
import org.w3c.dom.HTMLDivElement
import tailwind.TailwindScope
import tailwind.TransitionScope
import tailwind.color.Gray200
import tailwind.color.Opacity080
import tailwind.color.Opacity100
import tailwind.tailwind
import ui.color.BlackDark
import ui.icons.Check
import ui.icons.IconPlaceholder

@Composable
fun Checkbox(
    isSelected: Boolean,
    onClick: (() -> Unit)? = null,
    attrs: AttrBuilderContext<HTMLDivElement>? = null,
) {
  Div(
      attrs = {
        attrs?.invoke(this)

        onClick?.let { onClick { it() } }

        style {
          width(24.px)
          height(24.px)
        }

        tailwind {
          transition(TransitionScope.Property.All)

          background(color = Gray200, opacity = Opacity080)
          round(TailwindScope.Radius.Medium)
          text(color = BlackDark)

          hover { background(color = Gray200, opacity = Opacity100) }
        }
      },
  ) {
    if (isSelected) {
      Check()
    } else {
      IconPlaceholder()
    }
  }
}
