package ui.screens.projectOverview

import androidx.compose.runtime.Composable
import org.jetbrains.compose.web.dom.AttrBuilderContext
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.P
import org.jetbrains.compose.web.dom.Text
import org.w3c.dom.HTMLDivElement
import tailwind.tailwind
import ui.color.BlackMedium

@Composable
fun NoListMessage(
    text: String,
    attrs: AttrBuilderContext<HTMLDivElement>? = null,
) {
  Div(
      attrs = {
        attrs?.invoke(this)
        tailwind {
          mt(16)
          text(color = BlackMedium) { subtitle2() }
        }
      }) {
        P { Text(text) }
      }
}
