package utils

enum class Ordering {
  ASC,
  DESC;

  fun toggle(): Ordering {
    return when (this) {
      ASC -> DESC
      DESC -> ASC
    }
  }
}
