package ui.screens.integration.bexio

import androidx.compose.runtime.Composable
import org.jetbrains.compose.web.dom.*
import org.w3c.dom.HTMLDivElement
import tailwind.Full
import tailwind.tailwind
import ui.color.BlackDark

@Composable
fun BexioStageExplanation(
    title: String,
    explanation: String,
    attrs: AttrBuilderContext<HTMLDivElement>? = null,
) {
  Div(
      attrs = {
        attrs?.invoke(this)
        tailwind {
          w(Full)
          mb(8)
        }
      },
  ) {
    H2(attrs = { tailwind { text(color = BlackDark) { headline4() } } }) { Text(title) }

    P(attrs = { tailwind { text(color = BlackDark) { body1() } } }) { Text(explanation) }
  }
}
