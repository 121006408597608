package ui.components.table

import androidx.compose.runtime.Composable
import tailwind.Layout
import tailwind.TailwindScope
import tailwind.TextScope
import tailwind.tailwind
import ui.color.Primary100
import ui.material.FullRow

@Composable
fun TableSummary(summary: TableSummary) {
  FullRow(
      justify = Layout.Justify.End,
      alignItems = Layout.AlignItems.Center,
      attrs = {
        tailwind {
          mt(4)

          background(Primary100)
          round(TailwindScope.Radius.Large)

          p(4)
          text { align(TextScope.Align.Right) }
        }
      },
  ) {
    summary.display()
  }
}
