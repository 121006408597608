package ui.icons

import androidx.compose.runtime.Composable
import org.jetbrains.compose.web.dom.AttrBuilderContext
import org.w3c.dom.svg.SVGElement

@Composable
fun Traak(
    attrs: AttrBuilderContext<SVGElement>? = null,
) =
    Svg(
        attrs = {
          attrs?.invoke(this)
          attr("preserveAspectRation", "xMidYMid meet")
          attr("viewBox", "0 0 110 32")
          attr("fill", "currentColor")
        }) {
          Path {
            attr("fill", "currentColor")
            attr(
                "d",
                "M86.1926 7.26738V28.3429H91.4084L91.4084 23.2606L93.3647 21.2517L98.7077 28.3662L105.518 28.3221L96.8149 16.9393L105.843 7.26738H98.8885L91.4085 15.2789L91.4085 7.26738H86.1926Z",
            )
          }
          Path {
            attr("fill", "currentColor")
            attr(
                "d",
                "M9.29879 28.3429H14.6635V12.7179H20.3858V7.26738H3.57646V12.7179H9.29879V28.3429Z",
            )
          }
          Path {
            attr("fill", "currentColor")
            attr(
                "fill-rule",
                "evenodd",
            )
            attr(
                "clip-rule",
                "evenodd",
            )
            attr(
                "d",
                "M23.247 7.26738H32.1881C34.2749 7.26738 36.2762 8.10962 37.7518 9.60882C39.2274 11.108 40.0563 13.1414 40.0563 15.2615C40.0563 17.3817 39.2274 19.4151 37.7518 20.9143C37.3244 21.3485 36.8529 21.7276 36.348 22.0471L39.6987 28.3036H33.9764L31.273 23.2557H28.6117V28.3429H23.247V7.26738ZM28.6117 11.9912H31.4728C32.3265 11.9912 33.1452 12.3358 33.7489 12.9491C34.3525 13.5624 34.6916 14.3942 34.6916 15.2615C34.6916 16.1289 34.3525 16.9607 33.7489 17.574C33.1452 18.1873 32.3265 18.5319 31.4728 18.5319H28.6117V11.9912Z",
            )
          }
          Path {
            attr("fill", "currentColor")
            attr(
                "d",
                "M52.2163 3.63367L43.2751 9.08423V28.3429H49.3551V23.2557H55.0775V28.3429H61.1574V9.08423L52.2163 3.63367Z",
            )
          }
          Path {
            attr("fill", "currentColor")
            attr(
                "d",
                "M73.675 6.90401L64.7339 12.3546V28.3429H70.8139V23.2557H76.5362V28.3429H82.6162V12.3546L73.675 6.90401Z",
            )
          }
        }
