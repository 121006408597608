package ui.strings

object PortugueseStrings : LocalizedStrings {
  override val mondayAbbr = "Seg."
  override val tuesdayAbbr = "Ter."
  override val wednesdayAbbr = "Qua."
  override val thursdayAbbr = "Qui."
  override val fridayAbbr = "Sex."
  override val saturdayAbbr = "Sab."
  override val sundayAbbr = "Dom."

  override val january = "Janeiro"
  override val february = "Fevereiro"
  override val march = "Marcha"
  override val april = "Abril"
  override val may = "Pode"
  override val june = "Junho"
  override val july = "Julho"
  override val august = "Agosto"
  override val september = "Setembro"
  override val october = "Outubro"
  override val november = "Novembro"
  override val december = "Dezembro"

  override val hourSuffix = "h"
  override val minuteSuffix = "m"

  override val description = "descrição"
  override val success = "sucesso"
  override val save = "salvar"
  override val reset = "reinicialização"
  override val cancel = "cancelar"
  override val help = "ajuda"

  override val continueStr = "continuar"
  override val or = "ou"
  override val export = "exportar"

  override val exampleFullName = "João Silva"

  override val taskCreatorTitle = "Acrescentar uma nova tarefa"

  override val taskTableTotalTime = "Tempo total"

  override val navMenuTasks = "Tarefas"
  override val navMenuProjects: String = "Projetos"
  override val navMenuTeam: String = "Equipa"
  override val navMenuDetailTeamManagement = "Gestão de empresas"
  override val navMenuDetailSwitchTeams = "Mudar de equipa"
  override val navMenuDetailSwitchLanguage = "Mudar de língua"
  override val navMenuDetailHelp = "Ajuda"
  override val navMenuDetailLogOut = "Sair"

  override val loginSubtitle = "Bem-vindo sobre TRAAK"
  override val loginEmailPlaceholder = "Email"
  override val loginPasswordPlaceholder = "Palavra-passe"
  override val loginLogInButton = "Entrar"
  override val loginLogInBexioButton = "Entrar com bexio"
  override val loginNoAccount = "Vôce não tem uma conta ?"
  override val loginCreateAccount = "Cria uma agora"
  override val loginSeparationText = "Ou"
  override val loginExplanationIdentifying =
      "Para se conectar, por favor insira seu endereço de email ou seu número de telefone."
  override val loginExplanationValidatingPhone =
      "Por favor, insira o código que você recebeu por mensagem de texto."
  override val loginExplanationWaitingForEmailClick =
      "Você deve ter recebido um email contendo um link. Por favor, clique nele para se conectar. Se você não encontrar o email, espere alguns minutos ou verifique sua pasta de spam."
  override val loginIdentifierError =
      "O identificador deve ser algo como ‘john.doe@example.org’ ou ‘+41 79 123 45 67’."
  override val loginConnectionTitle = "Conexão"
  override val loginIdentifierFormIdentifierLabel = "Email ou número de telefone"
  override val loginIdentifierFormIdentifierPlaceholder = "john.doe@example.org ou +41 79 123 45 67"
  override val loginIdentifierFormIdentifierContinueButton = continueStr
  override val loginValidationFormValidationPlaceholder = "Código de validação"
  override val loginValidationFormValidationLabel = "Código de validação"
  override val loginValidationFormVerifyButton = "verificar"

  override val setupPanelTitle = "Configuração da sua conta"
  override val setupProgressionName = "Nome"
  override val setupProgressionEmail = "Endereço de email"
  override val setupProgressionPhone = "Número de telefone"

  override val setupNameTitle = "Qual é o seu nome?"
  override val setupNameExplanation = "Por favor, insira seu nome completo abaixo."
  override val setupNameFormNameLabel = "Nome completo"
  override val setupNameFormNamePlaceholder = exampleFullName
  override val setupNameFormContinueButton = continueStr

  override val setupPhoneExplanationRetrieving: String =
      "Por favor, insira seu número de telefone abaixo. Você receberá um código por SMS para validar este número."
  override val setupPhoneExplanationValidating: String =
      "Por favor, insira o código que você recebeu por mensagem de texto."
  override val setupPhoneTitle: String = "Configurando sua conta - Número de telefone"
  override val setupPhoneIdentifierFormPhoneLabel: String = "Número de telefone"
  override val setupPhoneIdentifierFormPhonePlaceholder: String = "+41 79 123 45 67"
  override val setupPhoneIdentifierFormContinueButton: String = "Continuar"
  override val setupPhoneValidationFormValidationCodePlaceholder: String = "Código de validação"
  override val setupPhoneValidationFormValidationCodeLabel: String = "Código de validação"
  override val setupPhoneValidationFormVerifyButton: String = "Verificar"

  override val setupEmailTitle = "Qual é o seu endereço de email?"
  override val setupEmailExplanation =
      "Depois de inserir seu endereço de email abaixo, você receberá um email. Para se conectar, clique no link que você terá recebido."
  override val setupEmailFormEmailLabel = "Email"
  override val setupEmailFormEmailPlaceholder = "john.doe@example.org"
  override val setupEmailFormContinueButton = continueStr

  override val registerTraakHeadline = "Junte-se a comunidade TRAAK"
  override val registerEmailPlaceholder = "Email"
  override val registerFirstNamePlaceholder = "Primero nome"
  override val registerLastNamePlaceholder = "Sobrenome"
  override val registerPasswordPlaceholder = "Palavra-passe"
  override val registerPasswordConfirmationPlaceholder = "Confirmação da palavra-passe"
  override val registerButton = "Registar"
  override val registerGoBackButton = "Voltar"
  override val registerErrorEmptyName = "Por favor, entrem o vosso nome."
  override val registerSuccess =
      "Obrigado pela criação de uma conta. Será agora redirecionado para a página de conexão."

  override val authErrorFailureInternal = "Desculpa, encontramos um erro interno."
  override val authErrorFailureUserUnknown = "Esse utilizador não existe"
  override val authErrorFailureUserAlreadyRegistered = "Esse utilizador já existe"
  override val authErrorFailurePasswordTooWeak =
      "A palavra-passe entrada e muita fraca. Escolhe uma mais complexa."
  override val authErrorFailurePasswordWrong = "Palavra-passe errada. Tente novamente."
  override val authErrorFailureInvalidEmail = "O email entrado não é valido."
  override val authErrorFailureInvalidPhoneNumber = "Este número de telefone é inválido."
  override val authErrorFailureEmailAlreadyInUse = "Esse email já existe."
  override val authErrorFailureInvalidVerificationCode = "O código de verificação é inválido."
  override val authErrorFailureUnknown = "Um erro desconhecido aconteceu. Contacte o suporte."

  override val teamSelectionHeadline = "Com que equipa você deseja conectar-se ?"
  override val teamSelectionLogOut = "Sair"

  override fun teamSelectionMembers(members: Int): String = "$members membres"

  override val teamSelectionNoTeamHeadline = "Nenhuma equipa está associado com a sua conta."
  override val teamSelectionNoTeamInformation =
      "Se deveria fazer parte de uma equipa existente, contacte o seu administrador para ele poder dar-vos o acesso. Senão, pode criar uma equipa."
  override val teamSelectionNewTeamButton = "Nova equipa"
  override val teamSelectionExistingTeamButton = "Equipa existante"

  override val requestTeamAccessHeadline = "Entre o identificador da equipa que quer juntar."
  override val requestTeamAccessHeadlineSuccess = "A vosso pedido foi registado."
  override val requestTeamAccessSuccessMessage =
      "O administrador da equipa tem que validar a vosso pedido. Quando isso for efetuado, o acesso à equipa será disponível."
  override val requestTeamAccessIdPlaceholder = "Identificador da equipa"
  override val requestTeamAccessRequestAccessButton = "Juntar-se a equipa"
  override val requestTeamAccessRequestFailure =
      "O pedido falhou. Verifica o identificador da equipa."

  override val teamCreationFailure =
      "Desculpe, não conseguimos criar a equipa. Por favor, refresca a pagina ao informe o suporte técnico."
  override val teamCreationMissingData = "Preencha por favor o formulário na íntegra"

  override val newTeamTraakHeadline = "Junta-se a comunidade TRAAK ão criar uma equipa."
  override val newTeamCreateButton = "Criar uma equipa"
  override val newTeamCancelButton = "Anular"
  override val newTeamBillingHeadline = "Informação de faturação"
  override val newTeamNamePlaceholder = "Nome da vossa equipa"
  override val newTeamContactNamePlaceholder = "Nome da pessoa de contato"
  override val newTeamEmailPlaceholder = "Email de la pessoa de contato"
  override val newTeamPhonePlaceholder = "Número de telefone da pessoa de contato"
  override val newTeamAddressPlaceholder = "Endereço de faturação"
  override val newTeamLocalityPlaceholder = "Localidade"
  override val newTeamZipPlaceholder = "Código postal"

  override val projectTitleLabel = "Título do projeto"
  override val projectNameLabel = "Nome do cliente"
  override val projectPhoneLabel = "Telefone do cliente"
  override val projectStreetLabel = "Rua"
  override val projectPostalCodeLabel = "Código postal"
  override val projectLocalityLabel = "Localidade"
  override val projectLocalityWithZipLabel = "Localidade et código postal"
  override val projectTitlePlaceholder = "001 Exemplo"
  override val projectNamePlaceholder = "João Silva"
  override val projectPhonePlaceholder = "+41 79 123 45 67"
  override val projectStreetPlaceholder = "Rua do exemplo 1"
  override val projectPostalCodePlaceholder = "1234"
  override val projectLocalityPlaceholder = "Lausanne"
  override val projectLocalityWithZipPlaceholder = "1234 Lausanne"

  override val projectEmptyTitleError = "Por favor, entre o título do projeto."
  override val projectSavedError = "Um erro ocorreu."

  override val newProjectTitle = "Novo projeto"
  override val newProjectExplanation =
      "Preencha as informações necessárias. Você pode sempre as alterar posteriormente."
  override val newProjectCreateProjectButton = "Criar um novo projeto"

  override val projectOverviewTitle = "Seleccionar um projeto"
  override val projectOverviewNewProjectButton = "Novo projeto"
  override val projectOverviewSearchBarPlaceholder =
      "Procurar um projeto (com o título, o nome do cliente, a localidade, ...)"
  override val projectOverviewNoActiveProjects =
      "Nenhum projeto atualmente. Para criar um clique sobre '${projectOverviewNewProjectButton}'."
  override val projectOverviewNoArchivedProjects = "Não tem projectos arquivados."

  override val projectOverviewNoSearchedProjects = "Nenhum projeto encontrado..."
  override val projectOverviewSynchronizeBexioTooltipHeader =
      FrenchStrings.projectOverviewSynchronizeBexioTooltipHeader
  override val projectOverviewSynchronizeBexioTooltipParagraph =
      FrenchStrings.projectOverviewSynchronizeBexioTooltipParagraph

  override val projectStatusActive = "Activos"
  override val projectStatusArchived = "Arquivado"

  override val projectDetailEmptyAddressTitle = "Nenhum endereço registado"
  override val projectDetailEmptyAddressParagraph =
      "Para acrescentar as informações faltantes, edite o projeto."
  override val projectDetailAddressSubtitle = "Endereço"
  override val projectDetailEditProjectButton = "Editar o projeto"
  override val projectDetailExportProjectButton = "Exportar"
  override val projectDetailArchiveButton = "Arquivar"
  override val projectDetailRestoreButton = "Restaurá"
  override val projectDetailSearchPlaceholder = "Procurar um trabalho"
  override val projectDetailHeaderDate = "Data"
  override val projectDetailHeaderUser = "Utilizador"
  override val projectDetailHeaderTask = "Trabalho"
  override val projectDetailHeaderDuration = "Duração"

  override val projectEditionTitle = "Edição do projeto"
  override val projectEditionExplanation = "Edite as diferentes áreas e depois guarde o projeto."
  override val projectEditionSaveButton = "Guardar"
  override val projectEditionCancelButton = "Cancelar"

  override val teamOverviewTeamMemberTitle = "Membros da equipa"
  override val teamOverviewSearchPlaceholder = "Procurar os membros"
  override val teamOverviewNoFilteredMember = "Nenhum membro correspondente"
  override val teamSettingsInvitationTitle = "Acrescentar um novo membro"
  override val teamSettingsInvitationExplanation =
      "Para acrescentar um novo membro a sua equipa, o identificador da sua equipa devera ser copiado e enviado a pessoa desejada."
  override val teamSettingsInvitationExplanationAfterCopy =
      "Uma vez que uma pessoa envia o seu pedido, devera o validar aqui abaixo."
  override val teamSettingsInvitationCopied = "Copiado"
  override val teamSettingsRequestsTitle = "Pedidos"
  override val teamSettingsNoRequests = "Nenhum pedido para se juntar a vossa equipa atualmente."
  override val teamSettingsHeaderName = "Nome"
  override val teamSettingsHeaderRole = "Papel"
  override val teamSettingsRoleAdmin = "Administrador"
  override val teamSettingsRoleProjectManager = "Gerente"
  override val teamSettingsRoleMember = "Utilizador"
  override val teamSettingsRoleRemoved = "Removido"

  override val memberDetailHeaderProject = "Projeto"
  override val memberDetailSearchPlaceholder = "Procurar um trabalho"
  override val memberDetailExport = "Exportar"

  override val bexioIntegrationHeadline = "Ligar Traak ao bexio"
  override val bexioIntegrationStoringCredentials = "Estamos a armazenar as suas credenciais."
  override val bexioIntegrationSynchronizing =
      "Estamos a sincronizar a sua conta com beixo. Por favor, deixe esta página aberta, isto pode demorar algum tempo."
  override val bexioIntegrationError =
      "Desculpe, ocorreu um erro. Por favor, volte a carregar a página."
  override val bexioIntegrationDone =
      "Acabámos de sincronizar a sua conta. Será redireccionado em breve."
  override val bexioIntegrationSideBarConnection = "Ligação"
  override val bexioIntegrationSideBarTeamSelection = "Selecção de uma empresa"
  override val bexioIntegrationSideBarAuthorization = "Autorização"
  override val bexioIntegrationSideBarSynchronization = "Sincronização"
  override val bexioIntegrationConnectionTitle = "Entre com a sua conta bexio"
  override val bexioIntegrationConnectionExplanation =
      "A fim de ligar Traak e bexio, faça primeiro o login com a sua conta bexio."
  override val bexioIntegrationConnectionBexioExplanation =
      "Se ainda não tem uma conta com bexio, aproveite a nossa parceria para obter um mês de teste e 30% de desconto no seu primeiro ano. Para mais informações, consulte por favor a nossa"
  override val bexioIntegrationConnectionBexioLink = "página de informação"
  override val bexioIntegrationTeamSelectionTitle = "Escolha a sua equipa"
  override val bexioIntegrationTeamSelectionExplanation =
      "Escolha a equipa que pretende ligar à beixo. Se não vir a equipa que pretende ligar, pode criar uma nova equipa."
  override val bexioIntegrationAuthorizationTitle = "Permitir o acesso aos dados"
  override val bexioIntegrationAuthorizationExplanation =
      "A fim de sincronizar a sua conta bexio com Traak, precisamos de ter acesso aos seus dados. Por conseguinte, a bexio pedir-lhe-á que autorize a Traak a aceder aos seus dados."
  override val bexioIntegrationAuthorizationButton = "Autorizar"

  override val taskItemMenuEdit = "Editar"
  override val taskItemMenuDelete = "Eliminar"
  override val taskItemDeletionCancellableSnackbar = "Cancelar eliminação de tarefas ?"

  override val memberMenuEdit = "Editar"
  override val memberMenuDelete = "Eliminar"

  override val memberEditionTitle = "Edição de um membro da sua equipa"
  override val memberEditionFirstNamePlaceholder = "Primeiro nome"
  override val memberEditionLastNamePlaceholder = "Sobrenome"
  override val memberEditionReset = this.reset
  override val memberEditionSave = this.save

  override val recapListTitle = "Comunicação"
  override val recapListSeparatorTitle: (String, String) -> String = { start, end ->
    "De $start a $end"
  }
  override val recapListEmptyRecap = "Nenhuma comunicação registada"

  override val accountTitle = "Preferências pessoais"
  override val accountDisplaySettingsTitle = "Definições de visualização"
  override val accountOrderByFirstNameTitle = "Ordenar pelo primeiro nome"
  override val accountOrderByFirstNameExplanationActive =
      "Quando é apresentada uma lista de utilizadores, esta é ordenada pelo primeiro nome do empregado."
  override val accountOrderByFirstNameExplanationInactive =
      "Quando é apresentada uma lista de utilizadores, esta é ordenada pelo apelido do empregado."
  override val accountShowFirstNameFirstTitle = "Mostrar o nome próprio primeiro"
  override val accountShowFirstNameFirstExplanationActive =
      "O primeiro nome do utilizador será apresentado em primeiro lugar: João Silva"
  override val accountShowFirstNameFirstExplanationInactive =
      "O apelido do utilizador será apresentado em primeiro lugar: Silva João"

  override val searchableCheckboxDeselectAll = "Desmarcar todos"
  override val searchableCheckboxSelectAll = "Marcar todos"

  override val peopleChipTitleEverybody: String = "Todos"
  override val peopleChipTitleNobody: String = "Ninguém"

  override fun peopleChipTitle(numberOfPeople: Int): String {
    if (numberOfPeople <= 1) {
      throw IllegalArgumentException("numberOfPeople should be greater than 1.")
    }

    return "$numberOfPeople pessoas"
  }

  override val peopleChipSearchPlaceholder = "Procurar um colaborador"

  override val projectsChipSearchPlaceholder = "Buscar um projeto"
  override val projectsChipTitleAll: String = "Todos os projetos"
  override val projectsChipTitleNone: String = "Nenhum projeto"

  override fun projectsChipTitle(numberOfProjects: Int): String {
    if (numberOfProjects <= 1) {
      throw IllegalArgumentException("numberOfProjects should be greater than 1.")
    }

    return "$numberOfProjects projetos"
  }

  override val allTasksTitle = "Todas as tarefas"
  override val allTasksSearchPlaceholder = "Pesquisar em todas as tarefas..."
}
