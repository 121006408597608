package ui.screens.login.settingUp

import androidx.compose.runtime.Composable
import androidx.compose.runtime.remember
import api.traak.user.User
import ui.components.progressionPanel.ProgressionPanelEnum
import ui.components.progressionPanel.ProgressionPanelState
import ui.components.progressionPanel.Step
import ui.components.progressionPanel.StepState
import ui.strings.LocalStrings
import ui.strings.LocalizedStrings

enum class SettingUpProgression : ProgressionPanelEnum {
  Name,
  Email,
  Phone;

  override fun toLocalizedString(strings: LocalizedStrings) =
      when (this) {
        Name -> strings.setupProgressionName
        Email -> strings.setupProgressionEmail
        Phone -> strings.setupProgressionPhone
      }
}

class SettingUpProgressionState(
    user: User,
    private val strings: LocalizedStrings,
) : ProgressionPanelState {
  private val allSteps: List<Pair<SettingUpProgression, Boolean>> =
      listOf(
          SettingUpProgression.Name to (user.displayName != null),
          SettingUpProgression.Email to (user.email != null),
          SettingUpProgression.Phone to (user.phoneNumber != null),
      )

  private val firstNotDone: SettingUpProgression?
    get() = allSteps.filter { (_, done) -> !done }.map { it.first }.firstOrNull()

  val currentProgression: SettingUpProgression
    get() = allSteps.filter { (_, isDone) -> !isDone }.map { it.first }[0]

  override val progression: List<Step>
    get() =
        allSteps.map { (progressionStep, isDone) ->
          val state =
              when {
                isDone -> StepState.Done
                progressionStep == firstNotDone -> StepState.Current
                else -> StepState.Next
              }

          Step(
              indicator = progressionStep.ordinal.plus(1).toString(),
              text = progressionStep.toLocalizedString(strings),
              state = state,
          )
        }
}

@Composable
fun rememberSettingUpProgressionState(
    user: User,
    strings: LocalizedStrings = LocalStrings.current,
): SettingUpProgressionState {
  return remember(
      user,
      strings,
  ) {
    SettingUpProgressionState(user, strings)
  }
}
