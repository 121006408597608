package ui.screens.projectEdition

import androidx.compose.runtime.Composable
import api.traak.Project
import api.traak.Team
import org.jetbrains.compose.web.dom.AttrBuilderContext
import org.w3c.dom.HTMLDivElement
import ui.components.PageHeader
import ui.layouts.HalfScreenLayout
import ui.screens.newProject.toErrorMessage
import ui.strings.LocalStrings

@Composable
fun ProjectEdition(
    teamId: Team.Id,
    projectId: Project.Id,
    attrs: AttrBuilderContext<HTMLDivElement>? = null,
) {
  val strings = LocalStrings.current
  val state = rememberProjectEdition(teamId = teamId, projectId = projectId)

  ProjectEdition(
      title = strings.projectEditionTitle,
      explanation = strings.projectEditionExplanation,
      projectTitle = state.projectTitle,
      onProjectTitleInput = { state.projectTitle = it },
      projectTitleLabel = strings.projectTitleLabel,
      projectTitlePlaceholder = strings.projectTitlePlaceholder,
      name = state.name,
      onNameInput = { state.name = it },
      nameLabel = strings.projectNameLabel,
      namePlaceholder = strings.projectNamePlaceholder,
      phone = state.phone,
      onPhoneInput = { state.phone = it },
      phoneLabel = strings.projectPhoneLabel,
      phonePlaceholder = strings.projectPhonePlaceholder,
      locality = state.locality,
      onLocalityInput = { state.locality = it },
      localityLabel = strings.projectLocalityWithZipLabel,
      localityPlaceholder = strings.projectLocalityWithZipPlaceholder,
      street = state.street,
      onStreetInput = { state.street = it },
      streetLabel = strings.projectStreetLabel,
      streetPlaceholder = strings.projectStreetPlaceholder,
      saveButton = strings.projectEditionSaveButton,
      onSave = { state.saveProject() },
      cancelButton = strings.projectEditionCancelButton,
      onCancel = { state.cancel() },
      saving = state.saving,
      fetching = state.fetching,
      errorMessage = state.result?.toErrorMessage(strings),
      attrs = attrs,
  )
}

@Composable
fun ProjectEdition(
    title: String,
    explanation: String,
    projectTitle: String,
    onProjectTitleInput: (String) -> Unit,
    projectTitleLabel: String,
    projectTitlePlaceholder: String,
    name: String,
    onNameInput: (String) -> Unit,
    nameLabel: String,
    namePlaceholder: String,
    phone: String,
    onPhoneInput: (String) -> Unit,
    phoneLabel: String,
    phonePlaceholder: String,
    locality: String,
    onLocalityInput: (String) -> Unit,
    localityLabel: String,
    localityPlaceholder: String,
    street: String,
    onStreetInput: (String) -> Unit,
    streetLabel: String,
    streetPlaceholder: String,
    saveButton: String,
    onSave: () -> Unit,
    cancelButton: String,
    onCancel: () -> Unit,
    saving: Boolean,
    fetching: Boolean,
    errorMessage: String?,
    attrs: AttrBuilderContext<HTMLDivElement>? = null,
) {
  HalfScreenLayout(attrs) {
    PageHeader(title, explanation)

    if (fetching) {
      LoadingForm()
    } else {
      Form(
          title = projectTitle,
          onTitleInput = onProjectTitleInput,
          titleLabel = projectTitleLabel,
          titlePlaceholder = projectTitlePlaceholder,
          name = name,
          onNameInput = onNameInput,
          nameLabel = nameLabel,
          namePlaceholder = namePlaceholder,
          phone = phone,
          onPhoneInput = onPhoneInput,
          phoneLabel = phoneLabel,
          phonePlaceholder = phonePlaceholder,
          locality = locality,
          onLocalityInput = onLocalityInput,
          localityLabel = localityLabel,
          localityPlaceholder = localityPlaceholder,
          street = street,
          onStreetInput = onStreetInput,
          streetLabel = streetLabel,
          streetPlaceholder = streetPlaceholder,
          saveButton = saveButton,
          onSave = onSave,
          cancelButton = cancelButton,
          onCancelClick = onCancel,
          saving = saving,
          errorMessage = errorMessage,
      )
    }
  }
}
