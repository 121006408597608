package ui.screens.login.settingUp.email

import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.runtime.setValue
import api.traak.user.EmailUpdateResult
import api.traak.user.User
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.launch

class SetupEmailStateHolder(
    private val user: User,
    private val coroutineScope: CoroutineScope,
) : SetupEmailState {
  private var _email by mutableStateOf("")
  private var _loading by mutableStateOf(false)
  private var _error: SetupEmailError? by mutableStateOf(null)

  override val loading: Boolean
    get() = _loading

  override val error: SetupEmailError?
    get() = _error

  override var email
    get() = _email
    set(value) {
      _error = null
      _email = value
    }

  override fun validateEmail() {
    coroutineScope.launch {
      _loading = true
      val result = user.updateEmail(email)

      if (result == EmailUpdateResult.NeedsRelogin) {
        user.disconnect()
      } else if (result == EmailUpdateResult.EmailAlreadyInUse) {
        _error = SetupEmailError.EmailAlreadyInUse
      }

      _loading = false
    }
  }
}

@Composable
fun rememberSetupEmailState(
    user: User,
    coroutineScope: CoroutineScope = rememberCoroutineScope(),
): SetupEmailState {
  return remember(user, coroutineScope) { SetupEmailStateHolder(user, coroutineScope) }
}
