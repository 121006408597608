package ui.screens.integration.bexio.connection

import androidx.compose.runtime.Composable
import org.jetbrains.compose.web.attributes.ATarget
import org.jetbrains.compose.web.attributes.target
import org.jetbrains.compose.web.dom.A
import org.jetbrains.compose.web.dom.AttrBuilderContext
import org.jetbrains.compose.web.dom.P
import org.jetbrains.compose.web.dom.Text
import org.w3c.dom.HTMLDivElement
import tailwind.Auto
import tailwind.Full
import tailwind.TextScope
import tailwind.tailwind
import ui.color.BlackMedium
import ui.material.FullColumn
import ui.material.LogInWithBexioButton
import ui.screens.integration.bexio.BexioHolder
import ui.screens.integration.bexio.BexioIntegrationStage
import ui.screens.integration.bexio.BexioStageExplanation
import ui.screens.integration.bexio.SideBar
import ui.strings.LocalStrings

@Composable
fun BexioConnection(
    attrs: AttrBuilderContext<HTMLDivElement>? = null,
) {
  val state = rememberBexioConnectionState()
  val strings = LocalStrings.current

  BexioConnection(
      headline = strings.bexioIntegrationHeadline,
      title = strings.bexioIntegrationConnectionTitle,
      explanation = strings.bexioIntegrationConnectionExplanation,
      bexioExplanation = strings.bexioIntegrationConnectionBexioExplanation,
      bexioLinkText = strings.bexioIntegrationConnectionBexioLink,
      bexioLink = "https://www.traak.ch/partners/bexio",
      onLogInWithBexioClick = { state.loginWithBexio() },
      loading = state.loading,
      attrs = attrs,
  )
}

@Composable
fun BexioConnection(
    headline: String,
    title: String,
    explanation: String,
    bexioExplanation: String,
    bexioLinkText: String,
    bexioLink: String,
    onLogInWithBexioClick: () -> Unit,
    loading: Boolean,
    attrs: AttrBuilderContext<HTMLDivElement>? = null,
) {
  BexioHolder(
      headline = headline,
      attrs = attrs,
      sideBar = { SideBar(BexioIntegrationStage.Connection) },
  ) {
    FullColumn {
      BexioStageExplanation(title, explanation)

      LogInWithBexioButton(
          onLogInWithBexioClick = onLogInWithBexioClick,
          errorMessage = null,
          loading = loading,
          attrs = {
            tailwind {
              w(Full)
              mx(Auto)
              my(8)
            }
          })

      P(
          attrs = {
            tailwind {
              mt(4)
              text(color = BlackMedium) { body2() }
            }
          },
      ) {
        Text("$bexioExplanation ")
        A(
            href = bexioLink,
            attrs = {
              target(ATarget.Blank)
              tailwind { text(decoration = TextScope.Decoration.Underline) }
            },
        ) {
          Text(bexioLinkText)
        }
        Text(".")
      }
    }
  }
}
