package ui.screens.projectOverview

import androidx.compose.runtime.Composable
import org.jetbrains.compose.web.dom.AttrBuilderContext
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.P
import org.jetbrains.compose.web.dom.Text
import org.w3c.dom.HTMLDivElement
import tailwind.FlexItemScope
import tailwind.FlexScope
import tailwind.Full
import tailwind.Layout
import tailwind.TextScope
import tailwind.color.Gray050
import tailwind.tailwind
import ui.components.PageTitle
import ui.components.Tooltip
import ui.components.TooltipContainer
import ui.icons.Plus
import ui.material.FilledButton
import ui.material.RefreshButton

@Composable
fun TopBar(
    title: String,
    newProjectButton: String,
    onNewProjectClick: () -> Unit,
    onRefreshClick: () -> Unit,
    integratedWithBexio: Boolean,
    refreshingBexio: Boolean,
    synchronizeBexioTooltipHeader: String,
    synchronizeBexioTooltipParagraph: String,
    attrs: AttrBuilderContext<HTMLDivElement>? = null,
) {
  Div(
      attrs = {
        attrs?.invoke(this)
        tailwind {
          w(Full)
          flex(
              direction = FlexScope.Direction.Row,
              justify = Layout.Justify.Start,
              alignItems = Layout.AlignItems.Center,
          )
        }
      }) {
        PageTitle(
            title = title,
            attrs = { tailwind { flexItem(FlexItemScope.Grow.Grow) } },
        )

        if (integratedWithBexio) {
          TooltipContainer {
            RefreshButton(
                onClick = onRefreshClick,
                loading = refreshingBexio,
            )
            Tooltip(
                attrs = {
                  tailwind {
                    top(16)
                    w(72)
                    right(0)
                    p(4)
                  }
                }) {
                  P(
                      attrs = {
                        tailwind { text(color = Gray050) { subtitle1(size = TextScope.Size.Base) } }
                      }) {
                        Text(synchronizeBexioTooltipHeader)
                      }
                  P(
                      attrs = {
                        tailwind {
                          text(color = Gray050) {
                            body1(
                                size = TextScope.Size.Base,
                                weight = TextScope.Weight.Extralight,
                            )
                          }
                        }
                      }) {
                        Text(synchronizeBexioTooltipParagraph)
                      }
                }
          }
        } else {
          FilledButton(
              text = newProjectButton,
              icon = { Plus() },
              onClick = onNewProjectClick,
              attrs = {
                tailwind {
                  flex(direction = FlexScope.Direction.Row, alignItems = Layout.AlignItems.Center)
                  text { button() }
                }
              },
          )
        }
      }
}
