package tailwind

import org.jetbrains.compose.web.attributes.AttrsScope
import org.w3c.dom.Element
import tailwind.TextScope.Align
import tailwind.TextScope.Decoration
import tailwind.TextScope.Family
import tailwind.TextScope.Leading
import tailwind.TextScope.Overflow
import tailwind.TextScope.Size
import tailwind.TextScope.Tracking
import tailwind.TextScope.Transform
import tailwind.TextScope.Weight
import tailwind.TextScope.Whitespace
import tailwind.TextScope.WordBreak
import tailwind.color.Color
import tailwind.color.Opacity

class Text<TElement : Element>
internal constructor(
    builder: AttrsScope<TElement>,
    currentPrefix: String?,
) : Tailwind<TElement>(builder, currentPrefix), TextScope {
  override fun color(color: Color) = apply("text-$color")

  override fun opacity(opacity: Opacity) = apply("text-$opacity")

  // Fonts
  override fun family(type: Family) {
    val suffix =
        when (type) {
          Family.Sans -> "sans"
          Family.Mono -> "mono"
          Family.Serif -> "serif"
        }

    apply("font-${suffix}")
  }

  override fun size(type: Size) {
    val suffix =
        when (type) {
          Size.Xs -> "xs"
          Size.Sm -> "sm"
          Size.Base -> "base"
          Size.Lg -> "lg"
          Size.Xl -> "xl"
          Size.Xl2 -> "2xl"
          Size.Xl3 -> "3xl"
          Size.Xl4 -> "4xl"
          Size.Xl5 -> "5xl"
          Size.Xl6 -> "6xl"
          Size.Xl7 -> "7xl"
          Size.Xl8 -> "8xl"
          Size.Xl9 -> "9xl"
        }

    apply("text-${suffix}")
  }

  override fun weight(type: Weight) {
    val suffix =
        when (type) {
          Weight.Thin -> "thin"
          Weight.Extralight -> "extralight"
          Weight.Light -> "light"
          Weight.Normal -> "normal"
          Weight.Medium -> "medium"
          Weight.Semibold -> "semibold"
          Weight.Bold -> "bold"
          Weight.Extrabold -> "extrabold"
          Weight.Black -> "black"
        }

    apply("font-${suffix}")
  }

  override fun tracking(type: Tracking) {
    val suffix =
        when (type) {
          Tracking.Tighter -> "tighter"
          Tracking.Tight -> "tight"
          Tracking.Normal -> "normal"
          Tracking.Wide -> "wide"
          Tracking.Wider -> "wider"
          Tracking.Widest -> "widest"
        }

    apply("tracking-${suffix}")
  }

  override fun leading(type: Leading) {
    val suffix =
        when (type) {
          Leading.None -> "none"
          Leading.Tight -> "tight"
          Leading.Snug -> "snug"
          Leading.Normal -> "normal"
          Leading.Relaxed -> "relaxed"
          Leading.Loose -> "loose"
        }

    apply("leading-${suffix}")
  }

  override fun leading(amount: Int) = apply("leading-${amount}")

  override fun align(type: Align) {
    val suffix =
        when (type) {
          Align.Left -> "left"
          Align.Center -> "center"
          Align.Right -> "right"
          Align.Justify -> "justify"
        }

    apply("text-${suffix}")
  }

  override fun decorate(type: Decoration) {
    apply(
        when (type) {
          Decoration.Underline -> "underline"
          Decoration.LineThrough -> "line-through"
          Decoration.None -> "no-underline"
        })
  }

  override fun overflow(type: Overflow) {
    apply(
        when (type) {
          Overflow.Truncate -> "truncate"
          Overflow.Ellipsis -> "overflow-ellipsis"
          Overflow.Clip -> "overflow-clip"
        })
  }

  override fun whitespace(type: Whitespace) {
    val suffix =
        when (type) {
          Whitespace.Normal -> "normal"
          Whitespace.NoWrap -> "nowrap"
          Whitespace.Pre -> "pre"
          Whitespace.PreLine -> "pre-line"
          Whitespace.PreWrap -> "pre-wrap"
        }

    apply("whitespace-${suffix}")
  }

  override fun wordBreak(type: WordBreak) {
    val suffix =
        when (type) {
          WordBreak.Normal -> "normal"
          WordBreak.Words -> "words"
          WordBreak.All -> "all"
        }

    apply("break-${suffix}")
  }

  override fun transform(type: Transform) =
      apply(
          when (type) {
            Transform.Normal -> "normal-case"
            Transform.Uppercase -> "uppercase"
            Transform.Lowercase -> "lowercase"
            Transform.Capitalize -> "capitalize"
          })

  private fun applyDefaults(
      family: Family? = null,
      weight: Weight? = null,
      size: Size? = null,
      transform: Transform? = null,
      tracking: Tracking? = null,
  ) {
    family?.let { family(it) }
    weight?.let { weight(it) }
    size?.let { size(it) }
    transform?.let { transform(it) }
    tracking?.let { tracking(it) }
  }

  override fun headline1(
      family: Family?,
      weight: Weight?,
      size: Size?,
      transform: Transform?,
      tracking: Tracking?,
  ) =
      applyDefaults(
          family = family ?: Family.Sans,
          weight = weight ?: Weight.Medium,
          size = size ?: Size.Xl9,
          transform = transform,
          tracking = tracking,
      )

  override fun headline2(
      family: Family?,
      weight: Weight?,
      size: Size?,
      transform: Transform?,
      tracking: Tracking?,
  ) =
      applyDefaults(
          family = family ?: Family.Sans,
          weight = weight ?: Weight.Medium,
          size = size ?: Size.Xl7,
          transform = transform,
          tracking = tracking,
      )

  override fun headline3(
      family: Family?,
      weight: Weight?,
      size: Size?,
      transform: Transform?,
      tracking: Tracking?,
  ) =
      applyDefaults(
          family = family ?: Family.Sans,
          weight = weight ?: Weight.Medium,
          size = size ?: Size.Xl6,
          transform = transform,
          tracking = tracking,
      )

  override fun headline4(
      family: Family?,
      weight: Weight?,
      size: Size?,
      transform: Transform?,
      tracking: Tracking?,
  ) =
      applyDefaults(
          family = family ?: Family.Sans,
          weight = weight ?: Weight.Medium,
          size = size ?: Size.Xl4,
          transform = transform,
          tracking = tracking,
      )

  override fun headline5(
      family: Family?,
      weight: Weight?,
      size: Size?,
      transform: Transform?,
      tracking: Tracking?,
  ) =
      applyDefaults(
          family = family ?: Family.Sans,
          weight = weight ?: Weight.Semibold,
          size = size ?: Size.Xl3,
          transform = transform,
          tracking = tracking,
      )

  override fun headline6(
      family: Family?,
      weight: Weight?,
      size: Size?,
      transform: Transform?,
      tracking: Tracking?,
  ) =
      applyDefaults(
          family = family ?: Family.Sans,
          weight = weight ?: Weight.Medium,
          size = size ?: Size.Xl2,
          transform = transform,
          tracking = tracking,
      )

  override fun subtitle1(
      family: Family?,
      weight: Weight?,
      size: Size?,
      transform: Transform?,
      tracking: Tracking?,
  ) =
      applyDefaults(
          family = family ?: Family.Sans,
          weight = weight ?: Weight.Normal,
          size = size ?: Size.Xl,
          transform = transform,
          tracking = tracking,
      )

  override fun subtitle2(
      family: Family?,
      weight: Weight?,
      size: Size?,
      transform: Transform?,
      tracking: Tracking?,
  ) =
      applyDefaults(
          family = family ?: Family.Sans,
          weight = weight ?: Weight.Medium,
          size = size ?: Size.Lg,
          transform = transform,
          tracking = tracking,
      )

  override fun button(
      family: Family?,
      weight: Weight?,
      size: Size?,
      transform: Transform?,
      tracking: Tracking?,
  ) =
      applyDefaults(
          family = family ?: Family.Sans,
          weight = weight ?: Weight.Medium,
          size = size ?: Size.Lg,
          transform = transform ?: Transform.Uppercase,
          tracking = tracking ?: Tracking.Wide,
      )

  override fun body1(
      family: Family?,
      weight: Weight?,
      size: Size?,
      transform: Transform?,
      tracking: Tracking?,
  ) =
      applyDefaults(
          family = family ?: Family.Sans,
          weight = weight,
          size = size ?: Size.Xl,
          transform = transform,
          tracking = tracking,
      )

  override fun body2(
      family: Family?,
      weight: Weight?,
      size: Size?,
      transform: Transform?,
      tracking: Tracking?,
  ) =
      applyDefaults(
          family = family ?: Family.Sans,
          weight = weight,
          size = size ?: Size.Lg,
          transform = transform,
          tracking = tracking,
      )

  override fun numbers(
      family: Family?,
      weight: Weight?,
      size: Size?,
      transform: Transform?,
      tracking: Tracking?,
  ) =
      applyDefaults(
          family = family ?: Family.Mono,
          weight = weight,
          size = size ?: Size.Base,
          transform = transform,
          tracking = tracking,
      )

  override fun caption(
      family: Family?,
      weight: Weight?,
      size: Size?,
      transform: Transform?,
      tracking: Tracking?,
  ) =
      applyDefaults(
          family = family ?: Family.Sans,
          weight = weight ?: Weight.Medium,
          size = size ?: Size.Base,
          transform = transform,
          tracking = tracking,
      )

  override fun overline(
      family: Family?,
      weight: Weight?,
      size: Size?,
      transform: Transform?,
      tracking: Tracking?,
  ) =
      applyDefaults(
          family = family ?: Family.Sans,
          weight = weight ?: Weight.Medium,
          size = size ?: Size.Xs,
          transform = transform ?: Transform.Uppercase,
          tracking = tracking ?: Tracking.Widest,
      )
}
