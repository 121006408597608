package tailwind

import org.jetbrains.compose.web.attributes.AttrsScope
import org.w3c.dom.Element
import tailwind.FlexItemScope.*

class FlexItem<TElement : Element>
internal constructor(
    builder: AttrsScope<TElement>,
    currentPrefix: String?,
) : Tailwind<TElement>(builder, currentPrefix), FlexItemScope {

  override fun grow(type: Grow) {
    val suffix =
        when (type) {
          Grow.Grow -> "grow"
          Grow.Fixed -> "grow-0"
        }

    apply("flex-${suffix}")
  }

  override fun shrink(type: Shrink) {
    val suffix =
        when (type) {
          Shrink.Shrink -> "shrink"
          Shrink.Fixed -> "shrink-0"
        }

    apply("flex-${suffix}")
  }

  override fun order(type: Order) {
    val suffix =
        when (type) {
          Order.First -> "first"
          Order.Last -> "last"
          Order.None -> "none"
        }

    apply("order-${suffix}")
  }

  override val Int.order: Unit
    get() = apply("order-$this")

  override fun align(type: Align) {
    val suffix =
        when (type) {
          Align.Auto -> "auto"
          Align.Start -> "start"
          Align.End -> "end"
          Align.Center -> "center"
          Align.Baseline -> "baseline"
          Align.Stretch -> "stretch"
        }

    apply("self-${suffix}")
  }
}
