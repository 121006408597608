package ui.components.progressionPanel

import androidx.compose.runtime.Composable
import org.jetbrains.compose.web.dom.AttrBuilderContext
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.H2
import org.jetbrains.compose.web.dom.Text
import org.w3c.dom.HTMLDivElement
import tailwind.FlexItemScope
import tailwind.Full
import tailwind.Layout
import tailwind.TailwindScope
import tailwind.TextScope
import tailwind.color.Color
import tailwind.color.Gray100
import tailwind.color.Gray400
import tailwind.color.White
import tailwind.tailwind
import ui.color.BlackDark
import ui.color.Primary200
import ui.color.Primary300
import ui.color.Primary600
import ui.color.Secondary500
import ui.material.Card
import ui.material.Column
import ui.material.FullColumn
import ui.material.FullRow

/**
 * A [ProgressionPanel] is a stateless component used to display a series of [Step] that a user has
 * to take in order to progress on some complex task that we want him to do. For example, creating
 * an account, ...
 */
@Composable
fun ProgressionPanel(
    title: String,
    state: ProgressionPanelState,
    attrs: AttrBuilderContext<HTMLDivElement>? = null,
) {
  Card(
      attrs = {
        attrs?.invoke(this)
        tailwind {
          p(4)
          background(White)
        }
      }) {
        FullColumn(gap = 2) {
          Title(title)

          state.progression.forEach { step ->
            when (step.state) {
              StepState.Done -> Done(step)
              StepState.Current -> Current(step)
              StepState.Next -> Next(step)
            }
          }
        }
      }
}

@Composable
fun FullProgressionPanel(
    title: String,
    state: ProgressionPanelState,
    attrs: AttrBuilderContext<HTMLDivElement>? = null,
) {
  ProgressionPanel(
      title = title,
      state = state,
      attrs = {
        attrs?.invoke(this)
        tailwind { w(Full) }
      },
  )
}

@Composable
private fun Title(title: String) {
  H2(
      attrs = {
        tailwind {
          text(color = BlackDark, align = TextScope.Align.Center) { headline6() }

          mt(4)
          mb(8)
          w(Full)
        }
      },
  ) {
    Text(title)
  }
}

@Composable
private fun Done(step: Step) {
  DisplayStep(
      step = step,
      rowBgColor = Gray100,
      indicatorTextColor = Gray400,
      indicatorBgColor = Gray100,
      textColor = Gray400,
  )
}

@Composable
private fun Current(step: Step) {
  DisplayStep(
      step = step,
      rowBgColor = Primary300,
      indicatorTextColor = Secondary500,
      indicatorBgColor = Primary200,
      textColor = Secondary500,
  )
}

@Composable
private fun Next(step: Step) {
  DisplayStep(
      step = step,
      rowBgColor = White,
      indicatorTextColor = Primary600,
      indicatorBgColor = Primary200,
      textColor = Primary600,
  )
}

@Composable
private fun DisplayStep(
    step: Step,
    rowBgColor: Color,
    indicatorTextColor: Color,
    indicatorBgColor: Color,
    textColor: Color,
    attrs: AttrBuilderContext<HTMLDivElement>? = null,
) {
  FullRow(
      gap = 4,
      attrs = {
        attrs?.invoke(this)
        tailwind {
          round(TailwindScope.Radius.Medium)
          p(2)

          background(rowBgColor)
        }
      },
  ) {
    Indicator(
        text = step.indicator,
        textColor = indicatorTextColor,
        bgColor = indicatorBgColor,
    )

    Div(
        attrs = {
          tailwind {
            text(
                color = textColor,
            ) {
              subtitle2()
            }
          }
        }) {
          Text(step.text)
        }
  }
}

@Composable
private fun Indicator(
    text: String,
    textColor: Color,
    bgColor: Color,
) {
  Column(
      justify = Layout.Justify.Center,
      alignItems = Layout.AlignItems.Center,
      attrs = {
        tailwind {
          w(12)
          h(12)

          round(TailwindScope.Radius.Full)
          flexItem(shrink = FlexItemScope.Shrink.Fixed)

          text(color = textColor) { headline6() }
          background(color = bgColor)
        }
      },
  ) {
    Text(text)
  }
}
