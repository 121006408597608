package ui.screens.onboarding

import androidx.compose.runtime.Composable
import org.jetbrains.compose.web.dom.AttrBuilderContext
import org.jetbrains.compose.web.dom.Div
import org.w3c.dom.HTMLDivElement
import tailwind.FlexScope
import tailwind.Layout
import tailwind.TailwindScope
import tailwind.color.Black
import tailwind.color.White
import tailwind.seventh
import tailwind.tailwind
import ui.icons.Traak
import ui.icons.TraakHouse

@Composable
fun Onboarding(
    attrs: AttrBuilderContext<HTMLDivElement>? = null,
) {
  Div(
      attrs = {
        attrs?.invoke(this)
        tailwind {
          w(TailwindScope.Width.Screen)
          h(TailwindScope.Height.Screen)

          background(color = White)

          flex(
              direction = FlexScope.Direction.Row,
              justify = Layout.Justify.Center,
              alignItems = Layout.AlignItems.Center,
          )
        }
      }) {
        Div(
            attrs = {
              tailwind {
                w(1.seventh)

                flex(
                    direction = FlexScope.Direction.Column,
                    justify = Layout.Justify.Center,
                    alignItems = Layout.AlignItems.Center,
                )

                text(color = Black)
                animate(TailwindScope.Animation.Pulse)
              }
            }) {
              TraakHouse()
              Traak(attrs = { tailwind { mt(-4) } })
            }
      }
}
