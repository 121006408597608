package ui.screens.account

import androidx.compose.runtime.Composable
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import api.settings.LocalSettings
import api.settings.SettingsApi
import org.jetbrains.compose.web.dom.AttrBuilderContext
import org.w3c.dom.HTMLDivElement
import tailwind.tailwind
import ui.components.PageHeader
import ui.layouts.HalfScreenLayout
import ui.material.FullColumn
import ui.material.Switch
import ui.strings.LocalStrings

@Composable
fun Account(
    attrs: AttrBuilderContext<HTMLDivElement>? = null,
) {
  val settingsApi: SettingsApi = LocalSettings.current
  val strings = LocalStrings.current

  val orderByFirstName by settingsApi.orderByFirstName.collectAsState()
  val showFirstNameFirst by settingsApi.showFirstNameFirst.collectAsState()

  HalfScreenLayout(attrs = attrs) {
    PageHeader(
        strings.accountTitle,
        attrs = { tailwind { mb(8) } },
    )

    FullColumn {
      DisplaySection(
          orderByFirstName = orderByFirstName,
          onOrderByFirstNameSwitchClick = { settingsApi.setOrderByFirstNameTo(!orderByFirstName) },
          showFirstNameFirst = showFirstNameFirst,
          onShowFirstNameFirstSwitchClick = {
            settingsApi.setShowFirstNameFirstTo(!showFirstNameFirst)
          },
      )
    }
  }
}

@Composable
private fun DisplaySection(
    orderByFirstName: Boolean,
    onOrderByFirstNameSwitchClick: () -> Unit,
    showFirstNameFirst: Boolean,
    onShowFirstNameFirstSwitchClick: () -> Unit,
) {
  val strings = LocalStrings.current

  SettingsSection(strings.accountDisplaySettingsTitle) {
    SettingsItem(
        title = strings.accountOrderByFirstNameTitle,
        explanation =
            if (orderByFirstName) {
              strings.accountOrderByFirstNameExplanationActive
            } else {
              strings.accountOrderByFirstNameExplanationInactive
            },
    ) {
      Switch(
          active = orderByFirstName,
          onClick = { onOrderByFirstNameSwitchClick() },
      )
    }

    SettingsItem(
        title = strings.accountShowFirstNameFirstTitle,
        explanation =
            if (showFirstNameFirst) {
              strings.accountShowFirstNameFirstExplanationActive
            } else {
              strings.accountShowFirstNameFirstExplanationInactive
            },
    ) {
      Switch(
          active = showFirstNameFirst,
          onClick = { onShowFirstNameFirstSwitchClick() },
      )
    }
  }
}
