package ui.components

import androidx.compose.runtime.Composable
import org.jetbrains.compose.web.dom.AttrBuilderContext
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.P
import org.jetbrains.compose.web.dom.Text
import org.w3c.dom.HTMLDivElement
import tailwind.tailwind
import ui.color.BlackMedium
import ui.layouts.HalfScreenLayout

/**
 * This composable is usually used in conjunction with [HalfScreenLayout].
 *
 * It consists of a main title and a subtitle, which is lightly greyed out.
 */
@Composable
fun PageHeader(
    title: String,
    explanation: String? = null,
    attrs: AttrBuilderContext<HTMLDivElement>? = null,
) {
  Div(attrs) {
    PageTitle(title)

    if (explanation != null) {
      P(
          attrs = {
            tailwind {
              text(color = BlackMedium) { subtitle2() }
              mb(6)
            }
          }) {
            Text(explanation)
          }
    }
  }
}
