package ui.components.cancellableSnackbar

import androidx.compose.runtime.Composable
import kotlin.math.PI
import org.jetbrains.compose.web.dom.AttrBuilderContext
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Text
import org.w3c.dom.HTMLDivElement
import tailwind.TailwindScope
import tailwind.TextScope
import tailwind.color.Color
import tailwind.color.Gray200
import tailwind.tailwind
import ui.color.Primary500
import ui.components.snackbar.Snackbar
import ui.icons.Circle
import ui.icons.Svg
import ui.material.SmallOutlineButton

@Composable
fun CancellableSnackbar(
    message: String,
    buttonText: String,
    progress: Double,
    onButtonClick: () -> Unit,
    bottom: Int? = null,
    backgroundColor: Color? = null,
    borderColor: Color? = null,
    attrs: AttrBuilderContext<HTMLDivElement>? = null,
) {
  val radius = 25
  val stroke = 5

  Snackbar(
      bottom = bottom,
      backgroundColor = backgroundColor,
      borderColor = borderColor,
      attrs = attrs,
  ) {
    CircularProgression(
        radius = radius,
        stroke = stroke,
        progress = progress,
        outerCircleColor = Gray200,
        progressCircleColor = Primary500,
    )

    Div(
        attrs = {
          tailwind {
            maxW(TailwindScope.MaxWidth.Md)
            text { body1(size = TextScope.Size.Base) }

            mr(2)
          }
        },
    ) {
      Text(message)
    }

    SmallOutlineButton(
        text = buttonText,
        onClick = onButtonClick,
    )
  }
}

/**
 * Draws a SVG of a circular progression
 *
 * Initially from: https://css-tricks.com/building-progress-ring-quickly/
 *
 * @param radius the radius size in pixels
 * @param stroke the stroke width in pixels
 * @param progress the progress [0.0 - 1.0]
 * @param outerCircleColor the color of the outer circle
 * @param progressCircleColor the color of the inner circle, or the progression circle
 */
@Composable
fun CircularProgression(
    radius: Int,
    stroke: Int,
    progress: Double,
    outerCircleColor: Color,
    progressCircleColor: Color,
) {
  val normalizedRadius = radius - (stroke * 2)
  val circumference = normalizedRadius * 2 * PI
  val strokeDashoffset = circumference - (progress * circumference)

  Svg(
      attrs = {
        attr("height", (2 * radius).toString())
        attr("width", (2 * radius).toString())
      }) {
        Circle(
            attrs = {
              tailwind { text(color = outerCircleColor) }

              attr("r", normalizedRadius.toString())
              attr("cx", radius.toString())
              attr("cy", radius.toString())
              attr("stroke", "currentColor")
              attr("stroke-width", stroke.toString())
              attr("fill", "transparent")
            },
        )
        Circle(
            attrs = {
              tailwind { text(color = progressCircleColor) }
              classes("circularProgressionInner")

              attr("r", normalizedRadius.toString())
              attr("cx", radius.toString())
              attr("cy", radius.toString())
              attr("stroke", "currentColor")
              attr("stroke-width", stroke.toString())
              attr("stroke-linecap", "round")
              attr("stroke-dasharray", "$circumference $circumference")
              attr("style", "stroke-dashoffset: $strokeDashoffset")
              attr("fill", "transparent")
            },
        )
      }
}
