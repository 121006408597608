package ui.screens.teamManagement

import androidx.compose.runtime.Composable
import api.traak.Team
import api.traak.user.User
import org.jetbrains.compose.web.dom.AttrBuilderContext
import org.w3c.dom.HTMLDivElement
import tailwind.Full
import tailwind.Layout
import tailwind.tailwind
import tailwind.twelfth
import ui.layouts.FullScreenLayout
import ui.material.Column
import ui.material.Row

@Composable
fun TeamManagement(
    currentUser: User,
    team: Team,
    attrs: AttrBuilderContext<HTMLDivElement>? = null,
) {

  FullScreenLayout(attrs) {
    Row(
        alignItems = Layout.AlignItems.Start,
        gap = 4,
        attrs = { tailwind { pt(8) } },
    ) {
      Column(attrs = { tailwind { w(5.twelfth) } }) {
        val sharedAttrs: AttrBuilderContext<HTMLDivElement> = { tailwind { w(Full) } }

        Invitation(
            teamId = team.id,
            attrs = sharedAttrs,
        )

        Requests(
            team = team,
            attrs = sharedAttrs,
        )
      }

      Column(attrs = { tailwind { w(7.twelfth) } }) { TeamMembers(currentUser, team) }
    }
  }
}
