import androidx.compose.runtime.Composable
import androidx.compose.runtime.CompositionLocalProvider
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.rememberCoroutineScope
import api.bexio.Bexio
import api.bexio.BexioApi
import api.bexio.LocalBexioApi
import api.settings.Language
import api.settings.LocalSettings
import api.settings.LocalStorageSettings
import api.settings.SettingsApi
import api.traak.LocalTraakApi
import api.traak.Traak
import api.traak.TraakApi
import api.traak.authManager.phone.TraakPhoneAuthenticator
import configuration.process
import firebase.app.FirebaseOptions
import firebase.app.initializeApp
import firebase.auth.getAuth
import firebase.firestore.getFirestore
import firebase.functions.getFunctions
import kotlin.coroutines.EmptyCoroutineContext
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.flow.map
import navigation.Navigation
import org.jetbrains.compose.web.css.Position
import org.jetbrains.compose.web.css.position
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.renderComposableInBody
import ui.components.fullscreenModal.FullScreenModalManagerImpl
import ui.components.fullscreenModal.LocalFullScrenModalManager
import ui.strings.EnglishStrings
import ui.strings.FrenchStrings
import ui.strings.GermanStrings
import ui.strings.LocalStrings
import ui.strings.PortugueseStrings

/** The main entry point of the Traak SPA. */
fun main() {
  // We need the side effects of 'require' so we don't care about return value.
  // We need to specify 'as Any' for kotlin
  kotlinext.js.require("./app.css") as Any

  val options =
      FirebaseOptions().apply {
        apiKey = process.env.FIREBASE_API_KEY
        authDomain = process.env.FIREBASE_AUTH_DOMAIN
        projectId = process.env.FIREBASE_PROJECT_ID
        storageBucket = process.env.FIREBASE_STORAGE_BUCKET
        messagingSenderId = process.env.FIREBASE_MESSAGING_SENDER_ID
        appId = process.env.FIREBASE_APP_ID
        measurementId = process.env.FIREBASE_MEASUREMENT_ID
      }

  val cloudFunctionUrl = process.env.FIREBASE_CLOUD_FUNCTIONS_URL

  val app = initializeApp(options)
  val auth = getAuth(app)
  auth.settings.appVerificationDisabledForTesting = false

  val firestore = getFirestore()
  val functions = getFunctions()

  val authCoroutineScope = CoroutineScope(EmptyCoroutineContext)
  val phoneAuthenticator = TraakPhoneAuthenticator(auth)
  val api: TraakApi =
      Traak(
          auth = auth,
          firestore = firestore,
          functions = functions,
          cloudFunctionUrl = cloudFunctionUrl,
          phoneAuthenticator = phoneAuthenticator,
          authCoroutineScope = authCoroutineScope,
          baseUrl = process.env.BASE_URL,
      )
  val bexio: BexioApi =
      Bexio(
          baseUrl = process.env.BASE_URL,
      )
  val userPreferences: SettingsApi = LocalStorageSettings()

  renderComposableInBody {
    val strings =
        userPreferences.language
            .map {
              when (userPreferences.language.value) {
                Language.French -> FrenchStrings
                Language.Portuguese -> PortugueseStrings
                Language.English -> EnglishStrings
                Language.German -> GermanStrings
              }
            }
            .collectAsState(FrenchStrings)

    val fullScreenModalManager =
        FullScreenModalManagerImpl(
            coroutineScope = rememberCoroutineScope(),
        )

    CompositionLocalProvider(
        LocalFullScrenModalManager provides fullScreenModalManager,
        LocalTraakApi provides api,
        LocalBexioApi provides bexio,
        LocalSettings provides userPreferences,
        LocalStrings provides strings.value,
    ) {
      StackContext(2) { fullScreenModalManager.display() }

      StackContext(1) { Navigation() }
    }
  }
}

@Composable
private fun StackContext(
    zIndex: Int,
    content: @Composable () -> Unit,
) {
  Div(
      attrs = {
        style {
          position(Position.Relative)
          this.property("z-index", zIndex)
        }
      },
  ) {
    content()
  }
}
