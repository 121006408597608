package ui.material

import androidx.compose.runtime.Composable
import org.jetbrains.compose.web.dom.AttrBuilderContext
import org.jetbrains.compose.web.dom.Div
import org.w3c.dom.HTMLDivElement
import tailwind.FlexScope
import tailwind.TailwindScope
import tailwind.color.Opacity100
import tailwind.color.White
import tailwind.tailwind

@Composable
fun PopUpMenu(
    attrs: AttrBuilderContext<HTMLDivElement>? = null,
    content: @Composable () -> Unit,
) {
  Div(
      attrs = {
        attrs?.invoke(this)

        tailwind {
          flex(
              direction = FlexScope.Direction.Column,
          )
          position(TailwindScope.Position.Absolute)
          overflow(
              type = TailwindScope.OverflowType.Auto,
              direction = TailwindScope.OverflowDirection.Y,
          )
          overscroll(TailwindScope.Overscroll.Contain)
          maxH(96)
          z(50)

          background(color = White, opacity = Opacity100)
          CardTailwindScope(this)
        }
      },
  ) {
    content()
  }
}
