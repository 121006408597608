package navigation

import androidx.compose.runtime.Composable
import api.traak.Project
import api.traak.Team
import api.traak.user.User
import app.softwork.routingcompose.RouteBuilder
import app.softwork.routingcompose.Router
import kotlinx.browser.window
import ui.components.navmenu.AccountTab
import ui.components.navmenu.ProjectsTab
import ui.components.navmenu.TasksTab
import ui.components.navmenu.TeamTab
import ui.layouts.NavigationPage
import ui.screens.account.Account
import ui.screens.integration.bexio.connection.BexioConnection
import ui.screens.integration.bexio.synchronising.BexioSynchronising
import ui.screens.integration.bexio.teamSelection.BexioTeamSelection
import ui.screens.login.Login
import ui.screens.login.newLogin.signInWithEmailLink.SignInWithEmailLink
import ui.screens.login.settingUp.SettingUp
import ui.screens.memberDetail.MemberDetail
import ui.screens.memberEdition.MemberEdition
import ui.screens.newProject.NewProject
import ui.screens.newTeam.NewTeam
import ui.screens.onboarding.Onboarding
import ui.screens.projectDetail.ProjectDetail
import ui.screens.projectEdition.ProjectEdition
import ui.screens.projectOverview.ProjectOverview
import ui.screens.register.Register
import ui.screens.requestTeamAccess.RequestTeamAccess
import ui.screens.tasks.Tasks
import ui.screens.teamManagement.TeamManagement
import ui.screens.teamOverview.TeamOverview
import ui.screens.teamSelection.TeamSelection

typealias Routing = @Composable (RouteBuilder.() -> Unit)

val loadingRoute: Routing = { noMatch { Onboarding() } }

val disconnectedRoute: Routing = {
  route(signInWithEmailLink) { SignInWithEmailLink(Router.current.currentPath.toString()) }
  route(login) { noMatch { Login() } }
  route(register) { noMatch { Register() } }
  route(integration) { route(bexio) { noMatch { BexioConnection() } } }

  noMatch { Login() }
}

fun settingUpRoute(
    user: User,
): Routing = {
  route(signInWithEmailLink) { SignInWithEmailLink(Router.current.currentPath.toString(), user) }

  noMatch { SettingUp(user) }
}

fun authenticatedRoute(
    user: User,
    teams: List<Team>,
): Routing = {
  route(signInWithEmailLink) { SignInWithEmailLink(Router.current.currentPath.toString(), user) }

  route(team) {
    route(select) { noMatch { TeamSelection(teams) } }
    route(new) { noMatch { NewTeam(user) } }
    route(request) { noMatch { RequestTeamAccess(user) } }
  }

  route(integration) { route(bexio) { noMatch { BexioTeamSelection(user, teams) } } }

  noMatch { TeamSelection(teams) }
}

fun connectedRoute(
    user: User,
    currentTeam: Team,
): Routing = {
  route(signInWithEmailLink) { SignInWithEmailLink(Router.current.currentPath.toString(), user) }

  route(account) {
    NavigationPage(
        user = user,
        activeElement = AccountTab,
    ) {
      noMatch { Account() }
    }
  }

  route(tasks) { NavigationPage(user, activeElement = TasksTab) { Tasks(team = currentTeam) } }

  route(team) {
    route(management) {
      NavigationPage(user = user, activeElement = null) {
        route(memberEdition) {
          string { userId ->
            MemberEdition(
                currentTeam,
                User.Id(userId),
            )
          }
        }

        noMatch { TeamManagement(user, currentTeam) }
      }
    }

    route(detail) {
      NavigationPage(user = user, activeElement = TeamTab) {
        string { userId -> MemberDetail(currentTeam, User.Id(userId)) }
        noMatch { TeamOverview(currentTeam) }
      }
    }

    noMatch {
      NavigationPage(
          user = user,
          activeElement = TeamTab,
      ) {
        TeamOverview(currentTeam)
      }
    }
  }

  route(project) {
    NavigationPage(
        user = user,
        activeElement = ProjectsTab,
    ) {
      route(new) {
        noMatch {
          // Can't add projects if integrated to bexio
          if (currentTeam.integration.bexio) {
            ProjectOverview(currentTeam)
          } else {
            NewProject(currentTeam)
          }
        }
      }

      route(detail) {
        string { projectId -> ProjectDetail(Project.Id(projectId), currentTeam) }
        noMatch { ProjectOverview(currentTeam) }
      }

      route(edit) {
        string { projectId -> ProjectEdition(currentTeam.id, Project.Id(projectId)) }
        noMatch { ProjectOverview(currentTeam) }
      }

      noMatch { ProjectOverview(currentTeam) }
    }
  }

  route(integration) {
    route(bexio) {
      val code = parameters?.map?.get("code")
      val error = parameters?.map?.get("error")

      BexioSynchronising(
          user = user,
          team = currentTeam,
          code = code?.first(),
          error = error?.first(),
      )
    }
  }

  noMatch {
    NavigationPage(
        user = user,
        activeElement = ProjectsTab,
    ) {
      ProjectOverview(currentTeam)
    }
  }
}

/** Static function used to go back in navigation history */
fun Router.Companion.back() = window.history.back()
