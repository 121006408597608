package ui.components.chips

import androidx.compose.runtime.Composable
import org.jetbrains.compose.web.dom.AttrBuilderContext
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Span
import org.jetbrains.compose.web.dom.Text
import org.w3c.dom.HTMLDivElement
import tailwind.FlexItemScope.Grow
import tailwind.FlexScope
import tailwind.Layout
import tailwind.TailwindScope.Cursor
import tailwind.TailwindScope.Outline
import tailwind.TailwindScope.Radius
import tailwind.TailwindScope.SelectType
import tailwind.TailwindScope.Shadow
import tailwind.color.Transparent
import tailwind.color.White
import tailwind.tailwind
import ui.color.BlackDark
import ui.color.BlackMedium
import ui.color.Primary100
import ui.color.Primary300

@Composable
fun Chip(
    text: String,
    onClick: (() -> Unit)? = null,
    attrs: AttrBuilderContext<HTMLDivElement>? = null,
    leadingContent: (@Composable () -> Unit)? = null,
    trailingContent: (@Composable () -> Unit)? = null,
) {
  val cursorType = if (onClick != null) Cursor.Pointer else Cursor.Default
  val borderWidth = 2

  Div(
      attrs = {
        attrs?.invoke(this)
        onClick?.let { onClick { it() } }

        tailwind {
          px(4)
          py(2)
          flex(
              direction = FlexScope.Direction.Row,
              alignItems = Layout.AlignItems.Center,
              gap = 4,
          )

          background(color = Primary100)
          border(color = Transparent, width = borderWidth)
          text(color = BlackMedium) { body2() }

          round(Radius.Medium)
          outline(Outline.None)
          transition()

          focus {
            background(color = White)
            outline(Outline.None)
            shadow(Shadow.Normal)
          }

          hover {
            border(color = Primary300, width = borderWidth)
            text(color = BlackDark) {}
          }

          select(SelectType.None)
          cursor(cursorType)
        }
      },
  ) {
    leadingContent?.invoke()

    Span(
        attrs = {
          tailwind {
            background(color = Transparent)
            flexItem(grow = Grow.Grow)
            outline(Outline.None)
            select(SelectType.None)
          }
        },
    ) {
      Text(text)
    }

    trailingContent?.invoke()
  }
}
