package ui.screens.projectOverview

import androidx.compose.runtime.Composable
import api.traak.Project
import org.jetbrains.compose.web.dom.AttrBuilderContext
import org.jetbrains.compose.web.dom.Span
import org.jetbrains.compose.web.dom.Text
import org.w3c.dom.HTMLDivElement
import tailwind.TextScope
import tailwind.tailwind
import ui.material.Card
import ui.material.ClickableListItem

@Composable
fun ProjectList(
    projects: List<Project>,
    onProjectClick: (Project) -> Unit,
    attrs: AttrBuilderContext<HTMLDivElement>? = null,
) {
  Card(attrs) {
    projects.forEach { project ->
      ClickableListItem(onClick = { onProjectClick(project) }) {
        Span(
            attrs = { tailwind { text { body1(weight = TextScope.Weight.Semibold) } } },
        ) {
          Text(project.title)
        }
      }
    }
  }
}
