package tailwind

import org.jetbrains.compose.web.attributes.AttrsScope
import org.w3c.dom.Element
import tailwind.color.Color
import tailwind.color.Opacity

class Border<TElement : Element>
internal constructor(
    builder: AttrsScope<TElement>,
    currentPrefix: String?,
) : Tailwind<TElement>(builder, currentPrefix), BorderScope {

  override fun Int.width(side: Side) {
    val suffix =
        when (this) {
          1 -> null
          else -> "-$this"
        }

    apply("border${side.prefix.orEmpty()}${suffix.orEmpty()}")
  }

  override fun style(type: BorderStyle) = apply("border-$type")

  override fun color(color: Color) = apply("border-$color")

  override fun opacity(opacity: Opacity) = apply("border-$opacity")
}
