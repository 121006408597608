package ui.screens.integration.bexio.teamSelection

import api.traak.Team
import ui.screens.newTeam.NewTeamState

/**
 * Our main composable can in two distinct modes
 *
 * Either we select a team to connect with; or we can be creating a new team
 */
sealed class TeamSelectionMode {
  object SelectingTeam : TeamSelectionMode()

  object CreatingTeam : TeamSelectionMode()
}

interface BexioTeamSelectionState : NewTeamState {
  /** The curent selection mode of our composable */
  val mode: TeamSelectionMode

  /**
   * Selects a team
   *
   * @param team The team to select
   */
  fun selectTeam(team: Team): Unit

  /** Switches between [TeamSelectionMode.SelectingTeam] and [TeamSelectionMode.CreatingTeam] */
  fun switchMode(): Unit
}
