package ui.components.navmenu

import androidx.compose.runtime.Composable
import org.jetbrains.compose.web.dom.AttrBuilderContext
import org.w3c.dom.HTMLDivElement
import tailwind.TextScope
import tailwind.tailwind
import ui.material.PopUpMenu

@Composable
fun DetailMenu(
    onTeamManagementClick: () -> Unit,
    onSwitchTeamClick: () -> Unit,
    onSwitchLanguageClick: () -> Unit,
    onLogOutClick: () -> Unit,
    attrs: AttrBuilderContext<HTMLDivElement>? = null,
) {
  PopUpMenu(
      attrs = {
        attrs?.invoke(this)
        tailwind { text { subtitle2(size = TextScope.Size.Base) } }
      }) {
        TeamManagement(onClick = onTeamManagementClick)
        SwitchTeam(onClick = onSwitchTeamClick)
        SwitchLanguage(onClick = onSwitchLanguageClick)
        Help()
        LogOut(onClick = onLogOutClick)
      }
}
