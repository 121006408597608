package ui.screens.teamManagement

import androidx.compose.runtime.Composable
import androidx.compose.runtime.key
import api.traak.AccessRequest
import api.traak.Team
import org.jetbrains.compose.web.dom.AttrBuilderContext
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.P
import org.jetbrains.compose.web.dom.Text
import org.w3c.dom.HTMLDivElement
import tailwind.Full
import tailwind.tailwind
import ui.color.BlackMedium
import ui.components.PageTitle
import ui.data.Loadable
import ui.material.Card
import ui.screens.projectOverview.Loading
import ui.strings.LocalStrings

@Composable
fun Requests(
    team: Team,
    attrs: AttrBuilderContext<HTMLDivElement>? = null,
) {
  val strings = LocalStrings.current
  val state = rememberRequestState(team)

  Requests(
      title = strings.teamSettingsRequestsTitle,
      loadableRequests = state.loadableRequests,
      onAcceptClick = { state.onAcceptRequest(it) },
      onRejectClick = { state.onRejectRequest(it) },
      attrs = attrs,
  )
}

@Composable
fun Requests(
    title: String,
    loadableRequests: Loadable<List<AccessRequest>>,
    onAcceptClick: (AccessRequest) -> Unit,
    onRejectClick: (AccessRequest) -> Unit,
    attrs: AttrBuilderContext<HTMLDivElement>? = null,
) {
  Div(attrs = attrs) {
    PageTitle(title)

    val sharedAttr: AttrBuilderContext<HTMLDivElement> = { tailwind { w(Full) } }
    when (loadableRequests) {
      is Loadable.Loading -> Loading(sharedAttr)
      is Loadable.Loaded ->
          if (loadableRequests.resource.isEmpty()) {
            NoRequests(sharedAttr)
          } else {
            Card(attrs = sharedAttr) {
              loadableRequests.resource.forEach {
                key(it.uid) {
                  RequestItem(
                      request = it,
                      onAcceptClick = { onAcceptClick(it) },
                      onRejectClick = { onRejectClick(it) },
                  )
                }
              }
            }
          }
    }
  }
}

@Composable
fun NoRequests(
    attrs: AttrBuilderContext<HTMLDivElement>? = null,
) {
  val strings = LocalStrings.current

  Div(attrs) {
    P(attrs = { tailwind { text(color = BlackMedium) { body1() } } }) {
      Text(strings.teamSettingsNoRequests)
    }
  }
}
