package ui.data

import androidx.compose.runtime.Composable
import androidx.compose.runtime.State
import androidx.compose.runtime.collectAsState
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.map
import ui.data.Loadable.Loaded
import ui.data.Loadable.Loading

/**
 * Represents a type that has a [Loading] and a [Loaded] state. Usually, the [Loading] state is the
 * initial state, while [Loaded] is used when the data has been fetched or is available somehow.
 */
sealed interface Loadable<out T> {
  /** State in which the data is not available but is getting fetched */
  object Loading : Loadable<Nothing> {
    override fun get(): Nothing? = null
  }

  /** State in which the data has been made available */
  data class Loaded<out T>(val resource: T) : Loadable<T> {
    override fun get(): T? = resource
  }

  fun get(): T?
}

/** Helper function used to filter Loadable lists */
fun <T> Loadable<List<T>>.filter(contains: (T) -> Boolean) =
    when (this) {
      Loading -> emptyList()
      is Loaded -> resource.filter(contains).sortedBy(contains)
    }

fun <T> Loadable<List<T>>.sortedWith(comparator: Comparator<in T>): Loadable<List<T>> {
  return when (this) {
    is Loaded -> Loaded(this.resource.sortedWith(comparator))
    Loading -> Loading
  }
}

/** Helper function used to filter Loadable lists */
fun <A, B> Loadable<A>.map(f: (A) -> (B)): Loadable<B> =
    when (this) {
      Loading -> Loading
      is Loaded -> Loaded(f(resource))
    }

/** Helper function */
fun <T> Loadable<List<T>>.toList(): List<T> =
    when (this) {
      Loading -> emptyList()
      is Loaded -> resource
    }

fun <T> Flow<T>.asLoadable(): Flow<Loadable<T>> = this.map { Loaded(it) }

@Composable
fun <T> Flow<Loadable<T>>.collectAsState(): State<Loadable<T>> = this.collectAsState(Loading)
