package ui.screens.login.settingUp.phone

import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.runtime.setValue
import api.traak.AuthResult
import api.traak.toErrorMessage
import api.traak.user.User
import kotlinx.browser.window
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.launch
import ui.strings.LocalStrings
import ui.strings.LocalizedStrings

class SetupPhoneStateHolder(
    private val user: User,
    private val strings: LocalizedStrings,
    private val coroutineScope: CoroutineScope,
) : SetupPhoneState {
  private var _loading by mutableStateOf(false)
  private var _currentState by mutableStateOf(SetupPhoneProgression.RetrievingPhoneNumber)
  private var _errorMsg by mutableStateOf<String?>(null)
  private var _phoneNumber by mutableStateOf("")
  private var _smsCode by mutableStateOf("")

  override val loading: Boolean
    get() = _loading

  override val currentState: SetupPhoneProgression
    get() = _currentState

  override val loginButtonId = "phoneLinkButtonId"
  override var phoneNumber
    get() = _phoneNumber
    set(value) {
      _phoneNumber = value
      _errorMsg = null
    }

  override var smsCode
    get() = _smsCode
    set(value) {
      _smsCode = value
      _errorMsg = null
    }

  override val errorMsg: String?
    get() = _errorMsg

  override fun sendSms() {
    coroutineScope.launch {
      _loading = true

      val result = user.phoneAuthenticator.linkWithPhoneNumber(phoneNumber, loginButtonId)

      if (result == AuthResult.Success) {
        _currentState = SetupPhoneProgression.ValidatingPhoneNumber
      } else {
        _errorMsg = result.toErrorMessage(strings)
      }

      _loading = false
    }
  }

  override fun validateSmsCode() {
    coroutineScope.launch {
      _loading = true

      val result = user.phoneAuthenticator.validateSmsCode(smsCode)

      _loading = false

      if (result != AuthResult.Success) {
        _errorMsg = result.toErrorMessage(strings)
        return@launch
      }

      window.location.reload()
    }
  }
}

@Composable
fun rememberSetupPhoneState(
    user: User,
    strings: LocalizedStrings = LocalStrings.current,
    coroutineScope: CoroutineScope = rememberCoroutineScope(),
): SetupPhoneState {
  return remember(user, coroutineScope) {
    SetupPhoneStateHolder(
        user,
        strings,
        coroutineScope,
    )
  }
}
