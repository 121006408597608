package ui.screens.login.settingUp.name

import androidx.compose.runtime.Composable
import api.traak.user.User
import org.jetbrains.compose.web.attributes.AutoComplete
import org.jetbrains.compose.web.dom.AttrBuilderContext
import org.jetbrains.compose.web.dom.Text
import org.w3c.dom.HTMLDivElement
import tailwind.Full
import tailwind.tailwind
import ui.material.FilledButton
import ui.material.FullColumn
import ui.material.TextInput
import ui.screens.login.settingUp.Explanation
import ui.strings.LocalStrings

@Composable
fun SetupName(
    user: User,
    attrs: AttrBuilderContext<HTMLDivElement>? = null,
) {
  val strings = LocalStrings.current
  val state = rememberSetupNameState(user)

  FullColumn(
      gap = 8,
      attrs = attrs,
  ) {
    Explanation(strings.setupNameTitle) { Text(strings.setupNameExplanation) }

    FullColumn(
        gap = 4,
    ) {
      TextInput(
          value = state.displayName,
          onInput = { state.displayName = it },
          label = strings.setupNameFormNameLabel,
          placeholder = strings.setupNameFormNamePlaceholder,
          autoComplete = AutoComplete.name,
          attrs = { tailwind { w(Full) } },
      )

      FilledButton(
          text = strings.setupNameFormContinueButton,
          onClick = { state.saveName() },
          loading = state.loading,
          attrs = { tailwind { w(Full) } },
      )
    }
  }
}
