package firebase.firestore

/**
 * The direction of a [orderBy] clause is specified as 'desc' or 'asc' (descending or ascending).
 */
enum class OrderByDirection {
  ASC,
  DESC;

  override fun toString(): String =
      when (this) {
        ASC -> "asc"
        DESC -> "desc"
      }
}
