package ui.layouts

import androidx.compose.runtime.Composable
import org.jetbrains.compose.web.dom.AttrBuilderContext
import org.w3c.dom.HTMLDivElement
import tailwind.half
import tailwind.tailwind

@Composable
fun HalfScreenLayout(
    attrs: AttrBuilderContext<HTMLDivElement>? = null,
    content: @Composable () -> Unit,
) =
    CenteredLayout(
        attrs = {
          attrs?.invoke(this)
          tailwind {
            w(1.half)
            py(16)
          }
        },
        content = content,
    )
