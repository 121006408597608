package tailwind

@TailwindDsl
interface FlexItemScope {
  enum class Grow {
    Grow,
    Fixed,
  }

  fun grow(type: Grow)

  enum class Shrink {
    Shrink,
    Fixed,
  }

  fun shrink(type: Shrink)

  enum class Order {
    First,
    Last,
    None,
  }

  fun order(type: Order)

  val Int.order: Unit

  enum class Align {
    Auto,
    Start,
    End,
    Center,
    Baseline,
    Stretch,
  }

  fun align(type: Align)
}
