package ui.components.calendar

import androidx.compose.runtime.Composable
import kotlinx.datetime.LocalDate
import org.jetbrains.compose.web.dom.AttrBuilderContext
import org.jetbrains.compose.web.dom.Div
import org.w3c.dom.HTMLDivElement
import tailwind.Layout
import tailwind.TailwindScope
import tailwind.color.White
import tailwind.seventh
import tailwind.tailwind
import ui.color.Primary100
import ui.material.Card
import ui.material.FullColumn
import ui.material.FullRow

/**
 * Displays a calendar.
 *
 * @param currentMonth The first day of the current month
 * @param today Today's date. Will be shown differently than other days
 * @param onPrevMonthClick Function to execute when the previous month button is clicked
 * @param onNextMonthClick Function to execute when the next month button is clicked
 * @param prefixedDays The list of days to show, including "empty days" (where there is no date to
 *   show)
 * @param onDayClick Function to execute when the user clicks on a given day
 * @param onMouseEnter Function to execute when the user enters the DOM of a day
 * @param onMouseLeave Function to execute when the user leaves the DOM of a day
 * @param attrs Top level attributes
 */
@Composable
fun Calendar(
    currentMonth: LocalDate,
    today: LocalDate,
    onPrevMonthClick: () -> Unit,
    onNextMonthClick: () -> Unit,
    prefixedDays: List<DayItem?>,
    fillInRight: (LocalDate) -> Boolean,
    fillInLeft: (LocalDate) -> Boolean,
    onDayClick: (LocalDate) -> Unit,
    onMouseEnter: (LocalDate) -> Unit,
    onMouseLeave: (LocalDate) -> Unit,
    attrs: AttrBuilderContext<HTMLDivElement>? = null,
) {
  val childAttrs: TailwindScope.() -> Unit = {
    px(4)
    py(2)
  }

  Div(
      attrs = {
        attrs?.invoke(this)

        tailwind {
          w(80)
          select(TailwindScope.SelectType.None)
        }
      },
  ) {
    Div(
        attrs = {
          tailwind {
            background(Primary100)
            round(TailwindScope.Radius.Large)
          }
        },
    ) {
      MonthHeader(
          currentMonth = currentMonth,
          onPrevClick = onPrevMonthClick,
          onNextClick = onNextMonthClick,
          attrs = { tailwind { h(14) } },
      )
      DayHeaders(attrs = { tailwind(childAttrs) })
    }
    Card(
        attrs = {
          tailwind(childAttrs)
          tailwind { background(White) }
        },
    ) {
      val chunked = prefixedDays.chunked(7)

      FullColumn(
          gap = 2,
      ) {
        chunked.forEach {
          FullRow(
              gap = 0,
              justify = Layout.Justify.Start,
              alignItems = Layout.AlignItems.Center,
          ) {
            it.forEach { day ->
              if (day == null) {
                Div(attrs = { tailwind { w(1.seventh) } }) {}
              } else {
                Day(
                    day = day,
                    isToday = day.date == today,
                    fillInRight = fillInRight(day.date),
                    fillInLeft = fillInLeft(day.date),
                    onClick = { onDayClick(day.date) },
                    onMouseEnter = { onMouseEnter(day.date) },
                    onMouseLeave = { onMouseLeave(day.date) },
                )
              }
            }
          }
        }
      }
    }
  }
}
