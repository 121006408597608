package ui.components.navmenu

import androidx.compose.runtime.Composable
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import api.settings.LocalSettings
import api.traak.LocalTraakApi
import api.traak.user.User
import app.softwork.routingcompose.Router
import navigation.Route
import navigation.navigate
import org.jetbrains.compose.web.dom.A
import org.jetbrains.compose.web.dom.AttrBuilderContext
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Nav
import org.w3c.dom.HTMLElement
import tailwind.FlexItemScope
import tailwind.FlexScope
import tailwind.Full
import tailwind.Layout
import tailwind.TailwindBuilderContext
import tailwind.TailwindScope
import tailwind.TransitionScope
import tailwind.color.Opacity030
import tailwind.color.Opacity050
import tailwind.color.White
import tailwind.half
import tailwind.tailwind
import ui.color.BlackMedium
import ui.color.Primary600
import ui.components.languageFooter.LanguageMenu
import ui.components.navmenu.navmenu.activeColor
import ui.components.navmenu.navmenu.transitionDuration
import ui.helpers.OnOutsideClick
import ui.icons.DotsVertical
import ui.icons.Traak

// TODO: Capitalize when Menu() composable has changed signature
internal object navmenu {
  val activeColor = Primary600
  val inactiveColor = BlackMedium
  val transitionDuration = TransitionScope.Duration.Duration0200
}

@Composable
fun Navmenu(
    user: User,
    activeElement: Tab?,
    attrs: AttrBuilderContext<HTMLElement>? = null,
) {
  val settings = LocalSettings.current
  val api = LocalTraakApi.current
  val router = Router.current

  val detailMenuActive = remember { mutableStateOf(false) }
  val languageMenuActive = remember { mutableStateOf(false) }

  if (detailMenuActive.value) {
    OnOutsideClick(detailMenuActive, false)
  }
  if (languageMenuActive.value) {
    OnOutsideClick(languageMenuActive, false)
  }

  Navmenu(
      user = user,
      activeElement = activeElement,
      detailMenuActive = detailMenuActive.value,
      languageMenuActive = languageMenuActive.value,
      onTeamManagementClick = { router.navigate(to = Route.TeamManagement) },
      onDetailMenuClick = { detailMenuActive.value = !detailMenuActive.value },
      onSwitchTeamClick = {
        settings.removeTeam()
        router.navigate(Route.TeamSelection)
      },
      onSwitchLanguageClick = {
        detailMenuActive.value = false
        languageMenuActive.value = true
      },
      onLogOutClick = {
        router.navigate(Route.Origin)
        settings.removeTeam()
        api.logOut()
      },
      attrs = attrs,
  )
}

@Composable
fun Navmenu(
    user: User,
    activeElement: Tab?,
    detailMenuActive: Boolean,
    languageMenuActive: Boolean,
    onTeamManagementClick: () -> Unit,
    onDetailMenuClick: () -> Unit,
    onSwitchTeamClick: () -> Unit,
    onSwitchLanguageClick: () -> Unit,
    onLogOutClick: () -> Unit,
    attrs: AttrBuilderContext<HTMLElement>? = null,
) {
  Nav(
      attrs = {
        attrs?.invoke(this)
        classes("backdrop-filter", "backdrop-blur-md")

        tailwind {
          display(TailwindScope.Display.Block)
          position(TailwindScope.Position.Sticky)
          top(0)

          w(Full)
          h(16)
          px(10)

          background(color = White, opacity = Opacity050)
          shadow(TailwindScope.Shadow.Sm)

          flex(
              direction = FlexScope.Direction.Row,
              justify = Layout.Justify.Between,
              alignItems = Layout.AlignItems.Center,
              alignContent = Layout.AlignContent.Center,
              wrap = FlexScope.Wrap.None,
              gap = 4,
          )

          // z index is needed here to force children of Nav to have their own stacking container
          // which is on top of others in the page
          z(10)
        }
      },
  ) {
    A(
        href = "/",
        attrs = {
          tailwind {
            display(TailwindScope.Display.Block)
            flex(direction = FlexScope.Direction.Row, alignItems = Layout.AlignItems.Center)
            h(Full)
          }
        },
    ) {
      Traak(attrs = { tailwind { h(1.half) } })
    }

    Div(
        attrs = {
          tailwind {
            h(Full)
            flexItem(grow = FlexItemScope.Grow.Grow)

            flex(
                direction = FlexScope.Direction.Row,
                justify = Layout.Justify.Center,
                gap = 16,
            )
          }
        },
    ) {
      mainTabs.forEach { NavmenuItem(it, it == activeElement) }
    }

    Div(
        attrs = {
          tailwind {
            h(Full)
            flex(
                direction = FlexScope.Direction.Row,
                alignItems = Layout.AlignItems.Center,
                gap = 5,
            )
          }
        },
    ) {
      Account(
          tab = AccountTab,
          active = activeElement == AccountTab,
          name = user.displayName.orEmpty(),
      )
      Div(
          attrs = {
            onClick { it.stopPropagation() }

            tailwind {
              w(9)
              h(9)

              flex(
                  direction = FlexScope.Direction.Row,
                  justify = Layout.Justify.Center,
                  alignItems = Layout.AlignItems.Center,
              )

              position(TailwindScope.Position.Relative)

              round(TailwindScope.Radius.Full)
              cursor(TailwindScope.Cursor.Pointer)

              hover { background(color = activeColor, opacity = Opacity030) }

              transition(duration = transitionDuration)
            }
          },
      ) {
        Div(attrs = { onClick { onDetailMenuClick() } }) { DotsVertical() }

        val popUpMenu: TailwindBuilderContext = {
          w(72)
          top(18)
          right(1)
        }
        if (detailMenuActive) {
          DetailMenu(
              onTeamManagementClick = onTeamManagementClick,
              onSwitchTeamClick = onSwitchTeamClick,
              onSwitchLanguageClick = onSwitchLanguageClick,
              onLogOutClick = onLogOutClick,
              attrs = { tailwind(popUpMenu) },
          )
        } else if (languageMenuActive) {
          LanguageMenu(
              attrs = { tailwind(popUpMenu) },
          )
        }
      }
    }
  }
}
