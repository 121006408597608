package ui.screens.teamSelection

import androidx.compose.runtime.Composable
import api.settings.LocalSettings
import api.traak.LocalTraakApi
import api.traak.Team
import app.softwork.routingcompose.Router
import navigation.Route
import navigation.navigate
import org.jetbrains.compose.web.dom.AttrBuilderContext
import org.w3c.dom.HTMLDivElement
import tailwind.tailwind
import ui.components.AuthForm
import ui.components.traakHeadline.TraakHeadline
import ui.layouts.AuthLayout
import ui.strings.LocalStrings

@Composable
fun TeamSelection(
    teams: List<Team>,
    attrs: AttrBuilderContext<HTMLDivElement>? = null,
) {
  val router = Router.current
  val strings = LocalStrings.current
  val api = LocalTraakApi.current
  val settings = LocalSettings.current

  TeamSelection(
      traakHeadline = strings.teamSelectionHeadline,
      logOutButton = strings.teamSelectionLogOut,
      teamMemberText = strings::teamSelectionMembers,
      teams = teams,
      onLogOutClick = {
        settings.removeTeam()
        api.logOut()
        router.navigate(Route.Origin)
      },
      onTeamClick = {
        settings.setTeamTo(it)
        router.navigate(Route.ProjectOverview)
      },
      newTeamButton = strings.teamSelectionNewTeamButton,
      existingTeamButton = strings.teamSelectionExistingTeamButton,
      onNewTeamClick = { router.navigate(Route.NewTeam) },
      onExistingTeamClick = { router.navigate(Route.RequestTeamAccess) },
      noTeamHeadline = strings.teamSelectionNoTeamHeadline,
      noTeamInformationText = strings.teamSelectionNoTeamInformation,
      attrs = attrs,
  )
}

@Composable
fun TeamSelection(
    traakHeadline: String,
    logOutButton: String,
    teamMemberText: (Int) -> String,
    teams: List<Team>,
    onTeamClick: (Team.Id) -> Unit,
    onLogOutClick: () -> Unit,
    newTeamButton: String,
    existingTeamButton: String,
    onNewTeamClick: () -> Unit,
    onExistingTeamClick: () -> Unit,
    noTeamHeadline: String,
    noTeamInformationText: String,
    attrs: AttrBuilderContext<HTMLDivElement>? = null,
) {
  AuthLayout {
    AuthForm(attrs) {
      TraakHeadline(
          text = traakHeadline,
          attrs = { tailwind { mb(4) } },
      )

      TeamPicker(
          logOutButton = logOutButton,
          teamMemberText = teamMemberText,
          teams = teams,
          onTeamClick = onTeamClick,
          onLogOutClick = onLogOutClick,
          newTeamButton = newTeamButton,
          existingTeamButton = existingTeamButton,
          onNewTeamClick = onNewTeamClick,
          onExistingTeamClick = onExistingTeamClick,
          noTeamHeadline = noTeamHeadline,
          noTeamInformationText = noTeamInformationText,
      )
    }
  }
}
