package ui.material

import androidx.compose.runtime.Composable
import org.jetbrains.compose.web.dom.AttrBuilderContext
import org.w3c.dom.svg.SVGElement
import tailwind.TailwindScope
import tailwind.color.Opacity080
import tailwind.tailwind
import ui.icons.Circle
import ui.icons.Path
import ui.icons.Svg

@Composable
fun Spinner(
    size: Int = 5,
    attrs: AttrBuilderContext<SVGElement>? = null,
) {
  Svg(
      attrs = {
        attrs?.invoke(this)

        attr("viewBox", "0 0 24 24")
        tailwind {
          h(size)
          w(size)
          mr(3)
          opacity(Opacity080)
          animate(TailwindScope.Animation.Spin)
        }
      }) {
        Circle(
            attrs = {
              attr("cx", "12")
              attr("cy", "12")
              attr("cr", "10")
              attr("stroke", "currentColor")
              attr("strokeWidth", "4")
            })
        Path(
            attrs = {
              attr("fill", "currentColor")
              attr(
                  "d",
                  "M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z",
              )
            })
      }
}
