package ui.components.taskTable

import api.traak.Task
import ui.components.table.TableSettingsState
import utils.Ordering

enum class OrderedBy {
  Date,
  Origin,
}

enum class TaskTableMode {
  Project,
  Member,
}

/** Represents the state of the TaskTable component. Includes filtering and ordering */
interface TaskTableState : TableSettingsState<Task> {
  /** Are the tasks currently loading */
  val loading: Boolean

  /** The current mode of the task table */
  val mode: TaskTableMode

  /** The current search value */
  val searchInput: String

  /**
   * Triggered function when the search value should change
   *
   * @param input The new search value
   */
  fun onSearchInput(input: String)

  /** The index of the header by which the tasks are ordered */
  val orderingByHeader: Int

  /** The current ordering of the tasks */
  val ordering: Ordering

  /** Triggered function when the ordering should change */
  fun onHeaderClick(index: Int)

  /** The list of tasks filtered by the search value */
  val filteredTasks: List<Task>

  /** Is the header row of the table currently hovered by the users mouse */
  val headerIsHovered: Boolean

  /**
   * Deletes the given task
   *
   * @param item The task to delete
   */
  fun delete(item: Task)

  /** Is the cancellable snackbar visible */
  val cancelSnackbarIsVisible: Boolean

  /** The progression of the timer in the snackbar [0.0-1.0] */
  val cancellableSnackbarProgression: Double

  /** Executed when the cancel button is clicked */
  fun onSnackbarCancelClick()

  fun onItemEditClick(item: Task)

  val firstNameIsFirst: Boolean
}
