package ui.icons

import androidx.compose.runtime.Composable
import org.jetbrains.compose.web.dom.AttrBuilderContext
import org.w3c.dom.svg.SVGElement

@Composable
fun TraakHouse(
    attrs: AttrBuilderContext<SVGElement>? = null,
) =
    Svg(
        attrs = {
          attrs?.invoke(this)
          attr("viewBox", "0 0 112 102")
          attr("fill", "none")
        }) {
          Path {
            attr("fill", "white")
            attr(
                "d",
                "M10.7245 29.956V65.253L40.0005 78.15V40.1379L24.3412 17.0591L10.7245 29.956Z",
            )
          }
          Path {
            attr("fill", "black")
            attr(
                "d",
                "M57.0215 10.271L24.3413 17.0589L40.0006 40.1377V78.1498L71.9999 66.6104V29.9558L57.0215 10.271Z",
            )
          }
          Path {
            attr("fill-rule", "evenodd")
            attr("clip-rule", "evenodd")
            attr("fill", "black")
            attr(
                "d",
                "M62.2515 11.5289C59.9185 8.46295 56.0318 7.00982 52.2597 7.79331L28.2605 12.7781C24.6246 13.5333 21.2708 15.2858 18.5746 17.8394L7.32031 28.4989V67.4637L39.874 81.8046L75.4041 68.992V32.1862C75.4041 29.9987 74.6868 27.8716 73.3622 26.1308L62.2515 11.5289ZM36.5963 41.1779L23.7729 22.2786L14.1287 31.413V63.042L19.5754 65.4415V53.6521L29.1071 58.0642V69.6405L36.5963 72.9397V41.1779ZM43.4047 39.0976V73.3131L68.5957 64.2289V31.0976L55.6096 14.0311L30.0052 19.3493L43.4047 39.0976Z",
            )
          }
          Path {
            attr("fill", "white")
            attr(
                "d",
                "M40.0001 54.3926V78.1501L69.2761 91.0471V64.5744L53.6169 41.4956L40.0001 54.3926Z",
            )
          }
          Path {
            attr("fill", "black")
            attr(
                "d",
                "M86.2971 34.7075L53.6169 41.4954L69.2762 64.5742V91.0469L101.276 79.5075V54.3924L86.2971 34.7075Z",
            )
          }
          Path {
            attr("fill-rule", "evenodd")
            attr("clip-rule", "evenodd")
            attr("fill", "black")
            attr(
                "d",
                "M91.5271 35.9654C89.1942 32.8995 85.3074 31.4463 81.5353 32.2298L51.9744 38.3698L36.5959 52.9354V80.3609L69.1496 94.7017L104.68 81.8891V53.2508L91.5271 35.9654ZM65.872 65.6144L53.0485 46.7151L43.4043 55.8495V75.9392L48.851 78.3386V67.2468L58.3827 71.6589V82.5376L65.872 85.8368V65.6144ZM72.6803 63.5341V86.2102L97.8713 77.126V55.5341L84.8853 38.4676L59.2809 43.7858L72.6803 63.5341Z",
            )
          }
        }
