package ui.components.taskCreator

import androidx.compose.runtime.MutableState
import androidx.compose.runtime.mutableStateOf
import api.traak.Task
import api.traak.Task.Project as TaskProject
import api.traak.dto.TaskEditionDto
import api.traak.fromFirestore.Author
import api.traak.fromFirestore.Project
import api.traak.toFirestore.toFirestoreField
import kotlin.time.Duration
import kotlinx.datetime.Clock
import kotlinx.datetime.Instant
import kotlinx.datetime.LocalDate
import kotlinx.datetime.TimeZone
import kotlinx.datetime.atTime
import kotlinx.datetime.toInstant
import kotlinx.datetime.toLocalDateTime
import kotlinx.datetime.todayIn

private const val DEFAULT_DURATION = "01:00"

private val today = Clock.System.todayIn(TimeZone.currentSystemDefault())
private val defaultDate = today.toString()

/** Sets the time of a LocalDate to 7:30. */
private fun LocalDate.toDefaultStartTime(): Instant {
  return this.atTime(7, 30).toInstant(TimeZone.currentSystemDefault())
}

open class InnerTaskCreationStateHolder(
    protected val initialProject: TaskProject?,
    protected val initialMember: Task.Author?,
    protected val initialStartDate: Instant? = null,
    protected val initialEndDate: Instant? = null,
    protected val initialDescription: String? = null,
) : InnerTaskEditionState {
  protected val _currentProject: MutableState<TaskProject?> = mutableStateOf(initialProject)
  protected val _selectedMember = mutableStateOf(initialMember)

  protected val initialDate =
      initialStartDate?.toLocalDateTime(TimeZone.currentSystemDefault())?.date?.toString()
  protected val initialDuration =
      initialStartDate?.let { start ->
        initialEndDate?.let { end ->
          val duration = end - start
          val hours = duration.inWholeHours.toString().padStart(2, '0')
          val minutes = duration.inWholeMinutes.mod(60).toString().padStart(2, '0')
          "$hours:$minutes"
        }
      }

  protected val _taskDate = mutableStateOf(initialDate ?: defaultDate)
  protected val _taskDuration = mutableStateOf(initialDuration ?: DEFAULT_DURATION)
  protected val _taskDescription = mutableStateOf(initialDescription ?: "")

  override var currentProject: TaskProject?
    get() = _currentProject.value
    set(project) {
      _currentProject.value = project
    }

  override var selectedAuthor: Task.Author?
    get() = _selectedMember.value
    set(value) {
      _selectedMember.value = value
    }

  override var taskDate: String
    get() = _taskDate.value
    set(value) {
      _taskDate.value = value
    }

  override var taskDuration: String
    get() = _taskDuration.value
    set(value) {
      _taskDuration.value = value
    }

  override var taskDescription: String
    get() = _taskDescription.value
    set(value) {
      _taskDescription.value = value
    }

  override val creationIsValid: Boolean
    get() =
        _taskDate.value.isNotBlank() &&
            _taskDescription.value.isNotBlank() &&
            _taskDuration.value.isNotBlank()

  override fun reset() {
    _selectedMember.value = initialMember
    _currentProject.value = initialProject
    _taskDate.value = initialDate ?: defaultDate
    _taskDuration.value = initialDuration ?: DEFAULT_DURATION
    _taskDescription.value = initialDescription ?: ""
  }

  protected fun prepareAuthor(): Author? {
    return selectedAuthor?.let { author ->
      Author(
          id = author.id.raw,
          firstName = author.firstName,
          lastName = author.lastName,
      )
    }
  }

  protected fun prepareProject(): Project? =
      currentProject?.let { project ->
        Project(
            id = project.id.raw,
            title = project.title,
            status = project.status.toFirestoreField(),
        )
      }

  protected fun prepareDuration(): Duration {
    val preparedDurationString = taskDuration.replace(":", "h ").plus("m")
    return Duration.parse(preparedDurationString)
  }

  override fun toEditionDto(): TaskEditionDto {
    val author = prepareAuthor()
    val project = prepareProject()
    val duration = prepareDuration()

    val startDate: Instant = LocalDate.parse(_taskDate.value).toDefaultStartTime()
    val endDate: Instant = startDate.plus(duration)

    return TaskEditionDto(
        author = author,
        project = project,
        description = taskDescription,
        startDate = startDate,
        endDate = endDate,
    )
  }
}

class InnerTaskEditionStateHolder(
    private val originalTask: Task,
    initialProject: TaskProject?,
    initialMember: Task.Author?,
    initialStartDate: Instant? = null,
    initialEndDate: Instant? = null,
    initialDescription: String? = null,
) :
    InnerTaskCreationStateHolder(
        initialProject,
        initialMember,
        initialStartDate,
        initialEndDate,
        initialDescription,
    ) {
  override fun toEditionDto(): TaskEditionDto {
    val author = prepareAuthor()
    val project = prepareProject()
    val duration = prepareDuration()

    val startDate: Instant = LocalDate.parse(_taskDate.value).toDefaultStartTime()
    val updateStartDate =
        (startDate.toLocalDateTime(TimeZone.currentSystemDefault()).date !=
            originalTask.start.toLocalDateTime(TimeZone.currentSystemDefault()).date)

    val endDate =
        if (updateStartDate) {
          startDate.plus(duration)
        } else {
          originalTask.start.plus(duration)
        }
    val updateDuration = updateStartDate || (duration != originalTask.duration)

    return TaskEditionDto(
        author = if (author?.id != originalTask.author.id.raw) author else null,
        project = if (project?.id != originalTask.project?.id?.raw) project else null,
        description = if (taskDescription != originalTask.description) taskDescription else null,
        startDate = if (updateStartDate) startDate else null,
        endDate = if (updateDuration) endDate else null,
    )
  }
}
