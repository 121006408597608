package ui.screens.teamManagement

import androidx.compose.runtime.Composable
import androidx.compose.runtime.State
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberCoroutineScope
import api.settings.LocalSettings
import api.settings.SettingsApi
import api.traak.LocalTraakApi
import api.traak.Member
import api.traak.Role
import api.traak.Team
import api.traak.TraakApi
import api.traak.user.User
import app.softwork.routingcompose.Router
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.launch
import navigation.Route
import navigation.navigate
import ui.components.table.TableSettingsDefaultState
import ui.components.table.TableSettingsState
import ui.data.Loadable
import ui.data.asLoadable
import ui.data.collectAsState
import ui.data.sortedWith

class TeamMembersStateHolder(
    private val currentUser: User,
    private val _loadableMembers: State<Loadable<List<Member>>>,
    private val settings: SettingsApi,
    private val router: Router,
    private val scope: CoroutineScope,
) : TeamMembersState, TableSettingsState<Member> by TableSettingsDefaultState() {
  private fun firstOrderElementOfMember(member: Member): String {
    return if (settings.orderByFirstName.value) {
      member.firstName
    } else {
      member.lastName
    }
  }

  private fun secondOrderElementOfMember(member: Member): String {
    return if (settings.orderByFirstName.value) {
      member.lastName
    } else {
      member.firstName
    }
  }

  private val memberComparator: Comparator<Member> =
      compareBy(
          { firstOrderElementOfMember(it) },
          { secondOrderElementOfMember(it) },
      )

  override val loadableMembers: Loadable<List<Member>>
    get() = _loadableMembers.value.sortedWith(memberComparator)

  override fun changeMemberRole(member: Member, newRole: Role) {
    if (!memberIsEditable(member)) {
      return
    }

    scope.launch { member.changeRole(newRole) }
  }

  override fun memberIsEditable(member: Member): Boolean {
    return currentUser.id.raw != member.id.raw
  }

  override fun editMember(member: Member) {
    router.navigate(Route.MemberEdition(member))
  }
}

@Composable
fun rememberTeamMembersState(
    currentUser: User,
    team: Team,
    api: TraakApi = LocalTraakApi.current,
    settings: SettingsApi = LocalSettings.current,
    router: Router = Router.current,
    scope: CoroutineScope = rememberCoroutineScope(),
): TeamMembersState {
  val loadableMembers = remember(api, team) { api.membersOf(team).asLoadable() }.collectAsState()

  return remember {
    TeamMembersStateHolder(
        currentUser = currentUser,
        _loadableMembers = loadableMembers,
        settings = settings,
        router = router,
        scope = scope,
    )
  }
}
