package ui.screens.register

import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.runtime.setValue
import api.traak.AuthResult
import api.traak.LocalTraakApi
import api.traak.TraakApi
import api.traak.toErrorMessage
import app.softwork.routingcompose.Router
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.delay
import kotlinx.coroutines.launch
import kotlinx.coroutines.sync.Mutex
import kotlinx.coroutines.sync.withLock
import navigation.Route
import navigation.navigate
import ui.strings.LocalStrings

sealed class RegisterResult {
  sealed class LocalError : RegisterResult() {

    object EmptyName : LocalError()
  }

  data class RemoteError(val authResult: AuthResult) : RegisterResult()

  object Success : RegisterResult()
}

class RegisterState(
    private val api: TraakApi,
    private val router: Router,
    private val coroutineScope: CoroutineScope,
) {
  private val refreshDelay = 2000L
  private val mutex = Mutex()

  private var _email by mutableStateOf("")
  private var _firstName by mutableStateOf("")
  private var _lastName by mutableStateOf("")
  private var _password by mutableStateOf("")
  private var _result by mutableStateOf<RegisterResult?>(null)

  private fun resetResult(): Unit {
    if (_result != null) {
      coroutineScope.launch { mutex.withLock { _result = null } }
    }
  }

  var email: String
    get() = _email
    set(value) {
      resetResult()
      _email = value
    }

  var firstName: String
    get() = _firstName
    set(value) {
      resetResult()
      _firstName = value
    }

  var lastName: String
    get() = _lastName
    set(value) {
      resetResult()
      _lastName = value
    }

  var password: String
    get() = _password
    set(value) {
      resetResult()
      _password = value
    }

  val result: RegisterResult?
    get() = _result

  fun register(): Unit {
    // Check locally
    if (_firstName.isBlank()) {
      _result = RegisterResult.LocalError.EmptyName
      return
    }
    if (_lastName.isBlank()) {
      _result = RegisterResult.LocalError.EmptyName
      return
    }

    coroutineScope.launch {
      val fullName = "$_firstName $_lastName"
      val result = api.register(_email, _password, fullName)

      if (result == AuthResult.Success) {
        _result = RegisterResult.Success

        // Force reloading
        delay(refreshDelay)
        router.navigate(to = Route.Origin)
      } else {
        _result = RegisterResult.RemoteError(authResult = result)
      }
    }
  }
}

@Composable
fun rememberRegisterState(
    api: TraakApi = LocalTraakApi.current,
    router: Router = Router.current,
    coroutineScope: CoroutineScope = rememberCoroutineScope(),
): RegisterState =
    remember(api) {
      RegisterState(
          api = api,
          router = router,
          coroutineScope = coroutineScope,
      )
    }

@Composable
fun RegisterResult.toErrorMessage(): String? =
    when (this) {
      RegisterResult.LocalError.EmptyName -> LocalStrings.current.registerErrorEmptyName
      is RegisterResult.RemoteError -> authResult.toErrorMessage(strings = LocalStrings.current)
      RegisterResult.Success -> null
    }
