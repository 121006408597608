package ui.screens.teamSelection

import androidx.compose.runtime.Composable
import org.jetbrains.compose.web.dom.AttrBuilderContext
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.P
import org.jetbrains.compose.web.dom.Text
import org.w3c.dom.HTMLDivElement
import tailwind.FlexScope
import tailwind.Layout
import tailwind.tailwind
import ui.color.BlackDark
import ui.color.BlackMedium

@Composable
fun NoTeamsMessage(
    headlineText: String,
    informationText: String,
    attrs: AttrBuilderContext<HTMLDivElement>? = null,
) {
  Div(
      attrs = {
        attrs?.invoke(this)
        tailwind {
          flex(
              direction = FlexScope.Direction.Column,
              justify = Layout.Justify.Start,
              alignItems = Layout.AlignItems.Start,
              gap = 4,
          )
        }
      }) {
        P(attrs = { tailwind { text(color = BlackDark) { body1() } } }) { Text(headlineText) }
        P(attrs = { tailwind { text(color = BlackMedium) { body2() } } }) { Text(informationText) }
      }
}
