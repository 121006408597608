package ui.components.chips.calendar

import androidx.compose.runtime.Composable
import org.jetbrains.compose.web.dom.AttrBuilderContext
import org.w3c.dom.HTMLDivElement
import ui.components.calendar.CalendarState
import ui.components.chips.dropdown.DropdownAlignment
import ui.components.chips.dropdown.DropdownChip
import ui.icons.CalendarRange

@Composable
fun CalendarChip(
    state: CalendarState,
    attrs: AttrBuilderContext<HTMLDivElement>? = null,
) {
  val chipState = rememberCalendarChipState(state)

  DropdownChip(
      state = chipState,
      text = chipState.title,
      attrs = attrs,
      leadingContent = { CalendarRange() },
      dropdownAlignment = DropdownAlignment.Right,
      dropdown = { dropdownAttrs -> state.Calendar(dropdownAttrs) },
  )
}
