package ui.screens.newTeam

import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.setValue
import api.traak.StorageResult
import api.traak.TraakApi
import api.traak.dto.BillingAddress
import api.traak.user.User
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.launch
import kotlinx.coroutines.sync.Mutex
import kotlinx.coroutines.sync.withLock

abstract class NewTeamGenericStateHolder(
    private val user: User,
    private val api: TraakApi,
    private val coroutineScope: CoroutineScope,
) : NewTeamState {
  private val mutex = Mutex()

  private var _result by mutableStateOf<NewTeamState.Result?>(null)
  private var _loading by mutableStateOf(false)

  override var teamName by mutableStateOf("")
  override var contactName by mutableStateOf(user.displayName ?: "")
  override var email by mutableStateOf(user.email ?: "")
  override var phone by mutableStateOf("")
  override var address by mutableStateOf("")
  override var locality by mutableStateOf("")
  override var zip by mutableStateOf("")

  override val result: NewTeamState.Result?
    get() = _result

  override val loading: Boolean
    get() = _loading

  abstract fun onTeamCreation(): Unit

  override fun createTeam(): Unit {
    coroutineScope.launch {
      if (teamName.isBlank() ||
          contactName.isBlank() ||
          email.isBlank() ||
          phone.isBlank() ||
          address.isBlank() ||
          zip.isBlank() ||
          locality.isBlank()) {
        mutex.withLock { _result = NewTeamState.Result.MissingData }
      } else {

        try {
          _loading = true

          mutex.withLock {
            val fullLocality = "$zip $locality"
            val result =
                api.createTeam(
                    teamName = teamName,
                    user = user,
                    billingAddress =
                        BillingAddress(
                            name = contactName,
                            email = email,
                            phone = phone,
                            address = address,
                            locality = fullLocality,
                        ))
            _result = NewTeamState.Result.Storage(result)
          }

          if (_result is NewTeamState.Result.Storage &&
              (_result as NewTeamState.Result.Storage).result == StorageResult.Success) {
            onTeamCreation()
          }
        } catch (t: Throwable) {
          mutex.withLock { _result = NewTeamState.Result.Storage(StorageResult.FailureUnknown) }
        } finally {
          _loading = false
        }
      }
    }
  }
}
