package ui.screens.integration.bexio.synchronising

import androidx.compose.runtime.*
import api.bexio.BexioApi
import api.bexio.LocalBexioApi
import api.traak.*
import api.traak.user.User
import app.softwork.routingcompose.Router
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.launch
import navigation.Route
import navigation.navigate
import org.w3c.dom.url.URL

class BexioSynchronisingStateHolder(
    private val user: User,
    private val team: Team,
    private val code: String?,
    private val error: String?,
    private val bexioApi: BexioApi,
    private val traakApi: TraakApi,
    private val router: Router,
    private val coroutineScope: CoroutineScope,
) : BexioSynchronisingState {

  private var _mode: MutableState<SynchronisingMode> =
      mutableStateOf(
          if (code != null) SynchronisingMode.StoringCredentials
          else if (error != null) SynchronisingMode.Error else SynchronisingMode.Initial)

  init {
    when (_mode.value) {
      is SynchronisingMode.StoringCredentials -> startIntegrationProcedure()
      else -> Unit
    }
  }

  private fun startIntegrationProcedure(): Unit {
    coroutineScope.launch {
      // 1. Store token
      val storedTokenResult =
          traakApi.validateToken(
              teamId = team.id,
              authorizationCode = code!!,
              redirectUri = bexioApi.redirectUri,
          )

      if (storedTokenResult != StorageResult.Success) {
        _mode.value = SynchronisingMode.Error
        return@launch
      }

      _mode.value = SynchronisingMode.Synchronising

      // 2. Update user profile
      val updateUserAuth = traakApi.updateAuthProfileFromBexio(team.id, user.id)
      if (updateUserAuth != StorageResult.Success) {
        _mode.value = SynchronisingMode.Error
        return@launch
      }

      // 3. Synchronize
      val syncResult = traakApi.synchronizeBexio(team.id)

      if (syncResult != StorageResult.Success) {
        _mode.value = SynchronisingMode.Error
      } else {
        _mode.value = SynchronisingMode.Done
        router.navigate(to = Route.ProjectOverview)
      }
    }
  }

  override val mode: SynchronisingMode
    get() = _mode.value

  override val authorizeUrl: URL
    get() = bexioApi.authorizeUrlWithScope(scopes = bexioApi.requiredScopes)

  override val loading: Boolean
    get() =
        when (this._mode.value) {
          SynchronisingMode.StoringCredentials -> true
          SynchronisingMode.Synchronising -> true
          SynchronisingMode.Done -> true
          SynchronisingMode.Error -> true
          SynchronisingMode.Initial -> false
        }
}

@Composable
fun rememberBexioSynchronisingState(
    user: User,
    team: Team,
    code: String?,
    error: String?,
    bexioApi: BexioApi = LocalBexioApi.current,
    traakApi: TraakApi = LocalTraakApi.current,
    router: Router = Router.current,
    coroutineScope: CoroutineScope = rememberCoroutineScope(),
): BexioSynchronisingState =
    remember(bexioApi) {
      BexioSynchronisingStateHolder(
          user = user,
          team = team,
          code = code,
          error = error,
          bexioApi = bexioApi,
          traakApi = traakApi,
          router = router,
          coroutineScope = coroutineScope,
      )
    }
