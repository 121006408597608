package ui.components.calendar

import androidx.compose.runtime.Composable
import org.jetbrains.compose.web.dom.AttrBuilderContext
import org.jetbrains.compose.web.dom.Text
import org.w3c.dom.HTMLDivElement
import tailwind.*
import ui.color.Primary200
import ui.color.Primary400
import ui.material.Row

@Composable
fun Day(
    day: DayItem,
    isToday: Boolean,
    fillInRight: Boolean,
    fillInLeft: Boolean,
    onClick: () -> Unit,
    onMouseEnter: () -> Unit,
    onMouseLeave: () -> Unit,
    attrs: AttrBuilderContext<HTMLDivElement>? = null,
) {
  val activeColor = Primary400
  val passivelySelectedColor = Primary200
  val side =
      if (fillInLeft && fillInRight) {
        null
      } else if (fillInLeft) {
        Side.Right
      } else if (fillInRight) {
        Side.Left
      } else {
        Side.All
      }

  val sideToRound: TailwindScope.() -> Unit = {
    side?.let {
      round(
          amount = TailwindScope.Radius.Full,
          side = it,
      )
    }
  }

  val noneStyle: TailwindScope.() -> Unit = {}
  val startStyle: TailwindScope.() -> Unit = {
    sideToRound.invoke(this)
    background(passivelySelectedColor)
  }

  val containedStyle: TailwindScope.() -> Unit = { background(passivelySelectedColor) }
  val hoverStyle: TailwindScope.() -> Unit = {
    sideToRound.invoke(this)
    background(passivelySelectedColor)
  }

  val endStyle: TailwindScope.() -> Unit = {
    sideToRound.invoke(this)
    background(passivelySelectedColor)
  }

  CalendarItem {
    Row(
        alignItems = Layout.AlignItems.Center,
        justify = Layout.Justify.Center,
        attrs = {
          attrs?.invoke(this)
          // not contained in tailwind DSL
          classes("aspect-w-1", "aspect-h-1")

          when (day.state) {
            DayItemState.NONE -> tailwind(noneStyle)
            DayItemState.START -> tailwind(startStyle)
            DayItemState.CONTAINED -> tailwind(containedStyle)
            DayItemState.END -> tailwind(endStyle)
            DayItemState.HOVERED -> tailwind(hoverStyle)
          }
        }) {
          Row(
              alignItems = Layout.AlignItems.Center,
              justify = Layout.Justify.Center,
              attrs = {
                onClick { onClick() }
                onMouseEnter { onMouseEnter() }
                onMouseLeave { onMouseLeave() }

                tailwind {
                  w(Full)
                  h(Full)

                  cursor(TailwindScope.Cursor.Pointer)
                  round(TailwindScope.Radius.Full)

                  if (day.state == DayItemState.START ||
                      day.state == DayItemState.END ||
                      day.state == DayItemState.HOVERED) {
                    background(activeColor)
                  }

                  if (isToday) {
                    border(
                        color = activeColor,
                        width = 1,
                    )
                  }
                }
              },
          ) {
            Text(day.date.dayOfMonth.toString())
          }
        }
  }
}
