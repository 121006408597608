package ui.screens.teamManagement

import androidx.compose.runtime.Composable
import api.traak.Member
import api.traak.Role
import api.traak.Team
import api.traak.user.User
import org.jetbrains.compose.web.attributes.ATarget
import org.jetbrains.compose.web.attributes.target
import org.jetbrains.compose.web.dom.A
import org.jetbrains.compose.web.dom.AttrBuilderContext
import org.jetbrains.compose.web.dom.Text
import org.w3c.dom.HTMLDivElement
import tailwind.FlexScope
import tailwind.Full
import tailwind.Layout
import tailwind.TailwindScope
import tailwind.tailwind
import ui.color.BlackLight
import ui.components.PageTitle
import ui.components.table.TableSettingsState
import ui.data.Loadable
import ui.icons.HelpCircleOutline
import ui.material.Column
import ui.strings.LocalStrings

@Composable
fun TeamMembers(
    currentUser: User,
    team: Team,
    attrs: AttrBuilderContext<HTMLDivElement>? = null,
) {
  val strings = LocalStrings.current
  val state = rememberTeamMembersState(currentUser, team)

  TeamMembers(
      title = strings.teamOverviewTeamMemberTitle,
      loadableMembers = state.loadableMembers,
      onRoleClick = { member, role -> state.changeMemberRole(member, role) },
      memberIsEditable = state::memberIsEditable,
      onMemberEditClick = state::editMember,
      settingsState = state,
      attrs = attrs,
  )
}

@Composable
fun TeamMembers(
    title: String,
    loadableMembers: Loadable<List<Member>>,
    onRoleClick: (Member, Role) -> Unit,
    memberIsEditable: (Member) -> Boolean,
    onMemberEditClick: (Member) -> Unit,
    settingsState: TableSettingsState<Member>,
    attrs: AttrBuilderContext<HTMLDivElement>? = null,
) {

  Column(
      justify = Layout.Justify.Start,
      alignItems = Layout.AlignItems.Start,
      gap = 4,
      attrs = {
        attrs?.invoke(this)
        tailwind { w(Full) }
      }) {
        PageTitle(
            attrs = {
              tailwind {
                w(Full)
                display(TailwindScope.Display.Block)
                flex(
                    direction = FlexScope.Direction.Row,
                    alignItems = Layout.AlignItems.Center,
                    justify = Layout.Justify.Start,
                    gap = 4,
                )
              }
            }) {
              Text(title)

              A(
                  href = "https://www.traak.ch/help/change-user-role/",
                  attrs = {
                    target(ATarget.Blank)
                    tailwind { text(color = BlackLight) }
                  },
              ) {
                HelpCircleOutline()
              }
            }

        MemberTable(
            loadableMembers = loadableMembers,
            onRoleClick = onRoleClick,
            memberIsEditable = memberIsEditable,
            onMemberEditClick = onMemberEditClick,
            settingsState = settingsState,
        )
      }
}
