package ui.screens.register

import androidx.compose.runtime.Composable
import org.jetbrains.compose.web.attributes.AutoComplete
import org.jetbrains.compose.web.dom.AttrBuilderContext
import org.w3c.dom.HTMLDivElement
import tailwind.Full
import tailwind.tailwind
import ui.components.AuthErrorMessage
import ui.components.AuthForm
import ui.components.traakHeadline.TraakHeadline
import ui.icons.ArrowLeft
import ui.material.EmailInput
import ui.material.FilledButton
import ui.material.PasswordInput
import ui.material.TextButton
import ui.material.TextInput

@Composable
fun RegisterForm(
    traakHeadline: String,
    email: String,
    setEmail: (String) -> Unit,
    emailPlaceholder: String,
    firstName: String,
    setFirstName: (String) -> Unit,
    lastName: String,
    setLastName: (String) -> Unit,
    firstNamePlaceholder: String,
    lastNamePlaceholder: String,
    password: String,
    setPassword: (String) -> Unit,
    passwordPlaceholder: String,
    registerButtonText: String,
    goBackButton: String,
    loading: Boolean,
    errorMessage: String?,
    onRegisterClick: () -> Unit,
    onGoBackClick: () -> Unit,
    attrs: AttrBuilderContext<HTMLDivElement>? = null,
) {
  AuthForm(attrs = attrs) {
    TraakHeadline(
        text = traakHeadline,
        attrs = { tailwind { mb(4) } },
    )

    EmailInput(
        value = email,
        onInput = setEmail,
        label = emailPlaceholder,
        autoComplete = AutoComplete.email,
        attrs = { tailwind { w(Full) } },
    )
    TextInput(
        value = firstName,
        onInput = setFirstName,
        label = firstNamePlaceholder,
        autoComplete = AutoComplete.givenName,
        attrs = { tailwind { w(Full) } },
    )

    TextInput(
        value = lastName,
        onInput = setLastName,
        label = lastNamePlaceholder,
        autoComplete = AutoComplete.familyName,
        attrs = { tailwind { w(Full) } },
    )

    PasswordInput(
        value = password,
        onInput = setPassword,
        label = passwordPlaceholder,
        autoComplete = AutoComplete.newPassword,
        attrs = { tailwind { w(Full) } },
    )

    FilledButton(
        text = registerButtonText,
        onClick = onRegisterClick,
        disabled = errorMessage != null,
        loading = loading,
        attrs = { tailwind { w(Full) } },
    )

    AuthErrorMessage(errorMessage)

    TextButton(
        text = goBackButton,
        icon = { ArrowLeft() },
        onClick = onGoBackClick,
        attrs = { tailwind { w(Full) } })
  }
}
