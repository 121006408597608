package ui.icons

import androidx.compose.runtime.Composable
import kotlinx.browser.document
import org.jetbrains.compose.web.dom.AttrBuilderContext
import org.jetbrains.compose.web.dom.ContentBuilder
import org.jetbrains.compose.web.dom.ElementBuilder
import org.jetbrains.compose.web.dom.TagElement
import org.w3c.dom.Element
import org.w3c.dom.svg.*

/*
TODO: To remove when Compose has integrated SVG
Source: https://app.slack.com/client/T09229ZC6/C01F2HV7868/thread/C01F2HV7868-1632681643.130300
 */

private val Svg: ElementBuilder<SVGElement> =
    object : ElementBuilder<SVGElement> {

      private val el: Element by lazy {
        document.createElementNS("http://www.w3.org/2000/svg", "svg")
      }

      override fun create(): SVGElement = el.cloneNode() as SVGElement
    }

private val Path: ElementBuilder<SVGPathElement> =
    object : ElementBuilder<SVGPathElement> {

      private val el: Element by lazy {
        document.createElementNS("http://www.w3.org/2000/svg", "path")
      }

      override fun create(): SVGPathElement = el.cloneNode() as SVGPathElement
    }

private val G: ElementBuilder<SVGGElement> =
    object : ElementBuilder<SVGGElement> {

      private val el: Element by lazy {
        document.createElementNS("http://www.w3.org/2000/svg", "g")
      }

      override fun create(): SVGGElement = el.cloneNode() as SVGGElement
    }

private val Circle: ElementBuilder<SVGCircleElement> =
    object : ElementBuilder<SVGCircleElement> {

      private val el: Element by lazy {
        document.createElementNS("http://www.w3.org/2000/svg", "circle")
      }

      override fun create(): SVGCircleElement = el.cloneNode() as SVGCircleElement
    }

private val Use: ElementBuilder<SVGUseElement> =
    object : ElementBuilder<SVGUseElement> {

      private val el: Element by lazy {
        document.createElementNS("http://www.w3.org/2000/svg", "use")
      }

      override fun create(): SVGUseElement = el.cloneNode() as SVGUseElement
    }

@Composable
fun Svg(
    attrs: AttrBuilderContext<SVGElement>? = null,
    content: ContentBuilder<SVGElement>? = null
) = TagElement(elementBuilder = Svg, applyAttrs = attrs, content = content)

@Composable
fun Path(
    attrs: AttrBuilderContext<SVGPathElement>? = null,
) = TagElement(elementBuilder = Path, applyAttrs = attrs, content = null)

@Composable
fun G(
    attrs: AttrBuilderContext<SVGGElement>? = null,
    content: ContentBuilder<SVGGElement>? = null
) = TagElement(elementBuilder = G, applyAttrs = attrs, content = content)

@Composable
fun Circle(
    attrs: AttrBuilderContext<SVGCircleElement>? = null,
    content: ContentBuilder<SVGCircleElement>? = null
) = TagElement(elementBuilder = Circle, applyAttrs = attrs, content = content)

@Composable
fun Use(
    attrs: AttrBuilderContext<SVGUseElement>? = null,
    content: ContentBuilder<SVGUseElement>? = null
) = TagElement(elementBuilder = Use, applyAttrs = attrs, content = content)
