package tailwind

enum class BorderStyle {
  Solid,
  Dashed,
  Dotted,
  Double,
  None,
  ;

  override fun toString(): String =
      when (this) {
        Solid -> "solid"
        Dashed -> "dashed"
        Dotted -> "dotted"
        Double -> "double"
        None -> "none"
      }
}
