package ui.screens.teamOverview

import androidx.compose.runtime.Composable
import api.settings.LocalSettings
import api.traak.Member
import org.jetbrains.compose.web.dom.AttrBuilderContext
import org.jetbrains.compose.web.dom.Span
import org.jetbrains.compose.web.dom.Text
import org.w3c.dom.HTMLDivElement
import tailwind.Full
import tailwind.TextScope
import tailwind.tailwind
import ui.material.Card
import ui.material.ClickableListItem

@Composable
fun TeamList(
    members: List<Member>,
    onMemberClick: (Member) -> Unit,
    attrs: AttrBuilderContext<HTMLDivElement>? = null,
) {
  val settings = LocalSettings.current

  Card(
      attrs = {
        attrs?.invoke(this)
        tailwind { w(Full) }
      }) {
        members.forEach { member ->
          ClickableListItem(onClick = { onMemberClick(member) }) {
            Span(
                attrs = { tailwind { text { body1(weight = TextScope.Weight.Semibold) } } },
            ) {
              if (settings.showFirstNameFirst.value) {
                Text("${member.firstName} ${member.lastName}")
              } else {
                Text("${member.lastName} ${member.firstName}")
              }
            }
          }
        }
      }
}
