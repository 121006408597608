package ui.screens.newTeam

import androidx.compose.runtime.Composable
import api.traak.StorageResult
import api.traak.user.User
import app.softwork.routingcompose.Router
import navigation.back
import org.jetbrains.compose.web.dom.AttrBuilderContext
import org.jetbrains.compose.web.dom.Br
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Text
import org.w3c.dom.HTMLDivElement
import tailwind.Full
import tailwind.Layout
import tailwind.TextScope
import tailwind.fifth
import tailwind.half
import tailwind.tailwind
import tailwind.twelfth
import ui.color.BlackMedium
import ui.components.AuthErrorMessage
import ui.components.traakHeadline.TraakHeadline
import ui.helpers.onEnterDown
import ui.layouts.HalfScreenLayout
import ui.material.Column
import ui.material.FilledButton
import ui.material.FullRow
import ui.material.TextButton
import ui.material.TextInput
import ui.strings.LocalStrings

@Composable
fun NewTeam(
    user: User,
    attrs: AttrBuilderContext<HTMLDivElement>? = null,
) {
  val strings = LocalStrings.current
  val teamState = rememberNewTeamState(user)

  fun NewTeamState.Result.toErrorMessage(): String? =
      when (this) {
        is NewTeamState.Result.Storage ->
            when (this.result) {
              StorageResult.Success -> null
              else -> strings.teamCreationFailure
            }
        NewTeamState.Result.MissingData -> strings.teamCreationMissingData
      }

  onEnterDown { teamState.createTeam() }

  NewTeam(
      traakHeadline = strings.newTeamTraakHeadline,
      billingHeadline = strings.newTeamBillingHeadline,
      teamName = teamState.teamName,
      setTeamName = { teamState.teamName = it },
      newTeamPlaceholder = strings.newTeamNamePlaceholder,
      contactName = teamState.contactName,
      setContactName = { teamState.contactName = it },
      contactNamePlaceholder = strings.newTeamContactNamePlaceholder,
      email = teamState.email,
      setEmail = { teamState.email = it },
      emailPlaceholder = strings.newTeamEmailPlaceholder,
      phone = teamState.phone,
      setPhone = { teamState.phone = it },
      phonePlaceholder = strings.newTeamPhonePlaceholder,
      address = teamState.address,
      setAddress = { teamState.address = it },
      addressPlaceholder = strings.newTeamAddressPlaceholder,
      locality = teamState.locality,
      setLocality = { teamState.locality = it },
      localityPlaceholder = strings.newTeamLocalityPlaceholder,
      zip = teamState.zip,
      setZip = { teamState.zip = it },
      zipPlaceholder = strings.newTeamZipPlaceholder,
      createButton = strings.newTeamCreateButton,
      onCreateClick = { teamState.createTeam() },
      cancelButton = strings.newTeamCancelButton,
      onCancelClick = { Router.back() },
      loading = teamState.loading,
      errorMessage = teamState.result?.toErrorMessage(),
      attrs = attrs,
  )
}

@Composable
fun NewTeam(
    traakHeadline: String,
    billingHeadline: String,
    teamName: String,
    setTeamName: (String) -> Unit,
    newTeamPlaceholder: String,
    contactName: String,
    setContactName: (String) -> Unit,
    contactNamePlaceholder: String,
    email: String,
    setEmail: (String) -> Unit,
    emailPlaceholder: String,
    phone: String,
    setPhone: (String) -> Unit,
    phonePlaceholder: String,
    address: String,
    setAddress: (String) -> Unit,
    addressPlaceholder: String,
    locality: String,
    setLocality: (String) -> Unit,
    localityPlaceholder: String,
    zip: String,
    setZip: (String) -> Unit,
    zipPlaceholder: String,
    createButton: String,
    onCreateClick: () -> Unit,
    cancelButton: String,
    onCancelClick: () -> Unit,
    loading: Boolean,
    errorMessage: String?,
    attrs: AttrBuilderContext<HTMLDivElement>? = null,
) {
  HalfScreenLayout {
    Column(
        gap = 4,
        attrs = attrs,
    ) {
      FullRow {
        TraakHeadline(
            text = traakHeadline,
            attrs = { tailwind { mb(4) } },
        )

        Div(attrs = { tailwind { w(7.twelfth) } })
      }

      TextInput(
          value = teamName,
          onInput = setTeamName,
          label = newTeamPlaceholder,
          attrs = { tailwind { w(Full) } },
      )

      Br()

      Div(
          attrs = {
            tailwind {
              w(Full)
              text(color = BlackMedium) {
                body1()
                align(TextScope.Align.Left)
              }
            }
          },
      ) {
        Text(billingHeadline)
      }

      FullRow(
          gap = 4,
          justify = Layout.Justify.Center,
          alignItems = Layout.AlignItems.Start,
      ) {
        Column(
            gap = 4,
            attrs = { tailwind { w(1.half) } },
        ) {
          TextInput(
              value = contactName,
              onInput = setContactName,
              label = contactNamePlaceholder,
              attrs = { tailwind { w(Full) } },
          )
          TextInput(
              value = email,
              onInput = setEmail,
              label = emailPlaceholder,
              attrs = { tailwind { w(Full) } },
          )
          TextInput(
              value = phone,
              onInput = setPhone,
              label = phonePlaceholder,
              attrs = { tailwind { w(Full) } },
          )
        }

        Column(
            gap = 4,
            attrs = { tailwind { w(1.half) } },
        ) {
          TextInput(
              value = address,
              onInput = setAddress,
              label = addressPlaceholder,
              attrs = { tailwind { w(Full) } },
          )

          FullRow(gap = 2) {
            TextInput(
                value = zip,
                onInput = setZip,
                label = zipPlaceholder,
                attrs = { tailwind { w(2.fifth) } },
            )

            TextInput(
                value = locality,
                onInput = setLocality,
                label = localityPlaceholder,
                attrs = { tailwind { w(3.fifth) } },
            )
          }
        }
      }

      FilledButton(
          text = createButton,
          onClick = onCreateClick,
          loading = loading,
          attrs = {
            tailwind {
              w(Full)
              mt(8)
            }
          },
      )

      TextButton(
          text = cancelButton,
          onClick = onCancelClick,
          attrs = { tailwind { w(Full) } },
      )

      AuthErrorMessage(errorMessage)
    }
  }
}
