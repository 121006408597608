package ui.screens.teamManagement

import androidx.compose.runtime.Composable
import androidx.compose.runtime.State
import androidx.compose.runtime.getValue
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberCoroutineScope
import api.traak.AccessRequest
import api.traak.LocalTraakApi
import api.traak.Team
import api.traak.TraakApi
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.flow.catch
import kotlinx.coroutines.launch
import ui.data.Loadable
import ui.data.asLoadable
import ui.data.collectAsState

class RequestStateHolder(
    private val api: TraakApi,
    private val coroutineScope: CoroutineScope,
    private val team: Team,
    loadableRequests: State<Loadable<List<AccessRequest>>>,
) : RequestState {
  override val loadableRequests by loadableRequests

  override fun onAcceptRequest(request: AccessRequest): Unit {
    coroutineScope.launch { api.acceptRequest(team, request) }
  }

  override fun onRejectRequest(request: AccessRequest): Unit {
    coroutineScope.launch { api.deleteRequest(team.id, request) }
  }
}

@Composable
fun rememberRequestState(
    team: Team,
    api: TraakApi = LocalTraakApi.current,
    coroutineScope: CoroutineScope = rememberCoroutineScope(),
): RequestState {
  val loadableRequests =
      remember(api) {
            api.pendingRequests(team.id).asLoadable().catch {
              console.error(it)
              Loadable.Loading
            }
          }
          .collectAsState()
  return remember(api, coroutineScope) {
    RequestStateHolder(
        api = api,
        coroutineScope = coroutineScope,
        team = team,
        loadableRequests = loadableRequests,
    )
  }
}
