package ui.screens.login.settingUp.email

interface SetupEmailState {
  val loading: Boolean

  val error: SetupEmailError?

  var email: String

  fun validateEmail()
}

enum class SetupEmailError {
  EmailAlreadyInUse
}
