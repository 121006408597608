package api.traak.user

import api.traak.Team
import api.traak.authManager.phone.PhoneAuthenticator
import kotlinx.coroutines.flow.Flow

/** Represents a user */
interface User {
  /** The display name of the user. */
  val displayName: String?

  /** The email of the user. */
  val email: String?

  /** The phone number normalized based on the E.164 standard (e.g. +16505550101) for the user. */
  val phoneNumber: String?

  /** The user's unique ID, scoped to the project. */
  val id: Id

  /** A [Flow] of all the [Team] that the user belongs to */
  val teams: Flow<List<Team>>

  val phoneAuthenticator: PhoneAuthenticator

  suspend fun updateDisplayName(displayName: String)

  suspend fun updateEmail(newEmail: String): EmailUpdateResult

  suspend fun disconnect()

  data class Id(val raw: String)
}
