package ui.color

import tailwind.color.UniModalColor
import tailwind.color.createColor

private val black = createColor("black")
private val primary = createColor("primary")
private val secondary = createColor("secondary")
private val error = createColor("error")

val BlackDark = black("dark")
val BlackMedium = black("medium")
val BlackLight = black("light")

val Surface = UniModalColor("surface")
val Section = UniModalColor("section")

val ErrorDark = error("dark")
val ErrorMedium = error("medium")
val ErrorLight = error("light")

val Primary100 = primary("100")
val Primary200 = primary("200")
val Primary300 = primary("300")
val Primary400 = primary("400")
val Primary500 = primary("500")
val Primary600 = primary("600")

val Secondary100 = secondary("100")
val Secondary200 = secondary("200")
val Secondary300 = secondary("300")
val Secondary400 = secondary("400")
val Secondary500 = secondary("500")
val Secondary600 = secondary("600")
