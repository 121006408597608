package ui.screens.projectDetail

import androidx.compose.runtime.Composable
import api.traak.Project
import org.jetbrains.compose.web.dom.AttrBuilderContext
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Text
import org.w3c.dom.HTMLDivElement
import tailwind.Layout
import tailwind.TextScope
import tailwind.tailwind
import ui.color.Secondary600
import ui.data.Loadable
import ui.material.Column

@Composable
fun Metadata(
    loadableProject: Loadable<Project>,
    addressSubtitle: String,
    attrs: AttrBuilderContext<HTMLDivElement>? = null,
) {
  Column(
      justify = Layout.Justify.Start,
      alignItems = Layout.AlignItems.Start,
      attrs = attrs,
  ) {
    Div {
      Div(
          attrs = {
            tailwind {
              text(color = Secondary600) { overline(weight = TextScope.Weight.Medium) }
              mb(4)
            }
          }) {
            Text(addressSubtitle)
          }

      when (loadableProject) {
        is Loadable.Loading ->
            LoadingProjectAddress(
                attrs = { tailwind { w(96) } },
            )
        is Loadable.Loaded ->
            ProjectAddress(
                attrs = { tailwind { w(96) } },
                project = loadableProject.resource,
            )
      }
    }
  }
}
