package ui.screens.projectOverview

import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.runtime.setValue
import androidx.compose.runtime.snapshotFlow
import api.traak.LocalTraakApi
import api.traak.Project
import api.traak.Team
import api.traak.TraakApi
import app.softwork.routingcompose.Router
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.DelicateCoroutinesApi
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.ExperimentalCoroutinesApi
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.flow.collect
import kotlinx.coroutines.flow.flatMapLatest
import kotlinx.coroutines.launch
import navigation.Route
import navigation.navigate
import ui.data.Loadable
import ui.data.asLoadable
import ui.data.map

@OptIn(ExperimentalCoroutinesApi::class)
class ProjectOverviewStateHolder(
    private val team: Team,
    private val api: TraakApi,
    private val router: Router,
    coroutineScope: CoroutineScope,
) : ProjectOverviewState {

  private var _refreshState: RefreshState by mutableStateOf(RefreshState.DONE)
  private var _activeStatus: Project.Status by mutableStateOf(Project.Status.Active)
  private var _projects: Loadable<List<Project>> by mutableStateOf(Loadable.Loading)

  init {
    coroutineScope.launch {
      snapshotFlow { _activeStatus }
          .flatMapLatest { api.projects(teamId = team.id, status = _activeStatus) }
          .asLoadable()
          .collect { _projects = it }
    }
  }

  override val projects: Loadable<FilteredProjects>
    get() = _projects.map { it.toFilteredProject() }

  override var search by mutableStateOf("")

  override val refreshState: RefreshState
    get() = _refreshState

  override val activeStatus: Project.Status
    get() = _activeStatus

  override fun onNewProjectClick() {
    router.navigate(Route.NewProject)
  }

  override fun onProjectClick(project: Project) {
    router.navigate(Route.ProjectDetail(project.id))
  }

  @OptIn(DelicateCoroutinesApi::class)
  override fun onRefreshClick() {
    if (_refreshState == RefreshState.DONE) {
      _refreshState = RefreshState.REFRESHING

      GlobalScope.launch(Dispatchers.Default) {
        api.synchronizeBexio(team.id)
        _refreshState = RefreshState.DONE
      }
    }
  }

  override fun onStatusClick(status: Project.Status) {
    _activeStatus = status
    _projects = Loadable.Loading
  }

  private fun List<Project>.toFilteredProject(): FilteredProjects =
      FilteredProjects(
          filtered = this.filter { it.contains(search) },
          noProjects = this.isEmpty(),
      )

  private fun Project.contains(content: String): Boolean =
      listOf(
              this.title,
              this.address.name,
              this.address.phone,
              this.address.place,
              this.address.locality,
          )
          .any { it.contains(content, ignoreCase = true) }
}

@Composable
fun rememberProjectOverviewState(
    team: Team,
    api: TraakApi = LocalTraakApi.current,
    router: Router = Router.current,
    coroutineScope: CoroutineScope = rememberCoroutineScope(),
): ProjectOverviewState {
  return remember(api, router) {
    ProjectOverviewStateHolder(
        team = team,
        api = api,
        router = router,
        coroutineScope = coroutineScope,
    )
  }
}
