package api.traak.fromFirestore

import api.traak.AccessRequest
import api.traak.user.User
import firebase.firestore.DocumentData
import kotlinx.datetime.Instant
import kotlinx.serialization.Serializable
import kotlinx.serialization.json.Json

@Serializable
data class FromFirestoreAccessRequest(
    val name: String,
    val firstName: String,
    val lastName: String,
    val uid: String,
    val requestDate: Timestamp,
) {
  companion object {
    private val json = Json { ignoreUnknownKeys = true }

    fun withData(data: DocumentData): FromFirestoreAccessRequest =
        json.decodeFromString(JSON.stringify(data))
  }
}

fun FromFirestoreAccessRequest.toAccessRequest(): AccessRequest =
    object : AccessRequest {
      override val name = this@toAccessRequest.name
      override val firstName = this@toAccessRequest.firstName
      override val lastName = this@toAccessRequest.lastName
      override val uid = User.Id(this@toAccessRequest.uid)
      override val requestDate = Instant.fromEpochSeconds(this@toAccessRequest.requestDate.seconds)
    }
