package ui.screens.integration.bexio.teamSelection

import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.runtime.setValue
import api.settings.LocalSettings
import api.settings.SettingsApi
import api.traak.LocalTraakApi
import api.traak.Team
import api.traak.TraakApi
import api.traak.user.User
import app.softwork.routingcompose.Router
import kotlinx.coroutines.CoroutineScope
import ui.screens.newTeam.NewTeamGenericStateHolder

class BexioTeamSelectionStateHolder(
    user: User,
    coroutineScope: CoroutineScope,
    private val settingsApi: SettingsApi,
    traakApi: TraakApi,
) : BexioTeamSelectionState, NewTeamGenericStateHolder(user, traakApi, coroutineScope) {
  init {
    // Make sure that we don't have a default team before we choose
    settingsApi.removeTeam()
  }

  private var _mode: TeamSelectionMode by mutableStateOf(TeamSelectionMode.SelectingTeam)

  override val mode: TeamSelectionMode
    get() = _mode

  override fun selectTeam(team: Team) {
    settingsApi.setTeamTo(team.id)
  }

  override fun switchMode() {
    _mode =
        if (_mode == TeamSelectionMode.SelectingTeam) TeamSelectionMode.CreatingTeam
        else TeamSelectionMode.SelectingTeam
  }

  override fun onTeamCreation() {
    switchMode()
  }
}

@Composable
fun rememberBexioTeamSelectionState(
    user: User,
    coroutineScope: CoroutineScope = rememberCoroutineScope(),
    settingsApi: SettingsApi = LocalSettings.current,
    traakApi: TraakApi = LocalTraakApi.current,
    router: Router = Router.current,
): BexioTeamSelectionState {
  return remember(user, coroutineScope, settingsApi, router) {
    BexioTeamSelectionStateHolder(
        user = user,
        coroutineScope = coroutineScope,
        settingsApi = settingsApi,
        traakApi = traakApi,
    )
  }
}
