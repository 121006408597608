package ui.screens.login.settingUp.phone

enum class SetupPhoneProgression {
  RetrievingPhoneNumber,
  ValidatingPhoneNumber
}

interface SetupPhoneState {
  val loading: Boolean

  val currentState: SetupPhoneProgression

  /** The id of the login/continue button. */
  val loginButtonId: String

  var phoneNumber: String

  var smsCode: String

  val errorMsg: String?

  fun sendSms()

  fun validateSmsCode()
}
