package api.traak.toFirestore

import api.traak.dto.BillingAddress
import api.traak.user.User
import firebase.firestore.DocumentData
import kotlinx.serialization.Serializable

@Serializable
data class ToFirestoreCreateTeam(
    val name: String,
    val roles: Roles,
    val billing: BillingAddress,
) : DocumentData {

  companion object {
    fun initializeWith(
        teamName: String,
        userId: User.Id,
        billing: BillingAddress,
    ): ToFirestoreCreateTeam =
        ToFirestoreCreateTeam(
            name = teamName,
            roles =
                Roles(
                    admin = listOf(userId.raw),
                    manager = listOf(userId.raw),
                    member = listOf(userId.raw),
                    removed = emptyList(),
                ),
            billing = billing,
        )
  }
}
