package ui.screens.login

import LoginForm
import androidx.compose.runtime.Composable
import api.traak.toErrorMessage
import org.jetbrains.compose.web.dom.AttrBuilderContext
import org.w3c.dom.HTMLDivElement
import ui.helpers.onEnterDown
import ui.layouts.AuthLayout
import ui.strings.LocalStrings
import ui.strings.LocalizedStrings

/** Stateful screen level composable to log in the user */
@Composable
fun Login(
    attrs: AttrBuilderContext<HTMLDivElement>? = null,
) {
  val strings: LocalizedStrings = LocalStrings.current
  val loginState = rememberLoginState()

  onEnterDown { loginState.login() }

  Login(
      traakHeadline = strings.loginSubtitle,
      email = loginState.email,
      setEmail = { loginState.email = it },
      emailPlaceholder = strings.loginEmailPlaceholder,
      password = loginState.password,
      setPassword = { loginState.password = it },
      passwordPlaceholder = strings.loginPasswordPlaceholder,
      logInText = strings.loginLogInButton,
      separationText = strings.loginSeparationText,
      onLogInClick = { loginState.login() },
      onLogInWithBexio = { loginState.logWithBexio() },
      loginNoAccount = strings.loginNoAccount,
      loginCreateAccount = strings.loginCreateAccount,
      errorMessage = loginState.result?.toErrorMessage(strings),
      loading = loginState.loading,
      attrs = attrs,
  )
}

/** Stateless screen level composable to log in the user */
@Composable
fun Login(
    traakHeadline: String,
    email: String,
    setEmail: (String) -> Unit,
    emailPlaceholder: String,
    password: String,
    setPassword: (String) -> Unit,
    passwordPlaceholder: String,
    logInText: String,
    separationText: String,
    onLogInClick: () -> Unit,
    onLogInWithBexio: () -> Unit,
    loginNoAccount: String,
    loginCreateAccount: String,
    errorMessage: String?,
    loading: Loading,
    attrs: AttrBuilderContext<HTMLDivElement>? = null,
) {
  AuthLayout(attrs = attrs) {
    LoginForm(
        traakHeadline = traakHeadline,
        email = email,
        setEmail = setEmail,
        emailPlaceholder = emailPlaceholder,
        password = password,
        setPassword = setPassword,
        passwordPlaceholder = passwordPlaceholder,
        logInText = logInText,
        separationText = separationText,
        onLogInClick = onLogInClick,
        onLogInWithBexioClick = onLogInWithBexio,
        loginNoAccount = loginNoAccount,
        loginCreateAccount = loginCreateAccount,
        errorMessage = errorMessage,
        loading = loading,
    )
  }
}
