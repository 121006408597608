package ui.components.navmenu

import androidx.compose.runtime.Composable
import org.jetbrains.compose.web.attributes.ATarget
import org.jetbrains.compose.web.attributes.target
import org.jetbrains.compose.web.dom.A
import org.jetbrains.compose.web.dom.AttrBuilderContext
import org.jetbrains.compose.web.dom.Text
import org.w3c.dom.HTMLDivElement
import tailwind.FlexScope
import tailwind.Full
import tailwind.tailwind
import ui.color.BlackMedium
import ui.icons.HelpCircleOutline
import ui.material.PopUpDetail
import ui.strings.LocalStrings

@Composable
fun Help(
    attrs: AttrBuilderContext<HTMLDivElement>? = null,
) {
  val strings = LocalStrings.current
  val traakHelpUrl = "https://www.traak.ch/help/"

  PopUpDetail(
      onClick = {},
      attrs = {
        attrs?.invoke(this)
        tailwind { text(color = BlackMedium) }
      },
  ) {
    A(
        href = traakHelpUrl,
        attrs = {
          target(ATarget.Blank)
          tailwind {
            w(Full)
            flex(direction = FlexScope.Direction.Row, gap = 4)
          }
        },
    ) {
      HelpCircleOutline()
      Text(strings.navMenuDetailHelp)
    }
  }
}
