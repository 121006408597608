package ui.components.languageFooter

import androidx.compose.runtime.Composable
import api.settings.Language
import api.settings.LocalSettings
import org.jetbrains.compose.web.dom.AttrBuilderContext
import org.w3c.dom.HTMLDivElement
import tailwind.TailwindBuilderContext
import tailwind.TextScope
import tailwind.tailwind
import ui.icons.AmericanFlag
import ui.icons.FrenchFlag
import ui.icons.GermanFlag
import ui.icons.PortugueseFlag
import ui.material.PopUpMenu

@Composable
fun Language.toFlag(
    tailwindSize: TailwindBuilderContext,
) =
    when (this) {
      Language.French -> FrenchFlag(tailwindSize)
      Language.English -> AmericanFlag(tailwindSize)
      Language.German -> GermanFlag(tailwindSize)
      Language.Portuguese -> PortugueseFlag(tailwindSize)
    }

fun Language.toText(): String =
    when (this) {
      Language.French -> "Français"
      Language.English -> "English"
      Language.German -> "Deutsch"
      Language.Portuguese -> "Português"
    }

@Composable
fun LanguageMenu(
    attrs: AttrBuilderContext<HTMLDivElement>? = null,
) {
  val settings = LocalSettings.current

  PopUpMenu(
      attrs = {
        attrs?.invoke(this)
        tailwind { text { subtitle2(size = TextScope.Size.Base) } }
      },
  ) {
    Language.entries.forEach { lang ->
      LanguageItem(
          text = lang.toText(),
          flag = { lang.toFlag { w(6) } },
          onClick = { settings.setLanguageTo(lang) },
      )
    }
  }
}
