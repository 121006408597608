package ui.material

import androidx.compose.runtime.Composable
import org.jetbrains.compose.web.ExperimentalComposeWebApi
import org.jetbrains.compose.web.css.cssRem
import org.jetbrains.compose.web.css.padding
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.css.transform
import org.jetbrains.compose.web.dom.Div
import tailwind.TailwindScope
import tailwind.TransitionScope
import tailwind.color.Blue050
import tailwind.color.Gray050
import tailwind.color.Gray100
import tailwind.color.Green400
import tailwind.color.White
import tailwind.tailwind

@OptIn(ExperimentalComposeWebApi::class)
@Composable
fun Switch(
    active: Boolean,
    onClick: () -> Unit,
    disabled: Boolean = false,
) {
  val transitionDuration = TransitionScope.Duration.Duration0200
  val backgroundColor = if (disabled) Gray100 else if (active) Green400 else Blue050
  val knobBackgroundColor = if (disabled) Gray050 else White
  val pointerStyle = if (disabled) TailwindScope.Cursor.NotAllowed else TailwindScope.Cursor.Pointer

  Div(
      attrs = {
        onClick { if (!disabled) onClick() }
        style { padding(2.px) }

        tailwind {
          w(11)
          h(7)

          round(TailwindScope.Radius.Full)
          shadow(TailwindScope.Shadow.Inner)

          background(color = backgroundColor)
          transition(duration = transitionDuration)

          cursor(pointerStyle)
          select(TailwindScope.SelectType.None)
        }
      },
  ) {
    Div(
        attrs = {
          style {
            if (active) {
              transform { translateX(0.9.cssRem) }
            }
          }

          tailwind {
            w(6)
            h(6)

            round(TailwindScope.Radius.Full)
            shadow(TailwindScope.Shadow.Md)

            background(color = knobBackgroundColor)

            transition(duration = transitionDuration)
          }
        },
    )
  }
}
