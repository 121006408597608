package ui.material

import androidx.compose.runtime.Composable
import org.jetbrains.compose.web.dom.AttrBuilderContext
import org.jetbrains.compose.web.dom.Div
import org.w3c.dom.HTMLDivElement
import tailwind.FlexScope
import tailwind.Layout
import tailwind.Side
import tailwind.TailwindScope
import tailwind.TextScope
import tailwind.TransitionScope
import tailwind.color.Color
import tailwind.color.Opacity
import tailwind.color.Opacity010
import tailwind.tailwind
import ui.color.BlackLight

@Composable
fun PopUpDetail(
    onClick: () -> Unit,
    hoverColor: Color = BlackLight,
    hoverOpacity: Opacity = Opacity010,
    direction: FlexScope.Direction = FlexScope.Direction.Row,
    justify: Layout.Justify = Layout.Justify.Start,
    alignItems: Layout.AlignItems = Layout.AlignItems.Center,
    gap: Int = 4,
    attrs: AttrBuilderContext<HTMLDivElement>? = null,
    content: @Composable () -> Unit,
) {
  Div(
      attrs = {
        attrs?.invoke(this)
        onClick { onClick() }

        tailwind {
          flex(
              direction = direction,
              justify = justify,
              alignItems = alignItems,
              gap = gap,
          )

          px(4)
          py(3)

          cursor(TailwindScope.Cursor.Pointer)

          hover { background(color = hoverColor, opacity = hoverOpacity) }
          transition(duration = TransitionScope.Duration.Duration0200)

          first { round(TailwindScope.Radius.Large, Side.Top) }
          last { round(TailwindScope.Radius.Large, Side.Bottom) }

          text { subtitle2(size = TextScope.Size.Base) }
        }
      }) {
        content()
      }
}
