package ui.components.taskTable

import androidx.compose.runtime.Composable
import api.traak.Task
import org.jetbrains.compose.web.dom.Text

@Composable
fun DisplayAuthor(
    author: Task.Author,
    firstNameFirst: Boolean,
) {
  Text(
      if (firstNameFirst) {
        "${author.firstName} ${author.lastName}"
      } else {
        "${author.lastName} ${author.firstName}"
      },
  )
}
