package ui.components

import androidx.compose.runtime.Composable
import org.jetbrains.compose.web.dom.AttrBuilderContext
import org.jetbrains.compose.web.dom.Div
import org.w3c.dom.HTMLDivElement
import tailwind.Full
import tailwind.TailwindScope
import tailwind.tailwind
import ui.color.Primary200

@Composable
fun LoadingPlaceholder(
    attrs: AttrBuilderContext<HTMLDivElement>? = null,
) {
  Div(attrs = { attrs?.invoke(this) }) {
    Div(
        attrs = {
          tailwind {
            w(Full)
            h(4)
            round(TailwindScope.Radius.Full)
            background(color = Primary200)

            animate(TailwindScope.Animation.Pulse)
          }
        }) {}
  }
}
