package ui.screens.login.settingUp.email

import androidx.compose.runtime.Composable
import api.traak.user.User
import org.jetbrains.compose.web.attributes.AutoComplete
import org.jetbrains.compose.web.dom.AttrBuilderContext
import org.jetbrains.compose.web.dom.Text
import org.w3c.dom.HTMLDivElement
import tailwind.Full
import tailwind.tailwind
import ui.material.FilledButton
import ui.material.FullColumn
import ui.material.TextInput
import ui.screens.login.settingUp.ErrorMessage
import ui.screens.login.settingUp.Explanation
import ui.strings.LocalStrings
import ui.strings.LocalizedStrings

@Composable
fun SetupEmail(
    user: User,
    attrs: AttrBuilderContext<HTMLDivElement>? = null,
) {
  val strings = LocalStrings.current
  val state = rememberSetupEmailState(user)

  val errorMsg = state.error?.toErrorMsg(strings)

  FullColumn(
      gap = 8,
      attrs = attrs,
  ) {
    Explanation(strings.setupEmailTitle) { Text(strings.setupEmailExplanation) }

    FullColumn(
        gap = 4,
    ) {
      TextInput(
          value = state.email,
          onInput = { state.email = it },
          label = strings.setupEmailFormEmailLabel,
          placeholder = strings.setupEmailFormEmailPlaceholder,
          autoComplete = AutoComplete.email,
          attrs = { tailwind { w(Full) } },
      )

      FilledButton(
          text = strings.setupEmailFormContinueButton,
          onClick = { state.validateEmail() },
          loading = state.loading,
          attrs = { tailwind { w(Full) } },
      )

      errorMsg?.let { ErrorMessage(errorMsg) }
    }
  }
}

private fun SetupEmailError.toErrorMsg(strings: LocalizedStrings): String =
    when (this) {
      SetupEmailError.EmailAlreadyInUse -> strings.authErrorFailureEmailAlreadyInUse
    }
