package ui.screens.integration.bexio.teamSelection

import androidx.compose.runtime.Composable
import api.traak.StorageResult
import api.traak.Team
import api.traak.user.User
import org.jetbrains.compose.web.dom.AttrBuilderContext
import org.w3c.dom.HTMLDivElement
import tailwind.Full
import tailwind.tailwind
import ui.material.FullColumn
import ui.material.OutlineButton
import ui.screens.integration.bexio.BexioHolder
import ui.screens.integration.bexio.BexioIntegrationStage
import ui.screens.integration.bexio.BexioStageExplanation
import ui.screens.integration.bexio.SideBar
import ui.screens.newTeam.NewTeamState
import ui.screens.teamSelection.TeamItem
import ui.strings.LocalStrings

@Composable
fun BexioTeamSelection(
    user: User,
    teams: List<Team>,
    attrs: AttrBuilderContext<HTMLDivElement>? = null,
) {
  val state =
      rememberBexioTeamSelectionState(
          user = user,
      )

  val strings = LocalStrings.current

  fun NewTeamState.Result.toErrorMessage(): String? =
      when (this) {
        is NewTeamState.Result.Storage ->
            when (this.result) {
              StorageResult.Success -> null
              else -> strings.teamCreationFailure
            }
        NewTeamState.Result.MissingData -> strings.teamCreationMissingData
      }

  when (state.mode) {
    TeamSelectionMode.SelectingTeam ->
        BexioTeamSelection(
            headline = strings.bexioIntegrationHeadline,
            title = strings.bexioIntegrationTeamSelectionTitle,
            explanation = strings.bexioIntegrationTeamSelectionExplanation,
            teamMemberText = strings::teamSelectionMembers,
            teams = teams,
            onTeamClick = state::selectTeam,
            newTeamButton = strings.teamSelectionNewTeamButton,
            onNewTeamClick = state::switchMode,
            attrs = attrs,
        )
    TeamSelectionMode.CreatingTeam ->
        ui.screens.newTeam.NewTeam(
            traakHeadline = strings.newTeamTraakHeadline,
            billingHeadline = strings.newTeamBillingHeadline,
            teamName = state.teamName,
            setTeamName = { state.teamName = it },
            newTeamPlaceholder = strings.newTeamNamePlaceholder,
            contactName = state.contactName,
            setContactName = { state.contactName = it },
            contactNamePlaceholder = strings.newTeamContactNamePlaceholder,
            email = state.email,
            setEmail = { state.email = it },
            emailPlaceholder = strings.newTeamEmailPlaceholder,
            phone = state.phone,
            setPhone = { state.phone = it },
            phonePlaceholder = strings.newTeamPhonePlaceholder,
            address = state.address,
            setAddress = { state.address = it },
            addressPlaceholder = strings.newTeamAddressPlaceholder,
            locality = state.locality,
            setLocality = { state.locality = it },
            localityPlaceholder = strings.newTeamLocalityPlaceholder,
            zip = state.zip,
            setZip = { state.zip = it },
            zipPlaceholder = strings.newTeamZipPlaceholder,
            createButton = strings.newTeamCreateButton,
            onCreateClick = { state.createTeam() },
            cancelButton = strings.newTeamCancelButton,
            onCancelClick = { state.switchMode() },
            loading = state.loading,
            errorMessage = state.result?.toErrorMessage(),
            attrs = attrs,
        )
  }
}

@Composable
fun BexioTeamSelection(
    headline: String,
    title: String,
    explanation: String,
    teamMemberText: (Int) -> String,
    teams: List<Team>,
    onTeamClick: (Team) -> Unit,
    newTeamButton: String,
    onNewTeamClick: () -> Unit,
    attrs: AttrBuilderContext<HTMLDivElement>? = null,
) {
  BexioHolder(
      headline = headline,
      attrs = attrs,
      sideBar = { SideBar(BexioIntegrationStage.TeamSelection) },
  ) {
    FullColumn {
      BexioStageExplanation(title, explanation)

      teams.forEach {
        TeamItem(
            team = it,
            membersText = teamMemberText,
            onClick = { onTeamClick(it) },
            attrs = {
              tailwind {
                w(Full)
                my(2)
              }
            },
        )
      }

      OutlineButton(
          text = newTeamButton,
          onClick = onNewTeamClick,
          attrs = {
            tailwind {
              mt(8)
              w(Full)
            }
          })
    }
  }
}
