package ui.screens.login.settingUp

import androidx.compose.runtime.Composable
import org.jetbrains.compose.web.dom.AttrBuilderContext
import org.jetbrains.compose.web.dom.P
import org.jetbrains.compose.web.dom.Text
import org.w3c.dom.HTMLParagraphElement
import tailwind.tailwind
import ui.color.ErrorMedium

@Composable
fun ErrorMessage(
    text: String,
    attrs: AttrBuilderContext<HTMLParagraphElement>? = null,
) {
  P(
      attrs = {
        attrs?.invoke(this)

        tailwind { text(color = ErrorMedium) { body1() } }
      },
  ) {
    Text(text)
  }
}
