package api.traak.fromFirestore

import api.traak.Team
import api.traak.TraakApi
import api.traak.TraakTeam
import api.traak.dto.BillingAddress
import api.traak.user.User
import firebase.firestore.DocumentData
import firebase.firestore.Firestore
import kotlinx.serialization.*
import kotlinx.serialization.json.Json

@Serializable
data class TeamIntegration(
    val integrating: Boolean,
    val bexio: Boolean,
)

@Serializable
data class FromFirestoreTeam(
    val name: String,
    val id: String,
    val roles: Roles,
    val integration: TeamIntegration,
    val billing: BillingAddress,
) {
  companion object {
    private val json = Json { ignoreUnknownKeys = true }

    fun withData(data: DocumentData): FromFirestoreTeam? {
      return try {
        json.decodeFromString(JSON.stringify(data))
      } catch (e: Exception) {
        console.error(e)
        null
      }
    }
  }
}

fun FromFirestoreTeam.toTeam(
    api: TraakApi,
    firestore: Firestore,
): Team =
    TraakTeam(
        api = api,
        firestore = firestore,
        name = this@toTeam.name,
        id = Team.Id(this@toTeam.id),
        roles =
            Team.Roles(
                admin = this@toTeam.roles.admin.map { User.Id(it) }.toList(),
                manager = this@toTeam.roles.manager.map { User.Id(it) }.toList(),
                member = this@toTeam.roles.member.map { User.Id(it) }.toList(),
                removed = this@toTeam.roles.removed.map { User.Id(it) }.toList(),
            ),
        integration =
            Team.Integration(
                this@toTeam.integration.integrating,
                this@toTeam.integration.bexio,
            ),
        billing = this@toTeam.billing,
    )
