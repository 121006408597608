package ui.components.languageFooter

import androidx.compose.runtime.Composable
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import org.jetbrains.compose.web.dom.AttrBuilderContext
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Span
import org.w3c.dom.HTMLDivElement
import tailwind.*
import ui.color.BlackDark
import ui.color.BlackMedium
import ui.helpers.OnOutsideClick
import ui.icons.World

@Composable
fun LanguageFooter(
    attrs: AttrBuilderContext<HTMLDivElement>? = null,
) {
  val menuActive = remember { mutableStateOf(false) }

  OnOutsideClick(
      property = menuActive,
      onOutsideClickValue = false,
  )

  LanguageFooter(
      onIconClick = { menuActive.value = !menuActive.value },
      showMenu = menuActive.value,
      attrs = attrs,
  )
}

@Composable
fun LanguageFooter(
    onIconClick: () -> Unit,
    showMenu: Boolean,
    attrs: AttrBuilderContext<HTMLDivElement>? = null,
) {
  Div(
      attrs = {
        attrs?.invoke(this)

        tailwind {
          w(Full)
          position(TailwindScope.Position.Relative)
          p(8)

          flex(
              direction = FlexScope.Direction.Row,
              justify = Layout.Justify.End,
          )
        }
      }) {
        if (showMenu) {
          LanguageMenu(
              attrs = {
                tailwind {
                  position(TailwindScope.Position.Absolute)
                  bottom(16)
                }
              })
        }

        Span(
            attrs = {
              onClick {
                it.stopPropagation()
                onIconClick()
              }

              tailwind {
                cursor(TailwindScope.Cursor.Pointer)
                text(color = BlackMedium)
                hover { text(color = BlackDark) }
              }
            }) {
              World()
            }
      }
}
