package tailwind

@TailwindDsl
interface TransitionScope {
  enum class Property {
    None,
    All,
    Default,
    Colors,
    Opacity,
    Shadow,
    Transform,
  }

  fun transition(property: Property = Property.All)

  enum class Duration {
    Duration0075,
    Duration0100,
    Duration0150,
    Duration0200,
    Duration0300,
    Duration0500,
    Duration0700,
    Duration1000,
  }

  fun duration(duration: Duration)

  enum class TimingFunction {
    Linear,
    In,
    Out,
    InOut,
  }

  fun timing(timingFunction: TimingFunction)

  enum class Delay {
    Delay0075,
    Delay0100,
    Delay0150,
    Delay0200,
    Delay0300,
    Delay0500,
    Delay0700,
    Delay1000,
  }

  fun delay(delay: Delay)
}
