package ui.components.navmenu

import androidx.compose.runtime.Composable
import app.softwork.routingcompose.Router
import navigation.navigate
import org.jetbrains.compose.web.dom.AttrBuilderContext
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Text
import org.w3c.dom.HTMLDivElement
import tailwind.FlexScope
import tailwind.Full
import tailwind.Layout
import tailwind.TailwindScope
import tailwind.tailwind

@Composable
fun Account(
    tab: Tab,
    active: Boolean,
    name: String,
    attrs: AttrBuilderContext<HTMLDivElement>? = null,
) {
  val router = Router.current

  Div(
      attrs = {
        attrs?.invoke(this)

        onClick { router.navigate(tab.route) }

        tailwind {
          h(Full)
          flex(
              direction = FlexScope.Direction.Row,
              alignItems = Layout.AlignItems.Center,
              gap = 5,
          )

          text(
              color = if (active) navmenu.activeColor else navmenu.inactiveColor,
          ) {
            button()
          }

          transition()

          cursor(TailwindScope.Cursor.Pointer)
          hover { text(color = navmenu.activeColor) }
        }
      }) {
        Text(name)

        if (active) tab.activeIcon() else tab.inactiveIcon()
      }
}
