package api.traak

import api.traak.user.User
import kotlin.time.Duration
import kotlinx.datetime.Instant

interface Task {
  /** Information related to the one who performed the task */
  interface Author {
    /** The id of the user who performed the task */
    val id: User.Id

    /** The first name of the user who performed the task */
    val firstName: String

    /** The last name of the user who performed the task */
    val lastName: String
  }

  /** Information related to the project in which the task belongs */
  interface Project {
    /** The id of the project in which the task belongs */
    val id: api.traak.Project.Id

    /** The title of the project in which the task belongs */
    val title: String

    /** The status of the project in which the task belongs */
    val status: api.traak.Project.Status
  }

  /** The performer of the task */
  val author: Author

  /** The project in which the task belongs */
  val project: Project?

  /** A description, or summary, of the task */
  val description: String

  /** The task's team Id */
  val team: Team.Id

  /** The task's starting timestamp */
  val start: Instant

  /** The task's ending timestamp */
  val end: Instant

  /** The task's duration */
  val duration: Duration

  val id: Id

  data class Id(val raw: String)
}

class TraakTask(
    override val author: Task.Author,
    override val project: Task.Project?,
    override val description: String,
    override val team: Team.Id,
    override val start: Instant,
    override val end: Instant,
    override val id: Task.Id
) : Task {

  override val duration: Duration
    get() = end - start

  override fun equals(other: Any?): Boolean {
    if (this === other) return true
    if (other == null || this::class.js != other::class.js) return false

    other as TraakTask

    if (author != other.author) return false
    if (project != other.project) return false
    if (description != other.description) return false
    if (team != other.team) return false
    if (start != other.start) return false
    if (end != other.end) return false
    if (id != other.id) return false

    return true
  }

  override fun hashCode(): Int {
    var result = author.hashCode()
    result = 31 * result + (project?.hashCode() ?: 0)
    result = 31 * result + description.hashCode()
    result = 31 * result + team.hashCode()
    result = 31 * result + start.hashCode()
    result = 31 * result + end.hashCode()
    result = 31 * result + id.hashCode()
    return result
  }
}
