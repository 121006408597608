package ui.components.chips.checkbox.searchable

import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import org.jetbrains.compose.web.css.maxHeight
import org.jetbrains.compose.web.css.minWidth
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.css.vh
import org.jetbrains.compose.web.dom.AttrBuilderContext
import org.jetbrains.compose.web.dom.Text
import org.w3c.dom.HTMLDivElement
import tailwind.Full
import tailwind.Layout
import tailwind.TailwindScope
import tailwind.TextScope
import tailwind.color.White
import tailwind.tailwind
import ui.color.BlackMedium
import ui.components.Checkbox
import ui.components.chips.dropdown.DropdownAlignment
import ui.components.chips.dropdown.DropdownChip
import ui.material.CardTailwindScope
import ui.material.Column
import ui.material.FullColumn
import ui.material.FullRow
import ui.material.SmallSearchInput
import ui.material.SmallTextButton

@Composable
fun <T> SearchableCheckboxChip(
    text: String,
    items: List<T>,
    onItemClick: (T) -> Unit,
    isItemSelected: (T) -> Boolean,
    itemMatches: (item: T, search: String) -> Boolean,
    searchPlaceholder: String,
    selectButtonText: String,
    onSelectClick: () -> Unit,
    attrs: AttrBuilderContext<HTMLDivElement>? = null,
    dropdownAlignment: DropdownAlignment = DropdownAlignment.None,
    chipIcon: (@Composable () -> Unit)? = null,
) {
  SearchableCheckboxChip(
      text = text,
      items = items,
      displayItem = {
        DefaultDisplayItem(
            it,
            isSelected = isItemSelected(it),
            onClick = { onItemClick(it) },
        )
      },
      itemMatches = itemMatches,
      searchPlaceholder = searchPlaceholder,
      selectButtonText = selectButtonText,
      onSelectClick = onSelectClick,
      attrs = attrs,
      dropdownAlignment = dropdownAlignment,
      chipIcon = chipIcon,
  )
}

@Composable
fun <T> SearchableCheckboxChip(
    text: String,
    items: List<T>,
    displayItem: @Composable (T) -> Unit,
    itemMatches: (item: T, search: String) -> Boolean,
    searchPlaceholder: String,
    selectButtonText: String,
    onSelectClick: () -> Unit,
    attrs: AttrBuilderContext<HTMLDivElement>? = null,
    dropdownAlignment: DropdownAlignment = DropdownAlignment.None,
    chipIcon: (@Composable () -> Unit)? = null,
) {
  DropdownChip(
      text,
      attrs,
      leadingContent = chipIcon,
      dropdownAlignment = dropdownAlignment,
      dropdown = {
        Dropdown(
            items = items,
            displayItem = displayItem,
            itemMatches = itemMatches,
            searchPlaceholder = searchPlaceholder,
            selectButtonText = selectButtonText,
            onSelectClick = onSelectClick,
            attrs = it,
        )
      },
  )
}

@Composable
private fun <T> DefaultDisplayItem(
    item: T,
    isSelected: Boolean,
    onClick: () -> Unit,
) {
  FullRow(
      gap = 4,
      attrs = {
        style { minWidth(200.px) }

        onClick { onClick() }

        tailwind {
          text(color = BlackMedium) { body1(size = TextScope.Size.Lg) }

          select(TailwindScope.SelectType.None)
          cursor(TailwindScope.Cursor.Pointer)
        }
      },
  ) {
    Checkbox(
        isSelected,
    )

    Text(item.toString())
  }
}

@Composable
private fun <T> Dropdown(
    items: List<T>,
    displayItem: @Composable (T) -> Unit,
    itemMatches: (item: T, search: String) -> Boolean,
    searchPlaceholder: String,
    selectButtonText: String,
    onSelectClick: () -> Unit,
    attrs: AttrBuilderContext<HTMLDivElement>? = null,
) {
  var search by remember { mutableStateOf("") }
  val filteredItems = items.filter { itemMatches(it, search) }

  Column(
      gap = 0,
      attrs = {
        attrs?.invoke(this)

        tailwind {
          CardTailwindScope(this)
          w(104)
          p(2)

          z(10)
          background(color = White)
        }
      },
  ) {
    FullColumn(
        gap = 2,
        attrs = { tailwind { pb(4) } },
    ) {
      SmallSearchInput(
          value = search,
          onInput = { search = it },
          placeholder = searchPlaceholder,
          attrs = { tailwind { w(Full) } },
      )

      FullRow(justify = Layout.Justify.End) {
        SmallTextButton(selectButtonText, onClick = onSelectClick)
      }
    }

    FullColumn(
        gap = 4,
        attrs = {
          style { maxHeight(40.vh) }

          tailwind {
            overflow(TailwindScope.OverflowType.Auto)
            overscroll(TailwindScope.Overscroll.Auto)
          }
        },
    ) {
      filteredItems.forEach { item -> displayItem(item) }
    }
  }
}
