package api.traak

import api.traak.dto.BillingAddress
import api.traak.user.User
import kotlinx.coroutines.flow.Flow
import kotlinx.datetime.LocalDate
import utils.Ordering

interface Team {
  /** The name of the team. */
  val name: String

  /** The team's unique ID */
  val id: Id

  /** The team's members, admins, etc... */
  val roles: Roles

  val integration: Integration

  val billing: BillingAddress

  val members: Flow<List<Member>>

  fun getProjects(status: Project.Status?): Flow<List<Project>>

  fun getTasks(
      startDate: LocalDate,
      endDate: LocalDate?,
      orderBy: Ordering?,
  ): Flow<List<Task>>

  fun roleOf(userId: User.Id): Role?

  data class Id(val raw: String)

  data class Roles(
      val admin: List<User.Id>,
      val manager: List<User.Id>,
      val member: List<User.Id>,
      val removed: List<User.Id>,
  )

  data class Integration(
      val integrating: Boolean,
      val bexio: Boolean,
  )
}
