package ui.screens.login.settingUp.name

import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.runtime.setValue
import api.traak.user.User
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.launch

class SetupNameStateHolder(
    private val user: User,
    private val coroutineScope: CoroutineScope,
) : SetupNameState {
  private var _loading by mutableStateOf(false)

  override val loading: Boolean
    get() = _loading

  override var displayName by mutableStateOf("")

  override fun saveName() {
    coroutineScope.launch {
      _loading = true

      user.updateDisplayName(displayName)

      _loading = false
    }
  }
}

@Composable
fun rememberSetupNameState(
    user: User,
    coroutineScope: CoroutineScope = rememberCoroutineScope(),
): SetupNameState {
  return remember(
      user,
      coroutineScope,
  ) {
    SetupNameStateHolder(
        user,
        coroutineScope,
    )
  }
}
