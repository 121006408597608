package ui.components.navmenu

import androidx.compose.runtime.Composable
import org.jetbrains.compose.web.dom.AttrBuilderContext
import org.jetbrains.compose.web.dom.Text
import org.w3c.dom.HTMLDivElement
import tailwind.color.Opacity100
import tailwind.color.White
import tailwind.tailwind
import ui.color.ErrorDark
import ui.icons.LogOut as LogOutIcon
import ui.material.PopUpDetail
import ui.strings.LocalStrings

@Composable
fun LogOut(
    onClick: () -> Unit,
    attrs: AttrBuilderContext<HTMLDivElement>? = null,
) {
  val strings = LocalStrings.current

  PopUpDetail(
      onClick = onClick,
      hoverColor = ErrorDark,
      hoverOpacity = Opacity100,
      attrs = {
        attrs?.invoke(this)

        tailwind {
          text(color = ErrorDark)
          hover { text(color = White) }
        }
      },
  ) {
    LogOutIcon()
    Text(strings.navMenuDetailLogOut)
  }
}
