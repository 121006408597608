package utils

import kotlin.time.DurationUnit
import kotlin.time.toDuration
import kotlinx.datetime.LocalDateTime
import kotlinx.datetime.TimeZone
import kotlinx.datetime.toInstant
import kotlinx.datetime.toLocalDateTime

val LocalDateTime.startOfWeek: LocalDateTime
  get() {
    val current = this.toInstant(TimeZone.currentSystemDefault())
    val startOfWeek = current.minus(this.dayOfWeek.ordinal.toDuration(DurationUnit.DAYS))
    return startOfWeek.toLocalDateTime(TimeZone.currentSystemDefault())
  }

val LocalDateTime.endOfWeek: LocalDateTime
  get() {
    val startOfWeek = this.startOfWeek.toInstant(TimeZone.currentSystemDefault())
    val endOfWeek = startOfWeek.plus(6.toDuration(DurationUnit.DAYS))
    return endOfWeek.toLocalDateTime(TimeZone.currentSystemDefault())
  }

fun Number.padWith0() = this.toString().padStart(2, '0')
