package ui.components.taskTable

import androidx.compose.runtime.Composable
import org.jetbrains.compose.web.dom.AttrBuilderContext
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Text
import org.w3c.dom.HTMLDivElement
import tailwind.TailwindScope
import tailwind.color.Opacity100
import tailwind.color.White
import tailwind.tailwind
import ui.color.BlackMedium
import ui.color.ErrorDark
import ui.icons.Delete as DeleteIcon
import ui.icons.Pencil
import ui.material.PopUpDetail
import ui.material.PopUpMenu
import ui.strings.LocalStrings

@Composable
fun ItemMenu(
    onEditClick: () -> Unit,
    onDeleteClick: () -> Unit,
    attrs: AttrBuilderContext<HTMLDivElement>? = null,
) {
  Div(
      attrs = {
        attrs?.invoke(this)
        tailwind { position(TailwindScope.Position.Relative) }
      }) {
        PopUpMenu(
            attrs = {
              tailwind {
                w(50)
                top(6)
                right(0)
              }
            }) {
              Edit(onEditClick)
              Delete(onDeleteClick)
            }
      }
}

@Composable
private fun Edit(
    onClick: () -> Unit,
    attrs: AttrBuilderContext<HTMLDivElement>? = null,
) {
  val strings = LocalStrings.current

  PopUpDetail(
      onClick = onClick,
      attrs = {
        attrs?.invoke(this)
        tailwind { text(color = BlackMedium) }
      },
  ) {
    Pencil()
    Text(strings.taskItemMenuEdit)
  }
}

@Composable
private fun Delete(
    onClick: () -> Unit,
    attrs: AttrBuilderContext<HTMLDivElement>? = null,
) {
  val strings = LocalStrings.current

  PopUpDetail(
      onClick = onClick,
      hoverColor = ErrorDark,
      hoverOpacity = Opacity100,
      attrs = {
        attrs?.invoke(this)

        tailwind {
          text(color = ErrorDark)
          hover { text(color = White) }
        }
      },
  ) {
    DeleteIcon()
    Text(strings.taskItemMenuDelete)
  }
}
