package ui.components.calendar

import androidx.compose.runtime.Composable
import kotlinx.coroutines.flow.StateFlow
import kotlinx.datetime.LocalDate
import org.jetbrains.compose.web.dom.AttrBuilderContext
import org.w3c.dom.HTMLDivElement

enum class PickerState {
  START,
  END,
}

data class DayRange(
    val start: LocalDate,
    val end: LocalDate,
)

/** Represents the external state of the Calendar component */
interface CalendarState {
  val range: StateFlow<DayRange>

  @Composable
  fun Calendar(
      attrs: AttrBuilderContext<HTMLDivElement>?,
  )
}
