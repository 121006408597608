package api.traak.fromFirestore

import api.traak.Recap
import api.traak.Team
import api.traak.user.User
import firebase.firestore.DocumentData
import kotlinx.serialization.*
import kotlinx.serialization.json.Json

@Serializable
data class FromFirestoreRecap(
    val id: String,
    val user: String,
    val team: String,
    val description: String,
    val creationDate: Timestamp,
) {
  fun toRecap(): Recap =
      object : Recap {
        override val id = Recap.Id(this@FromFirestoreRecap.id)
        override val user = User.Id(this@FromFirestoreRecap.user)
        override val team = Team.Id(this@FromFirestoreRecap.team)
        override val creationDate = this@FromFirestoreRecap.creationDate.toInstant()
        override val description = this@FromFirestoreRecap.description
      }

  companion object {
    private val json = Json { ignoreUnknownKeys = true }

    fun withData(data: DocumentData): FromFirestoreRecap =
        json.decodeFromString(JSON.stringify(data))
  }
}
