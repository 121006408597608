package ui.components.taskCreator

import androidx.compose.runtime.Composable
import api.traak.Task
import org.jetbrains.compose.web.dom.AttrBuilderContext
import org.w3c.dom.HTMLDivElement
import tailwind.Layout
import tailwind.TailwindScope
import tailwind.TransitionScope
import tailwind.color.White
import tailwind.tailwind
import ui.color.Primary600
import ui.icons.Plus
import ui.material.Column
import ui.material.Row

@Composable
fun TaskCreator(
    state: TaskCreatorState,
    bottom: Int = 10,
    right: Int = 10,
    attrs: AttrBuilderContext<HTMLDivElement>? = null,
) {
  TaskCreator(
      onCloseClick = { state.onCloseClick() },
      onFABClick = { state.onFABClick() },
      modalIsActive = state.modalIsActive,
      currentProject = state.currentProject,
      projects = state.projects,
      onProjectClick = { state.currentProject = it },
      selectedMember = state.selectedAuthor,
      members = state.members,
      onMemberClick = { state.selectedAuthor = it },
      description = state.taskDescription,
      onDescriptionChange = { state.taskDescription = it },
      taskDate = state.taskDate,
      onTaskDateChange = { state.taskDate = it },
      duration = state.taskDuration,
      onDurationChange = { state.taskDuration = it },
      isValid = state.creationIsValid,
      isLoading = state.loading,
      isSuccess = state.success,
      onSaveClick = { state.saveTask() },
      onResetClick = { state.reset() },
      bottom = bottom,
      right = right,
      attrs = attrs,
  )
}

@Composable
fun TaskCreator(
    onCloseClick: () -> Unit,
    onFABClick: () -> Unit,
    modalIsActive: Boolean,
    currentProject: Task.Project?,
    projects: List<Task.Project>,
    onProjectClick: (Task.Project) -> Unit,
    selectedMember: Task.Author?,
    members: List<Task.Author>,
    onMemberClick: (Task.Author) -> Unit,
    description: String,
    onDescriptionChange: (String) -> Unit,
    taskDate: String,
    onTaskDateChange: (String) -> Unit,
    duration: String,
    onDurationChange: (String) -> Unit,
    isValid: Boolean,
    isLoading: Boolean,
    isSuccess: Boolean,
    onSaveClick: () -> Unit,
    onResetClick: () -> Unit,
    bottom: Int = 10,
    right: Int = 10,
    attrs: AttrBuilderContext<HTMLDivElement>? = null,
) {
  Column(
      justify = Layout.Justify.End,
      alignItems = Layout.AlignItems.End,
      gap = 6,
      attrs = {
        attrs?.invoke(this)

        tailwind {
          position(TailwindScope.Position.Fixed)

          bottom(bottom)
          right(right)
        }
      }) {
        if (modalIsActive) {
          TaskCreationCard(
              onCloseClick = onCloseClick,
              currentProject = currentProject,
              projects = projects,
              onProjectClick = onProjectClick,
              selectedMember = selectedMember,
              members = members,
              onMemberClick = onMemberClick,
              description = description,
              onDescriptionChange = onDescriptionChange,
              date = taskDate,
              onDateChange = onTaskDateChange,
              duration = duration,
              onDurationChange = onDurationChange,
              isLoading = isLoading,
              isValid = isValid,
              isSuccess = isSuccess,
              onSaveClick = onSaveClick,
              onResetClick = onResetClick,
          )
        }

        AddTaskFAB(
            onClick = onFABClick,
        )
      }
}

@Composable
private fun AddTaskFAB(
    onClick: () -> Unit,
    attrs: AttrBuilderContext<HTMLDivElement>? = null,
) {
  Row(
      justify = Layout.Justify.Center,
      alignItems = Layout.AlignItems.Center,
      attrs = {
        attrs?.invoke(this)
        onClick { onClick() }

        tailwind {
          w(14)
          h(14)

          round(TailwindScope.Radius.Full)
          shadow(TailwindScope.Shadow.Md)
          hover { shadow(TailwindScope.Shadow.Xl) }
          transition(TransitionScope.Property.All, TransitionScope.Duration.Duration0300)

          cursor(TailwindScope.Cursor.Pointer)

          background(color = Primary600)
          text(color = White)
        }
      }) {
        Plus()
      }
}
