package ui.layouts

import androidx.compose.runtime.Composable
import org.jetbrains.compose.web.dom.AttrBuilderContext
import org.jetbrains.compose.web.dom.Div
import org.w3c.dom.HTMLDivElement
import tailwind.Full
import tailwind.tailwind

@Composable
fun FullScreenLayout(
    attrs: AttrBuilderContext<HTMLDivElement>? = null,
    content: @Composable () -> Unit,
) {
  Div(
      attrs = {
        attrs?.invoke(this)
        tailwind {
          w(Full)
          px(16)
          pt(4)
          mb(10)
        }
      },
  ) {
    content()
  }
}
