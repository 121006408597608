package ui.screens.newProject

import androidx.compose.runtime.Composable
import org.jetbrains.compose.web.attributes.AutoComplete
import org.jetbrains.compose.web.dom.AttrBuilderContext
import org.jetbrains.compose.web.dom.P
import org.jetbrains.compose.web.dom.Text
import org.w3c.dom.HTMLDivElement
import tailwind.Full
import tailwind.TextScope
import tailwind.fifth
import tailwind.fourth
import tailwind.tailwind
import ui.color.ErrorDark
import ui.material.Column
import ui.material.FilledButton
import ui.material.FullRow
import ui.material.TextInput

@Composable
fun Form(
    title: String,
    onTitleInput: (String) -> Unit,
    titleLabel: String,
    titlePlaceholder: String,
    name: String,
    onNameInput: (String) -> Unit,
    nameLabel: String,
    namePlaceholder: String,
    phone: String,
    onPhoneInput: (String) -> Unit,
    phoneLabel: String,
    phonePlaceholder: String,
    postalCode: String,
    onPostalCodeInput: (String) -> Unit,
    postalCodeLabel: String,
    postalCodePlaceholder: String,
    locality: String,
    onLocalityInput: (String) -> Unit,
    localityLabel: String,
    localityPlaceholder: String,
    street: String,
    onStreetInput: (String) -> Unit,
    streetLabel: String,
    streetPlaceholder: String,
    createProjectButton: String,
    onProjectCreate: () -> Unit,
    loading: Boolean,
    errorMessage: String?,
    attrs: AttrBuilderContext<HTMLDivElement>? = null,
) {
  Column(
      gap = 3,
      attrs = attrs,
  ) {
    TextInput(
        value = title,
        onInput = onTitleInput,
        label = titleLabel,
        placeholder = titlePlaceholder,
        autoComplete = AutoComplete.off,
        attrs = {
          tailwind {
            w(Full)
            mb(3)
          }
        },
    )

    FullRow(
        gap = 3,
        attrs = { tailwind { mb(3) } },
    ) {
      TextInput(
          value = name,
          onInput = onNameInput,
          label = nameLabel,
          placeholder = namePlaceholder,
          autoComplete = AutoComplete.off,
          attrs = { tailwind { w(3.fifth) } },
      )

      TextInput(
          value = phone,
          onInput = onPhoneInput,
          label = phoneLabel,
          placeholder = phonePlaceholder,
          autoComplete = AutoComplete.off,
          attrs = { tailwind { w(2.fifth) } },
      )
    }

    TextInput(
        value = street,
        onInput = onStreetInput,
        label = streetLabel,
        placeholder = streetPlaceholder,
        autoComplete = AutoComplete.off,
        attrs = { tailwind { w(Full) } },
    )
    FullRow(
        gap = 3,
        attrs = { tailwind { mb(3) } },
    ) {
      TextInput(
          value = postalCode,
          onInput = onPostalCodeInput,
          label = postalCodeLabel,
          placeholder = postalCodePlaceholder,
          autoComplete = AutoComplete.off,
      ) {
        tailwind { w(1.fourth) }
      }
      TextInput(
          value = locality,
          onInput = onLocalityInput,
          label = localityLabel,
          placeholder = localityPlaceholder,
          autoComplete = AutoComplete.off,
          attrs = { tailwind { w(3.fourth) } },
      )
    }

    FilledButton(
        text = createProjectButton,
        onClick = onProjectCreate,
        loading = loading,
        attrs = { tailwind { w(Full) } },
    )

    errorMessage?.let {
      P(
          attrs = {
            tailwind {
              w(Full)
              mt(4)
              text(
                  color = ErrorDark,
                  align = TextScope.Align.Left,
              ) {
                subtitle2()
              }
            }
          },
      ) {
        Text(it)
      }
    }
  }
}
