package tailwind

import org.jetbrains.compose.web.attributes.AttrsScope
import org.w3c.dom.Element
import tailwind.BackgroundScope.*
import tailwind.color.Color
import tailwind.color.Opacity

class Background<TElement : Element>
internal constructor(
    builder: AttrsScope<TElement>,
    currentPrefix: String?,
) : Tailwind<TElement>(builder, currentPrefix), BackgroundScope {
  override fun attachment(type: Attachment) {
    val suffix =
        when (type) {
          Attachment.Fixed -> "fixed"
          Attachment.Local -> "local"
          Attachment.Scroll -> "scroll"
        }

    apply("bg-${suffix}")
  }

  override fun clip(type: Clip) {
    val suffix =
        when (type) {
          Clip.Border -> "border"
          Clip.Padding -> "padding"
          Clip.Content -> "content"
          Clip.Text -> "text"
        }

    apply("bg-clip-${suffix}")
  }

  override fun origin(type: Origin) {
    val suffix =
        when (type) {
          Origin.Border -> "border"
          Origin.Padding -> "padding"
          Origin.Content -> "content"
        }

    apply("bg-origin-${suffix}")
  }

  override fun position(type: Position) {
    val suffix =
        when (type) {
          Position.LeftTop -> "left-top"
          Position.Top -> "top"
          Position.RightTop -> "right-top"
          Position.Left -> "left"
          Position.Center -> "center"
          Position.Right -> "right"
          Position.LeftBottom -> "left-bottom"
          Position.Bottom -> "bottom"
          Position.RightBottom -> "right-bottom"
        }

    apply("bg-${suffix}")
  }

  override fun repeat(type: Repeat) {
    val suffix =
        when (type) {
          Repeat.None -> "no-repeat"
          Repeat.Both -> "repeat"
          Repeat.X -> "repeat-x"
          Repeat.Y -> "repeat-y"
          Repeat.Round -> "repeat-round"
          Repeat.Space -> "repeat-space"
        }

    apply("bg-${suffix}")
  }

  override fun size(type: Size) {
    val suffix =
        when (type) {
          Size.Auto -> "auto"
          Size.Cover -> "cover"
          Size.Contain -> "contain"
        }

    apply("bg-${suffix}")
  }

  override fun color(color: Color) = apply("bg-${color}")

  override fun opacity(opacity: Opacity) = apply("bg-${opacity}")
}
