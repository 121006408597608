package api.traak.dto

import api.traak.fromFirestore.Author
import api.traak.fromFirestore.Project
import kotlinx.datetime.Instant

data class TaskEditionDto(
    val author: Author?,
    val project: Project?,
    val description: String?,
    val startDate: Instant?,
    val endDate: Instant?,
)
