package ui.screens.integration.bexio

import androidx.compose.runtime.Composable
import org.jetbrains.compose.web.attributes.AttrsScope
import org.jetbrains.compose.web.dom.Div
import org.w3c.dom.HTMLDivElement
import tailwind.*
import ui.components.languageFooter.LanguageFooter
import ui.components.traakHeadline.TraakHeadline
import ui.layouts.ThreeQuartersLayout
import ui.material.Column
import ui.material.FullColumn
import ui.material.FullRow
import ui.material.Row

@Composable
fun BexioHolder(
    headline: String,
    attrs: (AttrsScope<HTMLDivElement>.() -> Unit)? = null,
    sideBar: @Composable () -> Unit,
    content: @Composable () -> Unit,
) {
  ThreeQuartersLayout {
    FullColumn(
        gap = 4,
        justify = Layout.Justify.Start,
        attrs = {
          attrs?.invoke(this)
          tailwind { h(TailwindScope.Height.Screen) }
        },
    ) {
      Row(
          justify = Layout.Justify.Center,
          alignItems = Layout.AlignItems.Center,
          attrs = {
            tailwind {
              w(1.half)
              pt(8)
            }
          }) {
            TraakHeadline(
                text = headline,
                attrs = { tailwind { mb(4) } },
            )
          }

      FullRow(
          alignItems = Layout.AlignItems.Start,
          gap = 12,
          attrs = {
            tailwind {
              pt(4)
              h(Full)
            }
          },
      ) {
        Column(
            justify = Layout.Justify.Start,
            alignItems = Layout.AlignItems.Center,
            gap = 0,
            attrs = {
              attrs?.invoke(this)
              tailwind { w(4.twelfth) }
            },
        ) {
          sideBar()
        }

        Spacer()

        Column(
            justify = Layout.Justify.Start,
            alignItems = Layout.AlignItems.Center,
            gap = 0,
            attrs = {
              attrs?.invoke(this)
              tailwind {
                w(7.twelfth)
                h(Full)

                pt(16)
              }
            },
        ) {
          content()
        }
      }
    }
  }

  FullRow(
      attrs = {
        tailwind {
          position(TailwindScope.Position.Absolute)
          bottom(0)
          right(0)
        }
      }) {
        LanguageFooter()
      }
}

@Composable
private fun Spacer() {
  Column(
      justify = Layout.Justify.Center,
      alignItems = Layout.AlignItems.Center,
      gap = 0,
      attrs = { tailwind { w(1.twelfth) } },
  ) {
    Div(attrs = { tailwind { w(Full) } }) {}
  }
}
