package ui.icons

import androidx.compose.runtime.Composable
import org.jetbrains.compose.web.dom.AttrBuilderContext
import org.w3c.dom.HTMLDivElement

@Composable
fun ChevronDown(
    attrs: AttrBuilderContext<HTMLDivElement>? = null,
) =
    Icon(attrs) {
      attr(
          "d",
          "M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z",
      )
    }
