package firebase.firestore

enum class ErrorCode(private val code: String) {
  /** The operation was cancelled (typically by the caller). */
  CANCELLED("cancelled"),

  /** Unknown error or an error from a different error domain */
  UNKNOWN("unknown"),

  /**
   * Client specified an invalid argument. Note that this differs from 'failed-precondition'.
   * 'invalid-argument' indicates arguments that are problematic regardless of the state of the
   * system (e.g. an invalid field name).
   */
  INVALID_ARGUMENT("invalid-argument"),

  /**
   * Deadline expired before operation could complete. For operations that change the state of the
   * system, this error may be returned even if the operation has completed successfully. For
   * example, a successful response from a server could have been delayed long enough for the
   * deadline to expire.
   */
  DEADLINE_EXCEEDED("deadline-exceeded"),

  /** Some requested document was not found. */
  NOT_FOUND("not-found"),

  /** Some document that we attempted to create already exists. */
  ALREADY_EXISTS("already-exists"),

  /** The caller does not have permission to execute the specified operation. */
  PERMISSION_DENIED("permission-denied"),

  /**
   * Some resource has been exhausted, perhaps a per-user quota, or perhaps the entire file system
   * is out of space.
   */
  RESOURCE_EXHAUSTED("resource-exhausted"),

  /**
   * Operation was rejected because the system is not in a state required for the operation's
   * execution.
   */
  FAILED_PRECONDITION("failed-precondition"),

  /**
   * The operation was aborted, typically due to a concurrency issue like transaction aborts, etc.
   */
  ABORTED("aborted"),

  /** Operation was attempted past the valid range. */
  OUT_OF_RANGE("out-of-range"),

  /** Operation is not implemented or not supported/enabled. */
  UNIMPLEMENTED("unimplemented"),

  /**
   * Internal errors. Means some invariants expected by underlying system has been broken. If you
   * see one of these errors, something is very broken.
   */
  INTERNAL("internal"),

  /**
   * The service is currently unavailable. This is most likely a transient condition and may be
   * corrected by retrying with a backoff.
   */
  UNAVAILABLE("unavailable"),

  /** Unrecoverable data loss or corruption. */
  DATA_LOSS("data-loss"),

  /** The request does not have valid authentication credentials for the operation. */
  UNAUTHENTICATED("unauthenticated");

  companion object {
    fun fromString(code: String): ErrorCode? = entries.find { errorCode -> errorCode.code == code }
  }
}
