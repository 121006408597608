package api.traak.authManager

import firebase.auth.User
import firebase.auth.getIdTokenResult
import kotlinx.coroutines.await

data class TraakAuthUser(
    /** The user as seen by firebase */
    val user: User,

    /** The user's jwt allowing him to authenticate to firebase */
    val jwt: String?,
)

suspend fun User.toTraakAuthUser(): TraakAuthUser {
  val tokenResult = getIdTokenResult(this, true).await()

  return TraakAuthUser(
      this,
      tokenResult.token,
  )
}
