package ui.components.table

import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import org.jetbrains.compose.web.css.gridColumn
import org.jetbrains.compose.web.dom.AttrBuilderContext
import org.jetbrains.compose.web.dom.Div
import org.w3c.dom.HTMLDivElement
import tailwind.BorderStyle
import tailwind.Full
import tailwind.Side
import tailwind.color.Opacity050
import tailwind.tailwind
import ui.color.Primary100
import ui.color.Primary200
import ui.material.CardTailwindScope
import ui.material.FullColumn

@Composable
internal fun <E> TableMain(
    columns: List<TableColumn<E>>,
    elements: List<E>,
    gridContainer: AttrBuilderContext<HTMLDivElement> = {},
) {
  FullColumn(attrs = { tailwind { CardTailwindScope(this) } }) {
    elements.forEach {
      var currentRowIsHovered by remember { mutableStateOf(false) }

      val rowState = remember(currentRowIsHovered) { RowState(isHovered = currentRowIsHovered) }

      Div(
          attrs = {
            onMouseEnter { currentRowIsHovered = true }
            onMouseLeave { currentRowIsHovered = false }

            style { this@Div.gridContainer() }

            tailwind {
              w(Full)
              p(4)

              hover { background(color = Primary100, opacity = Opacity050) }
              border(color = Primary200) { 1.width(Side.Bottom) }
              last { border(style = BorderStyle.None) }
            }
          }) {
            columns.forEachIndexed { index, column ->
              val columnIndex = (index + 1)
              Div(attrs = { style { gridColumn(columnIndex, columnIndex) } }) {
                column.display(it, rowState)
              }
            }
          }
    }
  }
}
