package ui.screens.newTeam

import androidx.compose.runtime.Composable
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberCoroutineScope
import api.traak.LocalTraakApi
import api.traak.TraakApi
import api.traak.user.User
import app.softwork.routingcompose.Router
import kotlinx.coroutines.CoroutineScope
import navigation.Route
import navigation.navigate

class NewTeamStateHolder(
    user: User,
    api: TraakApi,
    private val router: Router,
    coroutineScope: CoroutineScope,
) : NewTeamGenericStateHolder(user, api, coroutineScope) {
  override fun onTeamCreation() {
    router.navigate(to = Route.TeamSelection)
  }
}

@Composable
fun rememberNewTeamState(
    user: User,
    api: TraakApi = LocalTraakApi.current,
    router: Router = Router.current,
    coroutineScope: CoroutineScope = rememberCoroutineScope(),
): NewTeamState =
    remember(user, api) {
      NewTeamStateHolder(
          user = user,
          api = api,
          router = router,
          coroutineScope = coroutineScope,
      )
    }
