package ui.material

import androidx.compose.runtime.Composable
import org.jetbrains.compose.web.css.LineStyle
import org.jetbrains.compose.web.css.border
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.AttrBuilderContext
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Span
import org.jetbrains.compose.web.dom.Text
import org.w3c.dom.HTMLDivElement
import tailwind.FlexScope
import tailwind.TailwindScope
import tailwind.TransitionScope
import tailwind.color.White
import tailwind.tailwind
import ui.color.BlackDark
import ui.color.CssUtils
import ui.color.Primary100
import ui.color.Primary600

data class SegmentedButtonItem(
    val text: String,
    val onClick: () -> Unit,
    val active: Boolean,
)

@Composable
fun SegmentedButton(
    items: List<SegmentedButtonItem>,
    attrs: AttrBuilderContext<HTMLDivElement>? = null,
) {
  Div(
      attrs = {
        attrs?.invoke(this)

        tailwind {
          inlineFlex(
              direction = FlexScope.Direction.Row,
              wrap = FlexScope.Wrap.None,
          )
        }
      },
  ) {
    val activeElementIndex = items.indexOfFirst { it.active }

    for (i in items.indices) {
      SegmentedButtonItem(
          item = items[i],
          first = i == 0,
          last = i == items.size - 1,
          activeElementIsPrevious = activeElementIndex == i - 1,
      )
    }
  }
}

@Composable
private fun SegmentedButtonItem(
    item: SegmentedButtonItem,
    first: Boolean,
    last: Boolean,
    activeElementIsPrevious: Boolean,
) {
  val radius = 8.px
  val backgroundColor = if (item.active) Primary600 else Primary100
  val textColor = if (item.active) White else BlackDark
  val activeBorderColor = CssUtils.secondary600Opacity20
  val borderColor = CssUtils.blackMediumOpacity20
  val borderWidth = 1.px

  Span(
      attrs = {
        onClick { item.onClick() }

        style {
          border(1.px, style = LineStyle.Solid)

          // Radius
          val topLeftRadius = if (first) radius else 0.px
          val bottomLeftRadius = if (first) radius else 0.px
          val topRightRadius = if (last) radius else 0.px
          val bottomRightRadius = if (last) radius else 0.px

          property("border-top-left-radius", topLeftRadius)
          property("border-bottom-left-radius", bottomLeftRadius)
          property("border-top-right-radius", topRightRadius)
          property("border-bottom-right-radius", bottomRightRadius)

          // Width
          val leftIsActive = item.active || first || !activeElementIsPrevious
          val leftWith = if (leftIsActive) borderWidth else 0.px

          val rightIsActive = item.active || last
          val rightWidth = if (rightIsActive) borderWidth else 0.px

          property("border-top-width", borderWidth)
          property("border-bottom-width", borderWidth)
          property("border-left-width", leftWith)
          property("border-right-width", rightWidth)

          property("border-color", if (item.active) activeBorderColor else borderColor)
        }

        tailwind {
          px(6)
          py(2)

          cursor(TailwindScope.Cursor.Pointer)
          select(TailwindScope.SelectType.None)

          background(color = backgroundColor)
          text(color = textColor) { overline() }

          transition(TransitionScope.Property.All, duration = TransitionScope.Duration.Duration0100)
        }
      },
  ) {
    Text(item.text)
  }
}
