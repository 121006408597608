package ui.material

import androidx.compose.runtime.Composable
import org.jetbrains.compose.web.attributes.disabled
import org.jetbrains.compose.web.css.CSSLengthValue
import org.jetbrains.compose.web.css.LineStyle
import org.jetbrains.compose.web.css.StyleScope
import org.jetbrains.compose.web.css.backgroundColor
import org.jetbrains.compose.web.css.border
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.AttrBuilderContext
import org.jetbrains.compose.web.dom.Button as DOMButton
import org.jetbrains.compose.web.dom.Span
import org.jetbrains.compose.web.dom.Text
import org.w3c.dom.HTMLButtonElement
import tailwind.FlexScope
import tailwind.Full
import tailwind.Layout
import tailwind.TailwindScope
import tailwind.TextScope
import tailwind.TransitionScope
import tailwind.color.Gray100
import tailwind.color.Gray400
import tailwind.color.Opacity010
import tailwind.color.Opacity090
import tailwind.color.Opacity100
import tailwind.color.Transparent
import tailwind.color.White
import tailwind.tailwind
import ui.color.CssUtils
import ui.color.CssUtils.Companion.bexioColor
import ui.color.CssUtils.Companion.bexioColorOpacity20
import ui.color.ErrorDark
import ui.color.ErrorLight
import ui.color.Primary600
import ui.color.Secondary600
import ui.icons.Refresh
import ui.strings.LocalStrings

object Buttons {
  val common: TailwindScope.() -> Unit = { round(TailwindScope.Radius.Medium) }
  val normal: TailwindScope.() -> Unit = { p(4) }

  val enabledFilled: TailwindScope.() -> Unit = {
    shadow(TailwindScope.Shadow.Md)
    background(color = Primary600)
    text(color = White, opacity = Opacity090)
    transition(TransitionScope.Property.Shadow)
    hover { shadow(TailwindScope.Shadow.Lg) }
  }
}

typealias Component = (@Composable () -> Unit)

enum class Size {
  Small,
  Normal,
}

enum class Style {
  Text,
  Outlined,
  Filled,
}

enum class Type {
  Normal,
  Warning,
}

private fun Size.padding(hasIcon: Boolean): TailwindScope.() -> Unit {
  val l = if (hasIcon) 3 else 4
  val r = 4
  val y =
      when (this@padding) {
        Size.Small -> 2
        Size.Normal -> 4
      }

  return {
    pl(l)
    pr(r)
    py(y)
  }
}

private fun cursorStyle(disabled: Boolean): TailwindScope.() -> Unit {
  val cursor =
      if (disabled) {
        TailwindScope.Cursor.NotAllowed
      } else {
        TailwindScope.Cursor.Pointer
      }

  return { cursor(cursor) }
}

private fun Style.text(
    disabled: Boolean,
    type: Type,
): TailwindScope.() -> Unit {
  val textColor =
      if (disabled) {
        Gray400
      } else {
        when (this) {
          Style.Text -> if (type == Type.Warning) ErrorDark else Secondary600
          Style.Outlined -> if (type == Type.Warning) ErrorDark else Secondary600
          Style.Filled -> White
        }
      }

  val textOpacity =
      when (this) {
        Style.Filled -> Opacity090
        else -> Opacity100
      }

  return {
    text(
        color = textColor,
        opacity = textOpacity,
    ) {
      button(size = TextScope.Size.Base)
    }

    if (this@text == Style.Text) {
      hover { text(decoration = TextScope.Decoration.Underline) }
    }
  }
}

private fun Style.background(
    disabled: Boolean,
    type: Type,
): TailwindScope.() -> Unit {
  val disabledColor = Gray100
  val bgColor =
      when (this) {
        Style.Text -> Transparent
        Style.Outlined ->
            if (disabled) disabledColor else if (type == Type.Warning) ErrorLight else White
        Style.Filled ->
            if (disabled) disabledColor else if (type == Type.Warning) ErrorLight else Primary600
      }
  val bgOpacity = if (this == Style.Outlined && type == Type.Warning) Opacity010 else null

  return {
    background(
        color = bgColor,
        opacity = bgOpacity,
    )
  }
}

private fun Style.shadow(disabled: Boolean): TailwindScope.() -> Unit {
  if (disabled) {
    return {}
  }

  val shadow =
      when (this) {
        Style.Text -> null
        Style.Outlined -> null
        Style.Filled -> TailwindScope.Shadow.Md
      }
  val hoverShadow =
      when (this) {
        Style.Text -> null
        Style.Outlined -> null
        Style.Filled -> TailwindScope.Shadow.Lg
      }

  return {
    shadow?.let { shadow(it) }
    hover { hoverShadow?.let { shadow(it) } }
  }
}

private fun Style.border(disabled: Boolean, type: Type): StyleScope.() -> Unit {
  val disabledColor = "#00000033"

  val borderColor: String =
      when (this) {
        Style.Text -> "transparent"
        Style.Outlined ->
            if (disabled) disabledColor
            else if (type == Type.Warning) CssUtils.errorMediumOpacity90 else CssUtils.secondary200
        Style.Filled -> if (disabled) disabledColor else CssUtils.secondary600Opacity20
      }

  val borderWidth: CSSLengthValue =
      when (this) {
        Style.Text -> 0.px
        Style.Outlined -> 1.px
        Style.Filled -> 1.px
      }

  return {
    border(
        width = borderWidth,
        style = LineStyle.Solid,
    )
    property("border-color", borderColor)
  }
}

@Composable
fun Button(
    text: String?,
    onClick: () -> Unit,
    style: Style? = Style.Text,
    size: Size = Size.Normal,
    type: Type = Type.Normal,
    disabled: Boolean = false,
    loading: Boolean = false,
    icon: Component? = null,
    attrs: AttrBuilderContext<HTMLButtonElement>? = null,
) {
  DOMButton(
      attrs = {
        attrs?.invoke(this)

        onClick {
          if (disabled || loading) {
            return@onClick
          }

          onClick()
        }
        if (disabled) disabled()

        style?.let {
          style(
              style.border(
                  disabled = disabled,
                  type = type,
              ))

          tailwind {
            style.shadow(disabled || loading).invoke(this)
            style
                .text(
                    disabled = disabled || loading,
                    type = type,
                )
                .invoke(this)
            style
                .background(
                    disabled = disabled || loading,
                    type = type,
                )
                .invoke(this)
          }
        }

        tailwind(cursorStyle(disabled || loading))
        tailwind(size.padding(icon != null || loading))
        tailwind {
          round(TailwindScope.Radius.Medium)
          transition()
        }
      },
  ) {
    Span(
        attrs = {
          tailwind {
            flex(
                direction = FlexScope.Direction.Row,
                justify = Layout.Justify.Center,
                alignItems = Layout.AlignItems.Center,
                gap = 2,
            )
          }
        },
    ) {
      if (loading) {
        Spinner()
      } else {
        icon?.invoke()
      }

      text?.let { Text(it) }
    }
  }
}

@Composable
fun TextButton(
    text: String?,
    onClick: () -> Unit,
    type: Type = Type.Normal,
    disabled: Boolean = false,
    loading: Boolean = false,
    icon: Component? = null,
    attrs: AttrBuilderContext<HTMLButtonElement>? = null,
) {
  Button(
      text = text,
      onClick = onClick,
      style = Style.Text,
      size = Size.Normal,
      type = type,
      disabled = disabled,
      loading = loading,
      icon = icon,
      attrs = attrs,
  )
}

@Composable
fun OutlineButton(
    text: String?,
    onClick: () -> Unit,
    type: Type = Type.Normal,
    disabled: Boolean = false,
    loading: Boolean = false,
    icon: Component? = null,
    attrs: AttrBuilderContext<HTMLButtonElement>? = null,
) {
  Button(
      text = text,
      onClick = onClick,
      style = Style.Outlined,
      size = Size.Normal,
      type = type,
      disabled = disabled,
      loading = loading,
      icon = icon,
      attrs = attrs,
  )
}

@Composable
fun FilledButton(
    text: String?,
    onClick: () -> Unit,
    type: Type = Type.Normal,
    disabled: Boolean = false,
    loading: Boolean = false,
    icon: Component? = null,
    attrs: AttrBuilderContext<HTMLButtonElement>? = null,
) {
  Button(
      text = text,
      onClick = onClick,
      style = Style.Filled,
      size = Size.Normal,
      type = type,
      disabled = disabled,
      loading = loading,
      icon = icon,
      attrs = attrs,
  )
}

@Composable
fun SmallTextButton(
    text: String?,
    onClick: () -> Unit,
    type: Type = Type.Normal,
    disabled: Boolean = false,
    loading: Boolean = false,
    icon: Component? = null,
    attrs: AttrBuilderContext<HTMLButtonElement>? = null,
) {
  Button(
      text = text,
      onClick = onClick,
      style = Style.Text,
      size = Size.Small,
      type = type,
      disabled = disabled,
      loading = loading,
      icon = icon,
      attrs = attrs,
  )
}

@Composable
fun SmallOutlineButton(
    text: String?,
    onClick: () -> Unit,
    type: Type = Type.Normal,
    disabled: Boolean = false,
    loading: Boolean = false,
    icon: Component? = null,
    attrs: AttrBuilderContext<HTMLButtonElement>? = null,
) {
  Button(
      text = text,
      onClick = onClick,
      style = Style.Outlined,
      size = Size.Small,
      type = type,
      disabled = disabled,
      loading = loading,
      icon = icon,
      attrs = attrs,
  )
}

@Composable
fun SmallFilledButton(
    text: String?,
    onClick: () -> Unit,
    type: Type = Type.Normal,
    disabled: Boolean = false,
    loading: Boolean = false,
    icon: Component? = null,
    attrs: AttrBuilderContext<HTMLButtonElement>? = null,
) {
  Button(
      text = text,
      onClick = onClick,
      style = Style.Filled,
      size = Size.Small,
      type = type,
      disabled = disabled,
      loading = loading,
      icon = icon,
      attrs = attrs,
  )
}

@Composable
fun RefreshButton(
    onClick: () -> Unit,
    loading: Boolean,
) {
  Button(
      text = null,
      onClick = onClick,
      loading = loading,
      disabled = false,
      style = Style.Filled,
      size = Size.Normal,
      icon = {
        Refresh(
            attrs = {
              tailwind {
                if (loading) {
                  animate(TailwindScope.Animation.Spin)
                }
              }
            })
      },
      attrs = {
        tailwind {
          position(TailwindScope.Position.Relative)
          flex(direction = FlexScope.Direction.Row, alignItems = Layout.AlignItems.Center)
          text { button() }
        }
      },
  )
}

@Composable
fun LogInWithBexioButton(
    onLogInWithBexioClick: () -> Unit,
    errorMessage: String?,
    loading: Boolean,
    attrs: AttrBuilderContext<HTMLButtonElement>? = null,
) {
  val strings = LocalStrings.current
  val disabled = errorMessage != null

  Button(
      text = strings.loginLogInBexioButton,
      onClick = onLogInWithBexioClick,
      style = null,
      disabled = errorMessage != null,
      loading = loading,
      attrs = {
        attrs?.invoke(this)
        style {
          backgroundColor(CssUtils.fromHexa(bexioColorOpacity20))
          border(1.px, style = LineStyle.Solid, color = CssUtils.fromHex(bexioColor))
        }
        tailwind {
          w(Full)
          Style.Filled.text(disabled, Type.Normal).invoke(this)
          Style.Filled.shadow(disabled).invoke(this)
        }
      },
  )
}
