package api.traak.authManager.phone

import api.traak.AuthResult
import api.traak.toAuthResult
import firebase.auth.Auth
import firebase.auth.ConfirmationResult
import firebase.auth.InvisibleCaptcha
import firebase.auth.RecaptchaVerifier
import firebase.auth.linkWithPhoneNumber
import firebase.auth.signInWithPhoneNumber
import kotlinx.coroutines.await

class TraakPhoneAuthenticator(
    private val auth: Auth,
) : PhoneAuthenticator {
  private var confirmationResult: ConfirmationResult? = null

  override suspend fun initiatePhoneLoginSequence(
      phoneNumber: String,
      idOfButton: String,
  ): AuthResult {
    val captcha = RecaptchaVerifier(auth, idOfButton, InvisibleCaptcha)

    return try {
      confirmationResult = signInWithPhoneNumber(auth, phoneNumber, captcha).await()
      AuthResult.Success
    } catch (t: Throwable) {
      console.error(t)
      captcha.clear()
      t.toAuthResult()
    }
  }

  override suspend fun linkWithPhoneNumber(phoneNumber: String, idOfButton: String): AuthResult {
    val user = auth.currentUser ?: return AuthResult.FailureInternal
    val captcha = RecaptchaVerifier(auth, idOfButton, InvisibleCaptcha)

    return try {
      confirmationResult = linkWithPhoneNumber(user, phoneNumber, captcha).await()
      AuthResult.Success
    } catch (t: Throwable) {
      console.error(t)
      captcha.clear()
      t.toAuthResult()
    }
  }

  override suspend fun validateSmsCode(smsCode: String): AuthResult {
    if (confirmationResult == null) {
      return AuthResult.FailureInternal
    }

    return try {
      confirmationResult?.confirm(smsCode)?.await()
      confirmationResult = null
      AuthResult.Success
    } catch (t: Throwable) {
      console.error(t)
      t.toAuthResult()
    }
  }
}
