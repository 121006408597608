package ui.components.calendar

import androidx.compose.runtime.Composable
import kotlinx.datetime.LocalDate
import kotlinx.datetime.Month
import org.jetbrains.compose.web.dom.AttrBuilderContext
import org.jetbrains.compose.web.dom.Text
import org.w3c.dom.HTMLDivElement
import tailwind.*
import ui.color.BlackDark
import ui.color.Primary100
import ui.color.Primary400
import ui.icons.ChevronLeft
import ui.icons.ChevronRight
import ui.material.FullRow
import ui.material.Row
import ui.strings.LocalStrings
import ui.strings.LocalizedStrings

fun Month.toLocalizedString(strings: LocalizedStrings): String =
    when (this) {
      Month.JANUARY -> strings.january
      Month.FEBRUARY -> strings.february
      Month.MARCH -> strings.march
      Month.APRIL -> strings.april
      Month.MAY -> strings.may
      Month.JUNE -> strings.june
      Month.JULY -> strings.july
      Month.AUGUST -> strings.august
      Month.SEPTEMBER -> strings.september
      Month.OCTOBER -> strings.october
      Month.NOVEMBER -> strings.november
      Month.DECEMBER -> strings.december
    }

@Composable
fun MonthHeader(
    currentMonth: LocalDate,
    onPrevClick: () -> Unit,
    onNextClick: () -> Unit,
    attrs: AttrBuilderContext<HTMLDivElement>? = null,
) {
  val strings = LocalStrings.current

  val chevronStyle: TailwindScope.() -> Unit = {
    w(9)
    h(9)

    cursor(TailwindScope.Cursor.Pointer)
    round(TailwindScope.Radius.Full)

    hover { background(Primary400) }
  }

  FullRow(
      justify = Layout.Justify.Center,
      alignItems = Layout.AlignItems.Center,
      gap = 4,
      attrs = {
        attrs?.invoke(this)
        tailwind {
          background(Primary100)
          round(TailwindScope.Radius.Large)

          px(2)

          text(color = BlackDark) { subtitle1() }
        }
      },
  ) {
    Row(
        justify = Layout.Justify.Center,
        alignItems = Layout.AlignItems.Center,
        attrs = {
          onClick { onPrevClick() }
          tailwind(chevronStyle)
        }) {
          ChevronLeft()
        }

    Row(
        justify = Layout.Justify.Center,
        alignItems = Layout.AlignItems.Center,
        attrs = {
          tailwind {
            flexItem(FlexItemScope.Grow.Grow)
            text(transformation = TextScope.Transform.Capitalize)
          }
        }) {
          Text("${currentMonth.month.toLocalizedString(strings)} ${currentMonth.year}")
        }

    Row(
        justify = Layout.Justify.Center,
        alignItems = Layout.AlignItems.Center,
        attrs = {
          onClick { onNextClick() }
          tailwind(chevronStyle)
        }) {
          ChevronRight()
        }
  }
}
