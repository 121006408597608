package api.traak.dto

import kotlinx.serialization.Serializable

@Serializable
data class BillingAddress(
    val name: String,
    val email: String,
    val phone: String,
    val address: String,
    val locality: String,
)
