package ui.screens.projectOverview

import api.traak.Project
import ui.data.Loadable

enum class RefreshState {
  REFRESHING,
  DONE,
}

data class FilteredProjects(
    val filtered: List<Project>,
    val noProjects: Boolean,
)

interface ProjectOverviewState {
  val projects: Loadable<FilteredProjects>

  var search: String
  val refreshState: RefreshState
  val activeStatus: Project.Status

  fun onNewProjectClick()

  fun onProjectClick(project: Project)

  fun onRefreshClick()

  fun onStatusClick(status: Project.Status)
}
