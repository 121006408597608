package ui.screens.integration.bexio.synchronising

import org.w3c.dom.url.URL
import ui.screens.integration.bexio.BexioIntegrationStage

sealed class SynchronisingMode {
  object Initial : SynchronisingMode()

  object StoringCredentials : SynchronisingMode()

  object Synchronising : SynchronisingMode()

  object Done : SynchronisingMode()

  object Error : SynchronisingMode()

  fun toBexioIntegrationStage(): BexioIntegrationStage =
      when (this) {
        Initial -> BexioIntegrationStage.Authorization
        StoringCredentials -> BexioIntegrationStage.Authorization
        Synchronising -> BexioIntegrationStage.Synchronization
        Done -> BexioIntegrationStage.Synchronization
        Error -> BexioIntegrationStage.Synchronization
      }
}

interface BexioSynchronisingState {
  /** Describes the mode in which the composable currently is */
  val mode: SynchronisingMode

  /** The [URL] used by bexio to authorize access */
  val authorizeUrl: URL

  /** Are we in a loading state */
  val loading: Boolean
}
