package ui.screens.register

import androidx.compose.runtime.Composable
import app.softwork.routingcompose.Router
import navigation.back
import org.jetbrains.compose.web.dom.AttrBuilderContext
import org.jetbrains.compose.web.dom.P
import org.jetbrains.compose.web.dom.Text
import org.w3c.dom.HTMLDivElement
import tailwind.tailwind
import ui.components.AuthForm
import ui.components.traakHeadline.TraakHeadline
import ui.helpers.onEnterDown
import ui.layouts.AuthLayout
import ui.strings.LocalStrings

@Composable
fun Register(
    attrs: AttrBuilderContext<HTMLDivElement>? = null,
) {
  val strings = LocalStrings.current
  val registerState = rememberRegisterState()

  onEnterDown { registerState.register() }

  Register(
      traakHeadline = strings.registerTraakHeadline,
      email = registerState.email,
      setEmail = { registerState.email = it },
      emailPlaceholder = strings.registerEmailPlaceholder,
      firstName = registerState.firstName,
      setFirstName = { registerState.firstName = it },
      lastName = registerState.lastName,
      setLastName = { registerState.lastName = it },
      firstNamePlaceholder = strings.registerFirstNamePlaceholder,
      lastNamePlaceholder = strings.registerLastNamePlaceholder,
      password = registerState.password,
      setPassword = { registerState.password = it },
      passwordPlaceholder = strings.registerPasswordPlaceholder,
      registerButtonText = strings.registerButton,
      goBackButton = strings.registerGoBackButton,
      loading = false,
      success = registerState.result?.let { it == RegisterResult.Success } ?: false,
      successMessage = strings.registerSuccess,
      errorMessage = registerState.result?.toErrorMessage(),
      onRegisterClick = { registerState.register() },
      onGoBackClick = { Router.back() },
      attrs = attrs,
  )
}

@Composable
fun Register(
    traakHeadline: String,
    email: String,
    setEmail: (String) -> Unit,
    emailPlaceholder: String,
    firstName: String,
    setFirstName: (String) -> Unit,
    lastName: String,
    setLastName: (String) -> Unit,
    firstNamePlaceholder: String,
    lastNamePlaceholder: String,
    password: String,
    setPassword: (String) -> Unit,
    passwordPlaceholder: String,
    registerButtonText: String,
    goBackButton: String,
    loading: Boolean,
    success: Boolean,
    successMessage: String,
    errorMessage: String?,
    onRegisterClick: () -> Unit,
    onGoBackClick: () -> Unit,
    attrs: AttrBuilderContext<HTMLDivElement>? = null,
) {
  AuthLayout(attrs = attrs) {
    if (success) {
      Success(
          traakHeadline,
          successMessage,
      )
    } else {
      RegisterForm(
          traakHeadline = traakHeadline,
          email = email,
          setEmail = setEmail,
          emailPlaceholder = emailPlaceholder,
          firstName = firstName,
          setFirstName = setFirstName,
          lastName = lastName,
          setLastName = setLastName,
          firstNamePlaceholder = firstNamePlaceholder,
          lastNamePlaceholder = lastNamePlaceholder,
          password = password,
          setPassword = setPassword,
          passwordPlaceholder = passwordPlaceholder,
          registerButtonText = registerButtonText,
          goBackButton = goBackButton,
          loading = loading,
          errorMessage = errorMessage,
          onRegisterClick = onRegisterClick,
          onGoBackClick = onGoBackClick,
      )
    }
  }
}

@Composable
fun Success(
    traakHeadline: String,
    successMessage: String,
    attrs: AttrBuilderContext<HTMLDivElement>? = null,
) {
  AuthForm(attrs = attrs) {
    TraakHeadline(
        text = traakHeadline,
        attrs = { tailwind { mb(4) } },
    )

    P { Text(successMessage) }
  }
}
