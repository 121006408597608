package tailwind

enum class Side {
  All,
  Top,
  Right,
  Bottom,
  Left
}

val Side.prefix: String?
  get() =
      when (this) {
        Side.All -> null
        Side.Top -> "-t"
        Side.Right -> "-r"
        Side.Bottom -> "-b"
        Side.Left -> "-l"
      }
