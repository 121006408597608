package ui.screens.teamSelection

import androidx.compose.runtime.Composable
import api.traak.Team
import tailwind.Full
import tailwind.tailwind
import ui.material.Button
import ui.material.FullColumn
import ui.material.Style
import ui.material.TextButton
import ui.material.Type

@Composable
fun TeamPicker(
    logOutButton: String,
    teamMemberText: (Int) -> String,
    teams: List<Team>,
    onTeamClick: (Team.Id) -> Unit,
    onLogOutClick: () -> Unit,
    newTeamButton: String,
    existingTeamButton: String,
    onNewTeamClick: () -> Unit,
    onExistingTeamClick: () -> Unit,
    noTeamHeadline: String,
    noTeamInformationText: String,
) {
  if (teams.isEmpty()) {
    NoTeamsMessage(
        headlineText = noTeamHeadline,
        informationText = noTeamInformationText,
        attrs = { tailwind { w(Full) } },
    )
  } else {
    teams.forEach {
      TeamItem(
          team = it,
          membersText = teamMemberText,
          onClick = { onTeamClick(it.id) },
          attrs = { tailwind { w(Full) } },
      )
    }
  }

  FullColumn(
      gap = 4,
      attrs = { tailwind { mt(8) } },
  ) {
    Button(
        text = newTeamButton,
        onClick = onNewTeamClick,
        style = if (teams.isEmpty()) Style.Filled else Style.Outlined,
        attrs = { tailwind { w(Full) } },
    )

    TextButton(
        text = existingTeamButton,
        onClick = onExistingTeamClick,
        attrs = { tailwind { w(Full) } },
    )

    TextButton(
        text = logOutButton,
        onClick = onLogOutClick,
        type = Type.Warning,
        attrs = { tailwind { w(Full) } },
    )
  }
}
