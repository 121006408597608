package ui.screens.requestTeamAccess

import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.runtime.setValue
import api.traak.LocalTraakApi
import api.traak.StorageResult
import api.traak.Team
import api.traak.TraakApi
import api.traak.user.User
import app.softwork.routingcompose.Router
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.launch
import kotlinx.coroutines.sync.Mutex
import kotlinx.coroutines.sync.withLock
import navigation.Route
import navigation.navigate

class RequestTeamAccessStateHolder(
    private val user: User,
    private val api: TraakApi,
    private val router: Router,
    private val coroutineScope: CoroutineScope,
) : RequestTeamAccessState {
  private val mutex = Mutex()

  private var _teamId by mutableStateOf("")
  private var _loading by mutableStateOf(false)
  private var _result by mutableStateOf<StorageResult?>(null)

  override var teamId
    get() = _teamId
    set(value) {
      if (_result != null) {
        _result = null
      }
      _teamId = value
    }

  override val loading: Boolean
    get() = _loading

  override val result: StorageResult?
    get() = _result

  override fun requestAccess(): Unit {
    if (_loading) return

    coroutineScope.launch {
      val firstName = user.displayName?.split(" ")?.firstOrNull() ?: ""
      val lastName = user.displayName?.split(" ")?.drop(1)?.joinToString(" ") ?: ""
      mutex.withLock { _loading = true }
      _result =
          api.requestAccessToTeam(
              teamId = Team.Id(teamId),
              userId = user.id,
              firstName = firstName,
              lastName = lastName,
          )
      mutex.withLock { _loading = false }
    }
  }

  override fun onCancelClick() {
    router.navigate(to = Route.TeamSelection)
  }
}

@Composable
fun rememberRequestTeamAccessState(
    user: User,
    api: TraakApi = LocalTraakApi.current,
    router: Router = Router.current,
    coroutineScope: CoroutineScope = rememberCoroutineScope(),
): RequestTeamAccessState =
    remember(user, api) {
      RequestTeamAccessStateHolder(
          user = user,
          api = api,
          router = router,
          coroutineScope = coroutineScope,
      )
    }
