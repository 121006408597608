package ui.components.navmenu

import AccountCircle
import AccountCircleOutline
import AccountMultiple
import AccountMultipleOutline
import Clock
import ClockOutline
import androidx.compose.runtime.Composable
import androidx.compose.runtime.remember
import navigation.Route
import ui.icons.Folder
import ui.icons.FolderOutline
import ui.strings.LocalizedStrings

sealed interface Tab {
  val route: Route
  val activeIcon: @Composable () -> Unit
  val inactiveIcon: @Composable () -> Unit
}

sealed interface DescriptiveTab : Tab {
  @Composable fun description(strings: LocalizedStrings): String
}

data object TasksTab : DescriptiveTab {
  @Composable
  override fun description(strings: LocalizedStrings): String =
      remember(strings) { strings.navMenuTasks }

  override val route: Route = Route.Tasks
  override val activeIcon = @Composable { Clock() }
  override val inactiveIcon = @Composable { ClockOutline() }
}

data object ProjectsTab : DescriptiveTab {
  @Composable
  override fun description(strings: LocalizedStrings): String =
      remember(strings) { strings.navMenuProjects }

  override val route = Route.ProjectOverview
  override val activeIcon = @Composable { Folder() }
  override val inactiveIcon = @Composable { FolderOutline() }
}

data object TeamTab : DescriptiveTab {
  @Composable
  override fun description(strings: LocalizedStrings): String =
      remember(strings) { strings.navMenuTeam }

  override val route = Route.TeamOverview
  override val activeIcon = @Composable { AccountMultiple() }
  override val inactiveIcon = @Composable { AccountMultipleOutline() }
}

data object AccountTab : Tab {
  override val route = Route.Account
  override val activeIcon = @Composable { AccountCircle() }
  override val inactiveIcon = @Composable { AccountCircleOutline() }
}

val mainTabs =
    listOf(
        TasksTab,
        ProjectsTab,
        TeamTab,
    )
