package ui.screens.teamOverview

import androidx.compose.runtime.Composable
import androidx.compose.runtime.State
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import api.settings.LocalSettings
import api.settings.SettingsApi
import api.traak.LocalTraakApi
import api.traak.Member
import api.traak.Team
import api.traak.TraakApi
import app.softwork.routingcompose.Router
import navigation.Route
import navigation.navigate
import ui.data.Loadable
import ui.data.asLoadable
import ui.data.collectAsState
import ui.data.filter
import ui.data.sortedWith

class TeamOverviewStateHolder(
    private val _loadableMembers: State<Loadable<List<Member>>>,
    private val settingsApi: SettingsApi,
    private val router: Router,
) : TeamOverviewState {
  private fun Member.contains(value: String): Boolean =
      this.firstName.contains(value, ignoreCase = true) ||
          this.lastName.contains(value, ignoreCase = true)

  private fun firstOrderElementOfMember(member: Member): String {
    return if (settingsApi.orderByFirstName.value) {
      member.firstName
    } else {
      member.lastName
    }
  }

  private fun secondOrderElementOfMember(member: Member): String {
    return if (settingsApi.orderByFirstName.value) {
      member.lastName
    } else {
      member.firstName
    }
  }

  private val memberComparator: Comparator<Member> =
      compareBy(
          { firstOrderElementOfMember(it) },
          { secondOrderElementOfMember(it) },
      )

  override val loadableMembers: Loadable<List<Member>>
    get() = _loadableMembers.value.sortedWith(memberComparator)

  override val filteredMembers: List<Member>
    get() = loadableMembers.filter { it.contains(search) }

  override var search by mutableStateOf("")

  override fun onMemberClick(member: Member) {
    router.navigate(Route.MemberDetail(member.id.raw))
  }
}

@Composable
fun rememberTeamOverviewState(
    team: Team,
    api: TraakApi = LocalTraakApi.current,
    settingsApi: SettingsApi = LocalSettings.current,
    router: Router = Router.current,
): TeamOverviewState {
  val loadableMembers = remember(api) { api.membersOf(team).asLoadable() }.collectAsState()

  return remember {
    TeamOverviewStateHolder(
        _loadableMembers = loadableMembers,
        settingsApi = settingsApi,
        router = router,
    )
  }
}
