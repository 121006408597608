package ui.screens.requestTeamAccess

import androidx.compose.runtime.Composable
import api.traak.StorageResult
import api.traak.user.User
import org.jetbrains.compose.web.dom.AttrBuilderContext
import org.jetbrains.compose.web.dom.P
import org.jetbrains.compose.web.dom.Text
import org.w3c.dom.HTMLDivElement
import tailwind.Full
import tailwind.tailwind
import ui.color.BlackMedium
import ui.components.AuthErrorMessage
import ui.components.AuthForm
import ui.components.traakHeadline.TraakHeadline
import ui.helpers.onEnterDown
import ui.layouts.AuthLayout
import ui.material.FilledButton
import ui.material.OutlineButton
import ui.material.TextInput
import ui.strings.LocalStrings

@Composable
fun RequestTeamAccess(
    user: User,
    attrs: AttrBuilderContext<HTMLDivElement>? = null,
) {
  val strings = LocalStrings.current
  val state = rememberRequestTeamAccessState(user)

  onEnterDown { state.requestAccess() }

  fun StorageResult.toErrorMessage(): String? =
      when (this) {
        StorageResult.Success -> null
        else -> strings.requestTeamAccessRequestFailure
      }

  RequestTeamAccess(
      traakHeadline = strings.requestTeamAccessHeadline,
      traakSuccessHeadline = strings.requestTeamAccessHeadlineSuccess,
      success = state.result == StorageResult.Success,
      successMessage = strings.requestTeamAccessSuccessMessage,
      teamId = state.teamId,
      setTeamId = { state.teamId = it },
      errorMessage = state.result?.toErrorMessage(),
      teamIdPlaceholder = strings.requestTeamAccessIdPlaceholder,
      requestAccessButton = strings.requestTeamAccessRequestAccessButton,
      onRequestAccessClick = { state.requestAccess() },
      cancelButton = strings.newTeamCancelButton,
      onCancelClick = { state.onCancelClick() },
      loading = state.loading,
      attrs = attrs,
  )
}

@Composable
fun RequestTeamAccess(
    traakHeadline: String,
    traakSuccessHeadline: String,
    success: Boolean,
    successMessage: String,
    teamId: String,
    setTeamId: (String) -> Unit,
    teamIdPlaceholder: String,
    errorMessage: String?,
    requestAccessButton: String,
    onRequestAccessClick: () -> Unit,
    cancelButton: String,
    onCancelClick: () -> Unit,
    loading: Boolean,
    attrs: AttrBuilderContext<HTMLDivElement>? = null,
) {
  AuthLayout {
    AuthForm(attrs) {
      TraakHeadline(
          text = if (success) traakSuccessHeadline else traakHeadline,
          attrs = { tailwind { mb(4) } },
      )

      if (success) {
        P(attrs = { tailwind { text(color = BlackMedium) { subtitle1() } } }) {
          Text(successMessage)
        }
      } else {
        TextInput(
            value = teamId,
            onInput = setTeamId,
            label = teamIdPlaceholder,
        ) {
          tailwind { w(Full) }
        }

        FilledButton(
            text = requestAccessButton,
            onClick = onRequestAccessClick,
            loading = loading,
            attrs = {
              tailwind {
                text { button() }
                w(Full)
                mt(8)
              }
            },
        )

        OutlineButton(
            text = cancelButton,
            onClick = onCancelClick,
            attrs = { tailwind { w(Full) } },
        )

        AuthErrorMessage(errorMessage)
      }
    }
  }
}
