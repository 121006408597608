package ui.components.chips.checkbox.searchable

import androidx.compose.runtime.Composable
import kotlinx.coroutines.flow.StateFlow
import org.jetbrains.compose.web.dom.AttrBuilderContext
import org.w3c.dom.HTMLDivElement

interface Item {
  val id: String
  val text: String
  val matchesSearch: (search: String) -> Boolean
}

data class DefaultItem(
    override val id: String,
    override val text: String,
) : Item {
  override val matchesSearch: (search: String) -> Boolean = { search ->
    this.text.contains(search, true)
  }

  override fun toString(): String = text
}

interface SearchableCheckboxState {
  val selectedItems: StateFlow<List<Item>>

  @Composable fun DisplayCheckbox(attrs: AttrBuilderContext<HTMLDivElement>?)
}
