package ui.material

import androidx.compose.runtime.Composable
import org.jetbrains.compose.web.dom.AttrBuilderContext
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.H2
import org.jetbrains.compose.web.dom.Text
import org.w3c.dom.HTMLDivElement
import tailwind.*
import tailwind.color.Gray200
import tailwind.color.Opacity020
import ui.color.BlackDark
import ui.color.BlackMedium
import ui.color.Secondary600
import ui.icons.Close

val CardTailwindScope: TailwindScope.() -> Unit = {
  round(TailwindScope.Radius.Large)
  shadow(TailwindScope.Shadow.Normal)
  border(
      width = 1,
      color = Secondary600,
      opacity = Opacity020,
  )
}

@Composable
fun Card(
    attrs: AttrBuilderContext<HTMLDivElement>? = null,
    content: @Composable () -> Unit,
) {
  Div(
      attrs = {
        attrs?.invoke(this)
        tailwind {
          CardTailwindScope(this)

          flex(
              direction = FlexScope.Direction.Column,
              justify = Layout.Justify.Start,
              alignItems = Layout.AlignItems.Start,
          )
        }
      },
  ) {
    content()
  }
}

@Composable
fun ClosableCard(
    onCloseClick: () -> Unit,
    attrs: AttrBuilderContext<HTMLDivElement>? = null,
    title: String? = null,
    content: @Composable () -> Unit,
) {
  Div(
      attrs = {
        attrs?.invoke(this)
        tailwind {
          CardTailwindScope(this)

          flex(
              direction = FlexScope.Direction.Column,
              justify = Layout.Justify.Start,
              alignItems = Layout.AlignItems.Start,
          )
        }
      },
  ) {
    FullRow(
        justify = Layout.Justify.End,
        alignItems = Layout.AlignItems.Center,
        attrs = { tailwind { p(4) } },
    ) {
      title?.let {
        H2(
            attrs = {
              tailwind {
                flexItem(FlexItemScope.Grow.Grow)
                text(color = BlackDark) { headline6() }
              }
            },
        ) {
          Text(it)
        }
      }

      Row(
          justify = Layout.Justify.Center,
          alignItems = Layout.AlignItems.Center,
          attrs = {
            onClick { onCloseClick() }

            tailwind {
              position(TailwindScope.Position.Relative)

              flexItem(shrink = FlexItemScope.Shrink.Fixed, grow = FlexItemScope.Grow.Fixed)

              w(7)
              h(7)

              round(TailwindScope.Radius.Full)

              cursor(TailwindScope.Cursor.Pointer)

              background(color = Gray200)
              text(color = BlackMedium)
            }
          },
      ) {
        Close()
      }
    }

    Div(
        attrs = {
          tailwind {
            w(Full)
            h(Full)

            overflow(TailwindScope.OverflowType.Scroll)
            overscroll(TailwindScope.Overscroll.None)
          }
        },
    ) {
      content()
    }
  }
}
