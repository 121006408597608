package tailwind

import tailwind.color.Color
import tailwind.color.Opacity
import tailwind.color.OpacityPainter

typealias TailwindBuilderContext = (TailwindScope.() -> Unit)

@TailwindDsl
interface TailwindScope : OpacityPainter {
  // Variants
  fun hover(scope: TailwindScope.() -> Unit)

  fun focus(scope: TailwindScope.() -> Unit)

  fun active(scope: TailwindScope.() -> Unit)

  fun groupHover(scope: TailwindScope.() -> Unit)

  fun groupFocus(scope: TailwindScope.() -> Unit)

  fun focusWithin(scope: TailwindScope.() -> Unit)

  fun disabled(scope: TailwindScope.() -> Unit)

  fun visited(scope: TailwindScope.() -> Unit)

  fun checked(scope: TailwindScope.() -> Unit)

  fun first(scope: TailwindScope.() -> Unit)

  fun last(scope: TailwindScope.() -> Unit)

  fun odd(scope: TailwindScope.() -> Unit)

  fun even(scope: TailwindScope.() -> Unit)

  // Responsive Variants
  fun small(scope: TailwindScope.() -> Unit)

  fun medium(scope: TailwindScope.() -> Unit)

  fun large(scope: TailwindScope.() -> Unit)

  fun xlarge(scope: TailwindScope.() -> Unit)

  fun xxlarge(scope: TailwindScope.() -> Unit)

  fun flex(
      direction: FlexScope.Direction? = null,
      justify: Layout.Justify? = null,
      alignItems: Layout.AlignItems? = null,
      alignContent: Layout.AlignContent? = null,
      wrap: FlexScope.Wrap? = null,
      gap: Int? = null,
  )

  fun inlineFlex(
      direction: FlexScope.Direction? = null,
      wrap: FlexScope.Wrap? = null,
      justify: Layout.Justify? = null,
      alignContent: Layout.AlignContent? = null,
      alignItems: Layout.AlignItems? = null,
      gap: Int? = null,
  )

  fun flexItem(
      grow: FlexItemScope.Grow? = null,
      shrink: FlexItemScope.Shrink? = null,
      align: FlexItemScope.Align? = null,
      order: FlexItemScope.Order? = null,
      explicitOrder: Int? = null,
  )

  fun grid(
      columns: Int?,
      rows: Int?,
      flow: GridScope.Flow? = null,
      justify: Layout.Justify? = null,
      alignContent: Layout.AlignContent? = null,
      alignItems: Layout.AlignItems? = null,
      gap: Int? = null,
  )

  fun grid(
      columns: GridScope.ImplicitGrid,
      rows: GridScope.ImplicitGrid,
      flow: GridScope.Flow? = null,
      justify: Layout.Justify? = null,
      alignContent: Layout.AlignContent? = null,
      alignItems: Layout.AlignItems? = null,
      gap: Int? = null,
  )

  fun gridItem(
      scope: (GridItemScope.() -> Unit),
  )

  fun background(
      color: Color? = null,
      opacity: Opacity? = null,
      attachment: BackgroundScope.Attachment? = null,
      clip: BackgroundScope.Clip? = null,
      origin: BackgroundScope.Origin? = null,
      position: BackgroundScope.Position? = null,
      repeat: BackgroundScope.Repeat? = null,
      size: BackgroundScope.Size? = null,
  )

  fun border(
      color: Color? = null,
      opacity: Opacity? = null,
      style: BorderStyle? = null,
      width: Int? = null,
      scope: (BorderScope.() -> Unit)? = null,
  )

  fun divide(direction: DivideDirection, scope: DivideScope.() -> Unit)

  fun ring(
      render: RingScope.Render? = null,
      size: Int? = null,
      offsetBy: Int? = null,
      offsetColor: Color? = null,
  )

  fun text(
      color: Color? = null,
      opacity: Opacity? = null,
      family: TextScope.Family? = null,
      size: TextScope.Size? = null,
      weight: TextScope.Weight? = null,
      tracking: TextScope.Tracking? = null,
      leading: TextScope.Leading? = null,
      align: TextScope.Align? = null,
      decoration: TextScope.Decoration? = null,
      overflow: TextScope.Overflow? = null,
      whitespace: TextScope.Whitespace? = null,
      wordBreak: TextScope.WordBreak? = null,
      transformation: TextScope.Transform? = null,
      scope: (TextScope.() -> Unit)? = null,
  )

  fun transition(
      property: TransitionScope.Property = TransitionScope.Property.All,
      duration: TransitionScope.Duration? = null,
      timingFunction: TransitionScope.TimingFunction? = null,
      delay: TransitionScope.Delay? = null,
  )

  fun translateX(amount: Int)

  fun translateY(amount: Int)

  enum class BoxSizing {
    Border,
    Content,
  }

  fun box(type: BoxSizing)

  // Display
  enum class Display {
    Block,
    InlineBlock,
    Inline,
    Table,
    TableCaption,
    TableCell,
    TableColumn,
    TableColumnGroup,
    TableFooterGroup,
    TableHeaderGroup,
    TableRowGroup,
    TableRow,
    Hidden,
    Contents,
  }

  fun display(type: Display)

  enum class Float {
    None,
    Right,
    Left,
  }

  fun float(type: Float)

  enum class Clear {
    None,
    Both,
    Right,
    Left,
  }

  fun clear(type: Clear)

  val group: Unit

  enum class OverflowDirection {
    X,
    Y,
    Both
  }

  enum class OverflowType {
    Auto,
    Hidden,
    Visible,
    Scroll
  }

  fun overflow(
      type: OverflowType = OverflowType.Auto,
      direction: OverflowDirection = OverflowDirection.Both
  )

  enum class Overscroll {
    Auto,
    Contain,
    None
  }

  fun overscroll(type: Overscroll)

  enum class Position {
    Static,
    Fixed,
    Absolute,
    Relative,
    Sticky
  }

  fun position(type: Position)

  fun inset(distance: Distance)

  fun inset(distance: Int)

  fun insetX(distance: Distance)

  fun insetX(distance: Int)

  fun insetY(distance: Distance)

  fun insetY(distance: Int)

  fun top(distance: Distance)

  fun top(distance: Int)

  fun right(distance: Distance)

  fun right(distance: Int)

  fun bottom(distance: Distance)

  fun bottom(distance: Int)

  fun left(distance: Distance)

  fun left(distance: Int)

  enum class Visibility {
    Visible,
    Invisible,
  }

  fun visibility(type: Visibility)

  fun z(index: Int?)

  // Spacing
  fun p(amount: Int)

  fun px(amount: Int)

  fun py(amount: Int)

  fun pt(amount: Int)

  fun pr(amount: Int)

  fun pb(amount: Int)

  fun pl(amount: Int)

  interface Margin

  fun m(amount: Int)

  fun m(type: Margin)

  fun mx(amount: Int)

  fun mx(type: Margin)

  fun my(amount: Int)

  fun my(type: Margin)

  fun mt(amount: Int)

  fun mt(type: Margin)

  fun mr(amount: Int)

  fun mr(type: Margin)

  fun mb(amount: Int)

  fun mb(type: Margin)

  fun ml(amount: Int)

  fun ml(type: Margin)

  fun spaceX(amount: Int)

  fun spaceY(amount: Int)

  // Sizing
  enum class Width {
    Screen,
    Min,
    Max,
    Auto,
    Pixel,
  }

  fun w(distance: Int)

  fun w(distance: Distance)

  fun w(distance: Width)

  enum class MinWidth {
    Zero,
    Full,
    Min,
    Max,
  }

  fun minW(type: MinWidth)

  enum class MaxWidth {
    Zero,
    None,
    Xs,
    Sm,
    Md,
    Lg,
    Xl,
    Xl2,
    Xl3,
    Xl4,
    Xl5,
    Xl6,
    Xl7,
    Full,
    Min,
    Max,
    Prose,
    ScreenSm,
    ScreenMd,
    ScreenLg,
    ScreenXl,
    ScreenXl2,
  }

  fun maxW(type: MaxWidth)

  enum class Height {
    Screen,
    Auto,
    Pixel,
  }

  fun h(distance: Int)

  fun h(distance: Distance)

  fun h(distance: Height)

  enum class MinHeight {
    Zero,
    Full,
    Screen,
  }

  fun minH(type: MinHeight)

  enum class MaxHeight {
    Pixel,
    Full,
    Screen,
  }

  fun maxH(distance: Int)

  fun maxH(distance: MaxHeight)

  // Fonts

  enum class Shadow {
    Sm,
    Normal,
    Md,
    Lg,
    Xl,
    Xl2,
    Inner,
    None,
  }

  fun shadow(type: Shadow)

  enum class Blur {
    Sm,
    Normal,
    Md,
    Lg,
    Xl,
    Xl2,
    Xl3,
    None,
  }

  fun blur(type: Blur)

  enum class Cursor {
    Auto,
    Default,
    Pointer,
    Wait,
    Text,
    Move,
    Help,
    NotAllowed,
  }

  fun cursor(type: Cursor)

  enum class Outline {
    None,
    White,
    Black,
  }

  fun outline(type: Outline)

  enum class Resize {
    None,
    Y,
    X,
    Both,
  }

  fun resize(type: Resize)

  enum class UserSelect {
    None,
    Text,
    All,
    Auto,
  }

  fun userSelect(type: UserSelect)

  enum class Radius {
    None,
    Small,
    Normal,
    Medium,
    Large,
    Xl,
    Xl2,
    Xl3,
    Full
  }

  fun round(amount: Radius = Radius.Normal, side: Side = Side.All)

  enum class ListStyle {
    None,
    Disc,
    Decimal,
  }

  fun listStyle(type: ListStyle)

  enum class Animation {
    None,
    Spin,
    Ping,
    Pulse,
    Bounce,
  }

  fun animate(type: Animation)

  enum class VerticalAlignment {
    Baseline,
    Top,
    Middle,
    Bottom,
    TextTop,
    TextBottom,
  }

  fun verticalAlign(type: VerticalAlignment)

  enum class SelectType {
    None,
    Text,
    All,
    Auto,
  }

  fun select(type: SelectType)
}
