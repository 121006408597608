package ui.color

import org.jetbrains.compose.web.css.CSSColorValue
import org.jetbrains.compose.web.css.rgba

class CssUtils {
  companion object {
    fun fromHex(hex: String): CSSColorValue {
      return fromHexa(hex + "ff")
    }

    fun fromHexa(hexa: String): CSSColorValue {
      if (!hexa.startsWith("#") || hexa.length != 9) {
        throw IllegalArgumentException(
            "Argument should be CSS hexadecimal color with transparency: #rrggbbaa")
      }

      val chunked = hexa.removePrefix("#").chunked(2).map { it.toInt(16) }
      val rgb = chunked.take(3)
      val alpha = (chunked.last().toDouble() / 255 * 100).toInt()

      return rgba(rgb.get(0), rgb.get(1), rgb.get(2), alpha)
    }

    val secondary600Opacity20 = "#074B8F33"
    val secondary200 = "#CDDBE9"
    val errorMediumOpacity90 = "#c13e3be6"
    val blackMediumOpacity20 = "#66666633"
    val bexioColor = "#10303d"
    val bexioColorOpacity20 = bexioColor + "33"
  }
}
