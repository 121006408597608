package ui.components.chips.dropdown

import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue

class DropdownChipStateHolder : DropdownChipState {
  override var isOpen by mutableStateOf(false)
}

@Composable
fun rememberDropdownChipState(): DropdownChipState = remember { DropdownChipStateHolder() }
