package ui.layouts

import androidx.compose.runtime.Composable
import api.traak.user.User
import ui.components.navmenu.Navmenu
import ui.components.navmenu.Tab

@Composable
inline fun NavigationPage(
    user: User,
    activeElement: Tab?,
    content: @Composable () -> Unit,
) {
  Navmenu(
      user = user,
      activeElement = activeElement,
  )

  content()
}
