package api.traak

/** Represents the different types of results that can be returned from an authentication action */
enum class AuthResult {
  Success,
  FailureInternal,
  FailureUserUnknown,
  FailureUserAlreadyRegistered,
  FailurePasswordTooWeak,
  FailurePasswordWrong,
  FailureInvalidEmail,
  FailureInvalidPhoneNumber,
  FailureEmailAlreadyInUse,
  FailureInvalidVerificationCode,
  FailureUnknown,
}
