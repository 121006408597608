package api.traak.fromFirestore

import kotlinx.serialization.Serializable

@Serializable
data class Roles(
    val admin: Array<String>,
    val manager: Array<String>,
    val member: Array<String>,
    val removed: Array<String> = arrayOf(),
) {
  override fun equals(other: Any?): Boolean {
    if (this === other) return true
    if (other !is Roles) return false

    if (!admin.contentEquals(other.admin)) return false
    if (!manager.contentEquals(other.manager)) return false
    if (!member.contentEquals(other.member)) return false
    if (!removed.contentEquals(other.removed)) return false

    return true
  }

  override fun hashCode(): Int {
    var result = admin.contentHashCode()
    result = 31 * result + manager.contentHashCode()
    result = 31 * result + member.contentHashCode()
    result = 31 * result + removed.contentHashCode()
    return result
  }
}
