package ui.components.chips.people

import AccountMultipleOutline
import androidx.compose.runtime.Composable
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberCoroutineScope
import api.settings.LocalSettings
import api.settings.SettingsApi
import api.traak.Member
import api.traak.Team
import kotlinx.coroutines.CoroutineScope
import ui.components.chips.checkbox.searchable.DefaultItem
import ui.components.chips.checkbox.searchable.Item
import ui.components.chips.checkbox.searchable.SearchableCheckboxState
import ui.components.chips.checkbox.searchable.SearchableCheckboxStateHolder
import ui.components.chips.dropdown.DropdownAlignment
import ui.data.toString
import ui.strings.LocalStrings
import ui.strings.LocalizedStrings

@Composable
fun rememberPeopleChipState(
    team: Team,
    coroutineScope: CoroutineScope = rememberCoroutineScope(),
    strings: LocalizedStrings = LocalStrings.current,
    settings: SettingsApi = LocalSettings.current
): SearchableCheckboxState {
  fun getTitle(items: List<Item>, selectedItems: List<Item>): String {
    val everybodyIsSelected = items.size == selectedItems.size
    val nobodyIsSelected = selectedItems.isEmpty()
    val onePersonIsSelected = selectedItems.size == 1

    return if (everybodyIsSelected) {
      strings.peopleChipTitleEverybody
    } else if (nobodyIsSelected) {
      strings.peopleChipTitleNobody
    } else if (onePersonIsSelected) {
      selectedItems[0].text
    } else {
      strings.peopleChipTitle(selectedItems.size)
    }
  }

  return remember(
      team,
      coroutineScope,
  ) {
    SearchableCheckboxStateHolder(
        itemsFlow = team.members,
        mapper = { it.toItem(settings) },
        coroutineScope = coroutineScope,
        searchPlaceholder = strings.peopleChipSearchPlaceholder,
        chipIcon = { AccountMultipleOutline() },
        titleFromState = { items, selectedItems -> getTitle(items, selectedItems) },
        dropdownAlignment = DropdownAlignment.Right,
        startWithAllSelected = true,
    )
  }
}

private fun Member.toItem(settings: SettingsApi): Item =
    DefaultItem(
        this.id.raw,
        this.toString(settings),
    )
