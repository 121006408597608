package firebase.auth

enum class ErrorCode {
  ADMIN_ONLY_OPERATION,
  ARGUMENT_ERROR,
  APP_NOT_AUTHORIZED,
  APP_NOT_INSTALLED,
  CAPTCHA_CHECK_FAILED,
  CODE_EXPIRED,
  CORDOVA_NOT_READY,
  CORS_UNSUPPORTED,
  CREDENTIAL_ALREADY_IN_USE,
  CREDENTIAL_MISMATCH,
  CREDENTIAL_TOO_OLD_LOGIN_AGAIN,
  DEPENDENT_SDK_INIT_BEFORE_AUTH,
  DYNAMIC_LINK_NOT_ACTIVATED,
  EMAIL_CHANGE_NEEDS_VERIFICATION,
  EMAIL_EXISTS,
  EMULATOR_CONFIG_FAILED,
  EXPIRED_OOB_CODE,
  EXPIRED_POPUP_REQUEST,
  INTERNAL_ERROR,
  INVALID_API_KEY,
  INVALID_APP_CREDENTIAL,
  INVALID_APP_ID,
  INVALID_AUTH,
  INVALID_AUTH_EVENT,
  INVALID_CERT_HASH,
  INVALID_CODE,
  INVALID_CONTINUE_URI,
  INVALID_CORDOVA_CONFIGURATION,
  INVALID_CUSTOM_TOKEN,
  INVALID_DYNAMIC_LINK_DOMAIN,
  INVALID_EMAIL,
  INVALID_EMULATOR_SCHEME,
  INVALID_IDP_RESPONSE,
  INVALID_MESSAGE_PAYLOAD,
  INVALID_MFA_SESSION,
  INVALID_OAUTH_CLIENT_ID,
  INVALID_OAUTH_PROVIDER,
  INVALID_OOB_CODE,
  INVALID_ORIGIN,
  INVALID_PASSWORD,
  INVALID_PERSISTENCE,
  INVALID_PHONE_NUMBER,
  INVALID_PROVIDER_ID,
  INVALID_RECIPIENT_EMAIL,
  INVALID_SENDER,
  INVALID_SESSION_INFO,
  INVALID_TENANT_ID,
  MFA_INFO_NOT_FOUND,
  MFA_REQUIRED,
  MISSING_ANDROID_PACKAGE_NAME,
  MISSING_APP_CREDENTIAL,
  MISSING_AUTH_DOMAIN,
  MISSING_CODE,
  MISSING_CONTINUE_URI,
  MISSING_IFRAME_START,
  MISSING_IOS_BUNDLE_ID,
  MISSING_OR_INVALID_NONCE,
  MISSING_MFA_INFO,
  MISSING_MFA_SESSION,
  MISSING_PHONE_NUMBER,
  MISSING_SESSION_INFO,
  MODULE_DESTROYED,
  NEED_CONFIRMATION,
  NETWORK_REQUEST_FAILED,
  NULL_USER,
  NO_AUTH_EVENT,
  NO_SUCH_PROVIDER,
  OPERATION_NOT_ALLOWED,
  OPERATION_NOT_SUPPORTED,
  POPUP_BLOCKED,
  POPUP_CLOSED_BY_USER,
  PROVIDER_ALREADY_LINKED,
  QUOTA_EXCEEDED,
  REDIRECT_CANCELLED_BY_USER,
  REDIRECT_OPERATION_PENDING,
  REJECTED_CREDENTIAL,
  SECOND_FACTOR_ALREADY_ENROLLED,
  SECOND_FACTOR_LIMIT_EXCEEDED,
  TENANT_ID_MISMATCH,
  TIMEOUT,
  TOKEN_EXPIRED,
  TOO_MANY_ATTEMPTS_TRY_LATER,
  UNAUTHORIZED_DOMAIN,
  UNSUPPORTED_FIRST_FACTOR,
  UNSUPPORTED_PERSISTENCE,
  UNSUPPORTED_TENANT_OPERATION,
  UNVERIFIED_EMAIL,
  USER_CANCELLED,
  USER_DELETED,
  USER_DISABLED,
  USER_MISMATCH,
  USER_SIGNED_OUT,
  WEAK_PASSWORD,
  WEB_STORAGE_UNSUPPORTED,
  ALREADY_INITIALIZED;

  companion object {
    fun fromString(code: String): ErrorCode? =
        when (code) {
          "auth/admin-restricted-operation" -> ADMIN_ONLY_OPERATION
          "auth/argument-error" -> ARGUMENT_ERROR
          "auth/app-not-authorized" -> APP_NOT_AUTHORIZED
          "auth/app-not-installed" -> APP_NOT_INSTALLED
          "auth/captcha-check-failed" -> CAPTCHA_CHECK_FAILED
          "auth/code-expired" -> CODE_EXPIRED
          "auth/cordova-not-ready" -> CORDOVA_NOT_READY
          "auth/cors-unsupported" -> CORS_UNSUPPORTED
          "auth/credential-already-in-use" -> CREDENTIAL_ALREADY_IN_USE
          "auth/custom-token-mismatch" -> CREDENTIAL_MISMATCH
          "auth/requires-recent-login" -> CREDENTIAL_TOO_OLD_LOGIN_AGAIN
          "auth/dependent-sdk-initialized-before-auth" -> DEPENDENT_SDK_INIT_BEFORE_AUTH
          "auth/dynamic-link-not-activated" -> DYNAMIC_LINK_NOT_ACTIVATED
          "auth/email-change-needs-verification" -> EMAIL_CHANGE_NEEDS_VERIFICATION
          "auth/email-already-in-use" -> EMAIL_EXISTS
          "auth/emulator-config-failed" -> EMULATOR_CONFIG_FAILED
          "auth/expired-action-code" -> EXPIRED_OOB_CODE
          "auth/cancelled-popup-request" -> EXPIRED_POPUP_REQUEST
          "auth/internal-error" -> INTERNAL_ERROR
          "auth/invalid-api-key" -> INVALID_API_KEY
          "auth/invalid-app-credential" -> INVALID_APP_CREDENTIAL
          "auth/invalid-app-id" -> INVALID_APP_ID
          "auth/invalid-user-token" -> INVALID_AUTH
          "auth/invalid-auth-event" -> INVALID_AUTH_EVENT
          "auth/invalid-cert-hash" -> INVALID_CERT_HASH
          "auth/invalid-verification-code" -> INVALID_CODE
          "auth/invalid-continue-uri" -> INVALID_CONTINUE_URI
          "auth/invalid-cordova-configuration" -> INVALID_CORDOVA_CONFIGURATION
          "auth/invalid-custom-token" -> INVALID_CUSTOM_TOKEN
          "auth/invalid-dynamic-link-domain" -> INVALID_DYNAMIC_LINK_DOMAIN
          "auth/invalid-email" -> INVALID_EMAIL
          "auth/invalid-emulator-scheme" -> INVALID_EMULATOR_SCHEME
          "auth/invalid-credential" -> INVALID_IDP_RESPONSE
          "auth/invalid-message-payload" -> INVALID_MESSAGE_PAYLOAD
          "auth/invalid-multi-factor-session" -> INVALID_MFA_SESSION
          "auth/invalid-oauth-client-id" -> INVALID_OAUTH_CLIENT_ID
          "auth/invalid-oauth-provider" -> INVALID_OAUTH_PROVIDER
          "auth/invalid-action-code" -> INVALID_OOB_CODE
          "auth/unauthorized-domain" -> INVALID_ORIGIN
          "auth/wrong-password" -> INVALID_PASSWORD
          "auth/invalid-persistence-type" -> INVALID_PERSISTENCE
          "auth/invalid-phone-number" -> INVALID_PHONE_NUMBER
          "auth/invalid-provider-id" -> INVALID_PROVIDER_ID
          "auth/invalid-recipient-email" -> INVALID_RECIPIENT_EMAIL
          "auth/invalid-sender" -> INVALID_SENDER
          "auth/invalid-verification-id" -> INVALID_SESSION_INFO
          "auth/invalid-tenant-id" -> INVALID_TENANT_ID
          "auth/multi-factor-info-not-found" -> MFA_INFO_NOT_FOUND
          "auth/multi-factor-auth-required" -> MFA_REQUIRED
          "auth/missing-android-pkg-name" -> MISSING_ANDROID_PACKAGE_NAME
          "auth/missing-app-credential" -> MISSING_APP_CREDENTIAL
          "auth/auth-domain-config-required" -> MISSING_AUTH_DOMAIN
          "auth/missing-verification-code" -> MISSING_CODE
          "auth/missing-continue-uri" -> MISSING_CONTINUE_URI
          "auth/missing-iframe-start" -> MISSING_IFRAME_START
          "auth/missing-ios-bundle-id" -> MISSING_IOS_BUNDLE_ID
          "auth/missing-or-invalid-nonce" -> MISSING_OR_INVALID_NONCE
          "auth/missing-multi-factor-info" -> MISSING_MFA_INFO
          "auth/missing-multi-factor-session" -> MISSING_MFA_SESSION
          "auth/missing-phone-number" -> MISSING_PHONE_NUMBER
          "auth/missing-verification-id" -> MISSING_SESSION_INFO
          "auth/app-deleted" -> MODULE_DESTROYED
          "auth/account-exists-with-different-credential" -> NEED_CONFIRMATION
          "auth/network-request-failed" -> NETWORK_REQUEST_FAILED
          "auth/null-user" -> NULL_USER
          "auth/no-auth-event" -> NO_AUTH_EVENT
          "auth/no-such-provider" -> NO_SUCH_PROVIDER
          "auth/operation-not-allowed" -> OPERATION_NOT_ALLOWED
          "auth/operation-not-supported-in-this-environment" -> OPERATION_NOT_SUPPORTED
          "auth/popup-blocked" -> POPUP_BLOCKED
          "auth/popup-closed-by-user" -> POPUP_CLOSED_BY_USER
          "auth/provider-already-linked" -> PROVIDER_ALREADY_LINKED
          "auth/quota-exceeded" -> QUOTA_EXCEEDED
          "auth/redirect-cancelled-by-user" -> REDIRECT_CANCELLED_BY_USER
          "auth/redirect-operation-pending" -> REDIRECT_OPERATION_PENDING
          "auth/rejected-credential" -> REJECTED_CREDENTIAL
          "auth/second-factor-already-in-use" -> SECOND_FACTOR_ALREADY_ENROLLED
          "auth/maximum-second-factor-count-exceeded" -> SECOND_FACTOR_LIMIT_EXCEEDED
          "auth/tenant-id-mismatch" -> TENANT_ID_MISMATCH
          "auth/timeout" -> TIMEOUT
          "auth/user-token-expired" -> TOKEN_EXPIRED
          "auth/too-many-requests" -> TOO_MANY_ATTEMPTS_TRY_LATER
          "auth/unauthorized-continue-uri" -> UNAUTHORIZED_DOMAIN
          "auth/unsupported-first-factor" -> UNSUPPORTED_FIRST_FACTOR
          "auth/unsupported-persistence-type" -> UNSUPPORTED_PERSISTENCE
          "auth/unsupported-tenant-operation" -> UNSUPPORTED_TENANT_OPERATION
          "auth/unverified-email" -> UNVERIFIED_EMAIL
          "auth/user-cancelled" -> USER_CANCELLED
          "auth/user-not-found" -> USER_DELETED
          "auth/user-disabled" -> USER_DISABLED
          "auth/user-mismatch" -> USER_MISMATCH
          "auth/user-signed-out" -> USER_SIGNED_OUT
          "auth/weak-password" -> WEAK_PASSWORD
          "auth/web-storage-unsupported" -> WEB_STORAGE_UNSUPPORTED
          "auth/already-initialized" -> ALREADY_INITIALIZED
          else -> null
        }
  }
}

/**
 * Transforms a [Throwable] into it's corresponding firebase auth [ErrorCode]. Will be null if
 * [Throwable] is in fact, not a [ErrorCode].
 */
fun Throwable.toErrorCode() = ErrorCode.fromString(this.asDynamic().code as String)
