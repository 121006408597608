package ui.material

import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import org.jetbrains.compose.web.attributes.AutoComplete
import org.jetbrains.compose.web.attributes.InputType
import org.jetbrains.compose.web.attributes.autoComplete
import org.jetbrains.compose.web.attributes.builders.InputAttrsScope
import org.jetbrains.compose.web.attributes.placeholder
import org.jetbrains.compose.web.dom.AttrBuilderContext
import org.jetbrains.compose.web.dom.DateInput
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Input
import org.jetbrains.compose.web.dom.Label
import org.jetbrains.compose.web.dom.Text
import org.jetbrains.compose.web.dom.TextInput
import org.jetbrains.compose.web.dom.TimeInput
import org.w3c.dom.HTMLDivElement
import org.w3c.dom.HTMLInputElement
import tailwind.FlexItemScope.Grow
import tailwind.FlexScope
import tailwind.Full
import tailwind.Layout
import tailwind.Tailwind.Variant.Focus
import tailwind.Tailwind.Variant.FocusWithin
import tailwind.TailwindScope
import tailwind.TailwindScope.Radius
import tailwind.TextScope
import tailwind.color.Color
import tailwind.color.Opacity040
import tailwind.color.Opacity100
import tailwind.color.Transparent
import tailwind.color.White
import tailwind.tailwind
import ui.color.BlackDark
import ui.color.BlackLight
import ui.color.Primary100
import ui.color.Primary200
import ui.color.Secondary600
import ui.icons.Close
import ui.icons.EyeOffOutline
import ui.icons.EyeOutline
import ui.icons.Magnify
import ui.material.Input.applyInputGenerals
import ui.material.Input.defaultBackground

const val emptyInput = ""

object Input {
  val defaultBackground = Primary100
  val common: TailwindScope.() -> Unit = {
    w(Full)
    round(Radius.Medium)
    text(color = BlackDark) { body2(size = TextScope.Size.Lg) }
    outline(TailwindScope.Outline.None)
    transition()
  }

  val focus: TailwindScope.() -> Unit = {
    background(color = White)
    outline(TailwindScope.Outline.None)
    shadow(TailwindScope.Shadow.Normal)
  }

  val neutralInputStyle: TailwindScope.() -> Unit = {
    background(color = Transparent)
    flexItem(grow = Grow.Grow)
    outline(TailwindScope.Outline.None)
  }

  fun <T> InputAttrsScope<T>.applyInputGenerals(
      value: String,
      onInput: (T) -> Unit,
      autoComplete: AutoComplete = AutoComplete.off,
      placeholder: String? = null,
      attrs: AttrBuilderContext<HTMLInputElement>? = null,
  ) {
    attrs?.invoke(this)

    value(value)
    onInput { onInput(it.value) }

    placeholder?.let { placeholder(it) }
    autoComplete(autoComplete)

    tailwind(neutralInputStyle)
  }
}

@Composable
private fun InputWrapper(
    label: String? = null,
    attrs: AttrBuilderContext<HTMLDivElement>? = null,
    endContent: (@Composable () -> Unit)? = null,
    content: @Composable () -> Unit,
) {
  var isHovered by remember { mutableStateOf(false) }

  Div(attrs = attrs) {
    Label(
        attrs = {
          tailwind {
            w(Full)
            flex(
                direction = FlexScope.Direction.Column,
                alignItems = Layout.AlignItems.Start,
                gap = 0,
            )
          }
        },
    ) {
      if (label != null) {
        Div(
            attrs = {
              tailwind {
                w(Full)
                pl(2)
                text(color = Secondary600) { overline(weight = TextScope.Weight.Medium) }
              }
            },
        ) {
          Text(label)
        }
      }

      Div(
          attrs = {
            attrs?.invoke(this)

            onMouseEnter { isHovered = true }
            onMouseLeave { isHovered = false }

            tailwind {
              w(Full)
              flex(
                  direction = FlexScope.Direction.Row,
                  alignItems = Layout.AlignItems.Center,
                  gap = 4,
              )
              p(5)
              background(color = defaultBackground)

              round(Radius.Medium)
              text(color = BlackDark) { body2(size = TextScope.Size.Xl) }

              border(
                  width = 1,
                  color = Primary200,
                  opacity = Opacity100,
              )
              outline(TailwindScope.Outline.None)

              transition()

              focusWithin {
                background(color = White)
                shadow(TailwindScope.Shadow.Normal)
                border(
                    width = 1,
                    color = BlackLight,
                    opacity = Opacity040,
                )
              }
            }
          },
      ) {
        content()

        endContent?.invoke()
      }
    }
  }
}

@Composable
fun EmailInput(
    value: String,
    onInput: (String) -> Unit,
    label: String? = null,
    placeholder: String? = null,
    autoComplete: AutoComplete = AutoComplete.off,
    attrs: AttrBuilderContext<HTMLDivElement>? = null,
) {
  InputWrapper(
      label = label,
      attrs = attrs,
  ) {
    Input(
        type = InputType.Email,
    ) {
      applyInputGenerals(
          value = value,
          onInput = onInput,
          placeholder = placeholder,
          autoComplete = autoComplete,
      )
    }
  }
}

@Composable
fun PasswordInput(
    value: String,
    onInput: (String) -> Unit,
    label: String? = null,
    placeholder: String? = null,
    autoComplete: AutoComplete = AutoComplete.off,
    attrs: AttrBuilderContext<HTMLDivElement>? = null,
) {
  var passwordIsVisible by remember { mutableStateOf(false) }
  val inputType = if (passwordIsVisible) InputType.Text else InputType.Password

  InputWrapper(
      label = label,
      endContent = {
        Row(
            justify = Layout.Justify.Center,
            alignItems = Layout.AlignItems.Center,
            attrs = {
              tailwind {
                w(8)
                h(8)
                round(Radius.Full)
                cursor(TailwindScope.Cursor.Pointer)
                hover { background(color = Primary200) }
              }
              onClick { passwordIsVisible = !passwordIsVisible }
            },
        ) {
          if (passwordIsVisible) {
            EyeOutline()
          } else {
            EyeOffOutline()
          }
        }
      },
      attrs = attrs,
  ) {
    Input(
        type = inputType,
    ) {
      applyInputGenerals(
          value = value,
          onInput = onInput,
          placeholder = placeholder,
          autoComplete = autoComplete,
      )
    }
  }
}

@Composable
fun TextInput(
    value: String,
    onInput: (String) -> Unit,
    label: String? = null,
    placeholder: String? = null,
    autoComplete: AutoComplete = AutoComplete.off,
    attrs: AttrBuilderContext<HTMLDivElement>? = null,
) {
  InputWrapper(
      label = label,
      attrs = attrs,
  ) {
    Input(
        type = InputType.Text,
    ) {
      applyInputGenerals(
          value = value,
          onInput = onInput,
          placeholder = placeholder,
          autoComplete = autoComplete,
      )
    }
  }
}

@Composable
fun SmallTextInput(
    value: String,
    onInput: (String) -> Unit,
    placeholder: String,
    background: Color = defaultBackground,
    attrs: AttrBuilderContext<HTMLInputElement>? = null,
) {
  TextInput(value = value) {
    attrs?.invoke(this)

    placeholder(placeholder)
    onInput { onInput(it.value) }

    tailwind(Input.common)
    tailwind(Focus, Input.focus)

    tailwind {
      p(2)
      text { body2() }
      border(
          width = 1,
          color = Primary200,
      )
      background(color = background)
    }
  }
}

@Composable
fun SmallDateInput(
    value: String,
    onInput: (String) -> Unit,
    background: Color = defaultBackground,
    attrs: AttrBuilderContext<HTMLInputElement>? = null,
) {
  DateInput(value = value) {
    attrs?.invoke(this)

    onInput { onInput(it.value) }

    tailwind(Input.common)
    tailwind(Focus, Input.focus)

    tailwind {
      p(2)
      text { body2() }
      border(
          width = 1,
          color = Primary200,
      )
      background(color = background)
    }
  }
}

@Composable
fun SmallTimeInput(
    value: String,
    onInput: (String) -> Unit,
    background: Color = defaultBackground,
    attrs: AttrBuilderContext<HTMLInputElement>? = null,
) {
  TimeInput(value = value) {
    attrs?.invoke(this)

    onInput { onInput(it.value) }

    tailwind(Input.common)
    tailwind(Focus, Input.focus)

    tailwind {
      p(2)
      text { body2() }
      border(
          width = 1,
          color = Primary200,
      )
      background(color = background)
    }
  }
}

@Composable
fun SearchInput(
    value: String,
    onInput: (String) -> Unit,
    placeholder: String? = null,
    attrs: AttrBuilderContext<HTMLDivElement>? = null,
) {
  GenericSearchInput(
      value = value,
      onInput = onInput,
      placeholder = placeholder,
      attrs = {
        attrs?.invoke(this)

        tailwind(Input.common)
        tailwind(FocusWithin, Input.focus)
        tailwind {
          p(4)
          text { body1() }
        }
      },
  )
}

@Composable
fun SmallSearchInput(
    value: String,
    onInput: (String) -> Unit,
    placeholder: String? = null,
    attrs: AttrBuilderContext<HTMLDivElement>? = null,
) {
  GenericSearchInput(
      value = value,
      onInput = onInput,
      placeholder = placeholder,
      attrs = {
        attrs?.invoke(this)

        tailwind(Input.common)
        tailwind(FocusWithin, Input.focus)
        tailwind {
          p(2)
          text { body1(size = TextScope.Size.Lg) }
        }
      },
  )
}

@Composable
private fun GenericSearchInput(
    value: String,
    onInput: (String) -> Unit,
    placeholder: String? = null,
    attrs: AttrBuilderContext<HTMLDivElement>? = null,
) {
  var isHovered by remember { mutableStateOf(false) }
  val showEraseIcon = isHovered && value.isNotEmpty()

  Div(
      attrs = {
        attrs?.invoke(this)

        onMouseEnter { isHovered = true }
        onMouseLeave { isHovered = false }

        tailwind {
          flex(
              direction = FlexScope.Direction.Row,
              alignItems = Layout.AlignItems.Center,
              gap = 4,
          )

          background(color = defaultBackground)
        }
      },
  ) {
    Magnify()

    TextInput(value = value) {
      placeholder?.let { placeholder(it) }
      onInput { onInput(it.value) }

      tailwind {
        background(color = Transparent)
        flexItem(grow = Grow.Grow)
        outline(TailwindScope.Outline.None)
      }
    }

    if (showEraseIcon) {
      Close {
        tailwind { cursor(TailwindScope.Cursor.Pointer) }
        onClick { onInput(emptyInput) }
      }
    }
  }
}
