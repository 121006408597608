package api.traak.toFirestore

import kotlinx.serialization.Serializable

@Serializable
data class ToFirestoreProfile(
    val name: String,
    val firstName: String,
    val lastName: String,
)
