package ui.components.calendar

import androidx.compose.runtime.*
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.StateFlow
import kotlinx.datetime.*
import kotlinx.datetime.Clock
import kotlinx.datetime.LocalDate
import kotlinx.datetime.TimeZone
import kotlinx.datetime.toLocalDateTime
import org.jetbrains.compose.web.dom.AttrBuilderContext
import org.w3c.dom.HTMLDivElement

class CalendarStateHolder(
    initialStartDay: LocalDate?,
    initialEndDay: LocalDate?,
) : CalendarState {
  private val today = Clock.System.now().toLocalDateTime(TimeZone.currentSystemDefault()).date
  private val currentMonth = today.minus(DatePeriod(days = today.dayOfMonth - 1))
  private val startAndEndOfCurrentMonth = getStartAndEndOfMonth(currentMonth)
  private val defaultStartDay = startAndEndOfCurrentMonth.first
  private val defaultEndDay = startAndEndOfCurrentMonth.second

  private var startDay: LocalDate? by mutableStateOf(initialStartDay ?: defaultStartDay)
  private var endDay: LocalDate? by mutableStateOf(initialEndDay ?: defaultEndDay)
  private var currentHoverDay: LocalDate? by mutableStateOf(null)
  private var pickerState by mutableStateOf(PickerState.START)

  private val initialShownMonth = initialStartDay?.let { getStartAndEndOfMonth(it).first }
  private var shownMonth by mutableStateOf(initialShownMonth ?: currentMonth)

  private val daysToDisplay: List<LocalDate>
    get() {
      val (startOfMonth, endOfMonth) = getStartAndEndOfMonth(shownMonth)
      return createListFromRange(startOfMonth, endOfMonth)
    }

  private val days: List<DayItem>
    get() =
        daysToDisplay.map { day ->
          DayItem(
              date = day,
              state = day.toDayItemState(currentHoverDay, startDay, endDay),
              pickerState = pickerState,
          )
        }

  private val validatedRange: MutableStateFlow<DayRange> =
      MutableStateFlow(
          DayRange(startDay!!, endDay!!),
      )

  private val prefixMonth: List<DayItem?>
    get() = List(shownMonth.dayOfWeek.ordinal) { null }

  override val range: StateFlow<DayRange> = validatedRange

  private fun getStartAndEndOfMonth(date: LocalDate): Pair<LocalDate, LocalDate> {
    val startOfMonth = date.minus(DatePeriod(days = date.dayOfMonth - 1))
    val endOfMonth = startOfMonth.plus(DatePeriod(months = 1)).minus(DatePeriod(days = 1))

    return startOfMonth to endOfMonth
  }

  private fun createListFromRange(start: LocalDate, end: LocalDate): List<LocalDate> {
    val month = mutableListOf<LocalDate>()

    var day = start
    do {
      month.add(day)
      day = day.plus(DatePeriod(days = 1))
    } while (day <= end)

    return month
  }

  private fun onDayClick(date: LocalDate): Unit {
    when (pickerState) {
      PickerState.START -> {
        pickerState = PickerState.END
        startDay = date
        endDay = null // clean previous value if present
      }
      PickerState.END -> {
        if (date <= startDay!!) {
          pickerState = PickerState.END
          startDay = date
        } else {
          pickerState = PickerState.START
          endDay = date
          validatedRange.value = DayRange(startDay!!, endDay!!)
        }
      }
    }
  }

  private fun fillInRight(currentDay: LocalDate): Boolean {
    val afterOrStart = startDay?.let { currentDay >= it } ?: false
    val beforeEnd = endDay?.let { currentDay < it } ?: false
    val beforeHover = currentHoverDay?.let { currentDay < it } ?: false
    val noEnd = endDay == null

    return (afterOrStart && beforeEnd) || (afterOrStart && beforeHover && noEnd)
  }

  private fun fillInLeft(currentDay: LocalDate): Boolean {
    val isEndDay = endDay == currentDay
    val isCurrentHover = currentHoverDay == currentDay
    val afterStart = startDay?.let { currentDay > it } ?: false
    val beforeEndOrNoEnd = endDay?.let { currentDay <= it } ?: true

    return isEndDay || (isCurrentHover && afterStart && beforeEndOrNoEnd)
  }

  @Composable
  override fun Calendar(attrs: AttrBuilderContext<HTMLDivElement>?) {
    Calendar(
        currentMonth = shownMonth,
        today = today,
        onPrevMonthClick = { shownMonth = shownMonth.minus(DatePeriod(months = 1)) },
        onNextMonthClick = { shownMonth = shownMonth.plus(DatePeriod(months = 1)) },
        prefixedDays = prefixMonth + days,
        fillInRight = { fillInRight(it) },
        fillInLeft = { fillInLeft(it) },
        onDayClick = { onDayClick(it) },
        onMouseEnter = { currentHoverDay = it },
        onMouseLeave = { currentHoverDay = null },
        attrs = attrs,
    )
  }
}

@Composable
fun rememberCalendarState(
    startDay: LocalDate? = null,
    endDay: LocalDate? = null,
): CalendarState {
  return remember(startDay, endDay) {
    CalendarStateHolder(
        startDay,
        endDay,
    )
  }
}
