package ui.screens.login.newLogin.signInWithEmailLink

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.rememberCoroutineScope
import api.traak.LocalTraakApi
import api.traak.user.User
import app.softwork.routingcompose.Router
import kotlinx.coroutines.launch
import navigation.Route
import navigation.navigate
import ui.screens.onboarding.Onboarding

@Composable
fun SignInWithEmailLink(
    link: String,
    user: User? = null,
) {
  val api = LocalTraakApi.current
  val coroutineScope = rememberCoroutineScope()
  val router = Router.current

  if (user != null) {
    router.navigate(Route.Origin)
  } else {
    LaunchedEffect(link) { coroutineScope.launch { api.authManager.email.validateLink(link) } }
  }

  Onboarding()
}
