package ui.components.navmenu

import androidx.compose.runtime.Composable
import org.jetbrains.compose.web.dom.AttrBuilderContext
import org.jetbrains.compose.web.dom.Text
import org.w3c.dom.HTMLDivElement
import tailwind.tailwind
import ui.color.BlackMedium
import ui.icons.World
import ui.material.PopUpDetail
import ui.strings.LocalStrings

@Composable
fun SwitchLanguage(
    onClick: () -> Unit,
    attrs: AttrBuilderContext<HTMLDivElement>? = null,
) {
  val strings = LocalStrings.current

  PopUpDetail(
      onClick = onClick,
      attrs = {
        attrs?.invoke(this)
        tailwind { text(color = BlackMedium) }
      },
  ) {
    World()
    Text(strings.navMenuDetailSwitchLanguage)
  }
}
