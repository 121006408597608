package ui.screens.newProject

import api.traak.StorageResult
import ui.strings.LocalizedStrings

sealed class Result {
  sealed class LocalResult : Result() {
    object EmptyTitle : Result()
  }

  data class RemoteResult(val result: StorageResult) : Result()
}

fun Result.toErrorMessage(strings: LocalizedStrings): String? =
    when (this) {
      Result.LocalResult.EmptyTitle -> strings.projectEmptyTitleError
      is Result.RemoteResult ->
          when (result) {
            StorageResult.Success -> null
            else -> strings.projectSavedError
          }
    }

/** State holder for [NewProject] screen */
interface NewProjectState {
  var projectTitle: String
  var name: String
  var phone: String
  var postalCode: String
  var locality: String
  var street: String

  /** Are we currently waiting for a server response */
  val loading: Boolean

  /** [Result] from the operation if any */
  val result: Result?

  /** Creates a new project based on the current data */
  fun createProject(): Unit
}
