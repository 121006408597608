package tailwind

@TailwindDsl
interface Layout {
  enum class Justify {
    // 123####
    Start,

    // ####123
    End,

    // ##123##
    Center,

    // 1##2##3
    Between,

    // #1##2##3#
    Around,

    // #1#2#3
    Evenly;

    override fun toString(): String =
        when (this) {
          Start -> "start"
          End -> "end"
          Center -> "center"
          Between -> "between"
          Around -> "around"
          Evenly -> "evenly"
        }
  }

  fun justify(type: Justify)

  enum class AlignItems {
    Start,
    End,
    Center,
    Baseline,
    Stretch;

    override fun toString(): String =
        when (this) {
          Start -> "start"
          End -> "end"
          Center -> "center"
          Baseline -> "baseline"
          Stretch -> "stretch"
        }
  }

  fun alignItems(type: AlignItems)

  enum class AlignContent {
    Start,
    End,
    Center,
    Between,
    Around,
    Evenly;

    override fun toString(): String =
        when (this) {
          Start -> "start"
          End -> "end"
          Center -> "center"
          Between -> "between"
          Around -> "around"
          Evenly -> "evenly"
        }
  }

  fun alignContent(type: AlignContent)

  val Int.gap: Unit
}
