package ui.icons

import androidx.compose.runtime.Composable
import org.jetbrains.compose.web.dom.AttrBuilderContext
import org.w3c.dom.HTMLDivElement

@Composable
fun Plus(
    attrs: AttrBuilderContext<HTMLDivElement>? = null,
) =
    Icon(attrs) {
      attr(
          "d",
          "M19 13.0001H13V19.0001H11V13.0001H5V11.0001H11V5.00012H13V11.0001H19V13.0001Z",
      )
    }
