package ui.material

import androidx.compose.runtime.Composable
import org.jetbrains.compose.web.dom.AttrBuilderContext
import org.w3c.dom.HTMLDivElement
import tailwind.Side
import tailwind.TailwindScope
import tailwind.color.Opacity020
import tailwind.tailwind
import ui.color.Secondary600

@Composable
fun ClickableListItem(
    onClick: (() -> Unit)? = null,
    attrs: AttrBuilderContext<HTMLDivElement>? = null,
    content: @Composable () -> Unit,
) {
  ListItem(
      attrs = {
        attrs?.invoke(this)

        if (onClick != null) {
          onClick { onClick() }
          tailwind { cursor(TailwindScope.Cursor.Pointer) }
        }
      },
      content = content,
  )
}

@Composable
fun ListItem(
    attrs: AttrBuilderContext<HTMLDivElement>? = null,
    content: @Composable () -> Unit,
) {
  FullRow(
      attrs = {
        attrs?.invoke(this)

        tailwind {
          py(5)
          px(6)

          border(
              color = Secondary600,
              opacity = Opacity020,
          ) {
            1.width(Side.Bottom)
          }
        }
      },
  ) {
    content()
  }
}
