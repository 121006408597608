package ui.components.fullscreenModal

import androidx.compose.runtime.*
import androidx.compose.runtime.Composable
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.coroutineScope
import kotlinx.coroutines.delay
import kotlinx.coroutines.launch
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.Div
import tailwind.FlexScope
import tailwind.Layout
import tailwind.TailwindScope
import tailwind.TransitionScope
import tailwind.color.Opacity000
import tailwind.color.Opacity040
import tailwind.color.Opacity100
import tailwind.color.White
import tailwind.tailwind
import ui.color.BlackLight
import ui.helpers.OnEscDown
import ui.material.ClosableCard

val defaultSize = 50.cssRem

class FullScreenModalManagerImpl(private val coroutineScope: CoroutineScope) :
    FullScreenModalManager {
  private var currentModal: Modal? by mutableStateOf(null)
  private var visible by mutableStateOf(false)

  @Composable
  fun display() {
    val modal = currentModal ?: return

    LaunchedEffect(null) { visible = true }

    OnEscDown { close() }

    FullScreenModal(
        visible = visible,
        title = modal.title,
        onClose = { close() },
        width = modal.width ?: defaultSize,
    ) {
      modal.content { close() }
    }
  }

  private fun close() {
    coroutineScope.launch {
      // This gives the time to the [FullScreenModal] to animate the opacity and visibility of
      // the modal. Make sure that the value of the delay and the animation are the same, or
      // close enough to not cause any inconvenience to the user.
      visible = false
      delay(300)

      currentModal?.onClose?.invoke()
      currentModal = null
    }
  }

  override fun registerForDisplay(modal: Modal) {
    currentModal = modal
  }
}

@Composable
fun FullScreenModal(
    visible: Boolean,
    title: String,
    onClose: () -> Unit,
    width: CSSSizeValue<out CSSUnit>,
    content: @Composable () -> Unit,
) {
  Div(
      attrs = {
        style {
          width(100.vw)
          height(100.vh)
        }

        classes("backdrop-filter", "backdrop-blur-sm")

        tailwind {
          position(TailwindScope.Position.Absolute)
          top(0)
          left(0)

          pt(20)

          flex(
              direction = FlexScope.Direction.Row,
              justify = Layout.Justify.Center,
              alignItems = Layout.AlignItems.Start,
          )

          overflow(TailwindScope.OverflowType.Hidden)
          overscroll(TailwindScope.Overscroll.None)

          background(
              color = BlackLight,
              opacity = Opacity040,
          )

          if (visible) {
            visibility(TailwindScope.Visibility.Visible)
            opacity(Opacity100)
          } else {
            visibility(TailwindScope.Visibility.Invisible)
            opacity(Opacity000)
          }

          transition(
              property = TransitionScope.Property.All,
              duration = TransitionScope.Duration.Duration0300,
          )
        }
      },
  ) {
    ClosableCard(
        onCloseClick = onClose,
        title = title,
        attrs = {
          style {
            width(width)
            maxHeight(80.vh)
          }

          tailwind { background(White) }
        },
    ) {
      content()
    }
  }
}

val LocalFullScrenModalManager =
    staticCompositionLocalOf<FullScreenModalManager> {
      error("The FullScreenModalManager hasn't been initialized yet.")
    }
