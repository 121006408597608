package ui.screens.memberDetail

import androidx.compose.runtime.Composable
import androidx.compose.runtime.State
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberCoroutineScope
import api.settings.LocalSettings
import api.traak.ExportType
import api.traak.LocalTraakApi
import api.traak.Member
import api.traak.Recap
import api.traak.Task
import api.traak.Team
import api.traak.user.User
import app.softwork.routingcompose.Router
import kotlinx.coroutines.flow.map
import kotlinx.coroutines.launch
import kotlinx.datetime.DateTimeUnit
import kotlinx.datetime.TimeZone
import kotlinx.datetime.atStartOfDayIn
import kotlinx.datetime.plus
import navigation.Route
import navigation.navigate
import org.jetbrains.compose.web.dom.AttrBuilderContext
import org.w3c.dom.HTMLDivElement
import tailwind.FlexItemScope
import tailwind.Layout
import tailwind.tailwind
import ui.components.calendar.CalendarState
import ui.components.calendar.rememberCalendarState
import ui.components.navigationBar.NavigationBar
import ui.components.taskCreator.TaskCreator
import ui.components.taskCreator.TaskCreatorState
import ui.components.taskCreator.rememberTaskCreatorState
import ui.components.taskTable.OrderedBy
import ui.components.taskTable.TaskTable
import ui.components.taskTable.TaskTableMode
import ui.components.taskTable.TaskTableState
import ui.components.taskTable.rememberTaskTableState
import ui.data.Loadable
import ui.data.asLoadable
import ui.data.collectAsState
import ui.data.map
import ui.data.toList
import ui.data.toString
import ui.layouts.FullScreenLayout
import ui.material.Column
import ui.material.FullColumn
import ui.material.FullRow
import ui.material.SmallOutlineButton
import ui.strings.LocalStrings

private fun Int.toOrderedBy(): OrderedBy? =
    when (this) {
      0 -> OrderedBy.Date
      1 -> OrderedBy.Origin
      else -> null
    }

@Composable
fun MemberDetail(
    team: Team,
    memberId: User.Id,
    attrs: AttrBuilderContext<HTMLDivElement>? = null,
) {
  val api = LocalTraakApi.current
  val router = Router.current
  val strings = LocalStrings.current
  val settings = LocalSettings.current
  val coroutineScope = rememberCoroutineScope()

  // Calendar
  val calendarState = rememberCalendarState()

  // Users and tasks
  val members = remember(api, team) { api.membersOf(team).asLoadable() }

  val member =
      members
          .map { loadable -> loadable.toList().find { it.id == memberId } }
          .asLoadable()
          .collectAsState()

  val loadableTasks: State<Loadable<List<Task>>> =
      remember(
              api,
              team.id,
              memberId,
              calendarState.range.value.start,
              calendarState.range.value.end,
          ) {
            api.tasksForUser(
                    teamId = team.id,
                    userId = memberId,
                    startDate = calendarState.range.value.start,
                    endDate = calendarState.range.value.end,
                )
                .asLoadable()
          }
          .collectAsState()

  // Recaps
  val recaps =
      remember(api, team, calendarState.range.value.start, calendarState.range.value.end) {
            api.getRecaps(
                    userId = memberId,
                    teamId = team.id,
                    from =
                        calendarState.range.value.start.atStartOfDayIn(
                            TimeZone.currentSystemDefault()),
                    to =
                        calendarState.range.value.start.atStartOfDayIn(
                            TimeZone.currentSystemDefault()),
                )
                .asLoadable()
          }
          .collectAsState()

  // Task creator/editor
  val taskCreatorState =
      rememberTaskCreatorState(
          team = team,
          initialMember =
              when (member.value) {
                is Loadable.Loaded -> (member.value as Loadable.Loaded<Member?>).resource
                Loadable.Loading -> null
              },
      )

  // Search & Filter
  val taskTableState =
      rememberTaskTableState(
          mode = TaskTableMode.Member,
          loadableTasks = loadableTasks.value,
          taskEditorState = taskCreatorState,
          toOrderedBy = { toOrderedBy() },
      )

  MemberDetail(
      onBackArrowClick = { router.navigate(Route.TeamOverview) },
      loadableUsername = member.value.map { it?.toString(settings) ?: "" },
      loadableTasks = loadableTasks.value,
      loadableRecaps = recaps.value,
      onExportClick = {
        coroutineScope.launch {
          api.exportView(
              team.id,
              ExportType.User,
              memberId.raw,
              calendarState.range.value.start.atStartOfDayIn(TimeZone.currentSystemDefault()),
              calendarState.range.value.end
                  .plus(DateTimeUnit.DayBased(1))
                  .atStartOfDayIn(TimeZone.currentSystemDefault()),
          )
        }
      },
      exportButtonText = strings.memberDetailExport,
      taskTableState = taskTableState,
      calendarState = calendarState,
      taskCreatorState = taskCreatorState,
      attrs = attrs,
  )
}

@Composable
fun MemberDetail(
    onBackArrowClick: () -> Unit,
    loadableUsername: Loadable<String>,
    loadableTasks: Loadable<List<Task>>,
    loadableRecaps: Loadable<List<Recap>>,
    onExportClick: () -> Unit,
    exportButtonText: String,
    taskTableState: TaskTableState,
    calendarState: CalendarState,
    taskCreatorState: TaskCreatorState,
    attrs: AttrBuilderContext<HTMLDivElement>? = null,
) {
  FullScreenLayout(
      attrs = {
        attrs?.invoke(this)
        tailwind { py(16) }
      },
  ) {
    FullColumn {
      NavigationBar(
          onBackArrowClick = onBackArrowClick,
          title = loadableUsername.get() ?: "",
          attrs = { tailwind { mb(4) } },
      ) {
        SmallOutlineButton(
            text = exportButtonText,
            onClick = onExportClick,
        )
      }

      FullRow(
          justify = Layout.Justify.Start,
          alignItems = Layout.AlignItems.Start,
          gap = 16,
      ) {
        Column(
            attrs = { tailwind { pt(10) } },
        ) {
          calendarState.Calendar(null)

          RecapList(loadableRecaps)
        }

        val taskTableAttr: AttrBuilderContext<HTMLDivElement> = {
          tailwind {
            pt(10)
            flexItem(grow = FlexItemScope.Grow.Grow)
          }
        }

        when (loadableTasks) {
          is Loadable.Loading -> {}
          is Loadable.Loaded -> {
            TaskTable(
                state = taskTableState,
                attrs = taskTableAttr,
            )
          }
        }
      }

      TaskCreator(taskCreatorState)
    }
  }
}
