package api.settings

import api.traak.Team
import kotlinx.browser.localStorage
import kotlinx.coroutines.flow.MutableStateFlow
import org.w3c.dom.get
import org.w3c.dom.set

class LocalStorageSettings : SettingsApi {
  private val keyLanguage = "language"
  private val keyTeam = "team"
  private val keyShowFirstNameFirst = "showFirstNameFirst"
  private val keyOrderByFirstName = "orderByFirstName"

  private fun prefixKey(key: String) = "ch.traak.$key"

  private fun readFromStorage(key: String) = localStorage[prefixKey(key)]

  private fun writeToStorage(key: String, value: Any) {
    localStorage[prefixKey(key)] = value.toString()
  }

  private fun removeFromStorage(key: String) = localStorage.removeItem(prefixKey(key))

  private val _team: MutableStateFlow<Team.Id?> = MutableStateFlow(null)
  private val _language: MutableStateFlow<Language> = MutableStateFlow(Language.French)
  private val _showFirstNameFirst: MutableStateFlow<Boolean> = MutableStateFlow(false)
  private val _orderByFirstName: MutableStateFlow<Boolean> = MutableStateFlow(false)

  init {
    // Initialize language
    val initialLanguage = Language.fromString(readFromStorage(keyLanguage))
    if (initialLanguage != null) {
      _language.value = initialLanguage
    } else {
      writeToStorage(keyLanguage, _language.value)
    }

    // Initialize preferred team
    readFromStorage(keyTeam)?.let { _team.value = Team.Id(it) }

    // Initialize show first name first
    val initialShowFirstNameFirst = readFromStorage(keyShowFirstNameFirst)?.toBooleanStrictOrNull()
    if (initialShowFirstNameFirst != null) {
      _showFirstNameFirst.value = initialShowFirstNameFirst
    } else {
      writeToStorage(keyShowFirstNameFirst, _showFirstNameFirst.value)
    }

    // Initialize order by first name
    val initialOrderByFirstName = readFromStorage(keyOrderByFirstName)?.toBooleanStrictOrNull()
    if (initialOrderByFirstName != null) {
      _orderByFirstName.value = initialOrderByFirstName
    } else {
      writeToStorage(keyOrderByFirstName, _orderByFirstName.value)
    }
  }

  override val language = _language

  override fun setLanguageTo(language: Language) {
    _language.value = language
    writeToStorage(keyLanguage, language)
  }

  override val team = _team

  override fun setTeamTo(teamId: Team.Id) {
    writeToStorage(keyTeam, teamId.raw)
    _team.value = teamId
  }

  override fun removeTeam() {
    removeFromStorage(keyTeam)
    _team.value = null
  }

  override val showFirstNameFirst = _showFirstNameFirst

  override fun setShowFirstNameFirstTo(showFirstNameFirst: Boolean) {
    writeToStorage(keyShowFirstNameFirst, showFirstNameFirst)
    _showFirstNameFirst.value = showFirstNameFirst
  }

  override val orderByFirstName = _orderByFirstName

  override fun setOrderByFirstNameTo(orderByFirstName: Boolean) {
    writeToStorage(keyOrderByFirstName, orderByFirstName)
    _orderByFirstName.value = orderByFirstName
  }
}
