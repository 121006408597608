package ui.screens.newProject

import androidx.compose.runtime.Composable
import api.traak.Team
import org.jetbrains.compose.web.dom.AttrBuilderContext
import org.w3c.dom.HTMLDivElement
import ui.components.PageHeader
import ui.layouts.HalfScreenLayout
import ui.strings.LocalStrings

@Composable
fun NewProject(
    team: Team,
    attrs: AttrBuilderContext<HTMLDivElement>? = null,
) {
  val strings = LocalStrings.current
  val state = rememberNewProjectState(team = team)

  NewProject(
      title = strings.newProjectTitle,
      explanation = strings.newProjectExplanation,
      projectTitle = state.projectTitle,
      onProjectTitleInput = { state.projectTitle = it },
      projectTitleLabel = strings.projectTitleLabel,
      projectTitlePlaceholder = strings.projectTitlePlaceholder,
      name = state.name,
      onNameInput = { state.name = it },
      nameLabel = strings.projectNameLabel,
      namePlaceholder = strings.projectNamePlaceholder,
      phone = state.phone,
      onPhoneInput = { state.phone = it },
      phoneLabel = strings.projectPhoneLabel,
      phonePlaceholder = strings.projectPhonePlaceholder,
      postalCode = state.postalCode,
      onPostalCodeInput = { state.postalCode = it },
      postalCodeLabel = strings.projectPostalCodeLabel,
      postalCodePlaceholder = strings.projectPostalCodePlaceholder,
      locality = state.locality,
      onLocalityInput = { state.locality = it },
      localityLabel = strings.projectLocalityLabel,
      localityPlaceholder = strings.projectLocalityPlaceholder,
      street = state.street,
      onStreetInput = { state.street = it },
      streetLabel = strings.projectStreetLabel,
      streetPlaceholder = strings.projectStreetPlaceholder,
      createProjectButton = strings.newProjectCreateProjectButton,
      onProjectCreate = { state.createProject() },
      loading = state.loading,
      errorMessage = state.result?.toErrorMessage(strings),
      attrs = attrs,
  )
}

@Composable
fun NewProject(
    title: String,
    explanation: String,
    projectTitle: String,
    onProjectTitleInput: (String) -> Unit,
    projectTitleLabel: String,
    projectTitlePlaceholder: String,
    name: String,
    onNameInput: (String) -> Unit,
    nameLabel: String,
    namePlaceholder: String,
    phone: String,
    onPhoneInput: (String) -> Unit,
    phoneLabel: String,
    phonePlaceholder: String,
    postalCode: String,
    onPostalCodeInput: (String) -> Unit,
    postalCodeLabel: String,
    postalCodePlaceholder: String,
    locality: String,
    onLocalityInput: (String) -> Unit,
    localityLabel: String,
    localityPlaceholder: String,
    street: String,
    onStreetInput: (String) -> Unit,
    streetLabel: String,
    streetPlaceholder: String,
    createProjectButton: String,
    onProjectCreate: () -> Unit,
    loading: Boolean,
    errorMessage: String?,
    attrs: AttrBuilderContext<HTMLDivElement>? = null,
) {
  HalfScreenLayout(attrs = attrs) {
    PageHeader(title, explanation)

    Form(
        title = projectTitle,
        onTitleInput = onProjectTitleInput,
        titleLabel = projectTitleLabel,
        titlePlaceholder = projectTitlePlaceholder,
        name = name,
        onNameInput = onNameInput,
        nameLabel = nameLabel,
        namePlaceholder = namePlaceholder,
        phone = phone,
        onPhoneInput = onPhoneInput,
        phoneLabel = phoneLabel,
        phonePlaceholder = phonePlaceholder,
        postalCode = postalCode,
        onPostalCodeInput = onPostalCodeInput,
        postalCodeLabel = postalCodeLabel,
        postalCodePlaceholder = postalCodePlaceholder,
        locality = locality,
        onLocalityInput = onLocalityInput,
        localityLabel = localityLabel,
        localityPlaceholder = localityPlaceholder,
        street = street,
        onStreetInput = onStreetInput,
        streetLabel = streetLabel,
        streetPlaceholder = streetPlaceholder,
        createProjectButton = createProjectButton,
        onProjectCreate = onProjectCreate,
        loading = loading,
        errorMessage = errorMessage,
    )
  }
}
