package ui.screens.integration.bexio.synchronising

import androidx.compose.runtime.Composable
import org.jetbrains.compose.web.dom.AttrBuilderContext
import org.jetbrains.compose.web.dom.P
import org.jetbrains.compose.web.dom.Text
import org.w3c.dom.HTMLDivElement
import tailwind.Layout
import tailwind.tailwind
import ui.color.Primary200
import ui.material.FullRow
import ui.material.Spinner

@Composable
fun Loading(
    message: String,
    attrs: AttrBuilderContext<HTMLDivElement>? = null,
) {
  FullRow(
      justify = Layout.Justify.Start,
      alignItems = Layout.AlignItems.Center,
      gap = 6,
      attrs = attrs,
  ) {
    Spinner(size = 10, attrs = { tailwind { text(color = Primary200) } })

    P(
        attrs = { tailwind { text { subtitle2() } } },
    ) {
      Text(message)
    }
  }
}
