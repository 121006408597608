package ui.components.taskTable

import androidx.compose.runtime.Composable
import api.traak.Task
import org.jetbrains.compose.web.css.fr
import org.jetbrains.compose.web.dom.AttrBuilderContext
import org.jetbrains.compose.web.dom.Text
import org.w3c.dom.HTMLDivElement
import tailwind.tailwind
import ui.components.LoadingPlaceholder
import ui.components.cancellableSnackbar.CancellableSnackbar
import ui.components.table.Table
import ui.components.table.TableColumn
import ui.components.table.TableColumnHeader
import ui.components.table.TableOrdering
import ui.components.table.TableSearch
import ui.components.table.TableSummary
import ui.components.table.settingsColumn
import ui.strings.LocalStrings

@Composable
fun TaskTable(
    state: TaskTableState,
    attrs: AttrBuilderContext<HTMLDivElement>? = null,
) {
  val strings = LocalStrings.current

  val dateColumn =
      TableColumn<Task>(
          header =
              TableColumnHeader(
                  display = { Text(strings.projectDetailHeaderDate) },
                  isOrderable = true,
              ),
          width = 2.fr,
          display = { task, _ -> DisplayDate(task) },
      )

  val originColumn =
      TableColumn<Task>(
          header =
              TableColumnHeader(
                  display = {
                    val title =
                        when (state.mode) {
                          TaskTableMode.Project -> strings.projectDetailHeaderUser
                          TaskTableMode.Member -> strings.memberDetailHeaderProject
                        }
                    Text(title)
                  },
                  isOrderable = true,
              ),
          width = 3.fr,
          display = { task, _ ->
            when (state.mode) {
              TaskTableMode.Project ->
                  DisplayAuthor(
                      task.author,
                      firstNameFirst = state.firstNameIsFirst,
                  )
              TaskTableMode.Member -> task.project?.let { Text(it.title) }
            }
          },
      )

  val taskColumn =
      TableColumn<Task>(
          header = TableColumnHeader(display = { Text(strings.projectDetailHeaderTask) }),
          width = 5.fr,
          display = { task, _ -> Text(task.description) },
      )

  val durationColumn =
      TableColumn<Task>(
          header = TableColumnHeader(display = { Text(strings.projectDetailHeaderDuration) }),
          width = 2.fr,
          display = { task, _ -> DisplayDuration(task) },
      )

  val settingsColumn =
      settingsColumn<Task>(
          state = state,
          menuContents = { task ->
            ItemMenu(
                onEditClick = {
                  state.onMenuClose()
                  state.onItemEditClick(task)
                },
                onDeleteClick = {
                  state.onMenuClose()
                  state.delete(task)
                },
            )
          },
      )

  val columns: List<TableColumn<Task>> =
      listOf(dateColumn, originColumn, taskColumn, durationColumn, settingsColumn)

  val loadingColumns: List<TableColumn<Task>> =
      columns.map { tableColumn ->
        tableColumn.copy(display = { _, _ -> LoadingPlaceholder(attrs = { tailwind { w(20) } }) })
      }

  val search =
      TableSearch(
          searchInput = state.searchInput,
          onSearchInput = { state.onSearchInput(it) },
          placeholder =
              when (state.mode) {
                TaskTableMode.Project -> strings.projectDetailSearchPlaceholder
                TaskTableMode.Member -> strings.memberDetailSearchPlaceholder
              },
      )

  val ordering =
      TableOrdering(
          current = Pair(state.orderingByHeader, state.ordering),
          onHeaderClick = { state.onHeaderClick(it) },
      )

  val summary = TableSummary(display = { Summarize(state.filteredTasks) })

  Table(
      columns = if (state.loading) loadingColumns else columns,
      elements = state.filteredTasks,
      search = search,
      ordering = ordering,
      summary = summary,
      attrs = attrs,
  )

  if (state.cancelSnackbarIsVisible) {
    CancellableSnackbar(
        message = strings.taskItemDeletionCancellableSnackbar,
        buttonText = strings.cancel,
        progress = state.cancellableSnackbarProgression,
        onButtonClick = { state.onSnackbarCancelClick() },
    )
  }
}
