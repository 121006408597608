package ui.screens.tasks

import androidx.compose.runtime.*
import androidx.compose.runtime.snapshots.SnapshotStateList
import api.settings.LocalSettings
import api.settings.SettingsApi
import api.traak.Project
import api.traak.Task
import api.traak.Team
import api.traak.user.User
import kotlin.time.Duration
import kotlin.time.Duration.Companion.seconds
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.ExperimentalCoroutinesApi
import kotlinx.coroutines.flow.*
import kotlinx.coroutines.launch
import kotlinx.datetime.TimeZone
import kotlinx.datetime.toLocalDateTime
import navigation.settings
import ui.components.calendar.DayRange
import utils.Ordering

@OptIn(ExperimentalCoroutinesApi::class)
class TasksStateHolder(
    private val team: Team,
    private val dayRange: Flow<DayRange>,
    private val people: Flow<List<User.Id>>,
    private val projects: Flow<List<Project.Id>>,
    private val settings: SettingsApi,
    private val coroutineScope: CoroutineScope,
) : TasksState {
  private val _tasks: SnapshotStateList<Task> = mutableStateListOf()
  private var selectedAuthors: SnapshotStateList<User.Id> = mutableStateListOf()
  private var selectedProjects: SnapshotStateList<Project.Id> = mutableStateListOf()

  init {
    val teamTasks: Flow<List<Task>> =
        dayRange.flatMapLatest { (start, end) ->
          team.getTasks(
              startDate = start,
              endDate = end,
              orderBy = dateOrdering,
          )
        }

    coroutineScope.launch {
      teamTasks.collect {
        _tasks.clear()
        _tasks.addAll(it)
      }
    }

    // Note that we filter in the data in kotlin and not when with firestore
    // because firestore has limitations that would prevent us from allowing
    // the user to select the authors and projects
    // (see: https://firebase.google.com/docs/firestore/query-data/queries#limits_on_or_queries)

    coroutineScope.launch {
      people.collect {
        selectedAuthors.clear()
        selectedAuthors.addAll(it)
      }
    }

    coroutineScope.launch {
      projects.collect {
        selectedProjects.clear()
        selectedProjects.addAll(it)
      }
    }
  }

  override var search: String by mutableStateOf("")

  override var dateOrdering: Ordering by mutableStateOf(Ordering.ASC)

  private val displayedTasks
    get() =
        _tasks
            .filter { it.matches(search) }
            .filter { selectedAuthors.contains(it.author.id) }
            .filter {
              if (it.project != null) {
                selectedProjects.contains(it.project?.id)
              } else {
                true
              }
            }
            .sortedWith { a, b ->
              val natural = a.start.compareTo(b.start)
              val inverted = natural * (-1)

              when (dateOrdering) {
                Ordering.ASC -> natural
                Ordering.DESC -> inverted
              }
            }

  override val tasks: List<UiTask>
    get() = displayedTasks.map { it.toUiTask(settings) }

  override val totalDuration: Duration
    get() =
        displayedTasks
            .map { it.end.minus(it.start).plus(1.seconds) }
            .fold(Duration.ZERO) { total, duration -> total.plus(duration) }
}

private fun Task.matches(search: String): Boolean =
    listOf(
            description,
            author.firstName,
            author.lastName,
            project?.title ?: "",
        )
        .any { it.contains(search, ignoreCase = true) }

private fun Task.toUiTask(settings: SettingsApi): UiTask =
    UiTask(
        date = start.toLocalDateTime(TimeZone.currentSystemDefault()).date,
        author = author.toString(settings),
        description = description,
        project = project?.title ?: "",
        duration = end.minus(start).plus(1.seconds),
    )

private fun Task.Author.toString(settings: SettingsApi): String =
    if (settings.showFirstNameFirst.value) {
      "$firstName $lastName"
    } else {
      "$lastName $firstName"
    }

@Composable
fun rememberTasksState(
    team: Team,
    dayRange: Flow<DayRange>,
    people: Flow<List<User.Id>>,
    projects: Flow<List<Project.Id>>,
    settings: SettingsApi = LocalSettings.current,
    coroutineScope: CoroutineScope = rememberCoroutineScope(),
): TasksState {
  return remember {
    TasksStateHolder(
        team = team,
        dayRange = dayRange,
        people = people,
        projects = projects,
        settings = settings,
        coroutineScope = coroutineScope,
    )
  }
}
