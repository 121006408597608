package api.traak.fromFirestore

import api.traak.Task
import api.traak.Team
import api.traak.TraakTask
import api.traak.user.User
import firebase.firestore.DocumentData
import kotlinx.datetime.Instant
import kotlinx.serialization.*
import kotlinx.serialization.json.Json

@Serializable
data class FromFirestoreTask(
    val author: Author,
    val description: String,
    val team: String,
    val project: Project?,
    val start: Timestamp,
    val end: Timestamp,
    val id: String,
) {
  companion object {
    private val json = Json { ignoreUnknownKeys = true }

    fun withData(data: DocumentData): FromFirestoreTask =
        json.decodeFromString(JSON.stringify(data))
  }
}

@Serializable
data class Timestamp(
    val seconds: Long,
    val nanoseconds: Int,
) {
  fun toInstant(): Instant = Instant.fromEpochSeconds(seconds, nanoseconds)
}

@Serializable
data class Author(
    val id: String,
    val firstName: String,
    val lastName: String,
)

@Serializable
data class Project(
    val id: String,
    val title: String,
    val status: String,
)

fun FromFirestoreTask.toTask(): Task =
    TraakTask(
        author =
            object : Task.Author {
              override val id = User.Id(this@toTask.author.id)
              override val firstName = this@toTask.author.firstName
              override val lastName = this@toTask.author.lastName
            },
        project =
            this@toTask.project?.let {
              object : Task.Project {
                override val id = api.traak.Project.Id(it.id)
                override val title = it.title
                override val status = it.status.toProjectStatus()
              }
            },
        description = this@toTask.description,
        team = Team.Id(this@toTask.team),
        start = this@toTask.start.toInstant(),
        end = this@toTask.end.toInstant(),
        id = Task.Id(this@toTask.id),
    )
