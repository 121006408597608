package ui.components.navmenu

import androidx.compose.runtime.Composable
import org.jetbrains.compose.web.dom.AttrBuilderContext
import org.jetbrains.compose.web.dom.Div
import org.w3c.dom.HTMLDivElement
import tailwind.Auto
import tailwind.Full
import tailwind.TailwindScope
import tailwind.tailwind

@Composable
fun Indicator(
    attrs: AttrBuilderContext<HTMLDivElement>? = null,
) {
  Div(
      attrs = {
        tailwind {
          w(Full)
          h(Full)

          position(TailwindScope.Position.Absolute)
          top(0)
          left(0)
        }
      }) {
        Div(
            attrs = {
              attrs?.let { it() }
              tailwind {
                m(Auto)
                h(Full)
              }
            }) {}
      }
}
