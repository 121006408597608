package ui.data

import api.settings.SettingsApi
import api.traak.Task

fun Task.Author.toString(settings: SettingsApi): String =
    if (settings.showFirstNameFirst.value) {
      "$firstName $lastName"
    } else {
      "$lastName $firstName"
    }
