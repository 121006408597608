package api.traak

import firebase.auth.ErrorCode as AuthErrorCode
import firebase.auth.toErrorCode
import firebase.firestore.ErrorCode as StorageErrorCode
import firebase.firestore.OrderByDirection
import js.objects.Object
import kotlinx.serialization.encodeToString
import kotlinx.serialization.json.Json
import ui.strings.LocalizedStrings
import utils.Ordering

inline fun <reified T> T.asFirestoreObject(): Object = JSON.parse(Json.encodeToString(this))

fun AuthResult.toErrorMessage(strings: LocalizedStrings): String? =
    when (this) {
      AuthResult.Success -> null
      AuthResult.FailureInternal -> strings.authErrorFailureInternal
      AuthResult.FailureUserUnknown -> strings.authErrorFailureUserUnknown
      AuthResult.FailureUserAlreadyRegistered -> strings.authErrorFailureUserAlreadyRegistered
      AuthResult.FailurePasswordTooWeak -> strings.authErrorFailurePasswordTooWeak
      AuthResult.FailurePasswordWrong -> strings.authErrorFailurePasswordWrong
      AuthResult.FailureUnknown -> strings.authErrorFailureUnknown
      AuthResult.FailureInvalidEmail -> strings.authErrorFailureInvalidEmail
      AuthResult.FailureEmailAlreadyInUse -> strings.authErrorFailureEmailAlreadyInUse
      AuthResult.FailureInvalidPhoneNumber -> strings.authErrorFailureInvalidPhoneNumber
      AuthResult.FailureInvalidVerificationCode -> strings.authErrorFailureInvalidVerificationCode
    }

fun AuthErrorCode.toAuthResult() =
    when (this) {
      AuthErrorCode.INTERNAL_ERROR -> AuthResult.FailureInternal
      AuthErrorCode.USER_DELETED -> AuthResult.FailureUserUnknown
      AuthErrorCode.WEAK_PASSWORD -> AuthResult.FailurePasswordTooWeak
      AuthErrorCode.INVALID_PASSWORD -> AuthResult.FailurePasswordWrong
      AuthErrorCode.INVALID_EMAIL -> AuthResult.FailureInvalidEmail
      AuthErrorCode.EMAIL_EXISTS -> AuthResult.FailureEmailAlreadyInUse
      AuthErrorCode.INVALID_PHONE_NUMBER -> AuthResult.FailureInvalidPhoneNumber
      AuthErrorCode.INVALID_CODE -> AuthResult.FailureInvalidVerificationCode
      else -> AuthResult.FailureUnknown
    }

fun StorageErrorCode.toStorageResult() =
    when (this) {
      StorageErrorCode.PERMISSION_DENIED -> StorageResult.FailurePermissionDenied
      else -> StorageResult.FailureUnknown
    }

fun Throwable.toAuthResult(): AuthResult =
    this.toErrorCode()?.toAuthResult() ?: AuthResult.FailureUnknown

fun Throwable.toStorageResult(): StorageResult =
    firebase.firestore.ErrorCode.fromString(this.asDynamic().code as String)?.toStorageResult()
        ?: StorageResult.FailureUnknown

fun <A, B> transformOrWarn(transform: (A) -> B, data: A): B? =
    try {
      transform(data)
    } catch (e: Exception) {
      console.error(e.message)
      null
    }

fun Ordering.toFirestoreOrdering(): OrderByDirection =
    when (this) {
      Ordering.ASC -> OrderByDirection.ASC
      Ordering.DESC -> OrderByDirection.DESC
    }
