package ui.screens.login.settingUp

import androidx.compose.runtime.Composable
import api.traak.user.User
import org.jetbrains.compose.web.dom.AttrBuilderContext
import org.w3c.dom.HTMLDivElement
import tailwind.Full
import tailwind.Layout
import tailwind.TailwindScope
import tailwind.fourth
import tailwind.tailwind
import tailwind.twelfth
import ui.components.progressionPanel.FullProgressionPanel
import ui.icons.Traak
import ui.layouts.ThreeQuartersLayout
import ui.material.Column
import ui.material.FullColumn
import ui.material.FullRow
import ui.material.Row
import ui.material.Spacer
import ui.screens.login.settingUp.email.SetupEmail
import ui.screens.login.settingUp.name.SetupName
import ui.screens.login.settingUp.phone.SetupPhone
import ui.strings.LocalStrings

@Composable
fun SettingUp(
    user: User,
    attrs: AttrBuilderContext<HTMLDivElement>? = null,
) {
  val strings = LocalStrings.current
  val progressionState = rememberSettingUpProgressionState(user)

  ThreeQuartersLayout(
      attrs = attrs,
  ) {
    FullColumn(
        gap = 4,
        justify = Layout.Justify.Start,
        attrs = { tailwind { h(TailwindScope.Height.Screen) } },
    ) {
      Headline()

      // Content
      FullRow(
          alignItems = Layout.AlignItems.Start,
          gap = 12,
          attrs = {
            tailwind {
              pt(10)
              h(Full)
            }
          },
      ) {
        Column(
            justify = Layout.Justify.Start,
            alignItems = Layout.AlignItems.Center,
            gap = 0,
            attrs = { tailwind { w(4.twelfth) } },
        ) {
          FullProgressionPanel(
              title = strings.setupPanelTitle,
              state = progressionState,
          )
        }

        Spacer()

        Column(
            justify = Layout.Justify.Start,
            alignItems = Layout.AlignItems.Center,
            gap = 0,
            attrs = {
              tailwind {
                w(7.twelfth)
                h(Full)
              }
            },
        ) {
          when (progressionState.currentProgression) {
            SettingUpProgression.Name -> SetupName(user)
            SettingUpProgression.Email -> SetupEmail(user)
            SettingUpProgression.Phone -> SetupPhone(user)
          }
        }
      }
    }
  }
}

@Composable
private fun Headline() {
  Row(
      justify = Layout.Justify.Center,
      alignItems = Layout.AlignItems.Center,
      attrs = {
        tailwind {
          w(1.fourth)
          pt(8)
        }
      },
  ) {
    Traak(attrs = { tailwind { w(Full) } })
  }
}
