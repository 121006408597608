package ui.material

import androidx.compose.runtime.Composable
import org.jetbrains.compose.web.dom.AttrBuilderContext
import org.jetbrains.compose.web.dom.Div
import org.w3c.dom.HTMLDivElement
import tailwind.FlexItemScope
import tailwind.tailwind

@Composable
fun Spacer(attrs: AttrBuilderContext<HTMLDivElement>? = null) {
  Div(
      attrs = {
        attrs?.invoke(this)
        tailwind { flexItem(grow = FlexItemScope.Grow.Grow) }
      },
  ) {}
}
