package ui.screens.projectEdition

import androidx.compose.runtime.*
import api.traak.*
import api.traak.dto.UpdateProjectDTO
import app.softwork.routingcompose.Router
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.flow.collect
import kotlinx.coroutines.flow.take
import kotlinx.coroutines.launch
import kotlinx.coroutines.sync.Mutex
import kotlinx.coroutines.sync.withLock
import navigation.Route
import navigation.navigate
import ui.screens.newProject.Result

class ProjectEditionStateHolder(
    private val teamId: Team.Id,
    private val projectId: Project.Id,
    private val coroutineScope: CoroutineScope,
    private val api: TraakApi,
    private val router: Router,
) : ProjectEditionState {
  private val mutex = Mutex()
  private var _fetching by mutableStateOf(true)
  private var _saving by mutableStateOf(false)
  private var _result by mutableStateOf<Result?>(null)

  init {
    coroutineScope.launch {
      api.project(projectId).take(1).collect { project ->
        mutex.withLock {
          projectTitle = project.title
          name = project.address.name
          phone = project.address.phone
          locality = project.address.locality
          street = project.address.place

          _fetching = false
        }
      }
    }
  }

  override var projectTitle by mutableStateOf("")
  override var name by mutableStateOf("")
  override var phone by mutableStateOf("")
  override var locality by mutableStateOf("")
  override var street by mutableStateOf("")

  override val fetching: Boolean
    get() = _fetching

  override val saving: Boolean
    get() = _saving

  override val result: Result?
    get() = _result

  override fun saveProject() {
    if (_saving) return

    if (projectTitle.isBlank()) {
      _result = Result.LocalResult.EmptyTitle
      return
    }

    val project =
        UpdateProjectDTO(
            title = projectTitle,
            name = name,
            phone = phone,
            locality = locality,
            street = street,
        )

    coroutineScope.launch {
      mutex.withLock { _saving = true }

      val result =
          api.updateProject(
              teamId = teamId,
              projectId = projectId,
              project = project,
          )

      if (result == StorageResult.Success) {
        navigateToProjectDetail()
      } else {
        _result = Result.RemoteResult(result)
        mutex.withLock { _saving = false }
      }
    }
  }

  override fun cancel() {
    navigateToProjectDetail()
  }

  private fun navigateToProjectDetail(): Unit {
    router.navigate(Route.ProjectDetail(projectId))
  }
}

@Composable
fun rememberProjectEdition(
    teamId: Team.Id,
    projectId: Project.Id,
    coroutineScope: CoroutineScope = rememberCoroutineScope(),
    api: TraakApi = LocalTraakApi.current,
    router: Router = Router.current,
): ProjectEditionState =
    remember(projectId, coroutineScope, api) {
      ProjectEditionStateHolder(
          teamId = teamId,
          projectId = projectId,
          coroutineScope = coroutineScope,
          api = api,
          router = router,
      )
    }
