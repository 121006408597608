package ui.screens.teamManagement

import androidx.compose.runtime.Composable
import androidx.compose.runtime.remember
import api.settings.LocalSettings
import api.traak.Member
import api.traak.Role
import org.jetbrains.compose.web.css.fr
import org.jetbrains.compose.web.dom.AttrBuilderContext
import org.jetbrains.compose.web.dom.Text
import org.w3c.dom.HTMLDivElement
import tailwind.Full
import tailwind.tailwind
import ui.components.LoadingPlaceholder
import ui.components.table.Table
import ui.components.table.TableColumn
import ui.components.table.TableColumnHeader
import ui.components.table.TableSettingsState
import ui.components.table.settingsColumn
import ui.data.Loadable
import ui.data.toString
import ui.material.FullRow
import ui.material.Select
import ui.strings.LocalStrings

@Composable
fun MemberTable(
    loadableMembers: Loadable<List<Member>>,
    onRoleClick: (Member, Role) -> Unit,
    memberIsEditable: (Member) -> Boolean,
    onMemberEditClick: (Member) -> Unit,
    settingsState: TableSettingsState<Member>,
    attrs: AttrBuilderContext<HTMLDivElement>? = null,
) {
  val strings = LocalStrings.current
  val settings = LocalSettings.current

  val orderedRoles: List<Role> = remember {
    listOf(Role.ADMIN, Role.PROJECT_MANAGER, Role.MEMBER, Role.REMOVED)
  }

  val nameColumnHeader =
      remember(strings) {
        TableColumnHeader(
            display = { Text(strings.teamSettingsHeaderName) },
        )
      }

  val roleColumnHeader =
      remember(strings) {
        TableColumnHeader(
            display = { Text(strings.teamSettingsHeaderRole) },
        )
      }

  val nameColumn =
      TableColumn<Member>(
          header = nameColumnHeader,
          width = 1.fr,
          display = { member, _ ->
            FullRow(
                attrs = { tailwind { text { subtitle2() } } },
            ) {
              Text(member.toString(settings))
            }
          })

  val roleColumn =
      TableColumn<Member>(
          header = roleColumnHeader,
          width = 2.fr,
          display = { member, _ ->
            FullRow {
              Select(
                  current = member.role,
                  items = orderedRoles,
                  onItemClick = { role -> onRoleClick(member, role) },
                  displayItem = { it.toLocalizedString(strings) },
                  disabled = !memberIsEditable(member),
              )
            }
          })

  val settingsColumn =
      settingsColumn(
          state = settingsState,
          menuContents = { member ->
            MemberMenu(
                onEditClick = { onMemberEditClick(member) },
            )
          },
      )

  val columns: List<TableColumn<Member>> = listOf(nameColumn, roleColumn, settingsColumn)
  val loadingColumns: List<TableColumn<Nothing?>> =
      columns.map { tableColumn ->
        TableColumn(
            header = tableColumn.header,
            width = tableColumn.width,
            display = { _, _ ->
              LoadingPlaceholder(
                  attrs = {
                    tailwind {
                      w(Full)
                      px(4)
                    }
                  })
            },
        )
      }

  when (loadableMembers) {
    is Loadable.Loaded ->
        Table(
            columns = columns,
            elements = loadableMembers.resource,
            attrs = attrs,
        )
    Loadable.Loading ->
        Table(
            columns = loadingColumns,
            elements = List(4) { null },
            attrs = attrs,
        )
  }
}
