package firebase.auth

import firebase.firestore.Unsubscribe
import kotlinx.coroutines.channels.BufferOverflow
import kotlinx.coroutines.channels.awaitClose
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.buffer
import kotlinx.coroutines.flow.callbackFlow

fun onAuthStateChanged(
    auth: Auth,
    next: (User?) -> Unit,
    error: (() -> Unit)?,
    completed: (() -> Unit)?,
): Unsubscribe =
    onAuthStateChanged(
        auth = auth,
        nextOrObserver = next,
        error = error,
        completed = completed,
    )

fun currentUser(auth: Auth): Flow<User?> =
    callbackFlow {
          val unsubscribe =
              onAuthStateChanged(
                  auth = auth,
                  next = { user -> trySend(user) },
                  error = { close() },
                  completed = { close() },
              )
          awaitClose { unsubscribe() }
        }
        .buffer(
            capacity = 1,
            onBufferOverflow = BufferOverflow.DROP_OLDEST,
        )

val InvisibleCaptcha: RecaptchaParameters =
    object : RecaptchaParameters {
      override val size = "invisible"
    }
