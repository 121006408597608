package ui.screens.memberEdition

import androidx.compose.runtime.*
import api.traak.LocalTraakApi
import api.traak.Team
import api.traak.TraakApi
import api.traak.toFirestore.ToFirestoreProfile
import api.traak.user.User
import app.softwork.routingcompose.Router
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.launch
import navigation.back

class MemberEditionStateHolder(
    private val team: Team,
    private val memberId: User.Id,
    private val api: TraakApi,
    private val coroutineScope: CoroutineScope,
) : MemberEditionState {
  private var _fetchingData by mutableStateOf(true)
  private var _savingData by mutableStateOf(false)

  private var initialFirstName = ""
  private var initialLastName = ""

  private var _firstName by mutableStateOf("")
  private var _lastName by mutableStateOf("")

  init {
    coroutineScope.launch {
      val member =
          api.member(
              team = team,
              userId = memberId,
          )

      initialFirstName = member.firstName
      initialLastName = member.lastName

      _firstName = member.firstName
      _lastName = member.lastName

      _fetchingData = false
    }
  }

  override val fetchingData: Boolean
    get() = _fetchingData

  override val savingData: Boolean
    get() = _savingData

  override var firstName: String
    get() = _firstName
    set(value) {
      if (_fetchingData) return
      _firstName = value
    }

  override var lastName: String
    get() = _lastName
    set(value) {
      if (_fetchingData) return
      _lastName = value
    }

  override fun save() {
    if (_fetchingData || _savingData) return

    _savingData = true

    coroutineScope.launch {
      val profile =
          ToFirestoreProfile(
              name = "$_firstName $_lastName",
              firstName = _firstName,
              lastName = _lastName,
          )

      api.updateProfile(
          teamId = team.id,
          memberId = memberId,
          profile = profile,
      )

      _savingData = false

      back()
    }
  }

  override fun reset() {
    _firstName = initialFirstName
    _lastName = initialLastName
  }

  override fun back() {
    Router.back()
  }
}

@Composable
fun rememberMemberEditionState(
    team: Team,
    memberId: User.Id,
    api: TraakApi = LocalTraakApi.current,
    coroutineScope: CoroutineScope = rememberCoroutineScope(),
): MemberEditionStateHolder {
  return remember(
      team,
      memberId,
      api,
      coroutineScope,
  ) {
    MemberEditionStateHolder(
        team = team,
        memberId = memberId,
        api = api,
        coroutineScope = coroutineScope,
    )
  }
}
