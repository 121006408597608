package ui.screens.login.settingUp.phone

import androidx.compose.runtime.Composable
import api.traak.user.User
import org.jetbrains.compose.web.attributes.AutoComplete
import org.jetbrains.compose.web.dom.AttrBuilderContext
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Text
import org.w3c.dom.HTMLDivElement
import tailwind.Full
import tailwind.tailwind
import ui.material.FilledButton
import ui.material.FullColumn
import ui.material.TextInput
import ui.material.Type
import ui.screens.login.settingUp.ErrorMessage
import ui.screens.login.settingUp.Explanation
import ui.strings.LocalStrings

@Composable
fun SetupPhone(
    user: User,
    attrs: AttrBuilderContext<HTMLDivElement>? = null,
) {
  val strings = LocalStrings.current
  val state = rememberSetupPhoneState(user)

  val explanation =
      when (state.currentState) {
        SetupPhoneProgression.RetrievingPhoneNumber -> strings.setupPhoneExplanationRetrieving
        SetupPhoneProgression.ValidatingPhoneNumber -> strings.setupPhoneExplanationValidating
      }

  FullColumn(
      gap = 8,
      attrs = attrs,
  ) {
    Explanation(strings.setupPhoneTitle) { Text(explanation) }

    when (state.currentState) {
      SetupPhoneProgression.RetrievingPhoneNumber ->
          IdentifierForm(
              phoneNumber = state.phoneNumber,
              onPhoneNumberInput = { state.phoneNumber = it },
              onContinueClick = { state.sendSms() },
              loading = state.loading,
              errorMsg = state.errorMsg,
              continueButtonId = state.loginButtonId,
          )
      SetupPhoneProgression.ValidatingPhoneNumber ->
          ValidationForm(
              smsCode = state.smsCode,
              onSmsCodeInput = { state.smsCode = it },
              onVerifyClick = { state.validateSmsCode() },
              loading = state.loading,
              errorMsg = state.errorMsg,
          )
    }
  }
}

@Composable
private fun IdentifierForm(
    phoneNumber: String,
    onPhoneNumberInput: (String) -> Unit,
    onContinueClick: () -> Unit,
    loading: Boolean,
    errorMsg: String?,
    continueButtonId: String,
    attrs: AttrBuilderContext<HTMLDivElement>? = null,
) {
  val strings = LocalStrings.current
  val hasError = errorMsg != null

  FullColumn(
      gap = 4,
      attrs = attrs,
  ) {
    TextInput(
        value = phoneNumber,
        onInput = onPhoneNumberInput,
        label = strings.setupPhoneIdentifierFormPhoneLabel,
        placeholder = strings.setupPhoneIdentifierFormPhonePlaceholder,
        autoComplete = AutoComplete.tel,
        attrs = { tailwind { w(Full) } },
    )

    // We have an additional Div here because of ReCaptcha. Recaptcha will add
    // a new element in the dom and screw up the layout. Adding a Div will make
    // the new element appear as a child of the Div and thus preserve the
    // layout.
    Div(
        attrs = { tailwind { w(Full) } },
    ) {
      FilledButton(
          text = strings.setupPhoneIdentifierFormContinueButton,
          onClick = onContinueClick,
          loading = loading,
          disabled = hasError,
          attrs = {
            id(continueButtonId)
            tailwind { w(Full) }
          },
      )
    }

    errorMsg?.let { ErrorMessage(errorMsg) }
  }
}

@Composable
private fun ValidationForm(
    smsCode: String,
    onSmsCodeInput: (String) -> Unit,
    onVerifyClick: () -> Unit,
    loading: Boolean,
    errorMsg: String?,
    attrs: AttrBuilderContext<HTMLDivElement>? = null,
) {
  val strings = LocalStrings.current

  FullColumn(
      gap = 4,
      attrs = attrs,
  ) {
    TextInput(
        value = smsCode,
        onInput = onSmsCodeInput,
        placeholder = strings.setupPhoneValidationFormValidationCodePlaceholder,
        label = strings.setupPhoneValidationFormValidationCodeLabel,
        attrs = { tailwind { w(Full) } },
    )

    FilledButton(
        text = strings.setupPhoneValidationFormVerifyButton,
        onClick = onVerifyClick,
        type = Type.Normal,
        loading = loading,
        attrs = { tailwind { w(Full) } },
    )

    errorMsg?.let { ErrorMessage(errorMsg) }
  }
}
