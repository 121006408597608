package api.traak.authManager.email

import api.traak.AuthResult
import api.traak.toAuthResult
import firebase.auth.ActionCodeSettings
import firebase.auth.Auth
import firebase.auth.isSignInWithEmailLink
import firebase.auth.sendSignInLinkToEmail
import firebase.auth.signInWithEmailLink
import kotlinx.browser.window

class TraakEmailAuthenticator(
    private val auth: Auth,
    private val baseUrl: String,
) : EmailAuthenticator {
  override suspend fun sendLinkToEmail(email: String): AuthResult {
    val settings =
        object : ActionCodeSettings {
          override val url = "http://localhost:8080/${navigation.signInWithEmailLink}"
          override val handleCodeInApp = true
        }

    window.localStorage.setItem(LOCAL_STORAGE_EMAIL_KEY, email)

    return try {
      sendSignInLinkToEmail(auth, email, settings)
      AuthResult.Success
    } catch (t: Throwable) {
      console.error(t)
      t.toAuthResult()
    }
  }

  override suspend fun validateLink(link: String): AuthResult {
    val email = window.localStorage.getItem(LOCAL_STORAGE_EMAIL_KEY)

    if (email == null) {
      console.error("TraakEmailAuthenticator: email value not set before link validation.")
      return AuthResult.FailureInternal
    }

    if (!isSignInWithEmailLink(auth, link)) {
      console.error("TraakEmailAuthenticator: link '${link}' does not match required format.")
      return AuthResult.FailureInternal
    }

    return try {
      signInWithEmailLink(auth, email, link)
      AuthResult.Success
    } catch (t: Throwable) {
      console.error(t)
      t.toAuthResult()
    }
  }

  companion object {
    private const val LOCAL_STORAGE_EMAIL_KEY = "ch.traak.email.authenticator.email"
  }
}
