package ui.screens.projectOverview

import androidx.compose.runtime.Composable
import androidx.compose.runtime.remember
import api.traak.Project
import api.traak.Team
import org.jetbrains.compose.web.dom.AttrBuilderContext
import org.w3c.dom.HTMLDivElement
import tailwind.Full
import tailwind.tailwind
import ui.data.Loadable
import ui.layouts.HalfScreenLayout
import ui.material.SearchInput
import ui.material.SegmentedButton
import ui.material.SegmentedButtonItem
import ui.strings.LocalStrings
import ui.strings.LocalizedStrings

@Composable
fun ProjectOverview(
    team: Team,
    attrs: AttrBuilderContext<HTMLDivElement>? = null,
) {
  val strings = LocalStrings.current
  val state = rememberProjectOverviewState(team = team)

  val segmentedItems =
      remember(state.activeStatus, strings) {
        Project.Status.entries.map { it.toSegmentedButton(strings, state) }
      }

  val noProjectsMessage =
      when (state.activeStatus) {
        Project.Status.Active -> strings.projectOverviewNoActiveProjects
        Project.Status.Archived -> strings.projectOverviewNoArchivedProjects
      }

  ProjectOverview(
      projects = state.projects,
      title = strings.projectOverviewTitle,
      onProjectClick = { state.onProjectClick(it) },
      newProjectButton = strings.projectOverviewNewProjectButton,
      integratedWithBexio = team.integration.bexio,
      onNewProjectClick = { state.onNewProjectClick() },
      onRefreshClick = { state.onRefreshClick() },
      search = state.search,
      onSearchInput = { state.search = it },
      searchBarPlaceholder = strings.projectOverviewSearchBarPlaceholder,
      noProjects = noProjectsMessage,
      noSearchedProjects = strings.projectOverviewNoSearchedProjects,
      refreshingBexio = state.refreshState == RefreshState.REFRESHING,
      synchronizeBexioTooltipHeader = strings.projectOverviewSynchronizeBexioTooltipHeader,
      synchronizeBexioTooltipParagraph = strings.projectOverviewSynchronizeBexioTooltipParagraph,
      segmentedItems = segmentedItems,
      attrs = attrs,
  )
}

@Composable
fun ProjectOverview(
    projects: Loadable<FilteredProjects>,
    title: String,
    onProjectClick: (Project) -> Unit,
    newProjectButton: String,
    onNewProjectClick: () -> Unit,
    onRefreshClick: () -> Unit,
    integratedWithBexio: Boolean,
    search: String,
    onSearchInput: (String) -> Unit,
    searchBarPlaceholder: String,
    noProjects: String,
    noSearchedProjects: String,
    refreshingBexio: Boolean,
    synchronizeBexioTooltipHeader: String,
    synchronizeBexioTooltipParagraph: String,
    segmentedItems: List<SegmentedButtonItem>,
    attrs: AttrBuilderContext<HTMLDivElement>? = null,
) {
  HalfScreenLayout(attrs = attrs) {
    TopBar(
        title = title,
        newProjectButton = newProjectButton,
        onNewProjectClick = onNewProjectClick,
        onRefreshClick = onRefreshClick,
        integratedWithBexio = integratedWithBexio,
        synchronizeBexioTooltipHeader = synchronizeBexioTooltipHeader,
        synchronizeBexioTooltipParagraph = synchronizeBexioTooltipParagraph,
        refreshingBexio = refreshingBexio,
    )

    SearchInput(
        value = search,
        onInput = onSearchInput,
        placeholder = searchBarPlaceholder,
        attrs = { tailwind { mt(6) } },
    )

    SegmentedButton(
        items = segmentedItems,
        attrs = { tailwind { mt(4) } },
    )

    val sharedAttrs: AttrBuilderContext<HTMLDivElement> = {
      tailwind {
        mt(5)
        w(Full)
      }
    }

    when (projects) {
      is Loadable.Loading -> Loading(sharedAttrs)
      is Loadable.Loaded -> {
        if (projects.resource.noProjects) {
          NoListMessage(noProjects)
        } else if (projects.resource.filtered.isEmpty()) {
          NoListMessage(noSearchedProjects)
        } else {
          ProjectList(
              projects = projects.resource.filtered,
              onProjectClick = onProjectClick,
              attrs = sharedAttrs,
          )
        }
      }
    }
  }
}

private fun Project.Status.toLocalizedString(strings: LocalizedStrings): String {
  return when (this) {
    Project.Status.Active -> strings.projectStatusActive
    Project.Status.Archived -> strings.projectStatusArchived
  }
}

private fun Project.Status.toSegmentedButton(
    strings: LocalizedStrings,
    state: ProjectOverviewState,
): SegmentedButtonItem {
  return SegmentedButtonItem(
      text = this.toLocalizedString(strings),
      onClick = { state.onStatusClick(this) },
      active = this == state.activeStatus,
  )
}
