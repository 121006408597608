package ui.components.languageFooter

import androidx.compose.runtime.Composable
import org.jetbrains.compose.web.dom.AttrBuilderContext
import org.jetbrains.compose.web.dom.Text
import org.w3c.dom.HTMLDivElement
import tailwind.tailwind
import ui.color.BlackMedium
import ui.material.PopUpDetail

@Composable
fun LanguageItem(
    text: String,
    flag: @Composable () -> Unit,
    onClick: () -> Unit,
    attrs: AttrBuilderContext<HTMLDivElement>? = null,
) {
  PopUpDetail(
      onClick = onClick,
      attrs = {
        attrs?.invoke(this)
        tailwind { text(color = BlackMedium) }
      },
  ) {
    flag()
    Text(text)
  }
}
