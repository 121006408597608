package firebase.firestore

import kotlinx.coroutines.channels.Channel
import kotlinx.coroutines.channels.awaitClose
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.buffer
import kotlinx.coroutines.flow.callbackFlow

fun <T : DocumentData> DocumentReference<T>.asFlow(): Flow<T> {
  return callbackFlow {
        val observer =
            Observer<DocumentSnapshot<T>>().apply {
              next = { trySend(it.getWithId()) }
              error = { close(IllegalStateException()) }
              complete = { close() }
            }
        val handle = onSnapshot(this@asFlow, observer)
        awaitClose { handle() }
      }
      .buffer(Channel.UNLIMITED)
}

fun <T : DocumentData> Query<T>.asFlow(): Flow<List<T>> {
  return callbackFlow {
        val observer =
            Observer<QuerySnapshot<T>>().apply {
              next = { snapshot ->
                val list = mutableListOf<T>()
                snapshot.docs.forEach { list.add(it.getWithId()) }
                trySend(list.toList())
              }
              error = {
                console.error(it)
                close()
              }
              complete = { close() }
            }
        val handle = onSnapshot(this@asFlow, observer)
        awaitClose { handle() }
      }
      .buffer(Channel.UNLIMITED)
}
