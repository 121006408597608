package ui.components.chips.dropdown

import androidx.compose.runtime.Composable
import org.jetbrains.compose.web.css.color
import org.jetbrains.compose.web.css.left
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.css.right
import org.jetbrains.compose.web.dom.AttrBuilderContext
import org.jetbrains.compose.web.dom.Div
import org.w3c.dom.HTMLDivElement
import tailwind.TailwindScope
import tailwind.TailwindScope.Position
import tailwind.TailwindScope.Radius
import tailwind.TailwindScope.Shadow
import tailwind.color.Transparent
import tailwind.tailwind
import ui.components.chips.Chip
import ui.helpers.OnEscDown
import ui.helpers.OnOutsideClick
import ui.icons.ChevronDown

enum class DropdownAlignment {
  None,

  /** The [Right] side of the dropdown will be aligned with the right side of the chip */
  Right,

  /** The [Left] side of the dropdown will be aligned with the left side of the chip */
  Left,
}

@Composable
fun DropdownChip(
    text: String,
    attrs: AttrBuilderContext<HTMLDivElement>? = null,
    leadingContent: (@Composable () -> Unit)? = null,
    dropdownAlignment: DropdownAlignment = DropdownAlignment.None,
    dropdown: @Composable (attrs: AttrBuilderContext<HTMLDivElement>?) -> Unit,
) {
  val state = rememberDropdownChipState()

  DropdownChip(
      state = state,
      text = text,
      attrs = attrs,
      leadingContent = leadingContent,
      dropdownAlignment = dropdownAlignment,
      dropdown = dropdown,
  )
}

@Composable
fun DropdownChip(
    state: DropdownChipState,
    text: String,
    attrs: AttrBuilderContext<HTMLDivElement>? = null,
    leadingContent: (@Composable () -> Unit)? = null,
    dropdownAlignment: DropdownAlignment = DropdownAlignment.None,
    dropdown: @Composable (attrs: AttrBuilderContext<HTMLDivElement>?) -> Unit,
) {
  OnOutsideClick { state.isOpen = false }
  OnEscDown { state.isOpen = false }

  DropdownChip(
      text = text,
      isOpen = state.isOpen,
      onClick = { state.isOpen = !state.isOpen },
      attrs = {
        attrs?.invoke(this)
        onClick { it.stopPropagation() }
      },
      leadingContent = leadingContent,
      dropdownAlignment = dropdownAlignment,
      dropdown = dropdown,
  )
}

@Composable
private fun DropdownChip(
    text: String,
    isOpen: Boolean,
    onClick: (() -> Unit)? = null,
    attrs: AttrBuilderContext<HTMLDivElement>? = null,
    leadingContent: (@Composable () -> Unit)? = null,
    dropdownAlignment: DropdownAlignment = DropdownAlignment.None,
    dropdown: @Composable (attrs: AttrBuilderContext<HTMLDivElement>?) -> Unit,
) {
  Div(
      attrs = {
        attrs?.invoke(this)

        tailwind {
          position(Position.Relative)
          background(color = Transparent)
          overflow(TailwindScope.OverflowType.Visible)
        }
      },
  ) {
    Chip(
        text = text,
        onClick = onClick,
        leadingContent = leadingContent,
        trailingContent = { ChevronDown() },
    )

    if (isOpen) {
      dropdown {
        style {
          if (dropdownAlignment == DropdownAlignment.Left) {
            left(0.px)
          } else if (dropdownAlignment == DropdownAlignment.Right) {
            right(0.px)
          }
        }

        tailwind {
          position(Position.Absolute)
          round(Radius.Large)

          top(14)
          shadow(Shadow.Md)
        }
      }
    }
  }
}
