package ui.components.taskCreator

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import api.settings.LocalSettings
import api.traak.Task
import kotlinx.coroutines.delay
import org.jetbrains.compose.web.dom.AttrBuilderContext
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Text
import org.w3c.dom.HTMLDivElement
import tailwind.FlexItemScope
import tailwind.Full
import tailwind.Layout
import tailwind.TailwindScope
import tailwind.TextScope
import tailwind.TransitionScope
import tailwind.color.Opacity000
import tailwind.color.Opacity100
import tailwind.color.White
import tailwind.tailwind
import ui.color.Primary200
import ui.color.Secondary500
import ui.data.toString
import ui.icons.Check
import ui.material.ClosableCard
import ui.material.FilledButton
import ui.material.FullColumn
import ui.material.FullRow
import ui.material.OutlineButton
import ui.material.Select
import ui.material.SmallDateInput
import ui.material.SmallTextInput
import ui.material.SmallTimeInput
import ui.strings.LocalStrings

@Composable
fun TaskCreationCard(
    onCloseClick: () -> Unit,
    currentProject: Task.Project?,
    projects: List<Task.Project>,
    onProjectClick: (Task.Project) -> Unit,
    selectedMember: Task.Author?,
    members: List<Task.Author>,
    onMemberClick: (Task.Author) -> Unit,
    description: String,
    onDescriptionChange: (String) -> Unit,
    date: String,
    onDateChange: (String) -> Unit,
    duration: String,
    onDurationChange: (String) -> Unit,
    isLoading: Boolean,
    isValid: Boolean,
    isSuccess: Boolean,
    onSaveClick: () -> Unit,
    onResetClick: () -> Unit,
    attrs: AttrBuilderContext<HTMLDivElement>? = null,
) {
  val strings = LocalStrings.current
  val settings = LocalSettings.current

  val visible = remember { mutableStateOf(false) }
  LaunchedEffect(null) {
    delay(1)
    visible.value = true
  }

  ClosableCard(
      onCloseClick = onCloseClick,
      title = strings.taskCreatorTitle,
      attrs = {
        attrs?.invoke(this)

        tailwind {
          w(96)
          z(5)

          p(4)

          background(White)
          if (visible.value) opacity(Opacity100) else opacity(Opacity000)

          transition(
              property = TransitionScope.Property.Opacity,
              duration = TransitionScope.Duration.Duration0200,
          )
        }
      },
  ) {
    FullColumn(
        justify = Layout.Justify.Start,
        alignItems = Layout.AlignItems.Start,
        gap = 6,
    ) {
      val childAttr: TailwindScope.() -> Unit = {
        border(color = Primary200, width = 1)
        flexItem(align = FlexItemScope.Align.End)
      }

      Div(attrs = { tailwind { mt(4) } }) {}
      FullRow {
        FullColumn(
            justify = Layout.Justify.Start,
            alignItems = Layout.AlignItems.Start,
            gap = 2,
        ) {
          FormLabel(strings.description)
          SmallTextInput(
              value = description,
              onInput = onDescriptionChange,
              placeholder =
                  strings.description.replaceFirstChar {
                    if (it.isLowerCase()) it.titlecase() else it.toString()
                  },
              attrs = { tailwind { w(Full) } },
          )
        }
      }

      FullRow {
        FormLabel(strings.memberDetailHeaderProject)
        Select(
            current = currentProject,
            items = projects,
            onItemClick = onProjectClick,
            displayItem = { it.title },
            attrs = { tailwind(childAttr) },
        )
      }

      FullRow {
        FormLabel(strings.projectDetailHeaderUser)
        Select(
            current = selectedMember,
            items = members,
            onItemClick = onMemberClick,
            displayItem = { it.toString(settings) },
            attrs = { tailwind(childAttr) },
        )
      }

      FullRow {
        FormLabel(strings.projectDetailHeaderDate)
        SmallDateInput(
            value = date,
            onInput = onDateChange,
            attrs = { tailwind(childAttr) },
        )
      }

      FullRow {
        FormLabel(strings.projectDetailHeaderDuration)
        SmallTimeInput(
            value = duration,
            onInput = onDurationChange,
            attrs = { tailwind(childAttr) },
        )
      }

      FullRow(attrs = { tailwind { mt(10) } }) {
        FullColumn(gap = 2) {
          val buttonAttrs: TailwindScope.() -> Unit = { w(Full) }

          if (isSuccess) {
            FilledButton(
                text = strings.success,
                onClick = {},
                icon = { Check() },
                attrs = { tailwind(buttonAttrs) },
            )
          } else {
            FilledButton(
                text = strings.save,
                onClick = onSaveClick,
                disabled = !isValid,
                loading = isLoading,
                attrs = { tailwind(buttonAttrs) },
            )
          }
          OutlineButton(
              text = strings.reset,
              onClick = onResetClick,
              attrs = { tailwind(buttonAttrs) },
          )
        }
      }
    }
  }
}

@Composable
private fun FormLabel(
    text: String,
    attrs: AttrBuilderContext<HTMLDivElement>? = null,
) {
  Div(
      attrs = {
        attrs?.invoke(this)
        tailwind {
          flexItem(FlexItemScope.Grow.Grow)
          mr(2)
          text(color = Secondary500) {
            overline(
                weight = TextScope.Weight.Medium,
                size = TextScope.Size.Xs,
            )
          }
        }
      },
  ) {
    Text(text)
  }
}
