package ui.components.navigationBar

import androidx.compose.runtime.Composable
import org.jetbrains.compose.web.dom.AttrBuilderContext
import org.jetbrains.compose.web.dom.H2
import org.jetbrains.compose.web.dom.Text
import org.w3c.dom.HTMLDivElement
import tailwind.FlexItemScope
import tailwind.FlexScope
import tailwind.Full
import tailwind.Layout
import tailwind.TailwindScope
import tailwind.tailwind
import ui.color.BlackMedium
import ui.icons.ArrowLeft
import ui.material.FullRow
import ui.material.Row

@Composable
fun NavigationBar(
    onBackArrowClick: () -> Unit,
    attrs: AttrBuilderContext<HTMLDivElement>? = null,
    title: @Composable () -> Unit,
    content: (@Composable () -> Unit)? = null,
) {
  FullRow(
      attrs = {
        attrs?.invoke(this)
        tailwind {
          w(Full)
          py(4)
        }
      },
  ) {
    Row(attrs = { tailwind { flexItem(FlexItemScope.Grow.Fixed) } }) {
      ArrowLeft(
          attrs = {
            onClick { onBackArrowClick() }
            tailwind {
              mr(10)
              text(color = BlackMedium)
              cursor(TailwindScope.Cursor.Pointer)
            }
          },
      )
      title()
    }

    content?.let {
      Row(
          attrs = {
            tailwind {
              flexItem(FlexItemScope.Grow.Grow)

              flex(
                  direction = FlexScope.Direction.Row,
                  justify = Layout.Justify.End,
                  alignItems = Layout.AlignItems.Center,
                  wrap = FlexScope.Wrap.Normal,
              )
            }
          },
      ) {
        content()
      }
    }
  }
}

/** [NavigationBar] with provided style for the title */
@Composable
fun NavigationBar(
    onBackArrowClick: () -> Unit,
    title: String,
    attrs: AttrBuilderContext<HTMLDivElement>? = null,
    content: (@Composable () -> Unit)? = null,
) {
  NavigationBar(
      onBackArrowClick = onBackArrowClick,
      title = {
        H2(
            attrs = { tailwind { text { headline6() } } },
        ) {
          Text(title)
        }
      },
      attrs = attrs,
      content = content,
  )
}
