package ui.components.taskTable

import androidx.compose.runtime.Composable
import api.traak.Task
import kotlinx.datetime.DayOfWeek
import kotlinx.datetime.LocalDate
import kotlinx.datetime.TimeZone
import kotlinx.datetime.toLocalDateTime
import org.jetbrains.compose.web.dom.Span
import org.jetbrains.compose.web.dom.Text
import tailwind.tailwind
import ui.strings.LocalStrings
import ui.strings.LocalizedStrings
import utils.padWith0

@Composable
fun DisplayDate(task: Task) {
  val date = task.start.toLocalDateTime(TimeZone.currentSystemDefault()).date

  DisplayDate(date)
}

@Composable
fun DisplayDate(date: LocalDate) {
  val strings = LocalStrings.current

  val dayOfWeek = date.dayOfWeek.toLocalizedString(strings)
  val dayOfMonth = date.dayOfMonth.padWith0()
  val month = (date.month.ordinal + 1).padWith0()

  Span(attrs = { tailwind { text { numbers() } } }) {
    Text("${dayOfWeek}, ${dayOfMonth}/${month}/${date.year}")
  }
}

/** Transforms a [DayOfWeek] to a localized string. */
fun DayOfWeek.toLocalizedString(strings: LocalizedStrings): String =
    when (this) {
      DayOfWeek.MONDAY -> strings.mondayAbbr
      DayOfWeek.TUESDAY -> strings.tuesdayAbbr
      DayOfWeek.WEDNESDAY -> strings.wednesdayAbbr
      DayOfWeek.THURSDAY -> strings.thursdayAbbr
      DayOfWeek.FRIDAY -> strings.fridayAbbr
      DayOfWeek.SATURDAY -> strings.saturdayAbbr
      DayOfWeek.SUNDAY -> strings.sundayAbbr
    }
