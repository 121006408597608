package ui.components.table

import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.setValue
import org.jetbrains.compose.web.css.cssRem
import org.jetbrains.compose.web.dom.AttrBuilderContext
import org.w3c.dom.HTMLDivElement
import tailwind.FlexScope
import tailwind.Layout
import tailwind.TailwindScope
import tailwind.tailwind
import ui.color.BlackDark
import ui.color.BlackLight
import ui.color.Primary200
import ui.helpers.OnEscDown
import ui.helpers.OnOutsideClick
import ui.icons.DotsVertical
import ui.material.Row

interface TableSettingsState<E> {
  fun menuIsActiveForItem(item: E): Boolean

  fun onMenuClick(item: E)

  fun onMenuClose()
}

class TableSettingsDefaultState<E> : TableSettingsState<E> {
  private var _menuIsActiveFor by mutableStateOf<E?>(null)

  override fun menuIsActiveForItem(item: E): Boolean = _menuIsActiveFor == item

  override fun onMenuClick(item: E) {
    _menuIsActiveFor = item
  }

  override fun onMenuClose() {
    _menuIsActiveFor = null
  }
}

fun <E> settingsColumn(
    state: TableSettingsState<E>,
    menuContents: (@Composable (E) -> Unit),
): TableColumn<E> =
    TableColumn(
        header = TableColumnHeader(display = {}),
        width = 3.cssRem,
        display = { item, rowState ->
          Settings(
              rowState = rowState,
              menuIsActive = state.menuIsActiveForItem(item),
              onMenuClick = { state.onMenuClick(item) },
              onMenuClose = { state.onMenuClose() },
              menuContents = { menuContents(item) },
          )
        },
    )

@Composable
private fun Settings(
    rowState: RowState,
    menuIsActive: Boolean,
    onMenuClick: () -> Unit,
    onMenuClose: () -> Unit,
    attrs: AttrBuilderContext<HTMLDivElement>? = null,
    menuContents: (@Composable () -> Unit),
) {
  if (menuIsActive) {
    OnOutsideClick { onMenuClose() }
    OnEscDown { onMenuClose() }
  }

  Row(
      justify = Layout.Justify.Center,
      alignItems = Layout.AlignItems.Center,
      attrs = {
        attrs?.invoke(this)

        tailwind {
          w(9)
          h(9)

          round(TailwindScope.Radius.Full)
          cursor(TailwindScope.Cursor.Pointer)
          hover { background(color = Primary200) }
        }
      },
  ) {
    DotsVertical(
        attrs = {
          onClick {
            it.stopImmediatePropagation()
            onMenuClick()
          }

          tailwind {
            w(9)
            h(9)

            flex(
                direction = FlexScope.Direction.Row,
                justify = Layout.Justify.Center,
                alignItems = Layout.AlignItems.Center,
            )

            tailwind {
              if (rowState.isHovered) {
                text(color = BlackDark)
              } else {
                text(color = BlackLight)
              }
            }
          }
        },
    )

    if (menuIsActive) {
      menuContents()
    }
  }
}
