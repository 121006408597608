package ui.screens.newProject

import androidx.compose.runtime.*
import api.traak.LocalTraakApi
import api.traak.Team
import api.traak.TraakApi
import api.traak.dto.CreateProjectDTO
import app.softwork.routingcompose.Router
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.launch
import kotlinx.coroutines.sync.Mutex
import kotlinx.coroutines.sync.withLock
import navigation.Route
import navigation.navigate

class NewProjectStateHolder(
    private val team: Team,
    private val coroutineScope: CoroutineScope,
    private val api: TraakApi,
    private val router: Router,
) : NewProjectState {
  private val mutex = Mutex()
  private var _loading by mutableStateOf(false)
  private var _result by mutableStateOf<Result?>(null)

  override var projectTitle by mutableStateOf("")
  override var name by mutableStateOf("")
  override var phone by mutableStateOf("")
  override var postalCode by mutableStateOf("")
  override var locality by mutableStateOf("")
  override var street by mutableStateOf("")

  override val loading: Boolean
    get() = _loading

  override val result: Result?
    get() = _result

  override fun createProject() {
    if (_loading) return

    if (projectTitle.isBlank()) {
      _result = Result.LocalResult.EmptyTitle
      return
    }

    coroutineScope.launch {
      mutex.withLock { _loading = true }
      val projectDTO =
          CreateProjectDTO(
              title = projectTitle,
              name = name,
              phone = phone,
              postalCode = postalCode,
              locality = locality,
              street = street,
          )

      val result =
          api.createProject(
              teamId = team.id,
              project = projectDTO,
          )

      _result = Result.RemoteResult(result)
      mutex.withLock { _loading = false }
      router.navigate(to = Route.ProjectOverview)
    }
  }
}

@Composable
fun rememberNewProjectState(
    team: Team,
    coroutineScope: CoroutineScope = rememberCoroutineScope(),
    api: TraakApi = LocalTraakApi.current,
    router: Router = Router.current,
): NewProjectState =
    remember(team, coroutineScope, api) {
      NewProjectStateHolder(
          team = team,
          coroutineScope = coroutineScope,
          api = api,
          router = router,
      )
    }
