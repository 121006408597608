package ui.screens.teamOverview

import androidx.compose.runtime.Composable
import api.traak.Member
import api.traak.Team
import org.jetbrains.compose.web.dom.AttrBuilderContext
import org.w3c.dom.HTMLDivElement
import tailwind.Full
import tailwind.Layout
import tailwind.TailwindScope
import tailwind.tailwind
import ui.components.PageTitle
import ui.data.Loadable
import ui.layouts.HalfScreenLayout
import ui.material.Column
import ui.material.FullRow
import ui.material.SearchInput
import ui.screens.projectOverview.Loading
import ui.screens.projectOverview.NoListMessage
import ui.strings.LocalStrings

@Composable
fun TeamOverview(
    team: Team,
    attrs: AttrBuilderContext<HTMLDivElement>? = null,
) {
  val strings = LocalStrings.current
  val state = rememberTeamOverviewState(team)

  TeamOverview(
      title = strings.teamOverviewTeamMemberTitle,
      searchValue = state.search,
      onSearchInput = { state.search = it },
      searchPlaceholder = strings.teamOverviewSearchPlaceholder,
      noFilteredMember = strings.teamOverviewNoFilteredMember,
      loadableMembers = state.loadableMembers,
      filteredMembers = state.filteredMembers,
      onMemberClick = { state.onMemberClick(it) },
      attrs = attrs,
  )
}

@Composable
fun TeamOverview(
    title: String,
    searchValue: String,
    onSearchInput: (String) -> Unit,
    searchPlaceholder: String? = null,
    noFilteredMember: String,
    loadableMembers: Loadable<List<Member>>,
    filteredMembers: List<Member>,
    onMemberClick: (Member) -> Unit,
    attrs: AttrBuilderContext<HTMLDivElement>? = null,
) {
  HalfScreenLayout {
    Column(
        justify = Layout.Justify.Start,
        alignItems = Layout.AlignItems.Start,
        gap = 4,
        attrs = {
          attrs?.invoke(this)
          tailwind { w(Full) }
        }) {
          FullRow(
              // bottom margin specifically chosen to match design of project overview page
              attrs = { tailwind { mb(3) } },
          ) {
            PageTitle(
                title = title,
                attrs = {
                  tailwind {
                    w(Full)
                    display(TailwindScope.Display.Block)
                  }
                })
          }

          SearchInput(
              value = searchValue,
              onInput = onSearchInput,
              placeholder = searchPlaceholder,
              attrs = { tailwind { w(Full) } },
          )

          val sharedAttr: AttrBuilderContext<HTMLDivElement> = {
            tailwind {
              w(Full)
              mt(4)
            }
          }
          when (loadableMembers) {
            is Loadable.Loading -> Loading(sharedAttr)
            is Loadable.Loaded ->
                if (filteredMembers.isEmpty()) {
                  NoListMessage(noFilteredMember)
                } else {
                  TeamList(
                      members = filteredMembers,
                      onMemberClick = onMemberClick,
                      attrs = sharedAttr,
                  )
                }
          }
        }
  }
}
