package ui.components

import androidx.compose.runtime.Composable
import org.jetbrains.compose.web.dom.AttrBuilderContext
import org.jetbrains.compose.web.dom.H4
import org.jetbrains.compose.web.dom.Text
import org.w3c.dom.HTMLHeadingElement
import tailwind.TextScope
import tailwind.tailwind

@Composable
fun PageTitle(
    title: String,
    attrs: AttrBuilderContext<HTMLHeadingElement>? = null,
) {
  H4(
      attrs = {
        attrs?.invoke(this)
        tailwind {
          text {
            align(TextScope.Align.Left)
            headline4()
          }
          mb(4)
        }
      },
  ) {
    Text(title)
  }
}

@Composable
fun PageTitle(
    attrs: AttrBuilderContext<HTMLHeadingElement>? = null,
    content: @Composable () -> Unit,
) {
  H4(
      attrs = {
        attrs?.invoke(this)
        tailwind {
          text {
            align(TextScope.Align.Left)
            headline4()
          }
          mb(4)
        }
      },
  ) {
    content()
  }
}
