package ui.material

import androidx.compose.runtime.Composable
import org.jetbrains.compose.web.dom.AttrBuilderContext
import org.jetbrains.compose.web.dom.Span
import org.jetbrains.compose.web.dom.Text
import org.w3c.dom.HTMLSpanElement
import tailwind.TailwindScope
import tailwind.color.Opacity020
import tailwind.tailwind
import ui.color.BlackDark
import ui.color.Primary100
import ui.color.Secondary600

@Composable
fun SmallTag(
    text: String,
    attrs: AttrBuilderContext<HTMLSpanElement>? = null,
) {
  Span(
      attrs = {
        tailwind {
          background(Primary100)
          border(color = Secondary600, opacity = Opacity020, width = 1)
          round(TailwindScope.Radius.Large)
          px(2)
          py(1)
          text(color = BlackDark) { overline() }
        }
        attrs?.invoke(this)
      }) {
        Text(text)
      }
}
