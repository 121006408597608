package ui.screens.integration.bexio.connection

import androidx.compose.runtime.Composable
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberCoroutineScope
import api.traak.LocalTraakApi
import api.traak.TraakApi
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.launch
import kotlinx.coroutines.sync.Mutex
import kotlinx.coroutines.sync.withLock

class BexioConnectionStateHolder(
    private val coroutineScope: CoroutineScope,
    private val traakApi: TraakApi,
) : BexioConnectionState {

  private val mutex = Mutex()

  private var _loading = false

  override val loading: Boolean
    get() = _loading

  override fun loginWithBexio() {
    coroutineScope.launch {
      if (_loading) return@launch

      mutex.withLock { _loading = true }

      traakApi.logInWithBexio()

      mutex.withLock { _loading = false }
    }
  }
}

@Composable
fun rememberBexioConnectionState(
    coroutineScope: CoroutineScope = rememberCoroutineScope(),
    traakApi: TraakApi = LocalTraakApi.current,
): BexioConnectionState =
    remember(coroutineScope, traakApi) {
      BexioConnectionStateHolder(
          coroutineScope = coroutineScope,
          traakApi = traakApi,
      )
    }
