package tailwind

import org.jetbrains.compose.web.attributes.AttrsScope
import org.w3c.dom.Element
import tailwind.FlexScope.Wrap

class Flex<TElement : Element>
internal constructor(
    builder: AttrsScope<TElement>,
    currentPrefix: String?,
) : Tailwind<TElement>(builder, currentPrefix), FlexScope {

  override fun direction(type: FlexScope.Direction) = apply("flex-${type}")

  override fun wrap(type: Wrap) = apply("flex-${type}")

  override val Int.gap: Unit
    get() = apply("gap-$this")

  override fun justify(type: Layout.Justify) = apply("justify-${type}")

  override fun alignContent(type: Layout.AlignContent) = apply("content-${type}")

  override fun alignItems(type: Layout.AlignItems) = apply("items-${type}")
}
