package ui.screens.integration.bexio.synchronising

import androidx.compose.runtime.Composable
import api.traak.Team
import api.traak.user.User
import org.jetbrains.compose.web.dom.A
import org.jetbrains.compose.web.dom.AttrBuilderContext
import org.jetbrains.compose.web.dom.Text
import org.w3c.dom.HTMLDivElement
import org.w3c.dom.url.URL
import tailwind.*
import ui.material.Buttons
import ui.material.FullColumn
import ui.screens.integration.bexio.BexioHolder
import ui.screens.integration.bexio.BexioIntegrationStage
import ui.screens.integration.bexio.BexioStageExplanation
import ui.screens.integration.bexio.SideBar
import ui.strings.LocalStrings

@Composable
fun BexioSynchronising(
    user: User,
    team: Team,
    code: String?,
    error: String?,
    attrs: AttrBuilderContext<HTMLDivElement>? = null,
) {
  val state =
      rememberBexioSynchronisingState(
          user,
          team,
          code,
          error,
      )
  val strings = LocalStrings.current

  val loadingText =
      when (state.mode) {
        SynchronisingMode.Initial -> ""
        SynchronisingMode.StoringCredentials -> strings.bexioIntegrationStoringCredentials
        SynchronisingMode.Synchronising -> strings.bexioIntegrationSynchronizing
        SynchronisingMode.Done -> strings.bexioIntegrationDone
        SynchronisingMode.Error -> strings.bexioIntegrationError
      }

  BexioSynchronising(
      headline = strings.bexioIntegrationHeadline,
      currentIntegrationStage = state.mode.toBexioIntegrationStage(),
      title = strings.bexioIntegrationAuthorizationTitle,
      explanation = strings.bexioIntegrationAuthorizationExplanation,
      showAuthorizeButton = state.mode == SynchronisingMode.Initial,
      authorizeUrl = state.authorizeUrl,
      authorizeButtonText = strings.bexioIntegrationAuthorizationButton,
      showLoading = state.loading,
      loadingText = loadingText,
      attrs = attrs,
  )
}

@Composable
fun BexioSynchronising(
    headline: String,
    currentIntegrationStage: BexioIntegrationStage,
    title: String,
    explanation: String,
    showAuthorizeButton: Boolean,
    authorizeUrl: URL,
    authorizeButtonText: String,
    showLoading: Boolean,
    loadingText: String,
    attrs: AttrBuilderContext<HTMLDivElement>? = null,
) {
  BexioHolder(
      headline = headline,
      attrs = attrs,
      sideBar = { SideBar(currentIntegrationStage) },
  ) {
    FullColumn {
      BexioStageExplanation(
          title = title,
          explanation = explanation,
      )

      if (showAuthorizeButton) {
        A(
            href = authorizeUrl.toString(),
            attrs = {
              tailwind {
                display(TailwindScope.Display.InlineBlock)
                w(Full)
                mx(Auto)
                mt(4)

                text(align = TextScope.Align.Center) { button() }

                Buttons.common(this)
                Buttons.normal(this)
                Buttons.enabledFilled(this)
              }
            },
        ) {
          Text(authorizeButtonText)
        }
      }

      if (showLoading) {
        Loading(
            message = loadingText,
            attrs = { tailwind { mt(8) } },
        )
      }
    }
  }
}
