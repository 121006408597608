package ui.icons

import androidx.compose.runtime.Composable
import org.jetbrains.compose.web.dom.AttrBuilderContext
import org.w3c.dom.HTMLDivElement

@Composable
fun HomeOutline(
    attrs: AttrBuilderContext<HTMLDivElement>? = null,
) =
    Icon(attrs) {
      attr(
          "d",
          "M9 13H15V19H18V10L12 5.5L6 10V19H9V13ZM4 21V9L12 3L20 9V21H4Z",
      )
    }
