package ui.components.calendar

import androidx.compose.runtime.Composable
import org.jetbrains.compose.web.dom.AttrBuilderContext
import org.jetbrains.compose.web.dom.Text
import org.w3c.dom.HTMLDivElement
import tailwind.FlexScope
import tailwind.Layout
import tailwind.TextScope
import tailwind.tailwind
import ui.material.FullRow
import ui.strings.LocalStrings

@Composable
fun DayHeaders(
    attrs: AttrBuilderContext<HTMLDivElement>? = null,
) {
  val strings = LocalStrings.current
  val childAttrs: AttrBuilderContext<HTMLDivElement> = {
    tailwind {
      flex(
          direction = FlexScope.Direction.Row,
          justify = Layout.Justify.Center,
          alignItems = Layout.AlignItems.Center,
      )
    }
  }

  FullRow(
      gap = 0,
      justify = Layout.Justify.Start,
      alignItems = Layout.AlignItems.Center,
      attrs = {
        attrs?.invoke(this)
        tailwind { text { overline(weight = TextScope.Weight.Normal, size = TextScope.Size.Xs) } }
      }) {
        CalendarItem(childAttrs) { Text(strings.mondayAbbr) }
        CalendarItem(childAttrs) { Text(strings.tuesdayAbbr) }
        CalendarItem(childAttrs) { Text(strings.wednesdayAbbr) }
        CalendarItem(childAttrs) { Text(strings.thursdayAbbr) }
        CalendarItem(childAttrs) { Text(strings.fridayAbbr) }
        CalendarItem(childAttrs) { Text(strings.saturdayAbbr) }
        CalendarItem(childAttrs) { Text(strings.sundayAbbr) }
      }
}
