package ui.screens.projectDetail

import androidx.compose.runtime.Composable
import org.jetbrains.compose.web.dom.AttrBuilderContext
import org.jetbrains.compose.web.dom.Div
import org.w3c.dom.HTMLDivElement
import tailwind.Full
import tailwind.tailwind
import ui.components.LoadingPlaceholder
import ui.material.Card
import ui.material.Column
import ui.material.FullRow

@Composable
fun LoadingProjectAddress(
    attrs: AttrBuilderContext<HTMLDivElement>? = null,
) {
  Card(
      attrs = {
        attrs?.invoke(this)
        tailwind { p(4) }
      }) {
        Column(gap = 4) {
          FullRow(gap = 6) {
            LoadingPlaceholder(
                attrs = {
                  tailwind {
                    w(20)
                    h(4)
                  }
                })

            LoadingPlaceholder(
                attrs = {
                  tailwind {
                    w(Full)
                    h(4)
                  }
                })
          }

          FullRow(gap = 6) {
            Div(
                attrs = {
                  tailwind {
                    w(20)
                    h(4)
                  }
                })

            LoadingPlaceholder(
                attrs = {
                  tailwind {
                    w(Full)
                    h(4)
                  }
                })
          }

          FullRow(gap = 6) {
            LoadingPlaceholder(
                attrs = {
                  tailwind {
                    w(20)
                    h(4)
                  }
                })

            LoadingPlaceholder(
                attrs = {
                  tailwind {
                    w(Full)
                    h(4)
                  }
                })
          }
        }
      }
}
