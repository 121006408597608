package ui.components.calendar

import androidx.compose.runtime.Composable
import org.jetbrains.compose.web.dom.AttrBuilderContext
import org.jetbrains.compose.web.dom.Div
import org.w3c.dom.HTMLDivElement
import tailwind.seventh
import tailwind.tailwind

@Composable
fun CalendarItem(
    attrs: AttrBuilderContext<HTMLDivElement>? = null,
    content: @Composable () -> Unit,
) {
  Div(
      attrs = {
        attrs?.invoke(this)
        tailwind { w(1.seventh) }
      }) {
        content()
      }
}
