package api.traak.toFirestore

import api.traak.fromFirestore.Timestamp
import kotlinx.serialization.Serializable

@Serializable
data class ToFirestoreTeamAccessRequest(
    /** The name of the user who wishes to add a request */
    val name: String,
    /** The first name of the user who wishes to add a request */
    val firstName: String,
    /** The last name of the user who wishes to add a request */
    val lastName: String,
    /** The uid of the user who wishes to add a request */
    val uid: String,
    /** The time at which the request was made */
    val requestDate: Timestamp,
)
