package ui.material

import androidx.compose.runtime.Composable
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import org.jetbrains.compose.web.dom.AttrBuilderContext
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Span
import org.jetbrains.compose.web.dom.Text
import org.w3c.dom.HTMLDivElement
import tailwind.*
import tailwind.color.Opacity090
import ui.color.BlackDark
import ui.color.Primary200
import ui.helpers.OnOutsideClick
import ui.icons.Check
import ui.icons.ChevronDown

@Composable
fun <T> Select(
    current: T?,
    items: List<T>,
    onItemClick: (T) -> Unit,
    displayItem: (T) -> String,
    disabled: Boolean = false,
    attrs: AttrBuilderContext<HTMLDivElement>? = null,
) {
  val menuIsActive = remember { mutableStateOf(false) }

  if (menuIsActive.value) {
    OnOutsideClick(menuIsActive, false)
  }

  Row(
      gap = 4,
      alignItems = Layout.AlignItems.Center,
      attrs = {
        attrs?.invoke(this)
        onClick { menuIsActive.value = true }

        tailwind {
          position(TailwindScope.Position.Relative)

          p(2)
          round(TailwindScope.Radius.Large)
          cursor(TailwindScope.Cursor.Pointer)
          w(30)

          if (!disabled) {
            hover { background(Primary200, opacity = Opacity090) }
          }

          transition()
        }
      }) {
        Span(
            attrs = {
              tailwind {
                text { overline(weight = TextScope.Weight.Normal, size = TextScope.Size.Sm) }
              }
            }) {
              if (current != null) {
                Text(displayItem(current))
              } else {
                Text("")
              }
            }

        if (!disabled) {
          ChevronDown()

          if (menuIsActive.value) {
            ActionMenu(
                current = current,
                items = items,
                onItemClick = onItemClick,
                displayItem = displayItem,
                attrs = {
                  tailwind {
                    w(60)
                    top(12)
                    right(0)
                  }
                })
          }
        }
      }
}

@Composable
fun <T> ActionMenu(
    current: T?,
    items: List<T>,
    onItemClick: (T) -> Unit,
    displayItem: (T) -> String,
    attrs: AttrBuilderContext<HTMLDivElement>? = null,
) {
  PopUpMenu(
      attrs = {
        attrs?.invoke(this)
        tailwind { text { subtitle2(size = TextScope.Size.Base) } }
      }) {
        items.forEach { item ->
          PopUpDetail(
              onClick = { onItemClick(item) },
              attrs = { tailwind { text(color = BlackDark) } },
          ) {
            Span(
                attrs = {
                  tailwind {
                    text { overline(weight = TextScope.Weight.Normal, size = TextScope.Size.Sm) }
                  }
                },
            ) {
              Text(displayItem(item))
            }

            Div(attrs = { tailwind { flexItem(FlexItemScope.Grow.Grow) } }) {}

            if (item == current) {
              Check()
            }
          }
        }
      }
}
