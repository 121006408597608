package ui.screens.tasks

import androidx.compose.runtime.Composable
import org.jetbrains.compose.web.css.fr
import org.jetbrains.compose.web.dom.AttrBuilderContext
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Text
import org.w3c.dom.HTMLDivElement
import tailwind.FlexItemScope
import tailwind.TextScope
import tailwind.tailwind
import ui.components.table.TableColumn
import ui.components.table.TableColumnHeader
import ui.components.taskTable.DisplayDate
import ui.components.taskTable.display
import ui.strings.LocalStrings

@Composable
private fun FillButNotGrow(
    attrs: AttrBuilderContext<HTMLDivElement>? = null,
    content: @Composable () -> Unit,
) {
  Div(
      attrs = {
        attrs?.invoke(this)

        tailwind {
          flexItem(grow = FlexItemScope.Grow.Fixed, shrink = FlexItemScope.Shrink.Fixed)
          text { wordBreak(TextScope.WordBreak.All) }

          pr(2)
        }
      },
  ) {
    content()
  }
}

val dateColumn =
    TableColumn<UiTask>(
        header =
            TableColumnHeader(
                display = {
                  val strings = LocalStrings.current
                  Text(strings.projectDetailHeaderDate)
                },
                isOrderable = true,
            ),
        width = 2.fr,
        display = { task, _ -> FillButNotGrow { DisplayDate(task.date) } },
    )

val authorColumn =
    TableColumn<UiTask>(
        header =
            TableColumnHeader(
                display = {
                  val strings = LocalStrings.current
                  Text(strings.projectDetailHeaderUser)
                },
                isOrderable = false,
            ),
        width = 2.fr,
        display = { task, _ -> FillButNotGrow { Text(task.author) } },
    )

val projectColumn =
    TableColumn<UiTask>(
        header =
            TableColumnHeader(
                display = {
                  val strings = LocalStrings.current
                  Text(strings.memberDetailHeaderProject)
                },
                isOrderable = false,
            ),
        width = 3.fr,
        display = { task, _ -> FillButNotGrow { Text(task.project) } },
    )

val desciptionColumn =
    TableColumn<UiTask>(
        header =
            TableColumnHeader(
                display = {
                  val strings = LocalStrings.current
                  Text(strings.projectDetailHeaderTask)
                },
                isOrderable = false,
            ),
        width = 5.fr,
        display = { task, _ -> FillButNotGrow { Text(task.description) } },
    )

val durationColumn =
    TableColumn<UiTask>(
        header =
            TableColumnHeader(
                display = {
                  val strings = LocalStrings.current
                  Text(strings.projectDetailHeaderDuration)
                },
            ),
        width = 1.fr,
        display = { task, _ ->
          FillButNotGrow(
              attrs = { tailwind { text { align(TextScope.Align.Right) } } },
          ) {
            task.duration.display()
          }
        },
    )

val taskTableColumns: List<TableColumn<UiTask>> =
    listOf(
        dateColumn,
        authorColumn,
        projectColumn,
        desciptionColumn,
        durationColumn,
    )
