package ui.components.taskTable

import androidx.compose.runtime.Composable
import api.traak.Task
import kotlin.time.Duration
import kotlin.time.Duration.Companion.seconds
import org.jetbrains.compose.web.dom.AttrBuilderContext
import org.jetbrains.compose.web.dom.Span
import org.jetbrains.compose.web.dom.Text
import org.w3c.dom.HTMLSpanElement
import tailwind.tailwind
import ui.color.Secondary300
import ui.strings.LocalStrings
import utils.padWith0

@Composable
fun DisplayDuration(task: Task) {
  task.end.minus(task.start).plus(1.seconds).display()
}

@Composable
fun Duration.display() =
    this.toComponents { hours, minutes, _, _ ->
      val timeAttr: AttrBuilderContext<HTMLSpanElement> = {
        tailwind {
          pr(2)
          text { numbers() }
        }
      }
      val suffixAttr: AttrBuilderContext<HTMLSpanElement> = {
        tailwind {
          pr(2)
          text(color = Secondary300) { numbers() }
        }
      }
      val strings = LocalStrings.current

      Span(timeAttr) { Text(hours.padWith0()) }
      Span(suffixAttr) { Text(strings.hourSuffix) }
      Span(timeAttr) { Text(minutes.padWith0()) }
      Span(suffixAttr) { Text(strings.minuteSuffix) }
    }
