package api.traak.dto

data class CreateProjectDTO(
    val title: String,
    val name: String,
    val phone: String,
    val postalCode: String,
    val locality: String,
    val street: String,
)
