package ui.strings

import androidx.compose.runtime.Stable
import androidx.compose.runtime.staticCompositionLocalOf

@Stable
interface LocalizedStrings {
  val mondayAbbr: String
  val tuesdayAbbr: String
  val wednesdayAbbr: String
  val thursdayAbbr: String
  val fridayAbbr: String
  val saturdayAbbr: String
  val sundayAbbr: String

  val january: String
  val february: String
  val march: String
  val april: String
  val may: String
  val june: String
  val july: String
  val august: String
  val september: String
  val october: String
  val november: String
  val december: String

  val hourSuffix: String
  val minuteSuffix: String

  val description: String
  val success: String
  val save: String
  val reset: String
  val cancel: String
  val help: String
  val continueStr: String
  val or: String
  val export: String

  val exampleFullName: String

  val taskCreatorTitle: String

  val taskTableTotalTime: String

  val navMenuTasks: String
  val navMenuProjects: String
  val navMenuTeam: String
  val navMenuDetailTeamManagement: String
  val navMenuDetailSwitchTeams: String
  val navMenuDetailSwitchLanguage: String
  val navMenuDetailHelp: String
  val navMenuDetailLogOut: String

  val loginSubtitle: String
  val loginEmailPlaceholder: String
  val loginPasswordPlaceholder: String
  val loginLogInButton: String
  val loginLogInBexioButton: String
  val loginNoAccount: String
  val loginCreateAccount: String
  val loginSeparationText: String
  val loginExplanationIdentifying: String
  val loginExplanationValidatingPhone: String
  val loginExplanationWaitingForEmailClick: String
  val loginIdentifierError: String
  val loginConnectionTitle: String
  val loginIdentifierFormIdentifierLabel: String
  val loginIdentifierFormIdentifierPlaceholder: String
  val loginIdentifierFormIdentifierContinueButton: String
  val loginValidationFormValidationPlaceholder: String
  val loginValidationFormValidationLabel: String
  val loginValidationFormVerifyButton: String

  val setupPanelTitle: String
  val setupProgressionName: String
  val setupProgressionEmail: String
  val setupProgressionPhone: String

  val setupNameTitle: String
  val setupNameExplanation: String
  val setupNameFormNameLabel: String
  val setupNameFormNamePlaceholder: String
  val setupNameFormContinueButton: String

  val setupPhoneExplanationRetrieving: String
  val setupPhoneExplanationValidating: String
  val setupPhoneTitle: String
  val setupPhoneIdentifierFormPhoneLabel: String
  val setupPhoneIdentifierFormPhonePlaceholder: String
  val setupPhoneIdentifierFormContinueButton: String
  val setupPhoneValidationFormValidationCodePlaceholder: String
  val setupPhoneValidationFormValidationCodeLabel: String
  val setupPhoneValidationFormVerifyButton: String

  val setupEmailTitle: String
  val setupEmailExplanation: String
  val setupEmailFormEmailLabel: String
  val setupEmailFormEmailPlaceholder: String
  val setupEmailFormContinueButton: String

  val registerTraakHeadline: String
  val registerEmailPlaceholder: String
  val registerFirstNamePlaceholder: String
  val registerLastNamePlaceholder: String
  val registerPasswordPlaceholder: String
  val registerPasswordConfirmationPlaceholder: String
  val registerButton: String
  val registerGoBackButton: String
  val registerErrorEmptyName: String
  val registerSuccess: String

  val authErrorFailureInternal: String
  val authErrorFailureUserUnknown: String
  val authErrorFailureUserAlreadyRegistered: String
  val authErrorFailurePasswordTooWeak: String
  val authErrorFailurePasswordWrong: String
  val authErrorFailureInvalidEmail: String
  val authErrorFailureInvalidPhoneNumber: String
  val authErrorFailureEmailAlreadyInUse: String
  val authErrorFailureInvalidVerificationCode: String
  val authErrorFailureUnknown: String

  val teamSelectionHeadline: String
  val teamSelectionLogOut: String

  fun teamSelectionMembers(members: Int): String

  val teamSelectionNoTeamHeadline: String
  val teamSelectionNoTeamInformation: String
  val teamSelectionNewTeamButton: String
  val teamSelectionExistingTeamButton: String

  val requestTeamAccessHeadline: String
  val requestTeamAccessHeadlineSuccess: String
  val requestTeamAccessSuccessMessage: String
  val requestTeamAccessIdPlaceholder: String
  val requestTeamAccessRequestAccessButton: String
  val requestTeamAccessRequestFailure: String

  val teamCreationFailure: String
  val teamCreationMissingData: String

  val newTeamTraakHeadline: String
  val newTeamCreateButton: String
  val newTeamCancelButton: String
  val newTeamBillingHeadline: String
  val newTeamNamePlaceholder: String
  val newTeamContactNamePlaceholder: String
  val newTeamEmailPlaceholder: String
  val newTeamPhonePlaceholder: String
  val newTeamAddressPlaceholder: String
  val newTeamLocalityPlaceholder: String
  val newTeamZipPlaceholder: String

  val projectTitleLabel: String
  val projectNameLabel: String
  val projectPhoneLabel: String
  val projectStreetLabel: String
  val projectPostalCodeLabel: String
  val projectLocalityLabel: String
  val projectLocalityWithZipLabel: String
  val projectTitlePlaceholder: String
  val projectNamePlaceholder: String
  val projectPhonePlaceholder: String
  val projectStreetPlaceholder: String
  val projectPostalCodePlaceholder: String
  val projectLocalityPlaceholder: String
  val projectLocalityWithZipPlaceholder: String

  val projectEmptyTitleError: String
  val projectSavedError: String

  val newProjectTitle: String
  val newProjectExplanation: String
  val newProjectCreateProjectButton: String

  val projectOverviewTitle: String
  val projectOverviewNewProjectButton: String
  val projectOverviewSearchBarPlaceholder: String
  val projectOverviewNoActiveProjects: String
  val projectOverviewNoArchivedProjects: String
  val projectOverviewNoSearchedProjects: String
  val projectOverviewSynchronizeBexioTooltipHeader: String
  val projectOverviewSynchronizeBexioTooltipParagraph: String

  val projectStatusActive: String
  val projectStatusArchived: String

  val projectDetailEmptyAddressTitle: String
  val projectDetailEmptyAddressParagraph: String
  val projectDetailAddressSubtitle: String
  val projectDetailEditProjectButton: String
  val projectDetailArchiveButton: String
  val projectDetailRestoreButton: String
  val projectDetailExportProjectButton: String
  val projectDetailSearchPlaceholder: String
  val projectDetailHeaderDate: String
  val projectDetailHeaderUser: String
  val projectDetailHeaderTask: String
  val projectDetailHeaderDuration: String

  val projectEditionTitle: String
  val projectEditionExplanation: String
  val projectEditionSaveButton: String
  val projectEditionCancelButton: String

  val teamOverviewTeamMemberTitle: String
  val teamOverviewSearchPlaceholder: String
  val teamOverviewNoFilteredMember: String

  val teamSettingsInvitationTitle: String
  val teamSettingsInvitationExplanation: String
  val teamSettingsInvitationExplanationAfterCopy: String
  val teamSettingsInvitationCopied: String
  val teamSettingsRequestsTitle: String
  val teamSettingsNoRequests: String
  val teamSettingsHeaderName: String
  val teamSettingsHeaderRole: String
  val teamSettingsRoleAdmin: String
  val teamSettingsRoleProjectManager: String
  val teamSettingsRoleMember: String
  val teamSettingsRoleRemoved: String

  val memberDetailHeaderProject: String
  val memberDetailSearchPlaceholder: String
  val memberDetailExport: String

  val bexioIntegrationHeadline: String
  val bexioIntegrationStoringCredentials: String
  val bexioIntegrationSynchronizing: String
  val bexioIntegrationError: String
  val bexioIntegrationDone: String
  val bexioIntegrationSideBarConnection: String
  val bexioIntegrationSideBarTeamSelection: String
  val bexioIntegrationSideBarAuthorization: String
  val bexioIntegrationSideBarSynchronization: String
  val bexioIntegrationConnectionTitle: String
  val bexioIntegrationConnectionExplanation: String
  val bexioIntegrationConnectionBexioExplanation: String
  val bexioIntegrationConnectionBexioLink: String
  val bexioIntegrationTeamSelectionTitle: String
  val bexioIntegrationTeamSelectionExplanation: String
  val bexioIntegrationAuthorizationTitle: String
  val bexioIntegrationAuthorizationExplanation: String
  val bexioIntegrationAuthorizationButton: String

  val taskItemMenuEdit: String
  val taskItemMenuDelete: String
  val taskItemDeletionCancellableSnackbar: String

  val memberMenuEdit: String
  val memberMenuDelete: String

  val memberEditionTitle: String
  val memberEditionFirstNamePlaceholder: String
  val memberEditionLastNamePlaceholder: String
  val memberEditionReset: String
  val memberEditionSave: String

  val recapListTitle: String
  val recapListSeparatorTitle: (String, String) -> String
  val recapListEmptyRecap: String

  val accountTitle: String
  val accountDisplaySettingsTitle: String
  val accountOrderByFirstNameTitle: String
  val accountOrderByFirstNameExplanationActive: String
  val accountOrderByFirstNameExplanationInactive: String
  val accountShowFirstNameFirstTitle: String
  val accountShowFirstNameFirstExplanationActive: String
  val accountShowFirstNameFirstExplanationInactive: String

  val searchableCheckboxDeselectAll: String
  val searchableCheckboxSelectAll: String

  val peopleChipTitleEverybody: String
  val peopleChipTitleNobody: String

  fun peopleChipTitle(numberOfPeople: Int): String

  val peopleChipSearchPlaceholder: String

  val projectsChipTitleAll: String
  val projectsChipTitleNone: String

  fun projectsChipTitle(numberOfProjects: Int): String

  val projectsChipSearchPlaceholder: String

  val allTasksTitle: String
  val allTasksSearchPlaceholder: String
}

val LocalStrings =
    staticCompositionLocalOf<LocalizedStrings> {
      error("String resources haven't been initialized yet.")
    }
