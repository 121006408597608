import androidx.compose.runtime.Composable
import navigation.Route
import navigation.RouteLink
import org.jetbrains.compose.web.attributes.AutoComplete
import org.jetbrains.compose.web.dom.AttrBuilderContext
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Text
import org.w3c.dom.HTMLDivElement
import tailwind.Auto
import tailwind.Full
import tailwind.TextScope
import tailwind.tailwind
import ui.color.BlackMedium
import ui.components.AuthErrorMessage
import ui.components.AuthForm
import ui.components.traakHeadline.TraakHeadline
import ui.material.EmailInput
import ui.material.FilledButton
import ui.material.LogInWithBexioButton
import ui.material.PasswordInput
import ui.screens.login.Loading

@Composable
fun LoginForm(
    traakHeadline: String,
    email: String,
    setEmail: (String) -> Unit,
    emailPlaceholder: String,
    password: String,
    setPassword: (String) -> Unit,
    passwordPlaceholder: String,
    logInText: String,
    separationText: String,
    onLogInClick: () -> Unit,
    onLogInWithBexioClick: () -> Unit,
    loginNoAccount: String,
    loginCreateAccount: String,
    errorMessage: String?,
    loading: Loading,
    attrs: AttrBuilderContext<HTMLDivElement>? = null,
) {
  AuthForm(attrs = attrs) {
    TraakHeadline(
        text = traakHeadline,
        attrs = { tailwind { mb(4) } },
    )

    EmailInput(
        value = email,
        onInput = setEmail,
        label = emailPlaceholder,
        autoComplete = AutoComplete.email,
        attrs = { tailwind { w(Full) } },
    )
    PasswordInput(
        value = password,
        onInput = setPassword,
        label = passwordPlaceholder,
        autoComplete = AutoComplete.currentPassword,
    ) {
      tailwind { w(Full) }
    }

    FilledButton(
        text = logInText,
        onClick = onLogInClick,
        disabled = errorMessage != null,
        loading = loading == Loading.SELF,
        attrs = {
          tailwind {
            w(Full)
            text { button() }
          }
        },
    )

    Div(
        attrs = {
          tailwind {
            w(Full)
            m(Auto)
            text(
                color = BlackMedium,
                transformation = TextScope.Transform.Uppercase,
                align = TextScope.Align.Center,
            ) {
              subtitle2()
            }
          }
        },
    ) {
      Text(separationText)
    }

    LogInWithBexioButton(
        onLogInWithBexioClick = onLogInWithBexioClick,
        errorMessage = errorMessage,
        loading = loading == Loading.BEXIO,
    )
    AuthErrorMessage(errorMessage)

    Div(
        attrs = {
          tailwind {
            mt(2)
            text(color = BlackMedium)
          }
        },
    ) {
      Text(loginNoAccount)
      RouteLink(
          to = Route.Register,
          text = loginCreateAccount,
          attrs = { tailwind { ml(4) } },
      )
    }
  }
}
