package ui.components.snackbar

import androidx.compose.runtime.Composable
import org.jetbrains.compose.web.dom.AttrBuilderContext
import org.w3c.dom.HTMLDivElement
import tailwind.Layout
import tailwind.TailwindScope
import tailwind.color.Color
import tailwind.color.Gray300
import tailwind.color.White
import tailwind.tailwind
import ui.material.Row

@Composable
fun Snackbar(
    bottom: Int? = null,
    backgroundColor: Color? = null,
    borderColor: Color? = null,
    attrs: AttrBuilderContext<HTMLDivElement>? = null,
    content: @Composable () -> Unit,
) {
  Row(
      justify = Layout.Justify.Center,
      alignItems = Layout.AlignItems.Center,
      attrs = {
        tailwind {
          position(TailwindScope.Position.Absolute)
          w(TailwindScope.Width.Screen)
          bottom(bottom ?: 12)
        }
      }) {
        Row(
            justify = Layout.Justify.Center,
            alignItems = Layout.AlignItems.Center,
            gap = 4,
            attrs = {
              attrs?.invoke(this)
              tailwind {
                px(2)
                py(1)

                z(10)

                background(color = backgroundColor ?: White)
                border(color = borderColor ?: Gray300, width = 1)
                round(TailwindScope.Radius.Medium)
                shadow(TailwindScope.Shadow.Md)
              }
            }) {
              content()
            }
      }
}
