package tailwind

import org.jetbrains.compose.web.attributes.AttrsScope
import org.w3c.dom.Element
import tailwind.TransitionScope.Property

class Transition<TElement : Element>
internal constructor(
    builder: AttrsScope<TElement>,
    property: Property,
    currentPrefix: String?,
) : Tailwind<TElement>(builder, currentPrefix), TransitionScope {
  init {
    transition(property)
  }

  override fun transition(property: Property) {
    val suffix =
        when (property) {
          Property.None -> "-none"
          Property.All -> "-all"
          Property.Default -> ""
          Property.Colors -> "-colors"
          Property.Opacity -> "-opacity"
          Property.Shadow -> "-shadow"
          Property.Transform -> "-transform"
        }

    apply("transition${suffix}")
  }

  override fun duration(duration: TransitionScope.Duration) {
    val suffix =
        when (duration) {
          TransitionScope.Duration.Duration0075 -> "75"
          TransitionScope.Duration.Duration0100 -> "100"
          TransitionScope.Duration.Duration0150 -> "150"
          TransitionScope.Duration.Duration0200 -> "200"
          TransitionScope.Duration.Duration0300 -> "300"
          TransitionScope.Duration.Duration0500 -> "500"
          TransitionScope.Duration.Duration0700 -> "700"
          TransitionScope.Duration.Duration1000 -> "1000"
        }

    apply("duration-${suffix}")
  }

  override fun timing(timingFunction: TransitionScope.TimingFunction) {
    val suffix =
        when (timingFunction) {
          TransitionScope.TimingFunction.Linear -> "linear"
          TransitionScope.TimingFunction.In -> "in"
          TransitionScope.TimingFunction.Out -> "out"
          TransitionScope.TimingFunction.InOut -> "in-out"
        }

    apply("ease-${suffix}")
  }

  override fun delay(delay: TransitionScope.Delay) {
    val suffix =
        when (delay) {
          TransitionScope.Delay.Delay0075 -> "75"
          TransitionScope.Delay.Delay0100 -> "100"
          TransitionScope.Delay.Delay0150 -> "150"
          TransitionScope.Delay.Delay0200 -> "200"
          TransitionScope.Delay.Delay0300 -> "300"
          TransitionScope.Delay.Delay0500 -> "500"
          TransitionScope.Delay.Delay0700 -> "700"
          TransitionScope.Delay.Delay1000 -> "1000"
        }

    apply("delay-${suffix}")
  }
}
