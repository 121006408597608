package ui.screens.projectEdition

import androidx.compose.runtime.Composable
import org.jetbrains.compose.web.dom.AttrBuilderContext
import org.jetbrains.compose.web.dom.P
import org.jetbrains.compose.web.dom.Text
import org.w3c.dom.HTMLDivElement
import tailwind.Full
import tailwind.Layout
import tailwind.TextScope
import tailwind.fourth
import tailwind.tailwind
import ui.color.ErrorDark
import ui.material.Column
import ui.material.FilledButton
import ui.material.FullRow
import ui.material.OutlineButton
import ui.material.TextInput

@Composable
fun Form(
    title: String,
    onTitleInput: (String) -> Unit,
    titleLabel: String,
    titlePlaceholder: String,
    name: String,
    onNameInput: (String) -> Unit,
    nameLabel: String,
    namePlaceholder: String,
    phone: String,
    onPhoneInput: (String) -> Unit,
    phoneLabel: String,
    phonePlaceholder: String,
    locality: String,
    onLocalityInput: (String) -> Unit,
    localityLabel: String,
    localityPlaceholder: String,
    street: String,
    onStreetInput: (String) -> Unit,
    streetLabel: String,
    streetPlaceholder: String,
    saveButton: String,
    onSave: () -> Unit,
    cancelButton: String,
    onCancelClick: () -> Unit,
    saving: Boolean,
    errorMessage: String?,
    attrs: AttrBuilderContext<HTMLDivElement>? = null,
) {
  Column(
      gap = 3,
      attrs = attrs,
  ) {
    TextInput(
        value = title,
        onInput = onTitleInput,
        label = titleLabel,
        placeholder = titlePlaceholder,
        attrs = {
          tailwind {
            w(Full)
            mb(3)
          }
        },
    )

    FullRow(
        gap = 3,
        attrs = { tailwind { mb(3) } },
    ) {
      TextInput(
          value = name,
          onInput = onNameInput,
          label = nameLabel,
          placeholder = namePlaceholder,
          attrs = { tailwind { w(3.fourth) } },
      )

      TextInput(
          value = phone,
          onInput = onPhoneInput,
          label = phoneLabel,
          placeholder = phonePlaceholder,
          attrs = { tailwind { w(1.fourth) } },
      )
    }

    TextInput(
        value = street,
        onInput = onStreetInput,
        label = streetLabel,
        placeholder = streetPlaceholder,
        attrs = { tailwind { w(Full) } },
    )
    TextInput(
        value = locality,
        onInput = onLocalityInput,
        label = localityLabel,
        placeholder = localityPlaceholder,
        attrs = {
          tailwind {
            mb(3)
            w(Full)
          }
        },
    )

    FullRow(
        justify = Layout.Justify.Around,
        gap = 3,
    ) {
      OutlineButton(
          text = cancelButton,
          onClick = onCancelClick,
          attrs = { tailwind { w(Full) } },
      )

      FilledButton(
          text = saveButton,
          onClick = onSave,
          loading = saving,
          attrs = { tailwind { w(Full) } },
      )
    }

    errorMessage?.let {
      P(
          attrs = {
            tailwind {
              w(Full)
              mt(4)
              text(
                  color = ErrorDark,
                  align = TextScope.Align.Left,
              ) {
                subtitle2()
              }
            }
          },
      ) {
        Text(it)
      }
    }
  }
}
