package tailwind

import tailwind.color.OpacityPainter
import tailwind.color.Painter

@TailwindDsl
interface TextScope : Painter, OpacityPainter {
  enum class Family {
    Sans,
    Mono,
    Serif
  }

  fun family(type: Family)

  enum class Size {
    Xs,
    Sm,
    Base,
    Lg,
    Xl,
    Xl2,
    Xl3,
    Xl4,
    Xl5,
    Xl6,
    Xl7,
    Xl8,
    Xl9,
  }

  fun size(type: Size)

  enum class Weight {
    // 100
    Thin,
    // 200
    Extralight,
    // 300
    Light,
    // 400
    Normal,
    // 500
    Medium,
    // 600
    Semibold,
    // 700
    Bold,
    // 800
    Extrabold,
    // 900
    Black
  }

  fun weight(type: Weight)

  enum class Tracking {
    Tighter,
    Tight,
    Normal,
    Wide,
    Wider,
    Widest
  }

  fun tracking(type: Tracking)

  enum class Leading {
    None,
    Tight,
    Snug,
    Normal,
    Relaxed,
    Loose
  }

  fun leading(type: Leading)

  fun leading(amount: Int)

  enum class Align {
    Left,
    Center,
    Right,
    Justify,
  }

  fun align(type: Align)

  enum class Decoration {
    Underline,
    LineThrough,
    None,
  }

  fun decorate(type: Decoration)

  enum class Overflow {
    Truncate,
    Ellipsis,
    Clip,
  }

  fun overflow(type: Overflow)

  enum class Whitespace {
    Normal,
    NoWrap,
    Pre,
    PreLine,
    PreWrap,
  }

  fun whitespace(type: Whitespace)

  enum class WordBreak {
    Normal,
    Words,
    All,
  }

  fun wordBreak(type: WordBreak)

  enum class Transform {
    Normal,
    Uppercase,
    Lowercase,
    Capitalize,
  }

  fun transform(type: Transform)

  fun headline1(
      family: Family? = null,
      weight: Weight? = null,
      size: Size? = null,
      transform: Transform? = null,
      tracking: Tracking? = null,
  )

  fun headline2(
      family: Family? = null,
      weight: Weight? = null,
      size: Size? = null,
      transform: Transform? = null,
      tracking: Tracking? = null,
  )

  fun headline3(
      family: Family? = null,
      weight: Weight? = null,
      size: Size? = null,
      transform: Transform? = null,
      tracking: Tracking? = null,
  )

  fun headline4(
      family: Family? = null,
      weight: Weight? = null,
      size: Size? = null,
      transform: Transform? = null,
      tracking: Tracking? = null,
  )

  fun headline5(
      family: Family? = null,
      weight: Weight? = null,
      size: Size? = null,
      transform: Transform? = null,
      tracking: Tracking? = null,
  )

  fun headline6(
      family: Family? = null,
      weight: Weight? = null,
      size: Size? = null,
      transform: Transform? = null,
      tracking: Tracking? = null,
  )

  fun subtitle1(
      family: Family? = null,
      weight: Weight? = null,
      size: Size? = null,
      transform: Transform? = null,
      tracking: Tracking? = null,
  )

  fun subtitle2(
      family: Family? = null,
      weight: Weight? = null,
      size: Size? = null,
      transform: Transform? = null,
      tracking: Tracking? = null,
  )

  fun button(
      family: Family? = null,
      weight: Weight? = null,
      size: Size? = null,
      transform: Transform? = null,
      tracking: Tracking? = null,
  )

  fun body1(
      family: Family? = null,
      weight: Weight? = null,
      size: Size? = null,
      transform: Transform? = null,
      tracking: Tracking? = null,
  )

  fun body2(
      family: Family? = null,
      weight: Weight? = null,
      size: Size? = null,
      transform: Transform? = null,
      tracking: Tracking? = null,
  )

  fun numbers(
      family: Family? = null,
      weight: Weight? = null,
      size: Size? = null,
      transform: Transform? = null,
      tracking: Tracking? = null,
  )

  fun caption(
      family: Family? = null,
      weight: Weight? = null,
      size: Size? = null,
      transform: Transform? = null,
      tracking: Tracking? = null,
  )

  fun overline(
      family: Family? = null,
      weight: Weight? = null,
      size: Size? = null,
      transform: Transform? = null,
      tracking: Tracking? = null,
  )
}
