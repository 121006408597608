package ui.screens.login

import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.runtime.setValue
import api.settings.LocalSettings
import api.settings.SettingsApi
import api.traak.AuthResult
import api.traak.LocalTraakApi
import api.traak.TraakApi
import app.softwork.routingcompose.Router
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.launch
import kotlinx.coroutines.sync.Mutex
import kotlinx.coroutines.sync.withLock

/** [LoginStateHolder] is a state holder for the [Login] composable */
class LoginStateHolder(
    private val api: TraakApi,
    private val settingsApi: SettingsApi,
    private val router: Router,
    private val coroutineScope: CoroutineScope,
) : LoginState {
  private var _email by mutableStateOf("")
  private var _password by mutableStateOf("")
  private var _result by mutableStateOf<AuthResult?>(null)
  private var _loading by mutableStateOf(Loading.NONE)

  private val loginMutex = Mutex()

  override fun login(): Unit {
    coroutineScope.launch {
      try {
        _loading = Loading.SELF
        loginMutex.withLock { _result = api.logIn(_email, _password) }
        router.navigate("/")
      } catch (t: Throwable) {
        loginMutex.withLock { _result = AuthResult.FailureUnknown }
      } finally {
        _loading = Loading.NONE
      }
    }
  }

  override fun logWithBexio() {
    coroutineScope.launch {
      try {
        _loading = Loading.BEXIO
        loginMutex.withLock { _result = api.logInWithBexio() }
        router.navigate("/")
      } catch (t: Throwable) {
        loginMutex.withLock { _result = AuthResult.FailureUnknown }
      } finally {
        _loading = Loading.NONE
      }
    }
  }

  private fun resetResult(): Unit {
    coroutineScope.launch { loginMutex.withLock { _result = null } }
  }

  override var email: String
    get() = _email
    set(value) {
      if (_result != null) {
        resetResult()
      }
      _email = value
    }

  override var password: String
    get() = _password
    set(value) {
      if (_result != null) {
        resetResult()
      }
      _password = value
    }

  override val result: AuthResult?
    get() = _result

  override val loading: Loading
    get() = _loading
}

@Composable
fun rememberLoginState(
    api: TraakApi = LocalTraakApi.current,
    settingsApi: SettingsApi = LocalSettings.current,
    router: Router = Router.current,
    coroutineScope: CoroutineScope = rememberCoroutineScope(),
): LoginStateHolder =
    remember(api, coroutineScope) {
      LoginStateHolder(
          api = api,
          settingsApi = settingsApi,
          router = router,
          coroutineScope = coroutineScope,
      )
    }
