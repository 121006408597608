package ui.components.table

import androidx.compose.runtime.Composable
import androidx.compose.runtime.remember
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.AttrBuilderContext
import org.w3c.dom.HTMLDivElement
import tailwind.Full
import tailwind.tailwind
import ui.material.FullColumn
import ui.material.SearchInput
import utils.Ordering

// We have to use data classes here because of a bug in Compose:
// https://github.com/JetBrains/compose-multiplatform/issues/2067

data class RowState(
    val isHovered: Boolean,
)

data class TableColumn<E>(
    val header: TableColumnHeader,
    val width: CSSNumeric?,
    val display: @Composable (E, RowState) -> Unit,
)

data class TableOrdering(
    val current: Pair<Int, Ordering>,
    val onHeaderClick: (Int) -> Unit,
)

data class TableColumnHeader(
    val display: @Composable () -> Unit,
    val isOrderable: Boolean = false,
)

data class TableSearch(
    val searchInput: String,
    val onSearchInput: (String) -> Unit,
    val placeholder: String,
)

data class TableSummary(
    val display: @Composable () -> Unit,
)

@Composable
fun <E> Table(
    columns: List<TableColumn<E>>,
    elements: List<E>,
    search: TableSearch? = null,
    ordering: TableOrdering? = null,
    summary: TableSummary? = null,
    attrs: AttrBuilderContext<HTMLDivElement>? = null,
) {
  val gridTemplateColumns: String =
      remember(columns) {
        columns.map { it.width.toString() }.reduce { acc, columnSize -> "$acc $columnSize" }
      }

  val gridContainer: AttrBuilderContext<HTMLDivElement> =
      remember(columns) {
        {
          style {
            display(DisplayStyle.Grid)
            gridTemplateColumns(gridTemplateColumns)
            alignItems(AlignItems.Center)
          }
        }
      }

  FullColumn(attrs = attrs) {
    search?.let {
      SearchInput(
          value = search.searchInput,
          onInput = { search.onSearchInput(it) },
          placeholder = search.placeholder,
          attrs = {
            tailwind {
              w(Full)
              mb(6)
            }
          },
      )
    }

    TableHeader(
        headers = columns.map { it.header },
        gridContainer = gridContainer,
        ordering = ordering,
    )

    TableMain(
        columns = columns,
        elements = elements,
        gridContainer = gridContainer,
    )

    summary?.let { TableSummary(summary = summary) }
  }
}
