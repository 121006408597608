package tailwind.color

val Current = UniModalColor("currentColor")
val Transparent = UniModalColor("transparent")
val White = UniModalColor("white")
val Black = UniModalColor("black")

private val gray = createColor("gray")
private val red = createColor("red")
private val yellow = createColor("yellow")
private val green = createColor("green")
private val blue = createColor("blue")
private val indigo = createColor("indigo")
private val purple = createColor("purple")
private val pink = createColor("pink")

val Gray050 = gray("50")
val Gray100 = gray("100")
val Gray200 = gray("200")
val Gray300 = gray("300")
val Gray400 = gray("400")
val Gray500 = gray("500")
val Gray600 = gray("600")
val Gray700 = gray("700")
val Gray800 = gray("800")
val Gray900 = gray("900")

val Red050 = red("50")
val Red100 = red("100")
val Red200 = red("200")
val Red300 = red("300")
val Red400 = red("400")
val Red500 = red("500")
val Red600 = red("600")
val Red700 = red("700")
val Red800 = red("800")
val Red900 = red("900")

val Yellow050 = yellow("50")
val Yellow100 = yellow("100")
val Yellow200 = yellow("200")
val Yellow300 = yellow("300")
val Yellow400 = yellow("400")
val Yellow500 = yellow("500")
val Yellow600 = yellow("600")
val Yellow700 = yellow("700")
val Yellow800 = yellow("800")
val Yellow900 = yellow("900")

val Green050 = green("50")
val Green100 = green("100")
val Green200 = green("200")
val Green300 = green("300")
val Green400 = green("400")
val Green500 = green("500")
val Green600 = green("600")
val Green700 = green("700")
val Green800 = green("800")
val Green900 = green("900")

val Blue050 = blue("50")
val Blue100 = blue("100")
val Blue200 = blue("200")
val Blue300 = blue("300")
val Blue400 = blue("400")
val Blue500 = blue("500")
val Blue600 = blue("600")
val Blue700 = blue("700")
val Blue800 = blue("800")
val Blue900 = blue("900")

val Indigo050 = indigo("50")
val Indigo100 = indigo("100")
val Indigo200 = indigo("200")
val Indigo300 = indigo("300")
val Indigo400 = indigo("400")
val Indigo500 = indigo("500")
val Indigo600 = indigo("600")
val Indigo700 = indigo("700")
val Indigo800 = indigo("800")
val Indigo900 = indigo("900")

val Purple050 = purple("50")
val Purple100 = purple("100")
val Purple200 = purple("200")
val Purple300 = purple("300")
val Purple400 = purple("400")
val Purple500 = purple("500")
val Purple600 = purple("600")
val Purple700 = purple("700")
val Purple800 = purple("800")
val Purple900 = purple("900")

val Pink050 = pink("50")
val Pink100 = pink("100")
val Pink200 = pink("200")
val Pink300 = pink("300")
val Pink400 = pink("400")
val Pink500 = pink("500")
val Pink600 = pink("600")
val Pink700 = pink("700")
val Pink800 = pink("800")
val Pink900 = pink("900")
