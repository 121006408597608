package api.traak

import androidx.compose.runtime.staticCompositionLocalOf
import api.traak.authManager.AuthManager
import api.traak.authManager.phone.PhoneAuthenticator
import api.traak.dto.BillingAddress
import api.traak.dto.CreateProjectDTO
import api.traak.dto.TaskEditionDto
import api.traak.dto.UpdateProjectDTO
import api.traak.toFirestore.ToFirestoreProfile
import api.traak.user.User
import firebase.auth.Profile
import kotlinx.coroutines.flow.Flow
import kotlinx.datetime.Instant
import kotlinx.datetime.LocalDate

interface TraakApi : PhoneAuthenticator {
  val authManager: AuthManager
  val user: Flow<User?>

  /** Logs in via email/password combination. */
  suspend fun logIn(
      email: String,
      password: String,
  ): AuthResult

  /** Logs in via OpenID against Bexio */
  suspend fun logInWithBexio(): AuthResult

  /** Logs the user out */
  fun logOut()

  /**
   * Registers a new user with his email.
   *
   * @param email The user's email address
   * @param password The user's password
   * @param displayName The user's name
   */
  suspend fun register(
      email: String,
      password: String,
      displayName: String,
  ): AuthResult

  /**
   * Updates a user's profile data.
   *
   * @param user The [User] to whom the profile will be updated
   * @param profile The user's new [Profile]
   */
  suspend fun updateProfile(
      teamId: Team.Id,
      memberId: User.Id,
      profile: ToFirestoreProfile,
  ): Unit

  /**
   * Creates a new team.
   *
   * @param teamName the team's name
   * @param user the team's first administrator
   * @param billingAddress the team's billing address
   */
  suspend fun createTeam(
      teamName: String,
      user: User,
      billingAddress: BillingAddress
  ): StorageResult

  /**
   * Creates a new project
   *
   * @param teamId the team's id
   * @param project the project to create
   */
  suspend fun createProject(
      teamId: Team.Id,
      project: CreateProjectDTO,
  ): StorageResult

  /**
   * Creates a new task
   *
   * @param teamId the team's id
   * @param taskEditionDto the task to create
   */
  suspend fun createTask(
      teamId: Team.Id,
      taskEditionDto: TaskEditionDto,
  ): StorageResult

  /**
   * Edits a task
   *
   * @param taskId the task's id
   * @param taskEditionDto the task to edit
   */
  suspend fun editTask(
      taskId: Task.Id,
      taskEditionDto: TaskEditionDto,
  ): StorageResult

  /** Deletes a task */
  suspend fun deleteTask(taskId: Task.Id)

  /**
   * Returns all the recaps for a given user and team.
   *
   * @param userId the user's id
   * @param teamId the team's id
   * @param from the start date of the range for the recap lookup
   * @param to the end date of the range for the recap lookup
   */
  fun getRecaps(
      userId: User.Id,
      teamId: Team.Id,
      from: Instant,
      to: Instant,
  ): Flow<List<Recap>>

  suspend fun editRecap(
      recapId: Recap.Id,
      description: String,
  ): StorageResult

  /**
   * Updates a project
   *
   * @param teamId the team's id
   * @param project the project to update
   */
  suspend fun updateProject(
      teamId: Team.Id,
      projectId: Project.Id,
      project: UpdateProjectDTO,
  ): StorageResult

  /**
   * Gets all the projects belonging to a team
   *
   * @param teamId The id of the team to whom the projects belong
   * @param status The optional status of the projects
   */
  fun projects(
      teamId: Team.Id,
      status: Project.Status? = null,
  ): Flow<List<Project>>

  /**
   * Gets a specific project
   *
   * @param projectId The project's id
   */
  fun project(projectId: Project.Id): Flow<Project>

  /**
   * Gets the list of tasks associated with a [Project]
   *
   * @param teamId The id of the team to whom the projects belongs
   * @param projectId The project's id
   */
  fun tasksForProject(teamId: Team.Id, projectId: Project.Id): Flow<List<Task>>

  /**
   * Gets the list of tasks associated with an [User]
   *
   * @param teamId The id of the team to whom the projects belongs
   * @param userId The user's id
   */
  fun tasksForUser(teamId: Team.Id, userId: User.Id): Flow<List<Task>>

  /**
   * Gets the list of tasks associated with an [User]
   *
   * @param teamId The id of the team to whom the projects belongs
   * @param userId The user's id
   * @param startDate The starting date of the tasks
   * @param endDate The ending date of the tasks
   */
  fun tasksForUser(
      teamId: Team.Id,
      userId: User.Id,
      startDate: LocalDate,
      endDate: LocalDate
  ): Flow<List<Task>>

  /**
   * Gets the list of members belonging to a given team
   *
   * @param team The team for which we want the members
   */
  fun membersOf(team: Team): Flow<List<Member>>

  suspend fun member(team: Team, userId: User.Id): Member

  /** Requests access to a given team */
  suspend fun requestAccessToTeam(
      teamId: Team.Id,
      userId: User.Id,
      firstName: String,
      lastName: String,
  ): StorageResult

  /**
   * Gets all the requests made to become a member of a certain team
   *
   * @param teamId The [Team.Id] of the team for which the requests were made
   */
  fun pendingRequests(teamId: Team.Id): Flow<List<AccessRequest>>

  /** Accepts an [AccessRequest] */
  suspend fun acceptRequest(team: Team, request: AccessRequest): StorageResult

  /** Deletes an [AccessRequest] */
  suspend fun deleteRequest(teamId: Team.Id, request: AccessRequest): Unit

  /**
   * Stores an OAuth authorization code that still needs to be verified in the team's integration
   * section so that it will be picked up and verified by the server at a latter time
   *
   * @param teamId The id of the team for which to set up the integration
   * @param authorizationCode The oauth authorization code
   * @param redirectUri The redirectUri used for the OAuth authorization
   */
  suspend fun validateToken(
      teamId: Team.Id,
      authorizationCode: String,
      redirectUri: String,
  ): StorageResult

  /**
   * Sends a request to synchronize all bexio dependencies to the server.
   *
   * @param teamId The id of the team for which to synchronize bexio
   */
  suspend fun synchronizeBexio(
      teamId: Team.Id,
  ): StorageResult

  /** Sends a request to update the user's oauth information from bexio servers. */
  suspend fun updateAuthProfileFromBexio(
      teamId: Team.Id,
      userId: User.Id,
  ): StorageResult

  suspend fun changeMemberRole(
      team: Team,
      userId: User.Id,
      newRole: Role,
  ): StorageResult

  /**
   * Exports a certain resource
   *
   * @param teamId The id of the team to which the resource belongs
   * @param resourceType The [ExportType] of the wanted resource
   * @param resourceId The id of the wanted resource
   */
  suspend fun exportView(
      teamId: Team.Id,
      resourceType: ExportType,
      resourceId: String,
      start: Instant,
      end: Instant,
  ): Unit

  /** Changes the status of a project */
  suspend fun changeProjectStatus(
      teamId: Team.Id,
      projectId: Project.Id,
      status: Project.Status,
  ): StorageResult
}

val LocalTraakApi =
    staticCompositionLocalOf<TraakApi> { error("Traak API has not been initialized yet.") }
