package navigation

import androidx.compose.runtime.Composable
import androidx.compose.runtime.remember
import app.softwork.routingcompose.BrowserRouter
import kotlinx.coroutines.FlowPreview

@OptIn(FlowPreview::class)
@Composable
fun Navigation() {
  val state = rememberUserState()

  val traakRouting: Routing =
      remember(state.userState) {
        when (val userState = state.userState) {
          UserState.Loading -> loadingRoute
          UserState.Disconnected -> disconnectedRoute
          is UserState.SettingUp -> settingUpRoute(userState.user)
          is UserState.Authenticated -> authenticatedRoute(userState.user, userState.teams)
          is UserState.Connected -> connectedRoute(userState.user, userState.currentTeam)
        }
      }

  BrowserRouter(
      initPath = "/",
      routeBuilder = traakRouting,
  )
}
