package api.settings

import androidx.compose.runtime.staticCompositionLocalOf
import api.traak.Team
import api.traak.TraakApi
import kotlinx.coroutines.flow.StateFlow

/** [SettingsApi] is used to store the preferences of the user */
interface SettingsApi {

  /** [Language] The language of the interface */
  val language: StateFlow<Language>

  fun setLanguageTo(language: Language)

  /**
   * The [TraakApi.Team] the user will be connected to by default. If null, no team will be selected
   * and the user will have to chose one.
   */
  val team: StateFlow<Team.Id?>

  fun setTeamTo(teamId: Team.Id)

  fun removeTeam()

  val showFirstNameFirst: StateFlow<Boolean>

  fun setShowFirstNameFirstTo(showFirstNameFirst: Boolean)

  val orderByFirstName: StateFlow<Boolean>

  fun setOrderByFirstNameTo(orderByFirstName: Boolean)
}

val LocalSettings =
    staticCompositionLocalOf<SettingsApi> { error("Settings haven't been initialized yet.") }
