package api.traak

/**
 * Represents the different types of objects that can be exported via Traak's API.
 *
 * The export will return a csv or xlsx file.
 */
sealed class ExportType {
  object Project : ExportType()

  object User : ExportType()

  override fun toString(): String =
      when (this) {
        Project -> "project"
        User -> "user"
      }
}
