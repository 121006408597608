package api.traak.authManager

import api.traak.authManager.email.EmailAuthenticator
import api.traak.authManager.email.TraakEmailAuthenticator
import api.traak.authManager.phone.PhoneAuthenticator
import api.traak.authManager.phone.TraakPhoneAuthenticator
import firebase.auth.Auth
import firebase.auth.User
import firebase.auth.onAuthStateChanged
import firebase.auth.reload
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.channels.Channel
import kotlinx.coroutines.flow.SharingStarted
import kotlinx.coroutines.flow.StateFlow
import kotlinx.coroutines.flow.consumeAsFlow
import kotlinx.coroutines.flow.map
import kotlinx.coroutines.flow.stateIn

class TraakAuthManager(
    private val auth: Auth,
    private val baseUrl: String,
    coroutineScope: CoroutineScope,
) : AuthManager {

  private val userChannel = Channel<User?>()

  init {
    onAuthStateChanged(
        auth = auth,
        next = { user -> userChannel.trySend(user) },
        error = { userChannel.close() },
        completed = { userChannel.close() },
    )
  }

  val user: StateFlow<TraakAuthUser?> =
      userChannel
          .consumeAsFlow()
          .map { it?.toTraakAuthUser() }
          .stateIn(
              scope = coroutineScope,
              started = SharingStarted.Lazily,
              initialValue = null,
          )

  fun refreshUserData() {
    val firebaseUser = user.value?.user

    if (firebaseUser != null) {
      reload(firebaseUser)
      userChannel.trySend(auth.currentUser)
    } else {
      userChannel.trySend(null)
    }
  }

  private val phoneAuthenticator = TraakPhoneAuthenticator(auth)
  private val emailAuthenticator = TraakEmailAuthenticator(auth, baseUrl)

  override val phone: PhoneAuthenticator
    get() = phoneAuthenticator

  override val email: EmailAuthenticator
    get() = emailAuthenticator
}
