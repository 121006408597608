package tailwind.color

open class Opacity(private val intensity: Int) {
  override fun toString(): String {
    return "opacity-$intensity"
  }
}

interface OpacityPainter {
  fun opacity(opacity: Opacity)
}
