package api.traak

import firebase.auth.Auth

/** This enum allows us to represent the context in which a change to the [Auth] may happen. */
enum class AuthContext {
  /** Normal case, the user has signed in, signed out, ... */
  Normal,

  /** The user is registering. The auth change should not be taken into account */
  Registering,
}
