package firebase.firestore

fun where(
    fieldPath: String,
    operator: WhereOperator,
    value: Any,
): QueryConstraint = where(fieldPath, operator.toString(), value)

fun <T : DocumentData> Query<T>.where(
    fieldPath: String,
    operator: WhereOperator,
    value: Any,
): Query<T> = query(this, where(fieldPath, operator.toString(), value))

fun <T : DocumentData> Query<T>.orderBy(
    fieldPath: String,
    direction: OrderByDirection = OrderByDirection.ASC,
): Query<T> = query(this, orderBy(fieldPath, direction.toString()))

fun <T : DocumentData> Query<T>.ifMatches(
    condition: Boolean,
    modify: Query<T>.() -> Query<T>,
): Query<T> = this.let { query -> if (condition) query else modify(query) }
