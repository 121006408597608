package ui.components.navmenu

import androidx.compose.runtime.Composable
import app.softwork.routingcompose.Router
import navigation.navigate
import org.jetbrains.compose.web.dom.AttrBuilderContext
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Text
import org.w3c.dom.HTMLDivElement
import tailwind.*
import tailwind.color.Transparent
import ui.strings.LocalStrings

@Composable
fun NavmenuItem(
    tab: DescriptiveTab,
    active: Boolean,
    attrs: AttrBuilderContext<HTMLDivElement>? = null,
) {
  val router = Router.current

  Div(
      attrs = {
        attrs?.invoke(this)

        onClick { router.navigate(tab.route) }

        tailwind {
          position(TailwindScope.Position.Relative)
          h(Full)
          pr(3)

          cursor(TailwindScope.Cursor.Pointer)

          flex(
              direction = FlexScope.Direction.Row,
              justify = Layout.Justify.Center,
              alignItems = Layout.AlignItems.Center,
              gap = 3,
          )

          text(color = if (active) navmenu.activeColor else navmenu.inactiveColor) { button() }

          transition(duration = navmenu.transitionDuration)

          hover { text(color = navmenu.activeColor) }
        }
      }) {
        if (active) tab.activeIcon() else tab.inactiveIcon()
        Text(tab.description(LocalStrings.current))

        Indicator(
            attrs = {
              tailwind {
                w(2.third)
                border(color = if (active) navmenu.activeColor else Transparent) {
                  2.width(side = Side.Bottom)
                }
                transition(duration = navmenu.transitionDuration)
              }
            })
      }
}
