package ui.screens.newTeam

import api.traak.StorageResult

/** State holder for [NewTeam] screen */
interface NewTeamState {
  /** Attribute of the form. */
  var teamName: String

  /** Name of the contact person */
  var contactName: String

  /** Email of the contact person */
  var email: String

  /** Phone number of the contact person */
  var phone: String

  /** Billing address */
  var address: String

  /** Billing address locality */
  var locality: String

  /** Billing address ZIP Code */
  var zip: String

  /** Result of team creation */
  val result: Result?

  /** Are we waiting for a server response */
  val loading: Boolean

  /** Creates the new team */
  fun createTeam(): Unit

  sealed class Result {
    class Storage(val result: StorageResult) : Result()

    object MissingData : Result()
  }
}
