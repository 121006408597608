package ui.screens.projectDetail

import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.runtime.setValue
import api.traak.ExportType
import api.traak.LocalTraakApi
import api.traak.Project
import api.traak.Task
import api.traak.Team
import api.traak.TraakApi
import app.softwork.routingcompose.Router
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.flow.collectLatest
import kotlinx.coroutines.launch
import kotlinx.datetime.Clock
import kotlinx.datetime.Instant
import navigation.Route
import navigation.navigate
import ui.data.Loadable
import ui.data.asLoadable
import ui.data.map
import ui.data.toList

class ProjectDetailStateHolder(
    private val projectId: Project.Id,
    private val teamId: Team.Id,
    private val api: TraakApi,
    private val router: Router,
    private val coroutineScope: CoroutineScope,
) : ProjectDetailState {
  init {
    coroutineScope.launch { api.project(projectId).asLoadable().collectLatest { _project = it } }
    coroutineScope.launch {
      api.tasksForProject(teamId = teamId, projectId = projectId).collectLatest {
        watchTasks(it)

        _tasks = Loadable.Loaded(it)
      }
    }
  }

  private var _project: Loadable<Project> by mutableStateOf(Loadable.Loading)
  private var _tasks: Loadable<List<Task>> by mutableStateOf(Loadable.Loading)
  private var _statusIsChanging by mutableStateOf(false)
  private var _currentStatus: Loadable<Project.Status> by mutableStateOf(Loadable.Loading)

  override val project: Loadable<Project>
    get() = _project

  override val tasks: Loadable<List<Task>>
    get() = _tasks

  override val statusIsChanging: Boolean
    get() = _statusIsChanging

  override val currentStatus: Loadable<Project.Status>
    get() = _currentStatus

  override fun export() {
    coroutineScope.launch {
      api.exportView(
          teamId,
          ExportType.Project,
          projectId.raw,
          Instant.fromEpochMilliseconds(0),
          Clock.System.now(),
      )
    }
  }

  override fun edit() {
    if (statusIsChanging) {
      return
    }

    router.navigate(Route.ProjectEdition(projectId))
  }

  override fun archive() {
    changeStatusTo(Project.Status.Archived)
  }

  override fun restore() {
    changeStatusTo(Project.Status.Active)
  }

  private fun changeStatusTo(status: Project.Status) {
    if (project.get()?.status == status || _statusIsChanging) {
      return
    }

    coroutineScope.launch {
      // Ideally `_statusIsChanging` would be reset to false when the triggered CF has finished. In
      // our case, this is checked via the tasks: they should all have the new status set once the
      // CF has terminated. When this is the case, `_statusIsChanging` will be set to false. See the
      // `watchTasks` method
      _statusIsChanging = true

      api.changeProjectStatus(
          teamId = teamId,
          projectId = projectId,
          status = status,
      )

      // If there are no tasks, we don't want to wait on them to change the `_statusIsChanging`
      // field.
      if (_tasks.toList().isEmpty()) {
        finalizeStatusChange()
      }
    }
  }

  private fun watchTasks(tasks: List<Task>) {
    val allTaskHaveChangedStatus =
        tasks.map { it.project?.status }.all { it == _project.get()?.status }

    if (allTaskHaveChangedStatus) {
      finalizeStatusChange()
    }
  }

  private fun finalizeStatusChange() {
    _statusIsChanging = false
    _currentStatus = _project.map { it.status }
  }
}

@Composable
fun rememberDetailProjectState(
    projectId: Project.Id,
    teamId: Team.Id,
    api: TraakApi = LocalTraakApi.current,
    router: Router = Router.current,
    coroutineScope: CoroutineScope = rememberCoroutineScope(),
): ProjectDetailState {
  return remember {
    ProjectDetailStateHolder(
        projectId = projectId,
        teamId = teamId,
        api = api,
        router = router,
        coroutineScope = coroutineScope,
    )
  }
}
