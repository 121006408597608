package tailwind.color

val Opacity000 = Opacity(0)
val Opacity005 = Opacity(5)
val Opacity010 = Opacity(10)
val Opacity020 = Opacity(20)
val Opacity030 = Opacity(30)
val Opacity040 = Opacity(40)
val Opacity050 = Opacity(50)
val Opacity060 = Opacity(60)
val Opacity070 = Opacity(70)
val Opacity080 = Opacity(80)
val Opacity090 = Opacity(90)
val Opacity100 = Opacity(100)
