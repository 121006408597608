package ui.images

import androidx.compose.runtime.Composable
import org.jetbrains.compose.web.dom.Img
import tailwind.TailwindScope
import tailwind.tailwind

@Composable
internal fun TraakAbstract(
    tailwindAttr: TailwindScope.() -> Unit = {},
) =
    Img(
        src = "/images/TraakAbstract.png",
        alt = "Traak abstract",
        attrs = { tailwind(tailwindAttr) })
