package api.traak

import api.traak.user.User
import kotlinx.datetime.Instant

interface Recap {
  /** The id of the recap. */
  val id: Id

  /** The id of the user the recap belongs to. */
  val user: User.Id

  /** The id of the team the recap belongs to. */
  val team: Team.Id

  /** The creation date of the recap. */
  val creationDate: Instant

  /** The description of the recap. */
  val description: String

  data class Id(val raw: String)
}
