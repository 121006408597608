package ui.screens.projectOverview

import androidx.compose.runtime.Composable
import org.jetbrains.compose.web.dom.AttrBuilderContext
import org.w3c.dom.HTMLDivElement
import tailwind.Full
import tailwind.tailwind
import ui.components.LoadingPlaceholder
import ui.material.Card
import ui.material.ClickableListItem

@Composable
fun Loading(
    attrs: AttrBuilderContext<HTMLDivElement>? = null,
) {
  Card(attrs = { attrs?.invoke(this) }) {
    (1..3).forEach { _ ->
      ClickableListItem {
        LoadingPlaceholder(
            attrs = {
              tailwind {
                w(Full)
                h(4)
              }
            })
      }
    }
  }
}
