package api.traak.user

import api.traak.Team
import api.traak.Traak
import api.traak.authManager.phone.PhoneAuthenticator
import firebase.auth.Auth
import firebase.auth.ErrorCode
import firebase.auth.Profile
import firebase.auth.User as AuthUser
import firebase.auth.toErrorCode
import firebase.auth.updateEmail
import firebase.auth.updateProfile
import kotlinx.coroutines.await
import kotlinx.coroutines.flow.Flow

class TraakUser(
    private val api: Traak,
    private val auth: Auth,
    private val user: AuthUser,
) : User {

  override val displayName: String?
    get() = user.displayName

  override val email: String?
    get() = user.email

  override val phoneNumber: String?
    get() = user.phoneNumber

  override val id: User.Id
    get() = User.Id(user.uid)

  override val teams: Flow<List<Team>> = api.teamsOf(id)
  override val phoneAuthenticator: PhoneAuthenticator
    get() = api

  override suspend fun updateDisplayName(displayName: String) {
    val currentUser = auth.currentUser ?: return

    updateProfile(
        currentUser,
        object : Profile {
          override val displayName = displayName
          override val photoURL = null
        },
    )

    api.authManager.refreshUserData()
  }

  override suspend fun updateEmail(newEmail: String): EmailUpdateResult {
    val currentUser = auth.currentUser ?: return EmailUpdateResult.NoUser
    var result = EmailUpdateResult.Success

    try {
      updateEmail(user = currentUser, newEmail = newEmail).await()
    } catch (t: Throwable) {
      console.error(t.message)
      result = t.toErrorCode()?.toEmailUpdateResult() ?: EmailUpdateResult.UnknownFailure
    }

    api.authManager.refreshUserData()

    return result
  }

  override suspend fun disconnect() {
    auth.signOut().await()
    api.authManager.refreshUserData()
  }
}

private fun ErrorCode.toEmailUpdateResult(): EmailUpdateResult {
  return when (this) {
    ErrorCode.CREDENTIAL_TOO_OLD_LOGIN_AGAIN -> EmailUpdateResult.NeedsRelogin
    ErrorCode.EMAIL_EXISTS -> EmailUpdateResult.EmailAlreadyInUse
    else -> EmailUpdateResult.UnknownFailure
  }
}
