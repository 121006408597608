package ui.screens.tasks

import androidx.compose.runtime.Composable
import api.traak.Project
import api.traak.Team
import api.traak.user.User
import kotlinx.coroutines.flow.map
import org.jetbrains.compose.web.dom.AttrBuilderContext
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.H4
import org.jetbrains.compose.web.dom.Text
import org.w3c.dom.HTMLDivElement
import tailwind.FlexItemScope
import tailwind.Full
import tailwind.Layout
import tailwind.TextScope
import tailwind.tailwind
import ui.components.calendar.CalendarState
import ui.components.calendar.rememberCalendarState
import ui.components.chips.calendar.CalendarChip
import ui.components.chips.checkbox.searchable.SearchableCheckboxState
import ui.components.chips.people.rememberPeopleChipState
import ui.components.chips.projects.rememberProjectsChipState
import ui.components.table.Table
import ui.components.table.TableColumn
import ui.components.table.TableOrdering
import ui.components.table.TableSearch
import ui.layouts.FullScreenLayout
import ui.material.Column
import ui.material.FullColumn
import ui.material.FullRow
import ui.material.Spacer
import ui.strings.LocalStrings

@Composable
fun Tasks(
    team: Team,
    attrs: AttrBuilderContext<HTMLDivElement>? = null,
) {
  val calendarState = rememberCalendarState()
  val peopleState = rememberPeopleChipState(team)
  val projectsState = rememberProjectsChipState(team)
  val tasksState =
      rememberTasksState(
          team = team,
          dayRange = calendarState.range,
          people = peopleState.selectedItems.map { list -> list.map { User.Id(it.id) } },
          projects = projectsState.selectedItems.map { list -> list.map { Project.Id(it.id) } },
      )

  Tasks(
      calendarState = calendarState,
      peopleState = peopleState,
      projectsState = projectsState,
      tasksState = tasksState,
      columns = taskTableColumns,
      attrs = attrs,
  )
}

@Composable
fun Tasks(
    calendarState: CalendarState,
    peopleState: SearchableCheckboxState,
    projectsState: SearchableCheckboxState,
    tasksState: TasksState,
    columns: List<TableColumn<UiTask>>,
    attrs: AttrBuilderContext<HTMLDivElement>?,
) {
  val strings = LocalStrings.current

  FullScreenLayout(
      attrs = {
        attrs?.invoke(this)
        tailwind { py(16) }
      },
  ) {
    FullColumn(gap = 0) {
      TitleRow()

      FullRow(
          justify = Layout.Justify.Start,
          alignItems = Layout.AlignItems.Start,
          gap = 16,
      ) {
        Column(
            gap = 4,
            attrs = { tailwind { flexItem(grow = FlexItemScope.Grow.Grow) } },
        ) {
          FormControlsRow(calendarState, peopleState, projectsState)

          Div(
              attrs = { tailwind { w(Full) } },
          ) {
            Table(
                columns = columns,
                elements = tasksState.tasks,
                search =
                    TableSearch(
                        searchInput = tasksState.search,
                        onSearchInput = { tasksState.search = it },
                        placeholder = strings.allTasksSearchPlaceholder,
                    ),
                summary = Summary(tasksState),
                ordering =
                    TableOrdering(
                        current = 0 to tasksState.dateOrdering,
                        onHeaderClick = {
                          tasksState.dateOrdering = tasksState.dateOrdering.toggle()
                        },
                    ),
                attrs = { tailwind { w(Full) } },
            )
          }
        }
      }
    }
  }
}

@Composable
private fun TitleRow() {
  FullRow(
      attrs = { tailwind { my(8) } },
  ) {
    val strings = LocalStrings.current

    H4(
        attrs = {
          tailwind {
            text {
              align(TextScope.Align.Left)
              headline4()
            }
          }
        },
    ) {
      Text(strings.allTasksTitle)
    }

    Spacer()

    // Add later
    // SmallOutlineButton(
    //    text = strings.export,
    //    onClick = {},
    // )
  }
}

@Composable
private fun FormControlsRow(
    calendarState: CalendarState,
    peopleState: SearchableCheckboxState,
    projectsState: SearchableCheckboxState,
    attrs: AttrBuilderContext<HTMLDivElement>? = null,
) {
  FullRow(
      justify = Layout.Justify.End,
      gap = 4,
      attrs = {
        attrs?.invoke(this)
        tailwind { mb(4) }
      },
  ) {
    CalendarChip(calendarState)
    peopleState.DisplayCheckbox(null)
    projectsState.DisplayCheckbox(null)
  }
}
