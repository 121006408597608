package ui.screens.projectDetail

import ArchiveOutline
import Edit
import FileExcelOutline
import RestoreOutline
import androidx.compose.runtime.Composable
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import api.traak.Project
import api.traak.Task
import api.traak.Team
import app.softwork.routingcompose.Router
import navigation.Route
import navigation.navigate
import org.jetbrains.compose.web.dom.AttrBuilderContext
import org.jetbrains.compose.web.dom.H6
import org.jetbrains.compose.web.dom.Text
import org.w3c.dom.HTMLDivElement
import tailwind.FlexItemScope
import tailwind.Layout
import tailwind.tailwind
import ui.color.BlackDark
import ui.components.navigationBar.NavigationBar
import ui.components.taskCreator.TaskCreator
import ui.components.taskCreator.TaskCreatorState
import ui.components.taskCreator.rememberTaskCreatorState
import ui.components.taskTable.OrderedBy
import ui.components.taskTable.TaskTable
import ui.components.taskTable.TaskTableMode
import ui.components.taskTable.TaskTableState
import ui.components.taskTable.rememberTaskTableState
import ui.data.Loadable
import ui.layouts.FullScreenLayout
import ui.material.Row
import ui.material.SmallFilledButton
import ui.material.SmallOutlineButton
import ui.material.SmallTag
import ui.strings.LocalStrings

private fun Int.toOrderedBy(): OrderedBy? =
    when (this) {
      0 -> OrderedBy.Date
      1 -> OrderedBy.Origin
      else -> null
    }

@Composable
fun ProjectDetail(
    projectId: Project.Id,
    team: Team,
    attrs: AttrBuilderContext<HTMLDivElement>? = null,
) {
  val router = Router.current
  val strings = LocalStrings.current

  val state = rememberDetailProjectState(projectId = projectId, teamId = team.id)

  val projectToAddTaskTo = remember(state.project) { mutableStateOf(state.project.get()) }

  // Task creator/editor
  val taskCreatorState =
      rememberTaskCreatorState(
          team = team,
          initialProject = projectToAddTaskTo.value,
      )

  // Search & Filter
  val taskTableState =
      rememberTaskTableState(
          mode = TaskTableMode.Project,
          loadableTasks = state.tasks,
          taskEditorState = taskCreatorState,
          toOrderedBy = { toOrderedBy() },
      )

  ProjectDetail(
      loadableProject = state.project,
      loadableTasks = state.tasks,
      integratedWithBexio = team.integration.bexio,
      addressSubtitle = strings.projectDetailAddressSubtitle,
      taskTableState = taskTableState,
      taskCreatorState = taskCreatorState,
      onBackArrowClick = { router.navigate(Route.ProjectOverview) },
      onEditProjectClick = { state.edit() },
      statusIsChanging = state.statusIsChanging,
      currentStatus = state.currentStatus,
      onArchiveClick = { state.archive() },
      onRestoreClick = { state.restore() },
      onExportClick = { state.export() },
      editProjectButtonText = strings.projectDetailEditProjectButton,
      archiveProjectButtonText = strings.projectDetailArchiveButton,
      restoreProjectButtonText = strings.projectDetailRestoreButton,
      exportProjectButtonText = strings.projectDetailExportProjectButton,
      archivedStatusText = strings.projectStatusArchived,
      attrs = attrs,
  )
}

@Composable
fun ProjectDetail(
    loadableProject: Loadable<Project>,
    loadableTasks: Loadable<List<Task>>,
    integratedWithBexio: Boolean,
    addressSubtitle: String,
    taskTableState: TaskTableState,
    taskCreatorState: TaskCreatorState,
    onBackArrowClick: () -> Unit,
    onEditProjectClick: () -> Unit,
    statusIsChanging: Boolean,
    currentStatus: Loadable<Project.Status>,
    onArchiveClick: () -> Unit,
    onRestoreClick: () -> Unit,
    onExportClick: () -> Unit,
    editProjectButtonText: String,
    archiveProjectButtonText: String,
    restoreProjectButtonText: String,
    exportProjectButtonText: String,
    archivedStatusText: String,
    attrs: AttrBuilderContext<HTMLDivElement>? = null,
) {
  FullScreenLayout(attrs = attrs) {
    NavigationBar(
        onBackArrowClick = onBackArrowClick,
        title = {
          H6(attrs = { tailwind { text(color = BlackDark) { headline6() } } }) {
            Text(loadableProject.get()?.title ?: "")
          }

          if (currentStatus.get() == Project.Status.Archived) {
            SmallTag(
                text = archivedStatusText,
                attrs = { tailwind { ml(4) } },
            )
          }
        },
    ) {
      Row(gap = 4) {
        if (!integratedWithBexio) {
          if (currentStatus == Loadable.Loaded(Project.Status.Active)) {
            SmallOutlineButton(
                text = editProjectButtonText,
                onClick = onEditProjectClick,
                loading = statusIsChanging,
                icon = { Edit() },
            )
            SmallOutlineButton(
                text = archiveProjectButtonText,
                onClick = { onArchiveClick() },
                loading = statusIsChanging,
                icon = { ArchiveOutline() },
            )
          } else {
            SmallOutlineButton(
                text = restoreProjectButtonText,
                onClick = { onRestoreClick() },
                loading = statusIsChanging,
                icon = { RestoreOutline() },
            )
          }
        }

        SmallFilledButton(
            text = exportProjectButtonText,
            onClick = onExportClick,
            icon = { FileExcelOutline() },
        )
      }
    }

    Row(
        justify = Layout.Justify.Start,
        alignItems = Layout.AlignItems.Start,
        gap = 16,
    ) {
      Metadata(
          loadableProject = loadableProject,
          addressSubtitle = addressSubtitle,
      )

      when (loadableTasks) {
        is Loadable.Loading -> {}
        is Loadable.Loaded -> {
          TaskTable(
              state = taskTableState,
              attrs = {
                tailwind {
                  pt(10)
                  flexItem(grow = FlexItemScope.Grow.Grow)
                }
              },
          )
        }
      }
    }

    if (currentStatus.get() == Project.Status.Active) {
      TaskCreator(taskCreatorState)
    }
  }
}
