package ui.layouts

import androidx.compose.runtime.Composable
import org.jetbrains.compose.web.dom.AttrBuilderContext
import org.w3c.dom.HTMLDivElement
import tailwind.fourth
import tailwind.tailwind

@Composable
fun ThreeQuartersLayout(
    attrs: AttrBuilderContext<HTMLDivElement>? = null,
    content: @Composable () -> Unit,
) =
    CenteredLayout(
        attrs = {
          attrs?.invoke(this)
          tailwind { w(3.fourth) }
        },
        content = content,
    )
