package tailwind

open class Distance(distance: Int? = null) {
  val prefix = distance?.let { if (it < 0) "-" else null }
}

class Half(private val value: Int) : Distance(value) {
  override fun toString(): String {
    return "$value/2"
  }
}

class Third(private val value: Int) : Distance(value) {
  override fun toString(): String {
    return "$value/3"
  }
}

class Fourth(private val value: Int) : Distance(value) {
  override fun toString(): String {
    return "$value/4"
  }
}

class Fifth(private val value: Int) : Distance(value) {
  override fun toString(): String {
    return "$value/5"
  }
}

class Seventh(private val value: Int) : Distance(value) {
  override fun toString(): String {
    return "$value/7"
  }
}

class Twelfth(private val value: Int) : Distance(value) {
  override fun toString(): String {
    return "$value/12"
  }
}

object Full : Distance(), GridItemScope.Span {
  override fun toString(): String {
    return "full"
  }
}

object Screen : Distance() {
  override fun toString(): String {
    return "screen"
  }
}

object Max : Distance() {
  override fun toString(): String {
    return "max"
  }
}

object Min : Distance() {
  override fun toString(): String {
    return "min"
  }
}

object Auto : Distance(), TailwindScope.Margin, GridItemScope.Span, GridItemScope.Placement {
  override fun toString(): String {
    return "auto"
  }
}

val Int.half: Distance
  get() = Half(this)

val Int.third: Distance
  get() = Third(this)

val Int.fourth: Distance
  get() = Fourth(this)

val Int.fifth: Distance
  get() = Fifth(this)

val Int.seventh: Distance
  get() = Seventh(this)

val Int.twelfth: Distance
  get() = Twelfth(this)
