package firebase.firestore

@JsNonModule
@JsModule("@firebase/firestore")
open external class DocumentSnapshot<T : DocumentData> {
  val id: String

  fun data(options: dynamic = definedExternally): T
}

fun <T : DocumentData> DocumentSnapshot<T>.getWithId(): T {
  val data = this.data()
  data.asDynamic().id = this.id
  return data
}
