package ui.screens.teamManagement

import androidx.compose.runtime.Composable
import api.traak.Team
import org.jetbrains.compose.web.dom.AttrBuilderContext
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.P
import org.jetbrains.compose.web.dom.Text
import org.w3c.dom.HTMLDivElement
import tailwind.Full
import tailwind.TailwindScope
import tailwind.TextScope
import tailwind.tailwind
import ui.color.BlackMedium
import ui.color.Primary100
import ui.color.Primary600
import ui.components.PageTitle
import ui.components.Tooltip
import ui.components.TooltipContainer
import ui.icons.Copy
import ui.material.Row
import ui.strings.LocalStrings

@Composable
fun Invitation(
    teamId: Team.Id,
    attrs: AttrBuilderContext<HTMLDivElement>? = null,
) {
  val strings = LocalStrings.current
  val state = rememberInvitationState(teamId)

  Invitation(
      title = strings.teamSettingsInvitationTitle,
      explanation = strings.teamSettingsInvitationExplanation,
      explanationAfterCopy = strings.teamSettingsInvitationExplanationAfterCopy,
      joinLink = state.joinLink,
      copied = strings.teamSettingsInvitationCopied,
      onCopyClick = { state.onCopyClick() },
      showCopyTooltip = state.showCopyTooltip,
      attrs = attrs,
  )
}

@Composable
fun Invitation(
    title: String,
    explanation: String,
    explanationAfterCopy: String,
    joinLink: String,
    copied: String,
    onCopyClick: () -> Unit,
    showCopyTooltip: Boolean,
    attrs: AttrBuilderContext<HTMLDivElement>? = null,
) {
  Div(attrs) {
    PageTitle(title)

    P(attrs = { tailwind { text(color = BlackMedium) { subtitle1() } } }) { Text(explanation) }

    Row(
        attrs = {
          tailwind {
            m(4)
            p(4)

            background(color = Primary100)
            round(TailwindScope.Radius.Medium)
          }
        }) {
          Div(
              attrs = {
                tailwind {
                  w(Full)
                  text(color = BlackMedium) {
                    subtitle2(family = TextScope.Family.Mono, size = TextScope.Size.Base)
                  }
                  select(TailwindScope.SelectType.All)
                }
              }) {
                Text(joinLink)
              }

          TooltipContainer {
            Div(
                attrs = {
                  onClick { onCopyClick() }

                  tailwind {
                    text(color = BlackMedium)
                    cursor(TailwindScope.Cursor.Pointer)

                    hover { text(color = Primary600) }
                  }
                }) {
                  Copy()
                }

            if (showCopyTooltip) {
              Tooltip(attrs = { tailwind { top(8) } }) { Text(copied) }
            }
          }
        }

    P(
        attrs = {
          tailwind {
            mb(4)
            text(color = BlackMedium) { subtitle1() }
          }
        }) {
          Text(explanationAfterCopy)
        }
  }
}
