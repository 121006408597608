package ui.components.progressionPanel

import androidx.compose.runtime.Composable
import androidx.compose.runtime.remember
import kotlin.enums.EnumEntries
import ui.strings.LocalStrings
import ui.strings.LocalizedStrings

interface ProgressionPanelEnum {
  fun toLocalizedString(strings: LocalizedStrings): String
}

/**
 * Creates a corresponding [ProgressionPanelState] for a given enumeration with the [currentState]
 * being the selected item. Assumes that the enumeration order follows the order that is displayed.
 * If it isn't so, please implement [ProgressionPanelState] manually.
 */
@Composable
fun <E> rememberProgressionPanelState(
    currentState: E,
    enumEntries: EnumEntries<E>,
    strings: LocalizedStrings = LocalStrings.current,
): ProgressionPanelState where E : Enum<E>, E : ProgressionPanelEnum {
  return remember(currentState, strings) {
    toProgressionPanelState(
        currentState = currentState,
        enumEntries = enumEntries,
        strings = strings,
    )
  }
}

fun <E> toProgressionPanelState(
    currentState: E,
    enumEntries: EnumEntries<E>,
    strings: LocalizedStrings,
): ProgressionPanelState where E : Enum<E>, E : ProgressionPanelEnum {
  return object : ProgressionPanelState {
    override val progression: List<Step>
      get() = enumEntries.map { it.toStep(currentState, strings) }
  }
}

fun <E> E.toStep(currentState: E, strings: LocalizedStrings): Step where
E : Enum<E>,
E : ProgressionPanelEnum {
  return Step(
      indicator = this.ordinal.plus(1).toString(),
      text = this.toLocalizedString(strings),
      state = this.ordinal.toStepState(currentState.ordinal),
  )
}

private fun Int.toStepState(currentStepOrdinal: Int): StepState =
    when {
      this < currentStepOrdinal -> StepState.Done
      this == currentStepOrdinal -> StepState.Current
      else -> StepState.Next
    }
