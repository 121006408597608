package ui.screens.projectEdition

import androidx.compose.runtime.Composable
import org.jetbrains.compose.web.dom.AttrBuilderContext
import org.w3c.dom.HTMLDivElement
import tailwind.Full
import tailwind.tailwind
import ui.components.LoadingPlaceholder
import ui.material.Column

@Composable
fun LoadingForm(
    attrs: AttrBuilderContext<HTMLDivElement>? = null,
) {
  Column(
      gap = 3,
      attrs = attrs,
  ) {
    val sharedAttrs: AttrBuilderContext<HTMLDivElement> = {
      tailwind {
        w(Full)
        h(4)
        m(2)
      }
    }

    LoadingPlaceholder(sharedAttrs)
    LoadingPlaceholder(sharedAttrs)
    LoadingPlaceholder(sharedAttrs)
    LoadingPlaceholder(sharedAttrs)
  }
}
