package ui.components.table

import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import org.jetbrains.compose.web.css.gridColumn
import org.jetbrains.compose.web.dom.AttrBuilderContext
import org.jetbrains.compose.web.dom.Div
import org.w3c.dom.HTMLDivElement
import tailwind.Full
import tailwind.TailwindScope
import tailwind.TextScope
import tailwind.TransitionScope
import tailwind.tailwind
import ui.color.BlackLight
import ui.color.BlackMedium
import ui.color.Primary100
import ui.icons.MenuDown
import ui.icons.MenuUp
import ui.material.Row
import utils.Ordering

@Composable
fun TableHeader(
    headers: List<TableColumnHeader>,
    gridContainer: AttrBuilderContext<HTMLDivElement> = {},
    ordering: TableOrdering? = null,
) {
  var headerIsHovered by remember { mutableStateOf(false) }

  Div(
      attrs = {
        onMouseEnter { headerIsHovered = true }
        onMouseLeave { headerIsHovered = false }

        style { this@Div.gridContainer() }
        tailwind {
          w(Full)
          px(4)
          py(2)

          background(Primary100)
          round(TailwindScope.Radius.Large)

          text { overline(weight = TextScope.Weight.Normal, size = TextScope.Size.Xs) }
        }
      },
  ) {
    headers.forEachIndexed { index, header ->
      val columnIndex = (index + 1)
      val isActive: Boolean = ordering?.current?.first == index

      Row(
          attrs = {
            style { gridColumn(columnIndex, columnIndex) }
            ordering?.let { onClick { ordering.onHeaderClick(index) } }
          },
      ) {
        header.display()

        if (ordering != null && (headerIsHovered || isActive) && header.isOrderable) {
          val iconAttrs: AttrBuilderContext<HTMLDivElement> = {
            tailwind {
              cursor(TailwindScope.Cursor.Pointer)

              text(color = BlackLight)
              hover { text(color = BlackMedium) }
              transition(TransitionScope.Property.All, TransitionScope.Duration.Duration0150)
            }
          }

          when (ordering.current.second) {
            Ordering.ASC -> MenuUp(iconAttrs)
            Ordering.DESC -> MenuDown(iconAttrs)
          }
        }
      }
    }
  }
}
