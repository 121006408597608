package api.settings

enum class Language {
  French,
  English,
  German,
  Portuguese;

  companion object {
    fun fromString(string: String?): Language? = values().find { it.toString() == string }
  }
}
