package ui.screens.account

import androidx.compose.runtime.Composable
import org.jetbrains.compose.web.dom.H6
import org.jetbrains.compose.web.dom.Text
import tailwind.Layout
import tailwind.TailwindScope
import tailwind.color.White
import tailwind.tailwind
import ui.color.Primary200
import ui.material.FullColumn

@Composable
fun SettingsSection(
    sectionTitle: String,
    gap: Int = 6,
    content: @Composable () -> Unit,
) {
  FullColumn(
      alignItems = Layout.AlignItems.Start,
      justify = Layout.Justify.Start,
      gap = 3,
  ) {
    H6(
        attrs = { tailwind { text { overline() } } },
    ) {
      Text(sectionTitle)
    }

    FullColumn(
        gap = gap,
        attrs = {
          tailwind {
            p(4)

            background(White)
            round(amount = TailwindScope.Radius.Medium)
            border(color = Primary200, width = 1)
          }
        },
    ) {
      content()
    }
  }
}
