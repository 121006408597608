package ui.screens.tasks

import androidx.compose.runtime.Composable
import kotlin.time.Duration
import org.jetbrains.compose.web.dom.Span
import org.jetbrains.compose.web.dom.Text
import tailwind.TextScope
import tailwind.tailwind
import ui.components.table.TableSummary
import ui.components.taskTable.display
import ui.strings.LocalStrings

@Composable
fun Display(duration: Duration) {
  val strings = LocalStrings.current

  Span(
      attrs = {
        tailwind {
          mr(6)
          text { overline(weight = TextScope.Weight.Normal, size = TextScope.Size.Xs) }
        }
      }) {
        Text(strings.taskTableTotalTime)
      }

  duration.display()
}

fun Summary(state: TasksState): TableSummary =
    TableSummary(display = { Display(state.totalDuration) })
