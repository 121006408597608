package ui.material

import androidx.compose.runtime.Composable
import org.jetbrains.compose.web.dom.AttrBuilderContext
import org.jetbrains.compose.web.dom.Div
import org.w3c.dom.HTMLDivElement
import tailwind.FlexScope
import tailwind.Full
import tailwind.Layout
import tailwind.tailwind

@Composable
fun Column(
    justify: Layout.Justify = Layout.Justify.Start,
    alignItems: Layout.AlignItems = Layout.AlignItems.Center,
    gap: Int? = null,
    attrs: AttrBuilderContext<HTMLDivElement>? = null,
    content: @Composable () -> Unit,
) {
  Div(
      attrs = {
        attrs?.invoke(this)
        tailwind {
          flex(
              direction = FlexScope.Direction.Column,
              justify = justify,
              alignItems = alignItems,
              gap = gap,
          )
        }
      }) {
        content()
      }
}

@Composable
fun FullColumn(
    justify: Layout.Justify = Layout.Justify.Start,
    alignItems: Layout.AlignItems = Layout.AlignItems.Center,
    gap: Int? = null,
    attrs: AttrBuilderContext<HTMLDivElement>? = null,
    content: @Composable () -> Unit,
) =
    Column(
        justify = justify,
        alignItems = alignItems,
        gap = gap,
        attrs = {
          attrs?.invoke(this)
          tailwind { w(Full) }
        },
        content = content,
    )
