package tailwind

@TailwindDsl
interface FlexScope : Layout {
  enum class Direction {
    Row,
    RowReverse,
    Column,
    ColumnReverse;

    override fun toString(): String =
        when (this) {
          Row -> "row"
          RowReverse -> "row-reverse"
          Column -> "col"
          ColumnReverse -> "col-reverse"
        }
  }

  fun direction(type: Direction)

  enum class Wrap {
    Normal,
    Reverse,
    None;

    override fun toString(): String =
        when (this) {
          Normal -> "wrap"
          Reverse -> "wrap-reverse"
          None -> "nowrap"
        }
  }

  fun wrap(type: Wrap)
}
