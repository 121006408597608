package api.traak.fromFirestore

import api.traak.*
import api.traak.user.User
import firebase.firestore.DocumentData
import kotlinx.serialization.*
import kotlinx.serialization.json.Json

@Serializable
data class FromFirestoreMember(
    val firstName: String,
    val lastName: String,
    val id: String,
) {
  companion object {
    private val json = Json { ignoreUnknownKeys = true }

    fun withData(data: DocumentData): FromFirestoreMember =
        json.decodeFromString(JSON.stringify(data))
  }
}

fun FromFirestoreMember.toMember(api: TraakApi, team: Team): Member =
    TraakMember(
        api = api,
        team = team,
        firstName = this.firstName,
        lastName = this.lastName,
        id = User.Id(this.id),
        role = team.roleOf(User.Id(this.id)) ?: Role.MEMBER,
    )
