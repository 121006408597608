package api

import kotlinx.datetime.Clock
import kotlinx.datetime.LocalDate
import kotlinx.datetime.TimeZone
import kotlinx.datetime.toLocalDateTime

/** Returns the current date as a [LocalDate] */
fun getCurrentDate(): LocalDate {
  val current = Clock.System.now()
  return current.toLocalDateTime(TimeZone.currentSystemDefault()).date
}
