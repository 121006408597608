package ui.components

import androidx.compose.runtime.Composable
import org.jetbrains.compose.web.dom.AttrBuilderContext
import org.jetbrains.compose.web.dom.P
import org.jetbrains.compose.web.dom.Text
import org.w3c.dom.HTMLParagraphElement
import tailwind.tailwind
import ui.color.ErrorDark

@Composable
fun AuthErrorMessage(
    errorMessage: String?,
    attrs: AttrBuilderContext<HTMLParagraphElement>? = null,
) {
  errorMessage?.let {
    P(
        attrs = {
          attrs?.invoke(this)
          tailwind { text(color = ErrorDark) { subtitle2() } }
        },
    ) {
      Text(it)
    }
  }
}
