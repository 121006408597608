package ui.screens.projectDetail

import androidx.compose.runtime.Composable
import api.traak.Project
import org.jetbrains.compose.web.dom.*
import org.w3c.dom.HTMLDivElement
import tailwind.*
import ui.color.BlackDark
import ui.color.BlackMedium
import ui.icons.HomeOutline
import ui.icons.PhoneOutline
import ui.material.Card
import ui.material.Row
import ui.strings.LocalStrings

@Composable
fun ProjectAddress(
    project: Project,
    attrs: AttrBuilderContext<HTMLDivElement>? = null,
) {
  val showAddress =
      project.address.name.isNotBlank() ||
          project.address.place.isNotBlank() ||
          project.address.locality.isNotBlank()

  val showPhone = project.address.phone.isNotBlank()

  Card(
      attrs = {
        attrs?.invoke(this)
        tailwind {
          flex(
              direction = FlexScope.Direction.Column,
              gap = 4,
          )
          p(6)
        }
      }) {
        if (!showAddress && !showPhone) {
          EmptyAddress()
        } else {
          FilledAddress(showAddress, showPhone, project)
        }
      }
}

@Composable
fun FilledAddress(
    showAddress: Boolean,
    showPhone: Boolean,
    project: Project,
) {
  @Composable
  fun LocalRow(content: @Composable () -> Unit) =
      Row(alignItems = Layout.AlignItems.Start, gap = 4) { content() }

  if (showAddress) {
    LocalRow {
      AddressIcon { HomeOutline() }
      Div {
        AddressText { Text(project.address.name) }
        AddressText { Text(project.address.place) }
        AddressText { Text(project.address.locality) }
      }
    }
  }
  if (showPhone) {
    LocalRow {
      AddressIcon { PhoneOutline() }
      Div { AddressText { Text(project.address.phone) } }
    }
  }
}

@Composable
fun EmptyAddress() {
  val strings = LocalStrings.current

  H6(attrs = { tailwind { text(color = BlackDark) { headline6() } } }) {
    Text(strings.projectDetailEmptyAddressTitle)
  }
  P(attrs = { tailwind { text(color = BlackMedium) } }) {
    Text(strings.projectDetailEmptyAddressParagraph)
  }
}

@Composable
private fun AddressIcon(
    attrs: AttrBuilderContext<HTMLDivElement>? = null,
    content: @Composable () -> Unit,
) {
  Div(
      attrs = {
        attrs?.invoke(this)
        tailwind { text(color = BlackDark) }
      }) {
        content()
      }
}

@Composable
private fun AddressText(
    attrs: AttrBuilderContext<HTMLDivElement>? = null,
    content: @Composable () -> Unit,
) {
  Div(
      attrs = {
        attrs?.invoke(this)
        tailwind {
          verticalAlign(TailwindScope.VerticalAlignment.Middle)
          text(
              color = BlackDark,
              overflow = TextScope.Overflow.Truncate,
          ) {
            body2(weight = TextScope.Weight.Normal)
          }
        }
      }) {
        content()
      }
}
