package ui.helpers

import androidx.compose.runtime.Composable
import androidx.compose.runtime.DisposableEffect
import androidx.compose.runtime.rememberUpdatedState
import kotlinx.browser.window
import org.w3c.dom.events.EventListener

@Composable
private fun OnKeyDown(
    keyCode: Int,
    function: () -> Unit,
) {
  val updatedFunction = rememberUpdatedState(function)

  DisposableEffect(true) {
    val listener = EventListener { event ->
      if (event.asDynamic().keyCode == keyCode) {
        event.preventDefault()
        updatedFunction.value()
      }
    }

    window.addEventListener("keydown", listener)
    onDispose { window.removeEventListener("keydown", listener) }
  }
}

/**
 * When called, `onEnterDown` will add a listener on the `window`. When the enter key is pressed,
 * the given function will be executed.
 *
 * When this composable is no longer in the view, the listener will be removed.
 */
@Composable
fun onEnterDown(
    function: () -> Unit,
) {
  OnKeyDown(13, function)
}

/**
 * When called, `onEscDown` will add a listener on the `window`. When the escape key is pressed, the
 * given function will be executed.
 *
 * When this composable is no longer in the view, the listener will be removed.
 */
@Composable
fun OnEscDown(
    function: () -> Unit,
) {
  OnKeyDown(27, function)
}
