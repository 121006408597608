package api.traak.toFirestore

import api.traak.Project
import api.traak.Team
import api.traak.dto.CreateProjectDTO
import api.traak.dto.UpdateProjectDTO
import kotlinx.serialization.Serializable

@Serializable
data class Address(
    override val name: String,
    override val phone: String,
    override val place: String,
    override val locality: String,
) : Project.Address

@Serializable
data class ToFirestoreProject(
    val title: String,
    val address: Address,
    val status: String,
    val team: String,
) {
  companion object {
    fun fromDTO(
        teamId: Team.Id,
        createProjectDTO: CreateProjectDTO,
    ): ToFirestoreProject {
      val address =
          Address(
              name = createProjectDTO.name,
              phone = createProjectDTO.phone,
              place = createProjectDTO.street,
              locality = "${createProjectDTO.postalCode} ${createProjectDTO.locality}",
          )

      return ToFirestoreProject(
          title = createProjectDTO.title,
          address = address,
          team = teamId.raw,
          status = "active",
      )
    }

    fun fromDTO(
        teamId: Team.Id,
        updateProjectDTO: UpdateProjectDTO,
    ): ToFirestoreProject {
      val address =
          Address(
              name = updateProjectDTO.name,
              phone = updateProjectDTO.phone,
              place = updateProjectDTO.street,
              locality = updateProjectDTO.locality,
          )

      return ToFirestoreProject(
          title = updateProjectDTO.title,
          address = address,
          team = teamId.raw,
          status = "active",
      )
    }
  }
}

fun Project.Status.toFirestoreField(): String {
  return when (this) {
    Project.Status.Active -> "active"
    Project.Status.Archived -> "archived"
  }
}
