package api.bexio

import androidx.compose.runtime.staticCompositionLocalOf
import navigation.Route
import org.w3c.dom.url.URL

class Bexio(baseUrl: String) : BexioApi {
  override val integrationType = Bexio.integrationType
  override val redirectUri = "$baseUrl${Route.IntegrationBexio}"
  override val requiredScopes =
      listOf(
          "contact_edit",
          "email",
          "monitoring_edit",
          "note_edit",
          "offline_access",
          "openid",
          "profile",
          "project_edit",
          "task_edit",
      )

  override fun authorizeUrlWithScope(scopes: List<String>): URL {
    val url = URL(authorizeEndpoint)
    url.searchParams.append("redirect_uri", redirectUri)
    url.searchParams.append("response_type", "code")
    url.searchParams.append("client_id", clientId)
    url.searchParams.append("scope", scopes.joinToString(" "))

    return url
  }

  companion object {
    // Bexio's authentication server
    private const val server = "https://idp.bexio.com"
    private const val authorizeEndpoint = "$server/authorize"
    private const val clientId = "3a66aed4-5f9f-4551-bf31-677da95a5014"
    private const val integrationType = "bexio"
  }
}

val LocalBexioApi =
    staticCompositionLocalOf<BexioApi> { error("Bexio API has not been initialized yet.") }
