package api.traak

import api.traak.authManager.TraakAuthManager
import api.traak.authManager.TraakAuthUser
import api.traak.authManager.phone.PhoneAuthenticator
import api.traak.authManager.phone.TraakPhoneAuthenticator
import api.traak.dto.BillingAddress
import api.traak.dto.CreateProjectDTO
import api.traak.dto.TaskEditionDto
import api.traak.dto.UpdateProjectDTO
import api.traak.fromFirestore.FromFirestoreAccessRequest
import api.traak.fromFirestore.FromFirestoreMember
import api.traak.fromFirestore.FromFirestoreProject
import api.traak.fromFirestore.FromFirestoreRecap
import api.traak.fromFirestore.FromFirestoreTask
import api.traak.fromFirestore.FromFirestoreTeam
import api.traak.fromFirestore.Timestamp
import api.traak.fromFirestore.toAccessRequest
import api.traak.fromFirestore.toMember
import api.traak.fromFirestore.toProject
import api.traak.fromFirestore.toTask
import api.traak.fromFirestore.toTeam
import api.traak.toFirestore.Roles
import api.traak.toFirestore.SynchronizeBexioData
import api.traak.toFirestore.ToFirestoreCreateTeam
import api.traak.toFirestore.ToFirestoreProfile
import api.traak.toFirestore.ToFirestoreProject
import api.traak.toFirestore.ToFirestoreRecapEdition
import api.traak.toFirestore.ToFirestoreTaskEdition
import api.traak.toFirestore.ToFirestoreTeamAccessRequest
import api.traak.toFirestore.ToFirestoreUpdateTeamRoles
import api.traak.toFirestore.UpdateBexioAuthUserData
import api.traak.toFirestore.ValidateTokensData
import api.traak.toFirestore.toFirestoreField
import api.traak.user.TraakUser
import api.traak.user.User
import firebase.auth.Auth
import firebase.auth.OAuthProvider
import firebase.auth.Profile
import firebase.auth.User as AuthUser
import firebase.auth.createUserWithEmailAndPassword
import firebase.auth.signInWithEmailAndPassword
import firebase.auth.signInWithPopup
import firebase.firestore.CollectionReference
import firebase.firestore.DocumentData
import firebase.firestore.DocumentReference
import firebase.firestore.Firestore
import firebase.firestore.OrderByDirection
import firebase.firestore.WhereOperator
import firebase.firestore.addDoc
import firebase.firestore.asFlow
import firebase.firestore.collection
import firebase.firestore.deleteDoc
import firebase.firestore.doc
import firebase.firestore.getDoc
import firebase.firestore.getWithId
import firebase.firestore.orderBy
import firebase.firestore.setDoc
import firebase.firestore.updateDoc
import firebase.firestore.where
import firebase.functions.Functions
import firebase.functions.httpsCallable
import kotlinx.browser.document
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.await
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.filter
import kotlinx.coroutines.flow.first
import kotlinx.coroutines.flow.map
import kotlinx.datetime.Clock
import kotlinx.datetime.DatePeriod
import kotlinx.datetime.Instant
import kotlinx.datetime.LocalDate
import kotlinx.datetime.TimeZone
import kotlinx.datetime.atStartOfDayIn
import kotlinx.datetime.plus
import kotlinx.datetime.toJSDate
import kotlinx.serialization.Serializable
import org.w3c.dom.HTMLFormElement

class Traak(
    private val auth: Auth,
    private val firestore: Firestore,
    private val functions: Functions,
    private val cloudFunctionUrl: String,
    private val phoneAuthenticator: TraakPhoneAuthenticator,
    authCoroutineScope: CoroutineScope,
    private val baseUrl: String,
) : TraakApi, PhoneAuthenticator by phoneAuthenticator {
  private val tasksCollection = collection(firestore, Tasks.path)
  private val teamsCollection = collection(firestore, Teams.path)
  private val projectsCollection = collection(firestore, Projects.path)
  private val recapsCollection = collection(firestore, Recaps.path)

  // Authentication
  override val authManager = TraakAuthManager(auth, baseUrl, authCoroutineScope)
  private val authContext = MutableStateFlow(AuthContext.Normal)
  override val user: Flow<User?> = authManager.user.map { it?.toUser() }

  override suspend fun logIn(
      email: String,
      password: String,
  ): AuthResult =
      try {
        authContext.emit(AuthContext.Normal)
        signInWithEmailAndPassword(
                auth = auth,
                email = email,
                password = password,
            )
            .await()
        AuthResult.Success
      } catch (throwable: Throwable) {
        console.error(throwable.message)
        throwable.toAuthResult()
      }

  override suspend fun logInWithBexio(): AuthResult =
      try {
        val bexio = OAuthProvider("oidc.bexio")
        bexio.addScope("email")
        bexio.addScope("openid")

        signInWithPopup(auth, bexio).await()
        AuthResult.Success
      } catch (throwable: Throwable) {
        console.error(throwable.message)
        throwable.toAuthResult()
      }

  override fun logOut() {
    auth.signOut()
  }

  override suspend fun register(
      email: String,
      password: String,
      displayName: String,
  ): AuthResult =
      try {
        val profile =
            object : Profile {
              override val displayName = displayName
              override val photoURL: String? = null
            }

        authContext.emit(AuthContext.Registering)
        val credential =
            createUserWithEmailAndPassword(
                    auth = auth,
                    email = email,
                    password = password,
                )
                .await()

        updateAuthProfile(credential.user, profile)

        AuthResult.Success
      } catch (throwable: Throwable) {
        console.error(throwable.message)
        throwable.toAuthResult()
      }

  override suspend fun updateProfile(
      teamId: Team.Id,
      memberId: User.Id,
      profile: ToFirestoreProfile
  ) {
    try {
      updateDoc(
              reference = Teams.profilesDocument(teamId, memberId),
              data = profile.asFirestoreObject(),
          )
          .await()
    } catch (throwable: Throwable) {
      console.error(throwable.message)
    }
  }

  private fun updateAuthProfile(user: AuthUser, profile: Profile) {
    try {
      firebase.auth.updateProfile(user, profile)
    } catch (throwable: Throwable) {
      console.error(throwable.message)
    }
  }

  // Firebase

  override suspend fun createTeam(
      teamName: String,
      user: User,
      billingAddress: BillingAddress,
  ): StorageResult {
    val createTeamData =
        ToFirestoreCreateTeam.initializeWith(
            teamName = teamName,
            userId = user.id,
            billing = billingAddress,
        )

    val name = user.displayName ?: ""
    val firstName = name.split(" ").firstOrNull() ?: ""
    val lastName = name.split(" ").drop(1).joinToString(" ")

    val createProfileData =
        ToFirestoreProfile(
            name = name,
            firstName = firstName,
            lastName = lastName,
        )

    return try {
      val teamRef =
          addDoc(
                  reference = teamsCollection,
                  data = createTeamData.asFirestoreObject(),
              )
              .await()
      val newTeamId = Team.Id(teamRef.id)

      // Make sure that the team was fully populated by our cloud function before adding the profile
      Teams.ref(newTeamId)
          .asFlow()
          .map { FromFirestoreTeam.withData(it)?.toTeam(this, firestore) }
          .filter { it != null }
          .first()

      saveProfileInTeam(
          teamId = newTeamId,
          userId = user.id,
          data = createProfileData,
      )

      StorageResult.Success
    } catch (throwable: Throwable) {
      console.error(throwable.message)
      throwable.toStorageResult()
    }
  }

  override suspend fun createProject(
      teamId: Team.Id,
      project: CreateProjectDTO,
  ): StorageResult {
    val data =
        ToFirestoreProject.fromDTO(
            teamId = teamId,
            createProjectDTO = project,
        )

    return try {
      addDoc(
              reference = projectsCollection,
              data = data.asFirestoreObject(),
          )
          .await()
      StorageResult.Success
    } catch (t: Throwable) {
      console.error(t.message)
      t.toStorageResult()
    }
  }

  override suspend fun createTask(
      teamId: Team.Id,
      taskEditionDto: TaskEditionDto,
  ): StorageResult {
    val data =
        ToFirestoreTaskEdition.fromDTO(
            teamId = teamId,
            taskEditionDto = taskEditionDto,
        )

    return try {
      addDoc(
              reference = tasksCollection,
              data = data.asJSObject(),
          )
          .await()
      StorageResult.Success
    } catch (t: Throwable) {
      console.error(t.message)
      t.toStorageResult()
    }
  }

  override suspend fun editTask(
      taskId: Task.Id,
      taskEditionDto: TaskEditionDto,
  ): StorageResult {
    val data =
        ToFirestoreTaskEdition.fromDTO(
            taskEditionDto = taskEditionDto,
        )

    return try {
      updateDoc(
              reference = Tasks.ref(taskId),
              data = data.asJSObject(),
          )
          .await()
      StorageResult.Success
    } catch (t: Throwable) {
      console.error(t.message)
      t.toStorageResult()
    }
  }

  override suspend fun deleteTask(taskId: Task.Id) {
    try {
      deleteDoc(reference = Tasks.ref(taskId))
    } catch (t: Throwable) {
      console.error(t.message)
    }
  }

  override fun getRecaps(
      userId: User.Id,
      teamId: Team.Id,
      from: Instant,
      to: Instant
  ): Flow<List<Recap>> =
      recapsCollection
          .where(Recaps.team, WhereOperator.EQUAL, teamId.raw)
          .where(Recaps.user, WhereOperator.EQUAL, userId.raw)
          .where(Recaps.creationDate, WhereOperator.GREATER, from.toJSDate())
          .where(Recaps.creationDate, WhereOperator.LESS_OR_EQUAL, to.toJSDate())
          .orderBy(Recaps.creationDate, OrderByDirection.DESC)
          .asFlow()
          .map { list ->
            list.mapNotNull { transformOrWarn(FromFirestoreRecap::withData, it)?.toRecap() }
          }

  override suspend fun editRecap(
      recapId: Recap.Id,
      description: String,
  ): StorageResult {
    val data = ToFirestoreRecapEdition(description = description)

    return try {
      updateDoc(
              reference = Recaps.ref(recapId),
              data = data.asJSObject(),
          )
          .await()
      StorageResult.Success
    } catch (t: Throwable) {
      console.error(t.message)
      t.toStorageResult()
    }
  }

  override suspend fun updateProject(
      teamId: Team.Id,
      projectId: Project.Id,
      project: UpdateProjectDTO
  ): StorageResult {
    val data =
        ToFirestoreProject.fromDTO(
            teamId = teamId,
            updateProjectDTO = project,
        )

    return try {
      updateDoc(
              reference = Projects.ref(projectId),
              data = data.asFirestoreObject(),
          )
          .await()

      StorageResult.Success
    } catch (t: Throwable) {
      console.error(t.message)
      t.toStorageResult()
    }
  }

  override fun projects(
      teamId: Team.Id,
      status: Project.Status?,
  ): Flow<List<Project>> {
    return projectsCollection
        .where(Projects.team, WhereOperator.EQUAL, teamId.raw)
        .let { query ->
          if (status == null) query
          else query.where(Projects.status, WhereOperator.EQUAL, status.toFirestoreField())
        }
        .orderBy(Projects.title)
        .asFlow()
        .map { list -> list.map { FromFirestoreProject.withData(it).toProject() } }
  }

  override fun project(projectId: Project.Id): Flow<Project> =
      Projects.ref(projectId).asFlow().map { FromFirestoreProject.withData(it).toProject() }

  override fun tasksForProject(teamId: Team.Id, projectId: Project.Id): Flow<List<Task>> =
      tasksCollection
          .where(Tasks.team, WhereOperator.EQUAL, teamId.raw)
          .where(Tasks.projectId, WhereOperator.EQUAL, projectId.raw)
          .orderBy(Tasks.start, OrderByDirection.DESC)
          .asFlow()
          .map { list ->
            list.mapNotNull { transformOrWarn(FromFirestoreTask::withData, it)?.toTask() }
          }

  override fun tasksForUser(teamId: Team.Id, userId: User.Id): Flow<List<Task>> =
      tasksCollection
          .where(Tasks.team, WhereOperator.EQUAL, teamId.raw)
          .where(Tasks.authorId, WhereOperator.EQUAL, userId.raw)
          .orderBy(Tasks.start, OrderByDirection.DESC)
          .asFlow()
          .map { list ->
            list.mapNotNull { transformOrWarn(FromFirestoreTask::withData, it)?.toTask() }
          }

  override fun tasksForUser(
      teamId: Team.Id,
      userId: User.Id,
      startDate: LocalDate,
      endDate: LocalDate,
  ): Flow<List<Task>> =
      tasksCollection
          .where(Tasks.team, WhereOperator.EQUAL, teamId.raw)
          .where(Tasks.authorId, WhereOperator.EQUAL, userId.raw)
          .where(
              Tasks.start,
              WhereOperator.GREATER_OR_EQUAL,
              startDate.atStartOfDayIn(TimeZone.currentSystemDefault()).toJSDate(),
          )
          .where(
              Tasks.start,
              WhereOperator.LESS_OR_EQUAL,
              endDate
                  .plus(DatePeriod(days = 1))
                  .atStartOfDayIn(TimeZone.currentSystemDefault())
                  .toJSDate(),
          )
          .orderBy(Tasks.start, OrderByDirection.DESC)
          .asFlow()
          .map { list ->
            list.mapNotNull { transformOrWarn(FromFirestoreTask::withData, it)?.toTask() }
          }

  override fun membersOf(team: Team): Flow<List<Member>> =
      Teams.profiles(team.id).asFlow().map { list ->
        list
            .map { FromFirestoreMember.withData(it).toMember(this, team) }
            .sortedWith { a, b -> a.lastName.compareTo(b.lastName) }
      }

  override suspend fun member(team: Team, userId: User.Id): Member {
    val data = getDoc(Teams.profileOf(team.id, userId)).await()
    return transformOrWarn(FromFirestoreMember::withData, data.getWithId())?.toMember(this, team)
        ?: throw Exception("Member with id ${userId.raw} not found")
  }

  override suspend fun requestAccessToTeam(
      teamId: Team.Id,
      userId: User.Id,
      firstName: String,
      lastName: String,
  ): StorageResult {
    val data =
        ToFirestoreTeamAccessRequest(
            name = "$firstName $lastName",
            firstName = firstName,
            lastName = lastName,
            uid = userId.raw,
            requestDate =
                Timestamp(
                    nanoseconds = 0,
                    seconds = Clock.System.now().epochSeconds,
                ),
        )

    return try {
      setDoc(
              reference = Teams.pendingDocument(teamId, userId),
              data = data.asFirestoreObject(),
          )
          .await()
      StorageResult.Success
    } catch (t: Throwable) {
      console.error(t.message)
      t.toStorageResult()
    }
  }

  override fun pendingRequests(teamId: Team.Id): Flow<List<AccessRequest>> =
      Teams.pending(teamId).asFlow().map { list ->
        list.mapNotNull {
          transformOrWarn(FromFirestoreAccessRequest::withData, it)?.toAccessRequest()
        }
      }

  override suspend fun acceptRequest(team: Team, request: AccessRequest): StorageResult {
    val profileData = ToFirestoreProfile(request.name, request.firstName, request.lastName)

    val newRoles =
        Roles.initializeWith(teamRoles = team.roles)
            .stripUser(userId = request.uid)
            .giveRoleTo(userId = request.uid, newRole = Role.MEMBER)

    val updateRolesData = ToFirestoreUpdateTeamRoles(roles = newRoles)

    return try {
      // Save profile
      saveProfileInTeam(
          teamId = team.id,
          userId = request.uid,
          data = profileData,
      )

      updateDoc(
              reference = Teams.ref(team.id),
              data = updateRolesData.asFirestoreObject(),
          )
          .await()

      deleteDoc(reference = Teams.pendingDocument(team.id, request.uid))

      StorageResult.Success
    } catch (t: Throwable) {
      console.error(t.message)
      t.toStorageResult()
    }
  }

  override suspend fun deleteRequest(teamId: Team.Id, request: AccessRequest) {
    deleteDoc(reference = Teams.pendingDocument(teamId, request.uid))
  }

  override suspend fun validateToken(
      teamId: Team.Id,
      authorizationCode: String,
      redirectUri: String,
  ): StorageResult =
      try {
        val data =
            ValidateTokensData(
                teamId = teamId.raw,
                authorizationCode = authorizationCode,
                redirectUri = redirectUri,
            )

        validateTokens(data)

        StorageResult.Success
      } catch (t: Throwable) {
        console.error(t.message)
        t.toStorageResult()
      }

  override suspend fun synchronizeBexio(
      teamId: Team.Id,
  ): StorageResult =
      try {
        val data =
            SynchronizeBexioData(
                teamId = teamId.raw,
            )

        synchronizeBexio(data)

        StorageResult.Success
      } catch (t: Throwable) {
        console.error(t.message)
        t.toStorageResult()
      }

  override suspend fun updateAuthProfileFromBexio(teamId: Team.Id, userId: User.Id): StorageResult =
      try {
        val data =
            UpdateBexioAuthUserData(
                teamId = teamId.raw,
                userId = userId.raw,
            )

        updateBexioAuthUser(data)

        StorageResult.Success
      } catch (t: Throwable) {
        console.error(t.message)
        t.toStorageResult()
      }

  override suspend fun changeMemberRole(team: Team, userId: User.Id, newRole: Role) =
      try {
        val newRoles =
            Roles.initializeWith(teamRoles = team.roles)
                .stripUser(userId = userId)
                .giveRoleTo(userId = userId, newRole = newRole)

        val updateRolesData = ToFirestoreUpdateTeamRoles(roles = newRoles)

        updateDoc(
                reference = Teams.ref(team.id),
                data = updateRolesData.asFirestoreObject(),
            )
            .await()

        StorageResult.Success
      } catch (t: Throwable) {
        console.error(t.message)
        t.toStorageResult()
      }

  // Cloud function requests
  override suspend fun exportView(
      teamId: Team.Id,
      resourceType: ExportType,
      resourceId: String,
      start: Instant,
      end: Instant,
  ) {
    val form = document.createElement("form") as HTMLFormElement
    form.method = "post"
    form.target = "_blank"
    form.action = CloudFunction.ExportView.url
    form.innerHTML =
        """
          <input type="hidden" name="jwtToken" value="${authManager.user.value?.jwt}">
          <input type="hidden" name="teamId" value="${teamId.raw}">
          <input type="hidden" name="resourceType" value="$resourceType">
          <input type="hidden" name="resourceId" value="$resourceId">
          <input type="hidden" name="startTimestamp" value="${start.toEpochMilliseconds()}">
          <input type="hidden" name="endTimestamp" value="${end.toEpochMilliseconds()}">
      """
            .trimIndent()

    document.body?.appendChild(form)
    form.submit()
    document.body?.removeChild(form)
  }

  override suspend fun changeProjectStatus(
      teamId: Team.Id,
      projectId: Project.Id,
      status: Project.Status
  ): StorageResult {
    @Serializable
    data class UpdateProjectStatus(
        val status: String,
    )

    val data = UpdateProjectStatus(status.toFirestoreField())

    return try {
      updateDoc(
              reference = Projects.ref(projectId),
              data = data.asFirestoreObject(),
          )
          .await()

      StorageResult.Success
    } catch (t: Throwable) {
      console.error(t.message)
      t.toStorageResult()
    }
  }

  // Callable functions
  private suspend fun validateTokens(data: ValidateTokensData) {
    val callable = httpsCallable(functions, "validateTokens")
    callable(data.asFirestoreObject()).await()
  }

  private suspend fun synchronizeBexio(data: SynchronizeBexioData) {
    val callable = httpsCallable(functions, "synchronizeBexio")
    callable(data.asFirestoreObject()).await()
  }

  private suspend fun updateBexioAuthUser(data: UpdateBexioAuthUserData) {
    val callable = httpsCallable(functions, "updateBexioAuthUser")
    callable(data.asFirestoreObject()).await()
  }

  // QUERIES
  fun teamsOf(userId: User.Id): Flow<List<Team>> =
      teamsCollection.where(Teams.member, WhereOperator.ARRAY_CONTAINS, userId.raw).asFlow().map {
          list ->
        list.mapNotNull { FromFirestoreTeam.withData(it)?.toTeam(this, firestore) }
      }

  private suspend fun saveProfileInTeam(
      teamId: Team.Id,
      userId: User.Id,
      data: ToFirestoreProfile
  ) =
      setDoc(
              reference = Teams.profilesDocument(teamId, userId),
              data = data.asFirestoreObject(),
          )
          .await()

  // HELPERS
  private fun <A, B> transformOrWarn(transform: (A) -> B, data: A): B? =
      try {
        transform(data)
      } catch (e: Exception) {
        console.error(e.message)
        null
      }

  private fun TraakAuthUser.toUser(): User =
      this.let { authUser -> TraakUser(this@Traak, auth, authUser.user) }

  /** Returns the [DocumentReference] of a given project */
  private fun Projects.ref(projectId: Project.Id): DocumentReference<DocumentData> =
      doc(firestore, "${path}/${projectId.raw}")

  private fun Teams.ref(teamId: Team.Id): DocumentReference<DocumentData> =
      doc(firestore, "${path}/${teamId.raw}/")

  private fun Tasks.ref(taskId: Task.Id): DocumentReference<DocumentData> =
      doc(firestore, "${path}/${taskId.raw}/")

  private fun Recaps.ref(recapId: Recap.Id): DocumentReference<DocumentData> =
      doc(firestore, "${path}/${recapId.raw}/")

  private fun Teams.profiles(teamId: Team.Id): CollectionReference<DocumentData> =
      collection(firestore, "${path}/${teamId.raw}/${profiles}")

  private fun Teams.profileOf(teamId: Team.Id, userId: User.Id): DocumentReference<DocumentData> =
      doc(firestore, "${path}/${teamId.raw}/${profiles}/${userId.raw}")

  private fun Teams.profilesDocument(
      teamId: Team.Id,
      userId: User.Id,
  ): DocumentReference<DocumentData> =
      doc(firestore, "${path}/${teamId.raw}/${profiles}/${userId.raw}")

  private fun Teams.pendingDocument(
      teamId: Team.Id,
      userId: User.Id,
  ): DocumentReference<DocumentData> =
      doc(firestore, "${path}/${teamId.raw}/${pending}/${userId.raw}")

  private fun Teams.pending(teamId: Team.Id): CollectionReference<DocumentData> =
      collection(firestore, "${path}/${teamId.raw}/${pending}")

  /** Returns the complete url of a [CloudFunction] endpoint based on the instance value */
  private val CloudFunction.url: String
    get() = "$cloudFunctionUrl/$endpoint"

  companion object {
    object Tasks {
      const val path = "/tasks"
      const val team = "team"
      const val projectId = "project.id"
      const val authorId = "author.id"
      const val start = "start"
      const val end = "end"
    }

    object Teams {
      const val path = "/teams"
      const val profiles = "profiles"
      const val profilesName = "name"
      const val pending = "pending"
      const val member = "roles.member"
      const val pendingIntegrations = "pendingIntegrations"
    }

    object Projects {
      const val path = "/projects"
      const val team = "team"
      const val status = "status"
      const val title = "title"
    }

    object Recaps {
      const val path = "/recaps"
      const val description = "description"
      const val team = "team"
      const val user = "user"
      const val creationDate = "creationDate"
    }
  }
}
