package ui.screens.teamSelection

import androidx.compose.runtime.Composable
import api.traak.Team
import org.jetbrains.compose.web.dom.AttrBuilderContext
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Text
import org.w3c.dom.HTMLDivElement
import tailwind.TextScope
import tailwind.tailwind
import ui.color.BlackDark
import ui.color.BlackMedium
import ui.material.Card

@Composable
fun TeamItem(
    team: Team,
    membersText: (Int) -> String,
    onClick: () -> Unit,
    attrs: AttrBuilderContext<HTMLDivElement>? = null,
) {
  Card(
      attrs = {
        onClick { onClick() }
        attrs?.invoke(this)

        tailwind {
          text(align = TextScope.Align.Left)

          p(4)
        }
      },
  ) {
    Div(
        attrs = {
          tailwind { text(color = BlackDark, weight = TextScope.Weight.Bold) { body1() } }
        },
    ) {
      Text(team.name)
    }

    Div(
        attrs = { tailwind { text(color = BlackMedium) { body2() } } },
    ) {
      Text(membersText(team.roles.member.size))
    }
  }
}
