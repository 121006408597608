package api.traak.toFirestore

import api.traak.Team
import api.traak.asFirestoreObject
import api.traak.dto.TaskEditionDto
import api.traak.fromFirestore.Author
import api.traak.fromFirestore.Project
import firebase.firestore.DocumentData
import js.objects.Object
import kotlin.js.Date
import kotlinx.datetime.toJSDate

data class ToFirestoreTaskEdition(
    val author: Author?,
    val project: Project?,
    val description: String?,
    val start: Date?,
    val end: Date?,
    val team: String?,
) : DocumentData {
  /**
   * Converts this object to a plain JavaScript object. Additionally, it only includes the
   * properties that are not null.
   */
  fun asJSObject(): Object {
    val obj = js("{}")

    author?.let { obj.author = it.asFirestoreObject() }
    project?.let { obj.project = project.asFirestoreObject() }
    description?.let { obj.description = description }
    start?.let { obj.start = start }
    end?.let { obj.end = end }
    team?.let { obj.team = team }

    return obj as Object
  }

  companion object {
    fun fromDTO(
        taskEditionDto: TaskEditionDto,
        teamId: Team.Id? = null,
    ): ToFirestoreTaskEdition =
        ToFirestoreTaskEdition(
            author = taskEditionDto.author,
            project = taskEditionDto.project,
            description = taskEditionDto.description,
            start = taskEditionDto.startDate?.toJSDate(),
            end = taskEditionDto.endDate?.toJSDate(),
            team = teamId?.raw,
        )
  }
}
