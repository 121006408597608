package ui.screens.login.settingUp

import androidx.compose.runtime.Composable
import org.jetbrains.compose.web.dom.AttrBuilderContext
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.H2
import org.jetbrains.compose.web.dom.Text
import org.w3c.dom.HTMLDivElement
import tailwind.Layout
import tailwind.tailwind
import ui.color.BlackMedium
import ui.material.FullColumn

@Composable
fun Explanation(
    header: String,
    attrs: AttrBuilderContext<HTMLDivElement>? = null,
    textContent: @Composable () -> Unit,
) {
  FullColumn(
      alignItems = Layout.AlignItems.Start,
      gap = 4,
  ) {
    H2(
        attrs = { tailwind { text { headline4() } } },
    ) {
      Text(header)
    }

    Div(
        attrs = { tailwind { text(color = BlackMedium) { body1() } } },
    ) {
      textContent()
    }
  }
}
