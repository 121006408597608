package navigation

import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.runtime.setValue
import api.settings.LocalSettings
import api.settings.SettingsApi
import api.traak.LocalTraakApi
import api.traak.Team
import api.traak.TraakApi
import api.traak.user.User
import kotlin.time.Duration.Companion.milliseconds
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.ExperimentalCoroutinesApi
import kotlinx.coroutines.FlowPreview
import kotlinx.coroutines.flow.combine
import kotlinx.coroutines.flow.debounce
import kotlinx.coroutines.flow.flatMapLatest
import kotlinx.coroutines.flow.flowOf
import kotlinx.coroutines.launch

/** Represents the state of the user in the navigation flow of the application. */
sealed class UserState {
  /** Represents the lack of knowledge of the user's state. */
  data object Loading : UserState()

  /** The user is not authenticated. */
  data object Disconnected : UserState()

  /** The user is authenticated but his account is not complete. */
  data class SettingUp(
      val user: User,
  ) : UserState()

  /** The user is authenticated, but hasn't chosen a team to work in. */
  data class Authenticated(
      val user: User,
      val teams: List<Team>,
  ) : UserState()

  /** The user is authenticated and has chosen a team to work in. */
  data class Connected(
      val user: User,
      val currentTeam: Team,
      val teams: List<Team>,
  ) : UserState()
}

@FlowPreview
@OptIn(ExperimentalCoroutinesApi::class)
class UserStateHolder(
    api: TraakApi,
    settings: SettingsApi,
    coroutineScope: CoroutineScope,
) {
  private var _userState: UserState by mutableStateOf(UserState.Loading)

  private val userFlow = api.user
  private val teamsFlow = userFlow.flatMapLatest { it?.teams ?: flowOf(emptyList()) }

  private val userStateFlow =
      combine(
          userFlow,
          teamsFlow,
          settings.team,
      ) { user, teams, selectedTeamId ->
        val selectedTeam = teams.firstOrNull { it.id == selectedTeamId }

        return@combine if (user == null) {
          UserState.Disconnected
          // TODO: Activate when other apps are ready for all sign-in options
          // } else if (userAccountIsIncomplete(user)) {
          //  UserState.SettingUp(user)
        } else if (selectedTeam == null) {
          UserState.Authenticated(user, teams)
        } else {
          UserState.Connected(user, selectedTeam, teams)
        }
      }

  private val debouncedUserStateFlow = userStateFlow.debounce(1000.milliseconds)

  init {
    coroutineScope.launch { debouncedUserStateFlow.collect { _userState = it } }
  }

  val userState: UserState
    get() = _userState

  private fun userAccountIsIncomplete(user: User): Boolean {
    return user.displayName == null || user.email == null || user.phoneNumber == null
  }
}

/** Retrieves the [UserState] from [TraakApi] and [api.settings.SettingsApi] */
@OptIn(FlowPreview::class)
@Composable
fun rememberUserState(
    api: TraakApi = LocalTraakApi.current,
    settings: SettingsApi = LocalSettings.current,
    coroutineScope: CoroutineScope = rememberCoroutineScope(),
): UserStateHolder {
  return remember(api, settings) { UserStateHolder(api, settings, coroutineScope) }
}
