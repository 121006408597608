package ui.components.taskTable

import androidx.compose.runtime.Composable
import androidx.compose.runtime.remember
import api.traak.Task
import kotlin.time.Duration
import kotlin.time.Duration.Companion.seconds
import org.jetbrains.compose.web.dom.Span
import org.jetbrains.compose.web.dom.Text
import tailwind.TextScope
import tailwind.tailwind
import ui.strings.LocalStrings

/** Summarizes the total time of all the tasks in the table. */
@Composable
fun Summarize(data: List<Task>) {
  val strings = LocalStrings.current
  val totalTime =
      remember(data) {
        data
            .map { it.start to it.end }
            .map { (start, end) -> end.minus(start).plus(1.seconds) }
            .fold(Duration.ZERO) { acc, duration -> acc.plus(duration) }
      }

  Span(
      attrs = {
        tailwind {
          mr(6)
          text { overline(weight = TextScope.Weight.Normal, size = TextScope.Size.Xs) }
        }
      }) {
        Text(strings.taskTableTotalTime)
      }

  totalTime.display()
}
