package ui.screens.integration.bexio

import androidx.compose.runtime.Composable
import org.jetbrains.compose.web.attributes.AttrsScope
import org.w3c.dom.HTMLDivElement
import ui.components.progressionPanel.ProgressionPanel
import ui.components.progressionPanel.ProgressionPanelEnum
import ui.components.progressionPanel.rememberProgressionPanelState
import ui.strings.LocalStrings
import ui.strings.LocalizedStrings

enum class BexioIntegrationStage : ProgressionPanelEnum {
  Connection,
  TeamSelection,
  Authorization,
  Synchronization;

  override fun toLocalizedString(strings: LocalizedStrings): String {
    return when (this) {
      Connection -> strings.bexioIntegrationSideBarConnection
      TeamSelection -> strings.bexioIntegrationSideBarTeamSelection
      Authorization -> strings.bexioIntegrationSideBarAuthorization
      Synchronization -> strings.bexioIntegrationSideBarSynchronization
    }
  }
}

@Composable
fun SideBar(
    currentStage: BexioIntegrationStage,
    attrs: (AttrsScope<HTMLDivElement>.() -> Unit)? = null,
) {
  val strings = LocalStrings.current
  val progressionPanelState =
      rememberProgressionPanelState(
          currentState = currentStage,
          enumEntries = BexioIntegrationStage.entries,
      )

  ProgressionPanel(
      title = strings.bexioIntegrationHeadline,
      state = progressionPanelState,
      attrs = attrs,
  )
}
