package ui.components.traakHeadline

import androidx.compose.runtime.Composable
import org.jetbrains.compose.web.dom.AttrBuilderContext
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Span
import org.jetbrains.compose.web.dom.Text
import org.w3c.dom.HTMLDivElement
import tailwind.Full
import tailwind.tailwind
import tailwind.third
import ui.color.BlackDark
import ui.icons.Traak

@Composable
fun TraakHeadline(
    text: String? = null,
    attrs: AttrBuilderContext<HTMLDivElement>? = null,
) {
  Div(
      attrs = {
        attrs?.invoke(this)

        tailwind {
          pb(4)
          w(Full)
          text(color = BlackDark)
        }
      }) {
        Traak(attrs = { tailwind { w(2.third) } })

        text?.let { Span(attrs = { tailwind { text { body1() } } }) { Text(text) } }
      }
}
