package ui.icons

import androidx.compose.runtime.Composable
import org.jetbrains.compose.web.dom.AttrBuilderContext
import org.w3c.dom.HTMLDivElement

@Composable
fun ArrowLeft(
    attrs: AttrBuilderContext<HTMLDivElement>? = null,
) =
    Icon(attrs) {
      attr(
          "d",
          "M20.5 11V13H8.50003L14 18.5L12.58 19.92L4.66003 12L12.58 4.07996L14 5.49996L8.50003 11H20.5Z",
      )
    }
