package ui.screens.teamManagement

import androidx.compose.runtime.Composable
import api.traak.AccessRequest
import kotlinx.datetime.LocalDateTime
import kotlinx.datetime.TimeZone
import kotlinx.datetime.toLocalDateTime
import org.jetbrains.compose.web.dom.AttrBuilderContext
import org.jetbrains.compose.web.dom.Span
import org.jetbrains.compose.web.dom.Text
import org.w3c.dom.HTMLDivElement
import tailwind.*
import tailwind.color.*
import ui.color.BlackDark
import ui.color.BlackMedium
import ui.icons.Check
import ui.icons.Close
import ui.material.Column
import ui.material.FullRow
import ui.material.ListItem
import utils.padWith0

@Composable
fun RequestItem(
    request: AccessRequest,
    onAcceptClick: () -> Unit,
    onRejectClick: () -> Unit,
    attrs: AttrBuilderContext<HTMLDivElement>? = null,
) {
  ListItem(
      attrs = attrs,
  ) {
    FullRow(
        justify = Layout.Justify.Start,
        alignItems = Layout.AlignItems.Center,
        gap = 4,
    ) {
      Column(
          justify = Layout.Justify.Center,
          alignItems = Layout.AlignItems.Start,
          attrs = { tailwind { flexItem(FlexItemScope.Grow.Grow) } },
      ) {
        Span(
            attrs = {
              tailwind { text(color = BlackDark) { body1(weight = TextScope.Weight.Semibold) } }
            },
        ) {
          Text(request.name)
        }

        Span(
            attrs = { tailwind { text(color = BlackMedium) { numbers() } } },
        ) {
          Text(
              request.requestDate
                  .toLocalDateTime(TimeZone.currentSystemDefault())
                  .toFormattedString())
        }
      }

      val sharedIconAttr: AttrBuilderContext<HTMLDivElement> = {
        tailwind {
          flex(
              direction = FlexScope.Direction.Row,
              justify = Layout.Justify.Center,
              alignItems = Layout.AlignItems.Center,
          )

          w(9)
          h(9)

          round(TailwindScope.Radius.Full)
          cursor(TailwindScope.Cursor.Pointer)
          flexItem(FlexItemScope.Grow.Fixed, FlexItemScope.Shrink.Fixed)
        }
      }

      Check(
          attrs = {
            onClick { onAcceptClick() }
            sharedIconAttr(this)

            tailwind {
              text(color = Green600)
              background(color = Green100, opacity = Opacity040)
              border(color = Green600, width = 1)

              hover {
                text(color = Green500)
                border(color = Green500, width = 1)
              }
            }
          })

      Close(
          attrs = {
            onClick { onRejectClick() }
            sharedIconAttr(this)

            tailwind {
              text(color = Red600)
              background(color = Red100, opacity = Opacity040)
              border(color = Red600, width = 1)

              hover {
                text(color = Red500)
                border(color = Red500, width = 1)
              }
            }
          },
      )
    }
  }
}

private fun LocalDateTime.toFormattedString(): String =
    "${this.dayOfMonth.padWith0()}/${this.monthNumber.padWith0()}/${this.year - 2000}"
