package ui.components.progressionPanel

enum class StepState {
  Done,
  Current,
  Next,
}

/**
 * A [Step] represent an action that a user must do in the context of a [ProgressionPanel]. It holds
 * the information that needs to be displayed by a [ProgressionPanel].
 */
data class Step(
    /**
     * The [indicator], usually a number, is used to display where this element sits compared to the
     * others.
     */
    val indicator: String,

    /** The [text] is used to display what is done during this [Step] of the Progression */
    val text: String,

    /** The [StepState] of this [Step] */
    val state: StepState
)

/**
 * The [ProgressionPanel] composable is stateless. In order to display the progression correctly,
 * its state needs to conform to this [ProgressionPanelState].
 */
interface ProgressionPanelState {
  val progression: List<Step>
}
