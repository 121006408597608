package ui.screens.login

import api.traak.AuthResult

/**
 * Describes what type of relation we have to the server
 *
 * Are we loading some results from:
 * 1. nobody
 * 2. our own authentication
 * 3. bexio's authentication
 */
enum class Loading {
  NONE,
  SELF,
  BEXIO,
}

/** State holder for [Login] screen */
interface LoginState {
  /** Email of the user to log in. Attribute of the form. */
  var email: String

  /** Password of the user to log in. Attribute of the form. */
  var password: String

  /** Result of requests to server */
  val result: AuthResult?

  /** Are we waiting for a server response */
  val loading: Loading

  /** Logs in the user with his email and password */
  fun login(): Unit

  /** Logs in the user against bexio */
  fun logWithBexio(): Unit
}
