package api.traak.fromFirestore

import api.traak.Project
import api.traak.toFirestore.Address
import firebase.firestore.DocumentData
import kotlinx.serialization.Serializable
import kotlinx.serialization.decodeFromString
import kotlinx.serialization.json.Json

@Serializable
data class FromFirestoreProject(
    val id: String,
    val title: String,
    val address: Address,
    val status: String? = null,
) {
  companion object {
    private val json = Json { ignoreUnknownKeys = true }

    fun withData(data: DocumentData): FromFirestoreProject =
        json.decodeFromString(JSON.stringify(data))
  }
}

fun FromFirestoreProject.toProject(): Project =
    object : Project {
      override val id = Project.Id(this@toProject.id)
      override val title = this@toProject.title
      override val address = this@toProject.address
      override val status = (this@toProject.status ?: "").toProjectStatus()
    }

fun String.toProjectStatus(): Project.Status =
    when (this) {
      "active" -> Project.Status.Active
      "archived" -> Project.Status.Archived
      else -> Project.Status.Active
    }
