package ui.screens.tasks

import kotlin.time.Duration
import kotlinx.datetime.LocalDate
import utils.Ordering

data class UiTask(
    val date: LocalDate,
    val author: String,
    val project: String,
    val description: String,
    val duration: Duration,
)

interface TasksState {
  var search: String

  var dateOrdering: Ordering
  val tasks: List<UiTask>

  val totalDuration: Duration
}
