package ui.layouts

import androidx.compose.runtime.Composable
import org.jetbrains.compose.web.dom.AttrBuilderContext
import org.jetbrains.compose.web.dom.Div
import org.w3c.dom.HTMLDivElement
import tailwind.*
import ui.components.languageFooter.LanguageFooter
import ui.images.TraakAbstract

@Composable
fun AuthLayout(
    attrs: AttrBuilderContext<HTMLDivElement>? = null,
    content: @Composable () -> Unit = {},
) {
  Div(
      attrs = {
        attrs?.invoke(this)
        tailwind {
          w(Full)
          h(TailwindScope.Height.Screen)

          flex(
              direction = FlexScope.Direction.Column,
              justify = Layout.Justify.Start,
              alignItems = Layout.AlignItems.Center,
          )
        }
      },
  ) {
    Div(
        attrs = {
          tailwind {
            w(Full)
            h(TailwindScope.Height.Screen)

            flex(
                direction = FlexScope.Direction.Row,
                justify = Layout.Justify.Evenly,
                alignItems = Layout.AlignItems.Center,
            )
          }
        },
    ) {
      content()
      TraakAbstract(
          tailwindAttr = {
            large { display(TailwindScope.Display.Block) }

            display(TailwindScope.Display.Hidden)
          },
      )
    }
    LanguageFooter()
  }
}
