package ui.components.taskTable

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.MutableState
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateListOf
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.runtime.setValue
import api.settings.LocalSettings
import api.settings.SettingsApi
import api.traak.LocalTraakApi
import api.traak.Task
import api.traak.TraakApi
import kotlin.coroutines.cancellation.CancellationException
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Job
import kotlinx.coroutines.delay
import kotlinx.coroutines.launch
import ui.components.table.TableSettingsDefaultState
import ui.components.table.TableSettingsState
import ui.components.taskCreator.TaskCreatorState
import ui.data.Loadable
import ui.data.toList
import utils.DelayedJob
import utils.Ordering
import utils.delayedLaunch

private const val CANCELLATION_DELAY = 4000L
private const val CANCELLATION_STEP = 50L

private fun Task.contains(content: String): Boolean =
    listOf(
            this.description,
            this.project?.title,
        )
        .any { it?.contains(content, ignoreCase = true) == true }

class TaskTableStateHolder(
    loadableTasks: Loadable<List<Task>>,
    private val editItem: (Task) -> Unit,
    override val mode: TaskTableMode,
    private val toOrderedBy: Int.() -> OrderedBy?,
    private val coroutineScope: CoroutineScope,
    private val api: TraakApi,
    private val settings: SettingsApi,
) : TaskTableState, TableSettingsState<Task> by TableSettingsDefaultState() {
  private val _loadableTasks = mutableStateOf(loadableTasks)
  private val _searchValue = mutableStateOf("")
  private val _headerIsHovered = mutableStateOf(false)

  // task deletion
  private var deleteJobs = mutableStateListOf<DelayedJob>()
  private val temporaryDeletedTasks = mutableStateListOf<Task>()

  // snackbar
  private var snackbarProgressionJob: Job? = null
  private val _cancellableSnackbarProgression: MutableState<Double> = mutableStateOf(0.0)

  private val workingTaskList: List<Task>
    get() =
        _loadableTasks.value.toList().filterNot { outerTask ->
          temporaryDeletedTasks.any { task -> task.id == outerTask.id }
        }

  override val loading: Boolean
    get() = _loadableTasks.value is Loadable.Loading

  override val searchInput: String
    get() = _searchValue.value

  override fun onSearchInput(input: String) {
    _searchValue.value = input
  }

  override val headerIsHovered: Boolean
    get() = _headerIsHovered.value

  override val cancelSnackbarIsVisible: Boolean
    get() = deleteJobs.isNotEmpty()

  override val cancellableSnackbarProgression: Double
    get() = _cancellableSnackbarProgression.value

  override fun onSnackbarCancelClick() {
    deleteJobs.lastOrNull()?.cancel(CancellationException(""))
    snackbarProgressionJob?.cancel()
  }

  override fun delete(item: Task) {
    if (temporaryDeletedTasks.contains(item)) {
      return
    }

    // remove task from UI
    temporaryDeletedTasks.add(item)

    // show snackbar
    if (snackbarProgressionJob != null) {
      _cancellableSnackbarProgression.value = 0.0
    } else {
      snackbarProgressionJob =
          coroutineScope.launch {
            startCancellableSnackbarTimer(
                CANCELLATION_DELAY,
                CANCELLATION_STEP,
            )
          }

      snackbarProgressionJob?.invokeOnCompletion {
        snackbarProgressionJob = null
        _cancellableSnackbarProgression.value = 0.0
      }
    }

    // force finish previous job
    deleteJobs.forEach { it.skipDelay() }

    val job =
        coroutineScope.delayedLaunch(CANCELLATION_DELAY) {
          api.deleteTask(
              taskId = item.id,
          )
        }

    job.invokeOnCompletion {
      deleteJobs.removeFirst()
      temporaryDeletedTasks.removeFirst()
    }

    deleteJobs.add(job)
  }

  override val filteredTasks: List<Task>
    get() {
      val comparator =
          when (_selectedHeader) {
            OrderedBy.Date -> compareByDate
            OrderedBy.Origin -> compareByOrigin
          }

      return workingTaskList.filter { it.contains(_searchValue.value) }.sortedWith(comparator)
    }

  private fun Task.compareAuthor(): Comparable<Task> {
    return object : Comparable<Task> {
      override fun compareTo(other: Task): Int {
        val authorComparator: Comparator<Task> =
            if (settings.orderByFirstName.value) {
              compareBy<Task> { it.author.firstName }.thenBy { it.author.lastName }
            } else {
              compareBy<Task> { it.author.lastName }.thenBy { it.author.firstName }
            }
        return authorComparator.compare(this@compareAuthor, other)
      }
    }
  }

  private val compareByDate: Comparator<Task>
    get() {
      val dateComparator: Comparator<Task> =
          when (_selectedHeaderOrder) {
            Ordering.ASC -> compareBy { it.start }
            Ordering.DESC -> compareByDescending { it.start }
          }

      return dateComparator
          .thenBy { firstElementOfAuthor(it.author) }
          .thenBy { secondElementOfAuthor(it.author) }
          .thenBy { it.description }
    }

  private fun firstElementOfAuthor(author: Task.Author): String {
    return if (settings.orderByFirstName.value) {
      author.firstName
    } else {
      author.lastName
    }
  }

  private fun secondElementOfAuthor(author: Task.Author): String {
    return if (settings.orderByFirstName.value) {
      author.lastName
    } else {
      author.firstName
    }
  }

  private val compareByOrigin: Comparator<Task>
    get() {
      val authorComparator: Comparator<Task> =
          when (_selectedHeaderOrder) {
            Ordering.ASC ->
                compareBy<Task> { firstElementOfAuthor(it.author) }
                    .thenBy { secondElementOfAuthor(it.author) }
            Ordering.DESC ->
                compareByDescending<Task> { firstElementOfAuthor(it.author) }
                    .thenBy { secondElementOfAuthor(it.author) }
          }

      return authorComparator.thenByDescending { it.start }.thenBy { it.description }
    }

  override fun onItemEditClick(item: Task) {
    editItem(item)
  }

  override val firstNameIsFirst: Boolean
    get() = settings.showFirstNameFirst.value

  override val orderingByHeader: Int
    get() = _selectedHeader.ordinal

  override val ordering: Ordering
    get() = _selectedHeaderOrder

  private var _selectedHeader by mutableStateOf(OrderedBy.Date)
  private var _selectedHeaderOrder by mutableStateOf(Ordering.DESC)

  override fun onHeaderClick(index: Int) {
    val newHeader = index.toOrderedBy() ?: return

    if (_selectedHeader == newHeader) {
      _selectedHeaderOrder = _selectedHeaderOrder.toggle()
    } else {
      _selectedHeader = newHeader
      _selectedHeaderOrder = Ordering.DESC
    }
  }

  fun updateLoadableTasks(newTasks: Loadable<List<Task>>) {
    _loadableTasks.value = newTasks
  }

  private suspend fun startCancellableSnackbarTimer(delay: Long, step: Long) {
    val stepPercent = step.toDouble() / delay.toDouble()

    do {
      _cancellableSnackbarProgression.value += stepPercent
      delay(step)
    } while (_cancellableSnackbarProgression.value < 100)
  }
}

@Composable
fun rememberTaskTableState(
    mode: TaskTableMode,
    loadableTasks: Loadable<List<Task>>,
    taskEditorState: TaskCreatorState,
    toOrderedBy: Int.() -> OrderedBy?,
    coroutineScope: CoroutineScope = rememberCoroutineScope(),
    api: TraakApi = LocalTraakApi.current,
    settings: SettingsApi = LocalSettings.current,
): TaskTableState {
  val state =
      remember(taskEditorState) {
        TaskTableStateHolder(
            mode = mode,
            loadableTasks = loadableTasks,
            editItem = { taskEditorState.edit(it) },
            toOrderedBy = toOrderedBy,
            coroutineScope = coroutineScope,
            api = api,
            settings = settings,
        )
      }

  LaunchedEffect(loadableTasks) { state.updateLoadableTasks(loadableTasks) }
  return state
}
