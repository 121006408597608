package utils

import kotlinx.coroutines.*
import kotlinx.coroutines.channels.Channel
import kotlinx.coroutines.selects.select

/** A job that will be executed after a delay, which can optionally be skipped. */
interface DelayedJob : Job {
  /** Skip the delay and execute the job immediately. */
  fun skipDelay()
}

/**
 * Delays the execution of the given [block] by [duration] milliseconds.
 *
 * @param duration The delay in milliseconds.
 * @param block The block to execute after the delay.
 */
fun CoroutineScope.delayedLaunch(
    duration: Long,
    block: suspend CoroutineScope.() -> Unit,
): DelayedJob {
  val channel = Channel<Unit>()

  // delayed launch
  val delayed =
      this.launch {
        delay(duration)
        channel.close()
      }

  // main execution
  val main = this.launch { select<Unit> { channel.onReceiveCatching { block(this@launch) } } }

  return object : DelayedJob, Job by main {
    override fun skipDelay() {
      this@delayedLaunch.launch { channel.close() }
    }

    override fun cancel(cause: CancellationException?) {
      delayed.cancel(cause)
      main.cancel(cause)
      channel.close()
    }
  }
}
