package tailwind

import kotlin.math.absoluteValue
import org.jetbrains.compose.web.attributes.AttrsScope
import org.w3c.dom.Element
import tailwind.TailwindScope.Animation
import tailwind.TailwindScope.Blur
import tailwind.TailwindScope.BoxSizing
import tailwind.TailwindScope.Clear
import tailwind.TailwindScope.Cursor
import tailwind.TailwindScope.Display
import tailwind.TailwindScope.Float
import tailwind.TailwindScope.Height
import tailwind.TailwindScope.ListStyle
import tailwind.TailwindScope.Margin
import tailwind.TailwindScope.MaxHeight
import tailwind.TailwindScope.MaxWidth
import tailwind.TailwindScope.MinHeight
import tailwind.TailwindScope.MinWidth
import tailwind.TailwindScope.Outline
import tailwind.TailwindScope.OverflowDirection
import tailwind.TailwindScope.OverflowType
import tailwind.TailwindScope.Overscroll
import tailwind.TailwindScope.Position
import tailwind.TailwindScope.Radius
import tailwind.TailwindScope.Resize
import tailwind.TailwindScope.SelectType
import tailwind.TailwindScope.Shadow
import tailwind.TailwindScope.UserSelect
import tailwind.TailwindScope.VerticalAlignment
import tailwind.TailwindScope.Visibility
import tailwind.TailwindScope.Width
import tailwind.color.Color
import tailwind.color.Opacity
import tailwind.color.OpacityPainter
import tailwind.color.White

fun <TElement : Element> AttrsScope<TElement>.tailwind(
    scope: TailwindScope.() -> Unit,
) {
  val tailwind = Tailwind(builder = this, currentPrefix = null)
  tailwind.scope()
}

fun <TElement : Element> AttrsScope<TElement>.tailwind(
    variant: Tailwind.Variant,
    scope: TailwindScope.() -> Unit,
) {
  val tailwind = Tailwind(builder = this, currentPrefix = null, variant = variant)
  tailwind.scope()
}

open class Tailwind<TElement : Element>
internal constructor(
    private val builder: AttrsScope<TElement>,
    private val currentPrefix: String?,
    private val variant: Variant? = null,
) : TailwindScope, OpacityPainter {

  private val prefix = "${currentPrefix.orEmpty()}${variant?.toString() ?: ""}"

  internal fun apply(value: String) = builder.classes("$prefix$value")

  sealed class Variant(private val prefix: String) {
    override fun toString(): String {
      return "$prefix:"
    }

    object Hover : Variant("hover")

    object Focus : Variant("focus")

    object Active : Variant("active")

    object GroupHover : Variant("group-hover")

    object GroupFocus : Variant("group-focus")

    object FocusWithin : Variant("focus-within")

    object Disabled : Variant("disabled")

    object Visited : Variant("visited")

    object Checked : Variant("checked")

    object FirstChild : Variant("first")

    object LastChild : Variant("last")

    object EvenChild : Variant("even")

    object OddChild : Variant("odd")
  }

  sealed class Responsive(prefix: String) : Variant(prefix) {
    object S : Responsive("sm")

    object M : Responsive("md")

    object L : Responsive("lg")

    object Xl : Responsive("xl")

    object Xxl : Responsive("2xl")
  }

  private fun prefixVariant(variant: Variant, scope: TailwindScope.() -> Unit) {
    val tailwind = Tailwind(builder = builder, currentPrefix = prefix, variant = variant)
    tailwind.scope()
  }

  override fun hover(scope: TailwindScope.() -> Unit) = prefixVariant(Variant.Hover, scope)

  override fun focus(scope: TailwindScope.() -> Unit) = prefixVariant(Variant.Focus, scope)

  override fun active(scope: TailwindScope.() -> Unit) = prefixVariant(Variant.Active, scope)

  override fun groupHover(scope: TailwindScope.() -> Unit) =
      prefixVariant(Variant.GroupHover, scope)

  override fun groupFocus(scope: TailwindScope.() -> Unit) =
      prefixVariant(Variant.GroupFocus, scope)

  override fun focusWithin(scope: TailwindScope.() -> Unit) =
      prefixVariant(Variant.FocusWithin, scope)

  override fun disabled(scope: TailwindScope.() -> Unit) = prefixVariant(Variant.Disabled, scope)

  override fun visited(scope: TailwindScope.() -> Unit) = prefixVariant(Variant.Visited, scope)

  override fun checked(scope: TailwindScope.() -> Unit) = prefixVariant(Variant.Checked, scope)

  override fun first(scope: TailwindScope.() -> Unit) = prefixVariant(Variant.FirstChild, scope)

  override fun last(scope: TailwindScope.() -> Unit) = prefixVariant(Variant.LastChild, scope)

  override fun odd(scope: TailwindScope.() -> Unit) = prefixVariant(Variant.OddChild, scope)

  override fun even(scope: TailwindScope.() -> Unit) = prefixVariant(Variant.EvenChild, scope)

  // Responsive
  override fun small(scope: TailwindScope.() -> Unit) = prefixVariant(Responsive.S, scope)

  override fun medium(scope: TailwindScope.() -> Unit) = prefixVariant(Responsive.M, scope)

  override fun large(scope: TailwindScope.() -> Unit) = prefixVariant(Responsive.L, scope)

  override fun xlarge(scope: TailwindScope.() -> Unit) = prefixVariant(Responsive.Xl, scope)

  override fun xxlarge(scope: TailwindScope.() -> Unit) = prefixVariant(Responsive.Xxl, scope)

  // Display
  override fun display(type: Display) =
      apply(
          when (type) {
            Display.Block -> "block"
            Display.InlineBlock -> "inline-block"
            Display.Inline -> "inline"
            Display.Table -> "table"
            Display.TableCaption -> "table-caption"
            Display.TableCell -> "table-cell"
            Display.TableColumn -> "table-column"
            Display.TableColumnGroup -> "table-column-group"
            Display.TableFooterGroup -> "table-footer-group"
            Display.TableHeaderGroup -> "table-header-group"
            Display.TableRowGroup -> "table-row-group"
            Display.TableRow -> "table-row"
            Display.Hidden -> "hidden"
            Display.Contents -> "contents"
          })

  private fun flexGenerator(
      type: String,
      direction: FlexScope.Direction?,
      wrap: FlexScope.Wrap?,
      justify: Layout.Justify?,
      alignContent: Layout.AlignContent?,
      alignItems: Layout.AlignItems?,
      gap: Int?
  ) {
    val flex = Flex(builder = builder, currentPrefix = prefix)
    val scope: FlexScope.() -> Unit = {
      direction?.let { direction(it) }
      wrap?.let { wrap(it) }
      justify?.let { justify(it) }
      alignContent?.let { alignContent(it) }
      alignItems?.let { alignItems(it) }
      gap?.gap
    }

    apply(type)
    flex.scope()
  }

  override fun flex(
      direction: FlexScope.Direction?,
      justify: Layout.Justify?,
      alignItems: Layout.AlignItems?,
      alignContent: Layout.AlignContent?,
      wrap: FlexScope.Wrap?,
      gap: Int?
  ) {
    flexGenerator(
        type = "flex",
        direction = direction,
        wrap = wrap,
        justify = justify,
        alignContent = alignContent,
        alignItems = alignItems,
        gap = gap,
    )
  }

  override fun inlineFlex(
      direction: FlexScope.Direction?,
      wrap: FlexScope.Wrap?,
      justify: Layout.Justify?,
      alignContent: Layout.AlignContent?,
      alignItems: Layout.AlignItems?,
      gap: Int?
  ) {
    flexGenerator(
        type = "inline-flex",
        direction = direction,
        wrap = wrap,
        justify = justify,
        alignContent = alignContent,
        alignItems = alignItems,
        gap = gap,
    )
  }

  override fun flexItem(
      grow: FlexItemScope.Grow?,
      shrink: FlexItemScope.Shrink?,
      align: FlexItemScope.Align?,
      order: FlexItemScope.Order?,
      explicitOrder: Int?
  ) {
    val flexItem = FlexItem(builder = builder, currentPrefix = prefix)
    val scope: FlexItemScope.() -> Unit = {
      grow?.let { grow(it) }
      shrink?.let { shrink(it) }
      align?.let { align(it) }

      if (order != null) {
        order(order)
      } else {
        explicitOrder?.order
      }
    }

    flexItem.scope()
  }

  private fun gridGenerator(
      type: String,
      columnsN: Int? = null,
      rowsN: Int? = null,
      implicitColumns: GridScope.ImplicitGrid? = null,
      implicitRows: GridScope.ImplicitGrid? = null,
      flow: GridScope.Flow?,
      justify: Layout.Justify? = null,
      alignContent: Layout.AlignContent? = null,
      alignItems: Layout.AlignItems? = null,
      gap: Int? = null,
  ) {
    val grid = Grid(builder = builder, currentPrefix = prefix)
    val scope: GridScope.() -> Unit = {
      // Columns
      if (columnsN != null) {
        columns(columnsN)
      } else {
        columns(implicitColumns ?: GridScope.ImplicitGrid.Auto)
      }

      // Rows
      if (rowsN != null) {
        rows(rowsN)
      } else {
        rows(implicitRows ?: GridScope.ImplicitGrid.Auto)
      }

      // Flow
      flow?.let { flow(it) }

      // Layout
      justify?.let { justify(it) }
      alignContent?.let { alignContent(it) }
      alignItems?.let { alignItems(it) }
      gap?.gap
    }

    apply(type)
    grid.scope()
  }

  override fun grid(
      columns: Int?,
      rows: Int?,
      flow: GridScope.Flow?,
      justify: Layout.Justify?,
      alignContent: Layout.AlignContent?,
      alignItems: Layout.AlignItems?,
      gap: Int?,
  ) =
      gridGenerator(
          "grid",
          columnsN = columns,
          rowsN = rows,
          flow = flow,
          justify = justify,
          alignContent = alignContent,
          alignItems = alignItems,
          gap = gap,
      )

  override fun grid(
      columns: GridScope.ImplicitGrid,
      rows: GridScope.ImplicitGrid,
      flow: GridScope.Flow?,
      justify: Layout.Justify?,
      alignContent: Layout.AlignContent?,
      alignItems: Layout.AlignItems?,
      gap: Int?,
  ) =
      gridGenerator(
          "grid",
          implicitColumns = columns,
          implicitRows = rows,
          flow = flow,
          justify = justify,
          alignContent = alignContent,
          alignItems = alignItems,
          gap = gap,
      )

  override fun gridItem(scope: (GridItemScope.() -> Unit)) {
    val item = GridItem(builder = builder, currentPrefix = prefix)
    item.scope()
  }

  override fun background(
      color: Color?,
      opacity: Opacity?,
      attachment: BackgroundScope.Attachment?,
      clip: BackgroundScope.Clip?,
      origin: BackgroundScope.Origin?,
      position: BackgroundScope.Position?,
      repeat: BackgroundScope.Repeat?,
      size: BackgroundScope.Size?
  ) {
    val bg = Background(builder = builder, currentPrefix = prefix)
    val scope: BackgroundScope.() -> Unit = {
      color?.let { color(it) }
      opacity?.let { opacity(it) }
      attachment?.let { attachment(it) }
      clip?.let { clip(it) }
      origin?.let { origin(it) }
      position?.let { position(it) }
      repeat?.let { repeat(it) }
      size?.let { size(it) }
    }

    bg.scope()
  }

  override fun border(
      color: Color?,
      opacity: Opacity?,
      style: BorderStyle?,
      width: Int?,
      scope: (BorderScope.() -> Unit)?,
  ) {
    val border = Border(builder = builder, currentPrefix = prefix)
    val internalScope: BorderScope.() -> Unit = {
      color?.let { color(it) }
      opacity?.let { opacity(it) }
      style?.let { style(it) }
      width?.width(Side.All)
    }

    border.internalScope()
    scope?.let { border.it() }
  }

  override fun divide(direction: DivideDirection, scope: DivideScope.() -> Unit) {
    val divide = Divide(direction = direction, builder = builder, currentPrefix = prefix)
    divide.scope()
  }

  override fun ring(render: RingScope.Render?, size: Int?, offsetBy: Int?, offsetColor: Color?) {
    val ring = Ring(builder = builder, currentPrefix = prefix)
    val scope: RingScope.() -> Unit = {
      render?.let { render(it) }
      size?.size
      offsetBy?.let {
        offset(
            it,
            offsetColor ?: White,
        )
      }
    }
    ring.scope()
  }

  override fun text(
      color: Color?,
      opacity: Opacity?,
      family: TextScope.Family?,
      size: TextScope.Size?,
      weight: TextScope.Weight?,
      tracking: TextScope.Tracking?,
      leading: TextScope.Leading?,
      align: TextScope.Align?,
      decoration: TextScope.Decoration?,
      overflow: TextScope.Overflow?,
      whitespace: TextScope.Whitespace?,
      wordBreak: TextScope.WordBreak?,
      transformation: TextScope.Transform?,
      scope: (TextScope.() -> Unit)?,
  ) {
    val text = Text(builder = builder, currentPrefix = prefix)
    val internalScope: TextScope.() -> Unit = {
      color?.let { color(it) }
      opacity?.let { opacity(it) }
      family?.let { family(it) }
      size?.let { size(it) }
      weight?.let { weight(it) }
      tracking?.let { tracking(it) }
      leading?.let { leading(it) }
      align?.let { align(it) }
      decoration?.let { decorate(it) }
      overflow?.let { overflow(it) }
      whitespace?.let { whitespace(it) }
      wordBreak?.let { wordBreak(it) }
      transformation?.let { transform(it) }
    }

    scope?.let { text.it() }
    text.internalScope()
  }

  override fun transition(
      property: TransitionScope.Property,
      duration: TransitionScope.Duration?,
      timingFunction: TransitionScope.TimingFunction?,
      delay: TransitionScope.Delay?
  ) {
    val transition = Transition(builder = builder, property = property, currentPrefix = prefix)
    val scope: TransitionScope.() -> Unit = {
      duration?.let { duration(it) }
      timingFunction?.let { timing(it) }
      delay?.let { delay(it) }
    }
    transition.scope()
  }

  override fun translateX(amount: Int) = affix("translate-x", amount)

  override fun translateY(amount: Int) = affix("translate-y", amount)

  override fun box(type: BoxSizing) {
    val suffix =
        when (type) {
          BoxSizing.Border -> "border"
          BoxSizing.Content -> "content"
        }

    apply("box-${suffix}")
  }

  // Floats
  override fun float(type: Float) {
    val suffix =
        when (type) {
          Float.None -> "none"
          Float.Right -> "right"
          Float.Left -> "left"
        }

    apply("float-${suffix}")
  }

  override fun clear(type: Clear) {
    val suffix =
        when (type) {
          Clear.None -> "none"
          Clear.Both -> "both"
          Clear.Right -> "right"
          Clear.Left -> "left"
        }

    apply("clear-${suffix}")
  }

  override val group: Unit
    get() = apply("group")

  private val OverflowDirection.prefix: String?
    get() =
        when (this) {
          OverflowDirection.X -> "-x"
          OverflowDirection.Y -> "-y"
          OverflowDirection.Both -> null
        }

  override fun overflow(type: OverflowType, direction: OverflowDirection) {
    val suffix =
        when (type) {
          OverflowType.Auto -> "auto"
          OverflowType.Hidden -> "hidden"
          OverflowType.Visible -> "visible"
          OverflowType.Scroll -> "scroll"
        }

    apply("overflow${direction.prefix.orEmpty()}-${suffix}")
  }

  private fun affix(type: String, distance: Distance): Unit =
      apply("${distance.prefix.orEmpty()}${type}-${distance}")

  private fun affix(type: String, distance: Int) {
    val prefix = if (distance < 0) "-" else null

    apply("${prefix.orEmpty()}${type}-${distance.absoluteValue}")
  }

  override fun inset(distance: Distance) = affix("inset", distance)

  override fun inset(distance: Int) = affix("inset", distance)

  override fun insetX(distance: Distance) = affix("inset-x", distance)

  override fun insetX(distance: Int) = affix("inset-x", distance)

  override fun insetY(distance: Distance) = affix("inset-y", distance)

  override fun insetY(distance: Int) = affix("inset-y", distance)

  override fun top(distance: Distance) = affix("top", distance)

  override fun top(distance: Int) = affix("top", distance)

  override fun right(distance: Distance) = affix("right", distance)

  override fun right(distance: Int) = affix("right", distance)

  override fun bottom(distance: Distance) = affix("bottom", distance)

  override fun bottom(distance: Int) = affix("bottom", distance)

  override fun left(distance: Distance) = affix("left", distance)

  override fun left(distance: Int) = affix("left", distance)

  override fun overscroll(type: Overscroll) {
    val suffix =
        when (type) {
          Overscroll.Auto -> "auto"
          Overscroll.Contain -> "contain"
          Overscroll.None -> "none"
        }

    apply("overscroll-${suffix}")
  }

  override fun position(type: Position) =
      when (type) {
        Position.Static -> apply("static")
        Position.Fixed -> apply("fixed")
        Position.Absolute -> apply("absolute")
        Position.Relative -> apply("relative")
        Position.Sticky -> apply("sticky")
      }

  override fun visibility(type: Visibility) =
      when (type) {
        Visibility.Visible -> apply("visible")
        Visibility.Invisible -> apply("invisible")
      }

  override fun z(index: Int?) =
      if (index != null) {
        apply("z-$index")
      } else {
        apply("z-auto")
      }

  // Spacing
  override fun p(amount: Int) = affix("p", amount)

  override fun px(amount: Int) = affix("px", amount)

  override fun py(amount: Int) = affix("py", amount)

  override fun pt(amount: Int) = affix("pt", amount)

  override fun pr(amount: Int) = affix("pr", amount)

  override fun pb(amount: Int) = affix("pb", amount)

  override fun pl(amount: Int) = affix("pl", amount)

  override fun m(amount: Int) = affix("m", amount)

  override fun m(type: Margin) = affix("m", Auto)

  override fun mx(amount: Int) = affix("mx", amount)

  override fun mx(type: Margin) = affix("mx", Auto)

  override fun my(amount: Int) = affix("my", amount)

  override fun my(type: Margin) = affix("my", Auto)

  override fun mt(amount: Int) = affix("mt", amount)

  override fun mt(type: Margin) = affix("mt", Auto)

  override fun mr(amount: Int) = affix("mr", amount)

  override fun mr(type: Margin) = affix("mr", Auto)

  override fun mb(amount: Int) = affix("mb", amount)

  override fun mb(type: Margin) = affix("mb", Auto)

  override fun ml(amount: Int) = affix("ml", amount)

  override fun ml(type: Margin) = affix("ml", Auto)

  override fun spaceX(amount: Int) = affix("space-x", amount)

  override fun spaceY(amount: Int) = affix("space-y", amount)

  // Sizing
  override fun w(distance: Int) = affix("w", distance)

  override fun w(distance: Distance) = affix("w", distance)

  override fun w(distance: Width) =
      when (distance) {
        Width.Screen -> apply("w-screen")
        Width.Min -> apply("w-min")
        Width.Max -> apply("w-max")
        Width.Auto -> apply("w-auto")
        Width.Pixel -> apply("w-px")
      }

  override fun minW(type: MinWidth) {
    val suffix =
        when (type) {
          MinWidth.Zero -> "0"
          MinWidth.Full -> "full"
          MinWidth.Min -> "min"
          MinWidth.Max -> "max"
        }
    apply("min-w-$suffix")
  }

  override fun maxW(type: MaxWidth) {
    val suffix =
        when (type) {
          MaxWidth.Zero -> "0"
          MaxWidth.None -> "none"
          MaxWidth.Xs -> "xs"
          MaxWidth.Sm -> "sm"
          MaxWidth.Md -> "md"
          MaxWidth.Lg -> "lg"
          MaxWidth.Xl -> "xl"
          MaxWidth.Xl2 -> "2xl"
          MaxWidth.Xl3 -> "3xl"
          MaxWidth.Xl4 -> "4xl"
          MaxWidth.Xl5 -> "5xl"
          MaxWidth.Xl6 -> "6xl"
          MaxWidth.Xl7 -> "7xl"
          MaxWidth.Full -> "full"
          MaxWidth.Min -> "min"
          MaxWidth.Max -> "max"
          MaxWidth.Prose -> "prose"
          MaxWidth.ScreenSm -> "screen-sm"
          MaxWidth.ScreenMd -> "screen-md"
          MaxWidth.ScreenLg -> "screen-lg"
          MaxWidth.ScreenXl -> "screen-xl"
          MaxWidth.ScreenXl2 -> "screen-2xl"
        }
    apply("max-w-$suffix")
  }

  override fun h(distance: Int) = affix("h", distance)

  override fun h(distance: Distance) = affix("h", distance)

  override fun h(distance: Height) =
      when (distance) {
        Height.Screen -> apply("h-screen")
        Height.Auto -> apply("h-auto")
        Height.Pixel -> apply("h-px")
      }

  override fun minH(type: MinHeight) {
    val suffix =
        when (type) {
          MinHeight.Zero -> "0"
          MinHeight.Full -> "full"
          MinHeight.Screen -> "screen"
        }
    apply("min-h-$suffix")
  }

  override fun maxH(distance: Int) = affix("max-h", distance)

  override fun maxH(distance: MaxHeight) {
    val suffix =
        when (distance) {
          MaxHeight.Pixel -> "px"
          MaxHeight.Full -> "full"
          MaxHeight.Screen -> "screen"
        }

    apply("max-h-$suffix")
  }

  override fun shadow(type: Shadow) {
    val suffix =
        when (type) {
          Shadow.Sm -> "-sm"
          Shadow.Normal -> ""
          Shadow.Md -> "-md"
          Shadow.Lg -> "-lg"
          Shadow.Xl -> "-xl"
          Shadow.Xl2 -> "-2xl"
          Shadow.Inner -> "-inner"
          Shadow.None -> "-none"
        }

    apply("shadow${suffix}")
  }

  override fun blur(type: Blur) {
    val suffix =
        when (type) {
          Blur.Sm -> "-sm"
          Blur.Normal -> ""
          Blur.Md -> "-md"
          Blur.Lg -> "-lg"
          Blur.Xl -> "-xl"
          Blur.Xl2 -> "-2xl"
          Blur.Xl3 -> "-3xl"
          Blur.None -> "-none"
        }

    apply("blur${suffix}")
  }

  override fun cursor(type: Cursor) {
    val suffix =
        when (type) {
          Cursor.Auto -> "auto"
          Cursor.Default -> "default"
          Cursor.Pointer -> "pointer"
          Cursor.Wait -> "wait"
          Cursor.Text -> "text"
          Cursor.Move -> "move"
          Cursor.Help -> "help"
          Cursor.NotAllowed -> "not-allowed"
        }

    apply("cursor-${suffix}")
  }

  override fun outline(type: Outline) {
    val suffix =
        when (type) {
          Outline.None -> "none"
          Outline.White -> "white"
          Outline.Black -> "black"
        }

    apply("outline-${suffix}")
  }

  override fun resize(type: Resize) {
    val suffix =
        when (type) {
          Resize.None -> "-none"
          Resize.Y -> "-y"
          Resize.X -> "-x"
          Resize.Both -> ""
        }

    apply("resize${suffix}")
  }

  override fun userSelect(type: UserSelect) {
    val suffix =
        when (type) {
          UserSelect.None -> "none"
          UserSelect.Text -> "text"
          UserSelect.All -> "all"
          UserSelect.Auto -> "auto"
        }

    apply("select-${suffix}")
  }

  override fun round(amount: Radius, side: Side) {
    val suffix =
        when (amount) {
          Radius.None -> "-none"
          Radius.Small -> "-sm"
          Radius.Normal -> null
          Radius.Medium -> "-md"
          Radius.Large -> "-lg"
          Radius.Xl -> "-xl"
          Radius.Xl2 -> "-2xl"
          Radius.Xl3 -> "-3xl"
          Radius.Full -> "-full"
        }

    apply("rounded${side.prefix.orEmpty()}${suffix.orEmpty()}")
  }

  override fun listStyle(type: ListStyle) {
    val suffix =
        when (type) {
          ListStyle.None -> "none"
          ListStyle.Disc -> "disc"
          ListStyle.Decimal -> "decimal"
        }

    apply("list-${suffix}")
  }

  override fun animate(type: Animation) {
    val suffix =
        when (type) {
          Animation.None -> "none"
          Animation.Spin -> "spin"
          Animation.Ping -> "ping"
          Animation.Pulse -> "pulse"
          Animation.Bounce -> "bounce"
        }

    apply("animate-${suffix}")
  }

  override fun opacity(opacity: Opacity) = apply(opacity.toString())

  override fun verticalAlign(type: VerticalAlignment) {
    val suffix =
        when (type) {
          VerticalAlignment.Baseline -> "baseline"
          VerticalAlignment.Top -> "top"
          VerticalAlignment.Middle -> "middle"
          VerticalAlignment.Bottom -> "bottom"
          VerticalAlignment.TextTop -> "text-top"
          VerticalAlignment.TextBottom -> "text-bottom"
        }

    apply("align-${suffix}")
  }

  override fun select(type: SelectType) {
    val suffix =
        when (type) {
          SelectType.None -> "none"
          SelectType.Text -> "text"
          SelectType.All -> "all"
          SelectType.Auto -> "auto"
        }

    apply("select-${suffix}")
  }
}
