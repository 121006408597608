package ui.components.chips.calendar

import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.runtime.setValue
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.flow.onEach
import kotlinx.coroutines.launch
import ui.components.calendar.CalendarState
import ui.components.calendar.DayRange
import ui.components.calendar.toLocalizedString
import ui.components.chips.dropdown.DropdownChipState
import ui.components.chips.dropdown.rememberDropdownChipState
import ui.strings.LocalStrings
import ui.strings.LocalizedStrings

class CalendarChipStateHolder(
    state: CalendarState,
    dropdownChipState: DropdownChipState,
    strings: LocalizedStrings,
    coroutineScope: CoroutineScope,
) : CalendarChipState, DropdownChipState by dropdownChipState {
  private var _title by mutableStateOf(state.range.value.toChipTitle(strings))

  init {
    coroutineScope.launch {
      state.range
          .onEach {
            isOpen = false
            _title = it.toChipTitle(strings)
          }
          .collect {}
    }
  }

  override val title: String
    get() = _title
}

private fun DayRange.toChipTitle(strings: LocalizedStrings): String {
  val startDay = this.start.dayOfMonth
  val endDay = this.end.dayOfMonth

  val startMonth = this.start.month.toLocalizedString(strings)
  val endMonth = this.end.month.toLocalizedString(strings)

  return if (this.start.month == this.end.month) {
    "$startDay - $endDay $endMonth"
  } else {
    "$startDay $startMonth - $endDay $endMonth"
  }
}

@Composable
fun rememberCalendarChipState(
    state: CalendarState,
    strings: LocalizedStrings = LocalStrings.current,
    coroutineScope: CoroutineScope = rememberCoroutineScope(),
): CalendarChipState {
  val dropdownState = rememberDropdownChipState()

  return remember { CalendarChipStateHolder(state, dropdownState, strings, coroutineScope) }
}
