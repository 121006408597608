package api.traak.toFirestore

import kotlinx.serialization.Serializable

@Serializable
data class ValidateTokensData(
    val teamId: String,
    val authorizationCode: String,
    val redirectUri: String,
)
