package ui.layouts

import androidx.compose.runtime.Composable
import org.jetbrains.compose.web.dom.AttrBuilderContext
import org.jetbrains.compose.web.dom.Div
import org.w3c.dom.HTMLDivElement
import tailwind.Auto
import tailwind.tailwind

@Composable
fun CenteredLayout(
    attrs: AttrBuilderContext<HTMLDivElement>? = null,
    content: @Composable () -> Unit,
) =
    Div(
        attrs = {
          attrs?.invoke(this)
          tailwind { m(Auto) }
        },
    ) {
      content()
    }
