package api.traak

import api.traak.user.User
import ui.strings.LocalizedStrings

/** Order matters. An admin is a project manager, who also is a member */
enum class Role {
  REMOVED,
  MEMBER,
  PROJECT_MANAGER,
  ADMIN;

  fun toLocalizedString(strings: LocalizedStrings): String =
      when (this) {
        ADMIN -> strings.teamSettingsRoleAdmin
        PROJECT_MANAGER -> strings.teamSettingsRoleProjectManager
        MEMBER -> strings.teamSettingsRoleMember
        REMOVED -> strings.teamSettingsRoleRemoved
      }
}

/** Represents a member of a [Team] */
interface Member : Task.Author {
  /** The first name of the user who performed the task */
  override val firstName: String

  /** The last name of the user who performed the task */
  override val lastName: String

  /** The members' unique ID. */
  override val id: User.Id

  /** The role of the user in his given team */
  val role: Role

  /**
   * Changes the role of the member inside his team
   *
   * @param to The new role to change to
   */
  suspend fun changeRole(to: Role)
}

class TraakMember(
    private val api: TraakApi,
    private val team: Team,
    override val firstName: String,
    override val lastName: String,
    override val id: User.Id,
    override var role: Role,
) : Member {
  override suspend fun changeRole(to: Role) {
    api.changeMemberRole(
        team = team,
        userId = id,
        newRole = to,
    )
  }
}
