package ui.screens.account

import androidx.compose.runtime.Composable
import org.jetbrains.compose.web.dom.P
import org.jetbrains.compose.web.dom.Text
import tailwind.FlexItemScope
import tailwind.Layout
import tailwind.TextScope
import tailwind.color.Opacity080
import tailwind.fourth
import tailwind.tailwind
import ui.color.BlackDark
import ui.color.BlackMedium
import ui.material.FullColumn
import ui.material.FullRow

@Composable
fun SettingsItem(
    title: String,
    explanation: String? = null,
    interaction: @Composable () -> Unit,
) {
  FullRow(
      justify = Layout.Justify.Start,
      alignItems = Layout.AlignItems.Start,
  ) {
    FullColumn(
        justify = Layout.Justify.Start,
        alignItems = Layout.AlignItems.Start,
        gap = 0,
        attrs = { tailwind { flexItem(grow = FlexItemScope.Grow.Grow) } },
    ) {
      P(
          attrs = { tailwind { text(color = BlackDark) { body1() } } },
      ) {
        Text(title)
      }

      if (explanation != null) {

        P(
            attrs = {
              tailwind {
                w(3.fourth)
                text(
                    color = BlackMedium,
                    opacity = Opacity080,
                ) {
                  subtitle2(size = TextScope.Size.Base)
                }
              }
            },
        ) {
          Text(explanation)
        }
      }
    }

    interaction()
  }
}
