package ui.screens.teamManagement

import androidx.compose.runtime.*
import api.traak.Team
import kotlinx.browser.window
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Job
import kotlinx.coroutines.delay
import kotlinx.coroutines.launch

class InvitationStateHolder(
    teamId: Team.Id,
    private val tooltipCoroutineScope: CoroutineScope,
) : InvitationState {
  private val tooltipDurationMillis = 800L
  private var tooltipJob: Job? = null

  private var _showCopyTooltip by mutableStateOf(false)

  override val joinLink = teamId.raw

  override val showCopyTooltip: Boolean
    get() = _showCopyTooltip

  override fun onCopyClick() {
    tooltipJob?.cancel()

    window.navigator.clipboard.writeText(joinLink)
    _showCopyTooltip = true

    tooltipJob =
        tooltipCoroutineScope.launch {
          delay(timeMillis = tooltipDurationMillis)
          _showCopyTooltip = false
        }
  }
}

@Composable
fun rememberInvitationState(
    teamId: Team.Id,
    coroutineScope: CoroutineScope = rememberCoroutineScope(),
): InvitationState {
  return remember {
    InvitationStateHolder(
        teamId,
        coroutineScope,
    )
  }
}
