package api.traak.toFirestore

import api.traak.Role
import api.traak.Team
import api.traak.user.User
import kotlinx.serialization.Serializable

@Serializable
data class Roles(
    val admin: List<String>,
    val manager: List<String>,
    val member: List<String>,
    val removed: List<String>,
) {

  fun stripUser(
      userId: User.Id,
  ): Roles {
    val admin = this.admin.filter { it != userId.raw }
    val manager = this.manager.filter { it != userId.raw }
    val member = this.member.filter { it != userId.raw }
    val removed = this.removed.filter { it != userId.raw }

    return Roles(
        admin,
        manager,
        member,
        removed,
    )
  }

  fun giveRoleTo(
      userId: User.Id,
      newRole: Role,
  ): Roles {
    val admin = updateListIf(newRole >= Role.ADMIN, this.admin, userId.raw)
    val manager = updateListIf(newRole >= Role.PROJECT_MANAGER, this.manager, userId.raw)
    val member = updateListIf(newRole >= Role.MEMBER, this.member, userId.raw)
    val removed = updateListIf(newRole == Role.REMOVED, this.removed, userId.raw)

    return Roles(
        admin,
        manager,
        member,
        removed,
    )
  }

  companion object {
    private fun <T> updateListIf(
        condition: Boolean,
        list: List<T>,
        item: T,
    ): List<T> =
        if (condition) {
          if (list.contains(item)) {
            list
          } else {
            val copy = list.toMutableList()
            copy.add(item)
            copy.toList()
          }
        } else {
          list
        }

    fun initializeWith(teamRoles: Team.Roles): Roles =
        Roles(
            admin = teamRoles.admin.map { it.raw },
            manager = teamRoles.manager.map { it.raw },
            member = teamRoles.member.map { it.raw },
            removed = teamRoles.removed.map { it.raw },
        )
  }
}
